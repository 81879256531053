import * as React from "react";

// Default context values
const values = {
  uploadedFiles: [],
  setUploadedFiles: () => {},
  handleErrorMsg: () => {},
};

const WidgetBodyContext = React.createContext(values);
export default WidgetBodyContext;
