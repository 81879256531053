const selectOption = [
  {
    options: [
      { value: "2020", label: "2020" },
      { value: "2021", label: "2021" },
      { value: "2022", label: "2022" },
      { value: "2023", label: "2023" },
      { value: "2024", label: "2024" },
      { value: "2025", label: "2025" },
      { value: "2026", label: "2026" },
    ],
  },
];

const fields = [
  { label: "fileUploads", order: 1, type: "file" },
  {
    label: "email",
    placeholder: "Email",
    order: 2,
    type: "email",
    isRequired: true,
  },
  {
    label: "firstName",
    placeholder: "First name",
    order: 3,
    type: "text",
    isRequired: true,
  },
  {
    label: "lastName",
    placeholder: "Last name",
    order: 4,
    type: "text",
    isRequired: true,
  },
  {
    label: "instagramUsername",
    placeholder: "Instagram username",
    order: 5,
    type: "text",
    isSocialMedia: true,
    isRequired: true,
  },
  {
    label: "tiktokUsername",
    placeholder: "Tiktok username",
    order: 5,
    type: "text",
    isSocialMedia: true,
    isRequired: false,
  },
  {
    label: "yearAtTU",
    placeholder: "Year at TU",
    order: 6,
    type: "select",
    selectOption: selectOption,
  },
];

export default fields;
