import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { KEYBOARD, TYPE_TERM_CONDITION } from "../../../constants/common";
import utils from "../../../services/utils";
import {
  StyledCheckbox,
  StyledLink,
  StyledRequiredCheckbox,
  StyledTooltip,
} from "../../../styled-components/WidgetBuilder/WidgetBuilderStyled";

const CheckboxItem = (props) => {
  const {
    data,
    handleTerms,
    isMobile,
    highlight,
    setActiveTerms,
    isNotChecked,
  } = props;
  const { isTermsMandated, displayType, agreementMessage, hyperlinkText } =
    data;
  let { hyperlinkUrl } = data;

  const checked = !!data.checked;
  const textTerm = displayType === TYPE_TERM_CONDITION.text;
  const isNotAgreedTerm =
    data.isMustView &&
    !data.isAgreed &&
    data.displayType === TYPE_TERM_CONDITION.popupText;
  const checkboxRef = useRef();

  useEffect(() => {
    if (data.isFocusing) {
      if (checkboxRef.current) {
        checkboxRef.current.focus();
      }
    }
  }, [data, checkboxRef]);

  if (displayType === TYPE_TERM_CONDITION.link) {
    if (hyperlinkUrl && !hyperlinkUrl.includes("https")) {
      hyperlinkUrl = `https://${hyperlinkUrl}`;
    }

    return (
      <StyledCheckbox
        ref={checkboxRef}
        checked={checked}
        onChange={(e) => handleTerms(e.target.checked, data)}
        isMobile={isMobile}
        color={highlight}
        onKeyPress={(e) =>
          utils.onKeyDown(e, handleTerms(!checked, data), KEYBOARD.ENTER.STR)
        }
      >
        {`${agreementMessage} `}
        <StyledLink
          target="_blank"
          rel="noopener noreferrer"
          href={hyperlinkUrl}
          color={highlight}
          required={isTermsMandated}
          fontSize={isMobile ? "15px" : "14px"}
        >
          {hyperlinkText}
        </StyledLink>
      </StyledCheckbox>
    );
  }

  return (
    <StyledCheckbox
      ref={checkboxRef}
      checked={checked}
      onChange={(e) => handleTerms(e.target.checked, data)}
      isMobile={isMobile}
      color={highlight}
      className={`checkbox_custom ${textTerm ? "overflow_text" : ""}`}
      disabled={isNotAgreedTerm}
      onKeyPress={(e) =>
        utils.onKeyDown(e, handleTerms(!checked, data), KEYBOARD.ENTER.STR)
      }
    >
      <StyledTooltip
        className={textTerm ? "tooltip_text" : ""}
        title={textTerm ? agreementMessage : ""}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        trigger={["hover", "click"]}
      >
        <span
          className={`text_term ${
            displayType === TYPE_TERM_CONDITION.text
              ? "text_term_truncation"
              : ""
          }`}
        >{`${agreementMessage} `}</span>
        <StyledLink
          tabIndex={hyperlinkText ? 0 : -1}
          rel="noopener noreferrer"
          className={isNotChecked ? "highlight" : ""}
          onKeyPress={(e) =>
            utils.onKeyDown(
              e,
              () => {
                setActiveTerms(data);
              },
              KEYBOARD.ENTER.STR
            )
          }
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            setActiveTerms(data);
          }}
          color={highlight}
        >
          {hyperlinkText}
        </StyledLink>
        {isTermsMandated && (
          <StyledRequiredCheckbox color={highlight}>*</StyledRequiredCheckbox>
        )}
      </StyledTooltip>
    </StyledCheckbox>
  );
};

CheckboxItem.propTypes = {
  data: PropTypes.object,
  res: PropTypes.object,
  handleTerms: PropTypes.func,
  isMobile: PropTypes.bool,
  highlight: PropTypes.string,
  setActiveTerms: PropTypes.func,
  colorTooltip: PropTypes.string,
  isNotChecked: PropTypes.bool,
  isNotAgreed: PropTypes.bool,
};

export default CheckboxItem;
