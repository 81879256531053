import React from "react";
import PropTypes from "prop-types";
import {
  StyledFlex,
  StyledIcon,
  StyledModal,
  StyledText,
} from "../../../styled-components/Common/CommonStyled";
import { FAILED, URL, WIDGET_STATUS } from "../../../constants/common";
import { Trans, useTranslation } from "react-i18next";
import { IconMinusCircle } from "../../../assets/img/icons";
import { StyledLink } from "../../../styled-components/WidgetBuilder/WidgetBuilderStyled";
import THEME from "../../../constants/themes";
import { useSelector } from "react-redux";

const WidgetStatus = (props) => {
  const { t } = useTranslation();
  const { widgetInfo, isMobile } = props;
  const { widgetSettingsDTO: { status } = {} } = widgetInfo || {};
  const { validateCampaignStatus } = useSelector((state) => state.widgets);

  const renderWidgetStatus = () => {
    switch (true) {
      case status === WIDGET_STATUS.DISABLED ||
        validateCampaignStatus === FAILED:
        return {
          content: t("widget_builder.widget_status.disabled"),
        };

      default:
        return {
          content: t("widget_builder.widget_status.closed"),
        };
    }
  };

  const modalProps = {
    visible:
      status !== WIDGET_STATUS.ACTIVE || validateCampaignStatus === FAILED,
    footer: false,
    closable: false,
  };

  const propsFQAs = {
    i18nKey: "widget_builder.widget_status.FAQs",
    components: {
      linkTo: (
        <StyledLink
          fontSize={isMobile ? "16px" : "18px"}
          target="_blank"
          color={THEME.colors.pinkBase}
          href={URL.UPLOADER_SUPPORT}
        />
      ),
    },
  };

  return (
    <StyledModal
      {...modalProps}
      centered
      width={384}
      maskStyle={{ backgroundColor: THEME.maskOverlay }}
    >
      <StyledFlex justify="center">
        <StyledIcon margin="0 8px 0 0">
          <IconMinusCircle />
        </StyledIcon>
        <StyledText fontSize={isMobile ? "28px" : "34px"} fontWeight="bold">
          {t("widget_builder.widget_status.sorry")}
        </StyledText>
      </StyledFlex>
      <StyledText
        margin="10px 0"
        align="center"
        fontSize={isMobile ? "16px" : "18px"}
      >
        {renderWidgetStatus().content}
      </StyledText>
      <StyledText
        margin="0 0 8px 0"
        align="center"
        fontSize={isMobile ? "16px" : "18px"}
      >
        <Trans t={t} {...propsFQAs} />
      </StyledText>
    </StyledModal>
  );
};

WidgetStatus.propTypes = {
  widgetInfo: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default WidgetStatus;
