import { Modal } from "antd";
import styled from "styled-components";
import { StyledIcon } from "./Common/CommonStyled";
import { PREFIX_CLS } from "constants/common";

const StyledUploadStatusListWrapper = styled.div`
  margin: ${({ margin }) => margin || "6.75rem 0 0 0"};
  max-height: 150px;
  overflow: auto scroll;
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px`};
`;

const StyledGoogleTranslateTP = styled.div`
  position: relative;
  margin: 15px 0 32px 0;

  ${StyledIcon} {
    position: absolute;
    right: 6px;
    top: 12px;
  }

  .goog-te-menu2-item {
    padding: 5px 10px; /* Adjust padding as needed */
  }

  .goog-te-gadget .goog-te-combo {
    font-family: "Product Sans", "Montserrat", "Arial", "sans-serif" !important;
    padding: 0 10px 0 10px;
    width: 100%;
    height: 38px;
    border: none;
    background-color: hsl(0, 0%, 100%);
    color: #495057;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-size: 14px !important;

    option {
      color: #495057;
      font-size: 14px !important;
      background-color: #fff;
    }

    &:hover {
      border-color: #40a9ff;
      border-right-width: 1px;
    }

    &:focus {
      border-color: #2684ff;
      box-shadow: 0 0 0 1px #2684ff;
      border-right-width: 1px;
      outline: 0;
      border-radius: 4px;
    }

    &:focus-visible {
      outline: none;
    }
  }

  .goog-logo-link {
    font-weight: 600 !important;

    &:focus-visible {
      font-weight: bold !important;
    }
  }
`;

const StyledTermsModal = styled(Modal)`
  width: 800px !important;

  .${PREFIX_CLS}-modal-content {
    border-radius: 4px;

    .${PREFIX_CLS}-modal-close-x {
      margin: 6px 0 0 0;

      svg {
        width: 14px;
        height: 14px;
      }
    }

    .${PREFIX_CLS}-modal-header {
      border-radius: 4px;
      padding: 20px;

      .${PREFIX_CLS}-modal-title {
        h4 {
          font-weight: bold;
          text-align: center;
          margin: 0;
          padding: 0;
        }
      }
    }

    .${PREFIX_CLS}-modal-body {
      padding: 30px 60px 40px;
      max-height: 80vh;
      overflow: auto;
    }
  }

  @media only screen and (max-width: 550px) {
    .${PREFIX_CLS}-modal-content {
      .${PREFIX_CLS}-modal-header {
        .${PREFIX_CLS}-modal-title {
          h4 {
            width: 95%;
          }
        }
      }

      .${PREFIX_CLS}-modal-body {
        padding: 20px 50px 30px 50px;
      }
    }
  }

  @media only screen and (max-width: 430px) {
    .${PREFIX_CLS}-modal-content {
      .${PREFIX_CLS}-modal-close-x {
        margin: 0 -6px 0 0;
      }

      .${PREFIX_CLS}-modal-header {
        padding: 15px;

        .${PREFIX_CLS}-modal-title {
          h4 {
            font-size: 18.5px;
          }
        }
      }

      .${PREFIX_CLS}-modal-body {
        padding: 20px;
      }
    }
  }

  @media only screen and (max-width: 320px) {
    .${PREFIX_CLS}-modal-content {
      .${PREFIX_CLS}-modal-close-x {
        margin: 0 -10px 0 0;
      }
    }
  }
`;

export {
  StyledGoogleTranslateTP,
  StyledTermsModal,
  StyledUploadStatusListWrapper,
};
