import { Col } from "antd";
import {
  IconCheckCircle,
  IconError,
  IconGoogleMediaPlaceholder,
  IconUploadDragger,
  IconXCircle,
} from "assets/img/icons";
import ScrollableWrapper from "components/shared/ScrollableWrapper";
import GoogleDrive from "components/widgets/WidgetBuilder/GoogleDrive";
import {
  CLASS_NAME,
  MEDIA_TYPE,
  SUPPORTED_FILE,
  UPLOADER_TYPE,
} from "constants/common";
import THEME from "constants/themes";
import WidgetBodyContext from "contexts/WidgetBody";
import WidgetBuilderContext from "contexts/WidgetBuilder";
import _, { isUndefined } from "lodash";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import utils from "services/utils";
import { generateThumbnailList } from "services/widgetBuilder";
import {
  StyledFlex,
  StyledIcon,
  StyledText,
} from "styled-components/Common/CommonStyled";
import { StyledTooltip } from "styled-components/WidgetBuilder/WidgetBuilderStyled";
import { StyledThumbnail } from "styled-components/WidgetBuilder/WidgetTikTokStyled";
import {
  StyledDropzone,
  StyledGoogleFileItem,
  StyledHelperTextOnDropzone,
  StyledSourceIcon,
  StyledSourceIconContainer,
  StyledWrapperUpload,
} from "styled-components/WidgetBuilder/WidgetUploadSourceStyled";
import TikTok from "./TikTok/TikTok";
import YouTube from "./YouTube/YouTube";
import { useTranslation } from "react-i18next";
import Instagram from "./Instagram/Instagram";

const WidgetUploadSource = (props) => {
  const { t } = useTranslation();
  const {
    widgetInfo,
    selectedVideoList,
    setSelectedVideoList,
    setActiveUploaderType,
    activeUploaderType,
    googleDrive,
    setGoogleDrive,
    setIsShowLoading,
  } = useContext(WidgetBuilderContext);
  const { uploadedFiles, setUploadedFiles } = useContext(WidgetBodyContext);
  const { onDrop } = props;
  const { getRootProps } = useDropzone({
    noClick: true,
    onDrop,
    accept: SUPPORTED_FILE.ACCEPTED,
  });

  const { metadata } = widgetInfo;
  const { colors } = metadata;

  const [thumbnailSrcList, setThumbnailSrcList] = useState([]);
  const timelineLoaded = useRef(false);

  const {
    colorUploadIcon = THEME.colors.orangeBase,
    enableSharing = false,
    enableSharingGoogleDrive = false,
    enableSharingYouTube = false,
    enableSharingInstagram = false,
    bgFileDropZone,
  } = colors;
  const { header: { dropzoneText } = {} } = metadata || {};

  useEffect(() => {
    if (uploadedFiles.length && !timelineLoaded.current) {
      handleThumbnailSrcList();
    }
    return () => {
      if (!uploadedFiles.length) {
        timelineLoaded.current = false;
      }
    };
  }, [uploadedFiles]);

  const handleThumbnailSrcList = async () => {
    setIsShowLoading(true);
    let convertedList = generateThumbnailList(uploadedFiles);
    convertedList = await Promise.all(convertedList).finally(() => {
      setIsShowLoading(false);
    });
    setThumbnailSrcList(convertedList);
    timelineLoaded.current = true;
  };

  const handleStandardUpload = () => {
    const el = document.getElementById("dropzone-upload");
    if (el) {
      el.click();
      setActiveUploaderType(UPLOADER_TYPE.UPLOAD);
    }
  };

  const renderUploadType = () => {
    const iconProps = {
      size: 32,
    };

    const iconList = [
      {
        icon: <IconUploadDragger className="standard-upload-icon" />,
        isShown: true,
        otherProps: { colorPath: colorUploadIcon },
        type: UPLOADER_TYPE.UPLOAD,
        onClick: handleStandardUpload,
        onKeyDown: (e) => utils.onKeyDown(e, handleStandardUpload),
      },
      {
        icon: <GoogleDrive />,
        isShown: enableSharingGoogleDrive,
        type: UPLOADER_TYPE.GOOGLE_DRIVE,
      },
      {
        icon: <Instagram />,
        isShown: enableSharingInstagram,
        type: UPLOADER_TYPE.INSTAGRAM,
      },
      {
        icon: <YouTube />,
        isShown: enableSharingYouTube,
        type: UPLOADER_TYPE.YOUTUBE,
      },
      {
        icon: <TikTok />,
        isShown: enableSharing,
        type: UPLOADER_TYPE.TIKTOK,
      },
    ];

    return iconList.map((item) => {
      return (
        item?.isShown &&
        (item?.type === UPLOADER_TYPE.UPLOAD ? (
          <StyledSourceIcon
            tabIndex={0}
            onClick={item?.onClick}
            onKeyDown={item?.onKeyDown}
            cursor="pointer"
            {...iconProps}
            {...item?.otherProps}
          >
            {item.icon}
          </StyledSourceIcon>
        ) : (
          <StyledFlex key={Math.random()} align="center">
            {item?.icon}
          </StyledFlex>
        ))
      );
    });
  };

  const filterList = (list, index) =>
    list.filter((_, fileIndex) => fileIndex !== index);

  const removeFile = (index) => {
    const filteredList = filterList(thumbnailSrcList, index);
    const uploadedFilesTemp = filterList(uploadedFiles, index);
    setThumbnailSrcList(filteredList);
    setUploadedFiles(uploadedFilesTemp);
  };

  const removeSelectedTikTokVideo = (video) => {
    const filteredList = selectedVideoList.filter(
      (item) => item.id !== video.id
    );
    setSelectedVideoList(filteredList);
  };

  const removeSelectedGoogleFile = (file) => {
    const filteredList = googleDrive?.selectedMediaList.filter(
      (item) => item.fileId !== file.fileId
    );
    setGoogleDrive({ ...googleDrive, selectedMediaList: filteredList });
  };

  const renderThumbnailItem = (src, actionRemove) => {
    return (
      <>
        <StyledIcon
          tabIndex={0}
          size={16}
          cursor="pointer"
          onClick={actionRemove}
          onKeyDown={(e) => utils.onKeyDown(e, actionRemove)}
        >
          <IconXCircle />
        </StyledIcon>
        <img
          src={src}
          onError={(e) => utils.handleThumbnailLoadedError(e, MEDIA_TYPE.IMAGE)}
        />
      </>
    );
  };

  const renderContentThumbnails = () => {
    return (
      !!thumbnailSrcList?.length &&
      thumbnailSrcList?.map((src, index) => {
        return (
          <StyledThumbnail key={index} subtractedValue={10}>
            {renderThumbnailItem(src, () => removeFile(index))}
          </StyledThumbnail>
        );
      })
    );
  };

  const renderSocialThumbnails = () => {
    return (
      !!selectedVideoList.length &&
      selectedVideoList.map((item, index) => {
        return (
          <StyledThumbnail key={index} subtractedValue={10}>
            {renderThumbnailItem(
              item?.cover_image_url || item?.thumbnailUrl || item?.mediaUrl,
              () => removeSelectedTikTokVideo(item)
            )}
          </StyledThumbnail>
        );
      })
    );
  };

  const renderGoogleDriveThumbnail = () => {
    return googleDrive?.selectedMediaList.map((item) => {
      const childElement = (
        <StyledGoogleFileItem align="middle" key={item.fileId}>
          <Col span={2}>
            <StyledFlex align="center">
              <img src={IconGoogleMediaPlaceholder} alt="icon-placeholder" />
            </StyledFlex>
          </Col>
          <Col span={20}>
            <StyledText fontSize="15px" padding="0 6px 0 0">
              {item.fileName}
            </StyledText>
          </Col>
          <Col span={2}>
            {_.isEmpty(item?.limitationError) && (
              <StyledIcon
                size={19}
                className="icon-checked"
                colorPath={THEME.colors.greenBase}
              >
                <IconCheckCircle />
              </StyledIcon>
            )}
            {!_.isEmpty(item?.limitationError) && (
              <StyledIcon
                colorPath={THEME.colors.redBase}
                size={18}
                className="icon-checked"
              >
                <IconError />
              </StyledIcon>
            )}
            <StyledIcon
              size={18}
              onClick={() => removeSelectedGoogleFile(item)}
              className="icon-remove"
              cursor="pointer"
            >
              <IconXCircle />
            </StyledIcon>
          </Col>
        </StyledGoogleFileItem>
      );

      return !_.isEmpty(item?.limitationError) ? (
        <StyledTooltip
          key={item.fileId}
          placement="topRight"
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          title={
            <div
              dangerouslySetInnerHTML={{
                __html: item?.limitationError?.msg || "",
              }}
            />
          }
          trigger={["hover", "focus"]}
        >
          {childElement}
        </StyledTooltip>
      ) : (
        childElement
      );
    });
  };

  const renderThumbnails = () => {
    switch (true) {
      case activeUploaderType === UPLOADER_TYPE.GOOGLE_DRIVE:
        return renderGoogleDriveThumbnail();

      case activeUploaderType === UPLOADER_TYPE.TIKTOK ||
        activeUploaderType === UPLOADER_TYPE.INSTAGRAM ||
        activeUploaderType === UPLOADER_TYPE.YOUTUBE:
        return renderSocialThumbnails();

      default:
        return renderContentThumbnails();
    }
  };

  const showThumbnails = useMemo(
    () =>
      !!uploadedFiles.length ||
      !!selectedVideoList.length ||
      !!googleDrive?.selectedMediaList.length,
    [uploadedFiles, selectedVideoList, googleDrive]
  );

  const helperDropzoneText = useMemo(() => {
    return isUndefined(dropzoneText)
      ? t("widget_builder.default_helper_text")
      : dropzoneText;
  }, [dropzoneText]);

  return (
    <>
      {!showThumbnails && (
        <StyledWrapperUpload className="dynamic-widget" tabIndex={-1}>
          <StyledSourceIconContainer>
            {renderUploadType()}
          </StyledSourceIconContainer>
          <StyledHelperTextOnDropzone
            title={helperDropzoneText}
            color={THEME.colors.darkBlue4}
          >
            {helperDropzoneText}
          </StyledHelperTextOnDropzone>
          <StyledDropzone
            {...getRootProps({
              tabIndex: -1,
            })}
          />
        </StyledWrapperUpload>
      )}
      {showThumbnails && (
        <ScrollableWrapper
          className={CLASS_NAME.THUMBNAIL_WRAPPER}
          bgColor={bgFileDropZone || "#f5f9fd"}
          border="1px dashed #d9e4ef"
          padding="10px"
          overflow={
            activeUploaderType === UPLOADER_TYPE.GOOGLE_DRIVE
              ? "auto"
              : "auto hidden"
          }
        >
          {renderThumbnails()}
        </ScrollableWrapper>
      )}
    </>
  );
};

export default React.memo(WidgetUploadSource);
