import { HEIGHT_DRAG_AND_DROP } from "constants/common";
import THEME from "constants/themes";
import React, { useEffect } from "react";
import styled from "styled-components";

const StyledWrapper = styled.div`
  ${({ display }) => `display: ${display}`};
  align-items: center;
  justify-content: center;
  position: relative;
  height: ${({ height }) => height || HEIGHT_DRAG_AND_DROP.desktop}px;
  ${({ cursor }) => `cursor: ${cursor}`};
  ${({ border }) => `border: ${border}`};
  ${({ bgColor }) => `background-color: ${bgColor}`};
  ${({ padding }) => `padding: ${padding}`};
  ${({ overflow }) => `overflow: ${overflow}`};
  white-space: nowrap;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${THEME.colors.gray3};
    border-radius: 20px;
    visibility: hidden;
  }

  &:hover {
    &::-webkit-scrollbar {
      opacity: 1;
    }
    &::-webkit-scrollbar-thumb {
      visibility: unset;
    }
  }
`;

const ScrollableWrapper = (props) => {
  const { children, wrapperWidth, className, ...otherProps } = props;

  useEffect(() => {
    let mouseDown = false;
    let startX, scrollLeft;
    const slider = document.querySelector(`.${className}`);

    if (slider) {
      const startDragging = (e) => {
        mouseDown = true;
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      };

      const stopDragging = (e) => {
        mouseDown = false;
      };

      const move = (e) => {
        e.preventDefault();
        if (!mouseDown) {
          return;
        }
        const x = e.pageX - slider.offsetLeft;
        const scroll = x - startX;
        slider.scrollLeft = scrollLeft - scroll;
      };

      slider.addEventListener("mousemove", move, false);
      slider.addEventListener("mousedown", startDragging, false);
      slider.addEventListener("mouseup", stopDragging, false);
      slider.addEventListener("mouseleave", stopDragging, false);

      return () => {
        slider.removeEventListener("mousemove", move, false);
        slider.removeEventListener("mousedown", startDragging, false);
        slider.removeEventListener("mouseup", stopDragging, false);
        slider.removeEventListener("mouseleave", stopDragging, false);
      };
    }
  }, [children]);

  return (
    <StyledWrapper className={className} width={wrapperWidth} {...otherProps}>
      {children}
    </StyledWrapper>
  );
};

export default React.memo(ScrollableWrapper);
