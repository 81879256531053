import { APIServer } from "../config";

// StatusCode
const STATUS_CODE = {
  SUCCESS: 200,
  FAILED403: 403,
  FAILED404: 404,
  FAILED400: 400,
  FAILED401: 401,
  FAILED504: 504,
};

const UPLOAD_CONTENT = `${APIServer}/widget/upload-content`;
const CREATE_CONTENT = `${APIServer}/content/public/create-content`;
const SEND_EMAIL = `${APIServer}/widget/send-mail`;
const SEND_SLACK = `${APIServer}/widget/fail/send-slack`;
const SEND_SLACK_SUCCESSFUL_UPLOAD = `${APIServer}/widget/success/send-slack`;
const PUBLIC_CLIENT = `${APIServer}/common/public/client`;
const CLIENT_SETTING = `${PUBLIC_CLIENT}/settings`;
const UPLOAD_GOOGLE_DRIVE_CONTENT = `${APIServer}/widget/google-drive/content`;
const UPLOAD_YOUTUBE_CONTENT = `${APIServer}/widget/youtube/content`;
const UPLOAD_INSTAGRAM_CONTENT = `${APIServer}/widget/instagram/content`;
const CAPTURATE_CONTENT = `${APIServer}/widget/capturate-content`;
const GET_GEOLOCATION = `${APIServer}/widget/location`;
const GET_TERMS = `${APIServer}/notification/public/terms-manager/terms-template`;

// Google
const GOOGLE_OAUTH_TOKEN_API = "https://oauth2.googleapis.com/token";
const GOOGLE_YOUTUBE_API = "https://www.googleapis.com/youtube/v3";

// Instagram
const OAUTH = "https://api.instagram.com/oauth";
const AUTHORIZE_INS = `${OAUTH}/authorize`;
const GET_ACCESS_TOKEN_INS = `${APIServer}/widget/instagram/oauth/access-token`;
const GET_MEDIA_INS = "https://graph.instagram.com/me/media";

export {
  STATUS_CODE,
  UPLOAD_CONTENT,
  CREATE_CONTENT,
  SEND_EMAIL,
  SEND_SLACK,
  SEND_SLACK_SUCCESSFUL_UPLOAD,
  CLIENT_SETTING,
  PUBLIC_CLIENT,
  UPLOAD_GOOGLE_DRIVE_CONTENT,
  CAPTURATE_CONTENT,
  GET_GEOLOCATION,
  GOOGLE_OAUTH_TOKEN_API,
  GOOGLE_YOUTUBE_API,
  UPLOAD_YOUTUBE_CONTENT,
  GET_TERMS,
  AUTHORIZE_INS,
  GET_ACCESS_TOKEN_INS,
  GET_MEDIA_INS,
  UPLOAD_INSTAGRAM_CONTENT,
};
