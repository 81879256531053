const getBrowserGeolocation = (successCallback, failedCallback) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        if (successCallback) {
          successCallback({ latitude, longitude });
        }
      },
      (error) => {
        if (failedCallback) {
          failedCallback();
        }
        console.log("Location error: ", error);
      }
    );
  } else {
    console.log("Geolocation is not supported by this browser.");
  }
};

export { getBrowserGeolocation };
