import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import configUrls from "../../../constants/configUrls";
import utils from "../../../services/utils";
import errorMsgs from "../../../constants/errorMsgs";

import { orderBy } from "lodash";

const BaseFormik = ({
  fields = [],
  renderField = () => {},
  clearMessage = () => {},
  setGeneralError = () => {},
  onSubmit = async () => {},
  generalMessage: { type = "", message = "" } = {},
  logo,
  uploadedFiles = [],
  isNewForm = false,
  isNewFormResponsive = false,
  isFromMasterSpa = false,
  isFromCirclekus = false,
  clientName = "",
  onAdditonalTermsClick = () => {},
}) => {
  const [isAgreed, setIsAgreed] = useState(false);
  const [isCirclekusAgreed, setIsCirclekusAgreed] = useState(false);
  const [isOver21, setIsOver21] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const generalMessageClassName =
    type === "error" ? "general-message error" : "general-message success";
  const GRADUATION_YEAR = "graduationYear";
  let initialValues = {};
  let validationSchema = {};
  const checkLegalClients = ["fireballWhisky", "buffaloTrace"];
  const checkOver21Client = ["spaGirlCocktails"];
  const eitherSocialAccount = ["reeses"];

  useEffect(() => {
    if (eitherSocialAccount.includes(clientName)) {
      handleDynamicRequiredSocialInput();
    }
  });

  fields.forEach((item) => {
    const {
      label = "",
      placeholder = "",
      isRequired = false,
      isCheckSpecialCharacter = false,
      isCheckUrlLink = false,
      requireTdext = "",
    } = item;
    const { string = () => {} } = Yup;
    const { specialCharacter, specialCharacterWithSlash, validYear } = utils;
    const value = string().trim();
    const placeHolderText = requireTdext ? requireTdext : placeholder;
    initialValues[label] = "";

    if (label === "email") {
      validationSchema[label] = value
        .email("(!) Invalid email address")
        .required("(!) Email is required");
    } else {
      if (
        isRequired &&
        eitherSocialAccount.includes(clientName) &&
        (label === "instagramUsername" || label === "tiktokUsername")
      ) {
        validationSchema[label] = value.required(
          `(!) Instagram or TikTok username is required`
        );
      } else if (isRequired) {
        validationSchema[label] = value.required(
          `(!) ${placeHolderText} is required`
        );
      }

      if (label === GRADUATION_YEAR) {
        if (isRequired) {
          validationSchema[label] = value
            .matches(validYear, `${placeHolderText} is not valid`)
            .required(`(!) ${placeHolderText} is required`);
        } else {
          validationSchema[label] = value.matches(
            validYear,
            `${placeHolderText} is not valid`
          );
        }
      }

      if (isCheckSpecialCharacter) {
        if (isRequired) {
          validationSchema[label] = value
            .matches(
              specialCharacter,
              `${placeHolderText} only allow characters such as _ .`
            )
            .required(`(!) ${placeHolderText} is required`);
        } else {
          validationSchema[label] = value.matches(
            specialCharacter,
            `${placeHolderText} only allow characters such as _ .`
          );
        }
      }

      if (isCheckUrlLink) {
        if (isRequired) {
          validationSchema[label] = value
            .matches(
              specialCharacterWithSlash,
              `${placeHolderText} only allow characters such as - . /`
            )
            .required(`(!) ${placeHolderText} is required`);
        } else {
          validationSchema[label] = value.matches(
            specialCharacterWithSlash,
            `${placeHolderText} only allow characters such as - . /`
          );
        }
      }
    }
  });

  const {
    values,
    getFieldProps,
    setValues,
    resetForm,
    touched,
    errors,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object().shape(validationSchema),
    onSubmit: (values) => {
      setValues({ ...values });
      onSubmit(values, resetForm, setIsAgreed);
    },
  });

  const handleDynamicRequiredSocialInput = () => {
    const instagramIndex = findIndex("instagramUsername");
    const tiktokIndex = findIndex("tiktokUsername");
    if (values.instagramUsername === "" && values.tiktokUsername === "") {
      fields[instagramIndex].isRequired = true;
      fields[tiktokIndex].isRequired = true;
    }
    if (values.instagramUsername !== "") {
      fields[tiktokIndex].isRequired = false;
    }
    if (values.tiktokUsername !== "") {
      fields[instagramIndex].isRequired = false;
    }
  };

  const orderedFields = orderBy(fields, ["order"], ["asc"]);

  const fieldsAfterModified = orderedFields.map((field) => {
    return { ...field, touched, errors, getFieldProps, setFieldValue };
  });

  const fileUploadField = fieldsAfterModified.find(
    (input) => input.type === "file"
  );
  const anotherFields = fieldsAfterModified.filter(
    (input) => input.type !== "file"
  );

  useEffect(() => {
    checkDisabledForm();
  }, [isAgreed, isOver21, isCirclekusAgreed]);

  const findIndex = (name) => {
    return fields.findIndex((item) => item.label === name);
  };

  const clickedCheckbox = () => {
    // checkDisabledForm();
    setIsAgreed(!isAgreed);
  };

  const clickedCirclekusCheckbox = () => {
    setIsCirclekusAgreed(!isCirclekusAgreed);
  };

  const clickedIsOver21 = () => {
    setIsOver21(!isOver21);
  };

  const submitForm = (e) => {
    e.preventDefault();
    handleSubmit();
    clearMessage();
    if (uploadedFiles.length > 0) {
      clearMessage();
    } else {
      setGeneralError(errorMsgs["en"].invalidFileType, "error");
    }
  };

  const checkDisabledForm = () => {
    const isRequiredToCheckLegel =
      checkLegalClients.includes(clientName) ||
      checkOver21Client.includes(clientName);
    if (!isRequiredToCheckLegel) {
      const isCirclekUsDisabled = !isAgreed || !isCirclekusAgreed;
      const disabled = isFromCirclekus
        ? !isAgreed || isCirclekUsDisabled
        : !isAgreed;
      setIsDisabled(disabled);
    } else {
      setIsDisabled(!isAgreed || !isOver21);
    }
  };

  return (
    <div
      className={
        isNewForm ? "formik-container container px-5" : "formik-container"
      }
    >
      <div className={isNewForm ? "widget-body d-flex" : "widget-body"}>
        {renderField(fileUploadField)}
        <div className={isNewForm ? "widget-form w-50 px-0" : "widget-form"}>
          <div
            className={generalMessageClassName}
            dangerouslySetInnerHTML={{ __html: message }}
          />
          {anotherFields.map((field) => renderField(field))}
          {isNewForm && (
            <div className="widget-agreement px-0">
              {(checkLegalClients.includes(clientName) ||
                checkOver21Client.includes(clientName)) && (
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="isOver21"
                    onChange={clickedIsOver21}
                    checked={isOver21}
                  />

                  {checkLegalClients.includes(clientName) && (
                    <label className="form-check-label" htmlFor="isOver21">
                      I confirm that I am of legal drinking age in my country.
                    </label>
                  )}

                  {checkOver21Client.includes(clientName) && (
                    <label className="form-check-label" htmlFor="isOver21">
                      I confirm that I am 21 years or older
                    </label>
                  )}
                </div>
              )}

              {isFromCirclekus && (
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="circlekusAgreement"
                    onChange={clickedCirclekusCheckbox}
                    checked={isCirclekusAgreed}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="circlekusAgreement"
                  >
                    I agree to the &nbsp;
                    <a
                      rel="noopener noreferrer"
                      onClick={() => onAdditonalTermsClick()}
                      href="#"
                    >
                      CircleK Content Licensing Terms*
                    </a>
                  </label>
                </div>
              )}

              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="agreement"
                  onChange={clickedCheckbox}
                  checked={isAgreed}
                />
                <label className="form-check-label" htmlFor="agreement">
                  I agree to the{" "}
                  <a
                    href={configUrls.App.terms}
                    target="_blank"
                    className="term"
                    rel="noopener noreferrer"
                  >
                    Terms & Conditions*
                  </a>
                </label>
              </div>
            </div>
          )}
          {isNewForm && (
            <div className="widget-footer p-0">
              <button
                className="btn button w-100 mx-0"
                disabled={isDisabled}
                onClick={submitForm}
                type="button"
              >
                Upload
              </button>
            </div>
          )}
        </div>
      </div>
      {!isNewForm && (
        <div className="widget-agreement add-padding-left">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="agreement"
              onChange={clickedCheckbox}
              checked={isAgreed}
            />
            <label className="form-check-label" htmlFor="agreement">
              I agree to the{" "}
              <a
                href={configUrls.App.terms}
                target="_blank"
                rel="noopener noreferrer"
                className="term"
              >
                Terms & Conditions*
              </a>
            </label>
          </div>
          {isNewFormResponsive && (
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="agreement"
                onChange={clickedCheckbox}
                checked={isAgreed}
              />
              <label className="form-check-label" htmlFor="agreement">
                I agree to the{" "}
                <a
                  href={configUrls.App.terms}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="term"
                >
                  Terms & Conditions*
                </a>
              </label>
            </div>
          )}
        </div>
      )}
      {!isNewForm && (
        <div className="widget-footer">
          <button
            className="btn btn-sm button"
            disabled={
              isFromCirclekus ? !isAgreed || !isCirclekusAgreed : !isAgreed
            }
            onClick={submitForm}
            type="button"
          >
            {isFromMasterSpa ? "Submit" : "Upload"}
          </button>
          <div className="signature add-padding-left">
            Powered By
            <a
              href="https://www.entribe.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={logo} alt="logo" />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default BaseFormik;
