import { useEffect } from "react";
import { KEYBOARD, PREFIX_CLS } from "../../../constants/common";

const scrollIndex = 20;

const useScrollHandler = (elRef, isElement) => {
  const preventDefault = (e) => {
    e.stopPropagation();
    e.stopImmediatePropagation();
    e.preventDefault();
  };

  const windowFunc = (e, node) => {
    // Pressing key up and down to pick an option in a dropdown
    const isDropdownList = e.target.classList.contains(
      `${PREFIX_CLS}-select-selection-search-input`
    );
    const tagName = e.target.tagName;
    const hasVerticalScroll = e.target.scrollHeight > e.target.clientHeight;

    const isScrollWindow =
      !isDropdownList &&
      !(tagName && tagName.toLowerCase() === "textarea" && hasVerticalScroll);

    if (
      (e.keyCode === KEYBOARD.DOWN.CODE || e.keyCode === KEYBOARD.UP.CODE) &&
      isScrollWindow
    ) {
      preventDefault(e);
    } else {
      if (isDropdownList && e.target.hasAttribute("aria-expanded")) {
        setTimeout(() => {
          const ariaExpanded = e.target.getAttribute("aria-expanded");
          if (ariaExpanded === "false") {
            e.target.blur();
          }
        }, 0);
      }
    }

    if (
      e.keyCode === KEYBOARD.DOWN.CODE &&
      window.innerHeight + window.scrollY < node.offsetHeight &&
      isScrollWindow
    ) {
      window.scrollTo({
        top: window.scrollY + scrollIndex,
        behavior: "smooth",
      });
    }

    if (
      e.keyCode === KEYBOARD.UP.CODE &&
      window.scrollY >= scrollIndex &&
      isScrollWindow
    ) {
      window.scrollTo({
        top: window.scrollY - scrollIndex,
        behavior: "smooth",
      });
    }
  };

  const elementFunc = (e, node) => {
    const customScrollIndex = scrollIndex * 3;

    if (e.keyCode === KEYBOARD.DOWN.CODE || e.keyCode === KEYBOARD.UP.CODE) {
      preventDefault(e);
    }

    if (e.keyCode === KEYBOARD.DOWN.CODE) {
      node.scrollTo({
        top: node.scrollTop + customScrollIndex,
        behavior: "smooth",
      });
    }
    if (e.keyCode === KEYBOARD.UP.CODE) {
      node.scrollTo({
        top: node.scrollTop - customScrollIndex,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (elRef.current) {
      const node = elRef.current;
      node.addEventListener(
        "keydown",
        (e) => (isElement ? elementFunc(e, node) : windowFunc(e, node)),
        true
      );

      return () => {
        node.removeEventListener(
          "keydown",
          (e) => (isElement ? elementFunc(e, node) : windowFunc(e, node)),
          true
        );
      };
    }
  }, [elRef.current]);
};

export default useScrollHandler;
