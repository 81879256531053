import { IconInstagram } from "assets/img/icons";
import { HomeUrl, instagram } from "config";
import { AUTHORIZE_INS } from "constants/APIs";
import { FAILED, NONE, UPLOADER_TYPE } from "constants/common";
import WidgetBuilderContext from "contexts/WidgetBuilder";
import { useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import utils from "services/utils";
import { popupWindow } from "services/widgetBuilder";
import { storeData } from "stores/actions";
import { StyledIcon } from "styled-components/Common/CommonStyled";

const Instagram = () => {
  const iconRef = useRef();
  const { setModalType, socialSource, setAuthorizeWindow, setVideoList } =
    useContext(WidgetBuilderContext);
  let { authorizeWindow } = useContext(WidgetBuilderContext);
  const dispatch = useDispatch();
  const SCOPE = "user_profile,user_media";

  const {
    getInstagramAccessTokenStatus,
    getInstagramMediaStatus,
    storedCode,
    instagramAccessTokenResult,
  } = useSelector((state) => state.widgets);

  const connectInstagram = () => {
    if (
      !instagramAccessTokenResult ||
      getInstagramAccessTokenStatus === FAILED ||
      getInstagramMediaStatus === FAILED
    ) {
      const params = {
        client_id: instagram.clientId,
        redirect_uri: HomeUrl,
        scope: SCOPE,
        response_type: "code",
      };

      resetData();
      const authUrl = `${AUTHORIZE_INS}?${utils.convertSearchParams(params)}`;
      authorizeWindow = popupWindow(authUrl);
      setAuthorizeWindow(authorizeWindow);
    } else {
      setModalType(UPLOADER_TYPE.INSTAGRAM);
    }
  };

  const resetData = () => {
    setVideoList([]);
    dispatch(
      storeData({
        storedCode: "",
      })
    );
  };

  const onKeyDown = () => {
    if (iconRef.current) {
      iconRef.current?.click();
    }
  };

  return (
    <>
      <StyledIcon
        className="instagram_icon"
        ref={iconRef}
        tabIndex={0}
        onClick={() => connectInstagram()}
        size={35}
        cursor="pointer"
        onKeyDown={(e) => {
          utils.onKeyDown(e, onKeyDown);
        }}
      >
        <IconInstagram />
      </StyledIcon>
    </>
  );
};

export default Instagram;
