import PropTypes from "prop-types";
import React from "react";

import { Viewer, Worker } from "@react-pdf-viewer/core";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import { StyledFlex } from "../../../styled-components/Common/CommonStyled";
import {
  StyledPDFContainer,
  StyledPDFViewer,
  StyledToolbar,
  StyledToolbarItem,
} from "../../../styled-components/PDFViewerStyled";

const PDFViewer = (props) => {
  const { data, onChangePDFPage, isMobile, isScriptTag } = props;
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;

  const renderSlotOfToolbar = (slots) => {
    const {
      EnterFullScreen,
      Zoom,
      ZoomIn,
      ZoomOut,
      GoToPreviousPage,
      CurrentPageInput,
      NumberOfPages,
      GoToNextPage,
    } = slots;
    const toolbarItems = [
      {
        key: 1,
        element: (
          <StyledFlex>
            <ZoomIn /> <Zoom /> <ZoomOut />
          </StyledFlex>
        ),
      },
      {
        key: 2,
        element: (
          <StyledFlex className="handle-page-btn" margin="0 0 0 -3rem">
            <GoToPreviousPage />
            <CurrentPageInput />
            {` / `}
            <NumberOfPages />
            <GoToNextPage />
          </StyledFlex>
        ),
      },
      {
        key: 3,
        element: <EnterFullScreen />,
        hidden: isScriptTag,
      },
    ];

    return (
      <StyledFlex width="100%">
        {toolbarItems.map((item) => {
          return (
            <StyledToolbarItem key={item.key} isHidden={item.hidden}>
              {item.element}
            </StyledToolbarItem>
          );
        })}
      </StyledFlex>
    );
  };

  return (
    <Worker
      // eslint-disable-next-line no-undef
      workerUrl={`${process.env.PUBLIC_URL}/libs/pdf-viewer/pdf.worker.min.js`}
    >
      <StyledPDFViewer isMobile={isMobile} className="rpv-core__viewer">
        <StyledToolbar>
          <Toolbar>{(slots) => renderSlotOfToolbar(slots)}</Toolbar>
        </StyledToolbar>
        <StyledPDFContainer>
          <Viewer
            fileUrl={data}
            plugins={[toolbarPluginInstance]}
            defaultScale="PageFit"
            onPageChange={onChangePDFPage}
          />
        </StyledPDFContainer>
      </StyledPDFViewer>
    </Worker>
  );
};

PDFViewer.propTypes = {
  data: PropTypes.string,
  onChangePDFPage: PropTypes.func,
  isMobile: PropTypes.bool,
  isScriptTag: PropTypes.bool,
};

export default PDFViewer;
