import PropTypes from "prop-types";
import { Component } from "react";
import { withRouter } from "react-router";

import { BRAND_NAME } from "constants/common";
import configUrls from "../../../constants/configUrls";
import utils from "../../../services/utils";
import WidgetBuilder from "../WidgetBuilder/WidgetBuilder";
import CirclekCanada from "./CirclekCanada/CirclekCanada";
import CirclekUS from "./CirclekUS/CirclekUS";
import EnTribe from "./EnTribe/EnTribe";
import Gemini from "./Gemini";
import Hersheys from "./Hersheys/Hersheys";
import HersheysBr from "./HersheysBr/HersheysBr";
import HersheysCa from "./HersheysCa/HersheysCa";
import Hersheyindia from "./HersheysIndia/HersheysIndia";
import HersheysMx from "./HersheysMx/HersheysMx";
import Inkedibles from "./Inkedibles/Inkedibles";
import MasterSpas from "./MasterSpas/MasterSpas";
import TeamPixel from "./TeamPixel/TeamPixel";
import TulsaUniversity from "./TulsaUniversity/TulsaUniversity";
import UCDavis from "./UCDavis/UCDavis";
import UIW from "./UIW/UIW";
import UIWAdmissions from "./UIWAdmissions/UIWAdmissions";
import { connect } from "react-redux";
import { storeDataTypes } from "stores/widgets/widgets.types";
import { isNull, isUndefined } from "lodash";

const widgets = {
  inkediblescustom: Inkedibles,
  teampixel: TeamPixel,
  circlek: CirclekUS,
  ucdavis: UCDavis,
  masterspas: MasterSpas,
  utulsa: TulsaUniversity,
  hersheysph: Hersheys,
  entribe: EnTribe,
  hersheysbr: HersheysBr,
  hersheysreposteria: HersheysMx,
  hersheyindia: Hersheyindia,
  uiw: UIW,
  admissions: UIWAdmissions,
  circlekcanada: CirclekCanada,
  herforshe: HersheysCa,
  gemini: Gemini,
};

class Custom extends Component {
  static propTypes = {
    globalProps: PropTypes.shape({
      setIsShowLoading: PropTypes.func,
      requestAPI: PropTypes.func,
      didMountCallback: PropTypes.func,
    }),
  };

  static defaultProps = {
    globalProps: {
      setIsShowLoading: () => {},
      requestAPI: () => {},
      didMountCallback: () => {},
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      client: null,
      challenge: null,
      selectId: null,
      location: null,
    };
  }

  componentWillMount() {
    utils.eraseCookie("googtrans");
    this.getClient();
  }

  componentDidMount() {
    const { client } = this.state;
    if (client) {
      this.getSelectId();

      if (client.name !== BRAND_NAME.GEMINI.toLowerCase()) {
        this.getChallengeAPI();
      }

      if (
        client.name &&
        client.name !== BRAND_NAME.TEAM_PIXEL.toLowerCase() &&
        client.name !== BRAND_NAME.GEMINI.toLowerCase()
      ) {
        this.getLocationAPI();
      }
    }
    const {
      globalProps: { didMountCallback },
    } = this.props;
    didMountCallback();
  }

  componentDidUpdate(prevProps, prevState) {
    const { client } = prevState;
    const { widgets } = prevProps;

    if (
      !client &&
      (isNull(widgets.isHiddenGeneralLoading) ||
        isUndefined(widgets.isHiddenGeneralLoading))
    ) {
      this.props.storeData({
        isHiddenGeneralLoading: true,
      });
    }
  }

  getClient = () => {
    const {
      match: { params = {} },
      location: { search = "" },
    } = this.props;

    let clientName = params.clientName.replace() || "";
    clientName = clientName.replace(/-/g, "");
    if (clientName) {
      const clientId = utils.getClientIdByName(clientName);
      const challengeId = utils.getChallengeId(search);
      sessionStorage.setItem("clientId", clientId);
      sessionStorage.setItem("clientName", clientName);

      if (clientId) {
        const client = {
          id: clientId,
          name: clientName,
          challengeId,
        };
        this.setState({ client: client });
      }
    }
  };

  getChallengeAPI() {
    const {
      location: { search = "" },
      globalProps: { requestAPI },
    } = this.props;

    const challengeId = utils.getChallengeId(search);
    this.setState({ challengeId });
    if (challengeId) {
      const successCallback = (resp) => {
        this.setState({ challenge: utils.parseChallengeData(resp) });
      };

      const failedCallback = (resp) => {};
      const clientId = sessionStorage.getItem("clientId");
      const url = `${configUrls.API.getChallengeById}?clientId=${clientId}&campaignId=${challengeId}`;
      requestAPI("get", url, {}, successCallback, failedCallback);
    }
  }

  getSelectId() {
    const {
      location: { search = "" },
    } = this.props;

    const selectId = utils.getSelectId(search);
    this.setState({ selectId });
  }

  getLocationAPI = () => {
    const {
      globalProps: { requestAPI, initializePendo },
    } = this.props;

    const url = configUrls.externalAPI.getLocation;

    const successCallback = (resp) => {
      const clientId = sessionStorage.getItem("clientId");
      initializePendo(resp, clientId ? clientId : 0);
      this.setState({ location: resp });
    };

    const failedCallback = (resp) => {};
    const errorCallback = () => {};

    requestAPI(
      "get",
      url,
      {},
      successCallback,
      failedCallback,
      errorCallback,
      true
    );
  };

  /*  
    Select upload - Required: selectId
    Direct upload - Required: challengeId || clientId
  */
  renderCustomWidget = () => {
    const { globalProps } = this.props;
    const { client, challenge, selectId, location, challengeId } = this.state;

    if (client) {
      const Widget = widgets[client.name.toLowerCase()];

      return Widget ? (
        <Widget
          globalProps={globalProps}
          client={client}
          challenge={challenge}
          selectId={selectId}
          challengeId={challengeId}
          uploadLocation={location} // Cannot named 'location' because it's duplicated with location prop of react (Route)
        />
      ) : null;
    } else {
      return <WidgetBuilder globalProps={globalProps} />;
    }
  };

  render() {
    return <div>{this.renderCustomWidget()}</div>;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    storeData: (payload) =>
      dispatch({ type: storeDataTypes.STORE_DATA, payload }),
  };
};

const mapStateToProps = (state) => ({
  widgets: state.widgets,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Custom));
