import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import WidgetBuilderContext from "../../../contexts/WidgetBuilder";
import {
  StyledHeader,
  StyledLogo,
} from "../../../styled-components/WidgetBuilder/WidgetBuilderStyled";

const WidgetHeader = (props) => {
  const { t } = useTranslation();
  const { srcLogo, sizeLogo: { widthLogoDesktop, widthLogoMobile } = {} } =
    props;

  const { isMobile } = useContext(WidgetBuilderContext);

  const widthImg = useMemo(() => {
    const result = isMobile ? widthLogoMobile : widthLogoDesktop;
    return result ? `${result}px` : "unset";
  }, [widthLogoDesktop, widthLogoMobile, isMobile]);

  return (
    <StyledHeader>
      {srcLogo && (
        <StyledLogo
          width={widthImg}
          src={srcLogo}
          alt={t("widget_builder.brand_logo")}
        />
      )}
    </StyledHeader>
  );
};

WidgetHeader.propTypes = {
  srcLogo: PropTypes.string,
  sizeLogo: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default WidgetHeader;
