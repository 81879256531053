import React from "react";
import { Button, Col, Form, Modal, Row } from "antd";
import styled, { css, keyframes } from "styled-components";
import THEME from "../../constants/themes";
import utils from "../../services/utils";
import { StyledError } from "styled-components/WidgetBuilder/WidgetBuilderStyled";
import { PREFIX_CLS } from "constants/common";

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0px rgba(245, 34, 45, 0.4);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(245, 34, 45, 0);
  }
`;

export const StyledForm = styled(Form)``;

export const StyledFlex = styled.div`
  position: relative;
  display: flex;
  ${({ width }) => width && `width: ${width}`};
  ${({ height }) => height && `height: ${height}`};
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}`};
  justify-content: ${({ justify }) => justify || "space-between !important"};
  align-items: ${({ align }) => align || "center"};
  margin: ${({ margin }) => margin || "unset"};
  padding: ${({ padding }) => padding || "unset"};
`;

export const StyledIcon = styled.div`
  ${({ position }) => position && `position: ${position}`};
  ${({ top }) => top && `top: ${top}`};
  ${({ bottom }) => bottom && `bottom: ${bottom}`};
  ${({ right }) => right && `right: ${right}`};
  ${({ left }) => left && `left: ${left}`};
  display: ${({ display }) => display || "inline-flex"};
  ${({ margin }) => margin && `margin: ${margin} !important`};
  ${({ padding }) => padding && `padding: ${padding}`};
  ${({ cursor }) => cursor && `cursor: ${cursor}`};
  ${({ zIndex }) => zIndex && `z-index: ${zIndex}`};
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.7;
      pointer-events: none;
    `};

  svg {
    ${({ size }) => size && `height: ${size}px !important`};
    ${({ size }) => size && `width: ${size}px !important`};
    ${({ width }) => width && `width: ${width}`};
    ${({ height }) => height && `height: ${height}`};

    & {
      ${({ color }) => color && `fill: ${color}`};

      path {
        ${({ colorPath }) => colorPath && `fill: ${colorPath}`};
      }

      &:focus {
        outline: ${({ color }) =>
          `2px solid ${utils.convertToRgba(
            color || THEME.colors.darkBlue4,
            0.75
          )}`};
        border-radius: 50%;
      }
    }
  }

  &.uploading-icon {
    &,
    &-error {
      width: max-content;
      height: max-content;
      display: flex;
      align-items: center;
      justify-content: center;

      &.upload-status-item {
        display: inline-block;
      }
    }

    &-error {
      & {
        margin: 0;

        svg {
          border-radius: 50%;
          animation: ${pulse} 2s linear infinite;
        }
      }
    }
  }

  &:focus-visible {
    outline: ${({ color }) =>
      `2px solid ${utils.convertToRgba(
        color || THEME.colors.darkBlue4,
        0.75
      )}`};
    border-radius: 50%;
  }

  &.close-icon {
    &:focus-visible {
      outline: none;

      svg {
        &,
        path {
          fill: ${THEME.colors.darkBlue1} !important;
        }
      }
    }
  }
`;

export const StyledCol = styled(Col)`
  ${({ textAlign }) => textAlign && `text-align: ${textAlign}`};

  .${PREFIX_CLS}-tooltip-arrow-content {
    &::before {
      background: #f00c;
    }
  }

  .${PREFIX_CLS}-tooltip-arrow {
    &::before {
      background: rgba(255, 0, 0, 0.8);
    }
  }

  .${PREFIX_CLS}-tooltip-inner {
    background-color: #f00c;
    min-width: 180px;
  }
`;

export const StyledText = styled.div`
  padding: ${({ padding }) => padding};
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => fontSize || "14px"};
  font-weight: ${({ fontWeight }) => fontWeight};
  ${({ align }) => align && `text-align: ${align}`};
  ${({ margin }) => margin && `margin: ${margin}`};
  ${({ textTransform }) => textTransform && `text-transform: ${textTransform}`};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledModal = styled(Modal)`
  .${PREFIX_CLS}-modal-mask {
    background-color: rgba(2, 12, 21, 0.8) !important;
  }

  .${PREFIX_CLS}-modal-header {
    border-radius: 6px 6px 0 0;

    .${PREFIX_CLS}-modal-title {
      font-weight: 600 !important;
    }
  }

  .${PREFIX_CLS}-modal-body {
    padding: ${({ padding }) => padding || "16px 20px"};
  }

  .${PREFIX_CLS}-modal-footer {
    ${({ border }) => `border: ${border} !important`};
  }

  .${PREFIX_CLS}-modal-content {
    border-radius: ${({ borderRadius }) => borderRadius || "6px"};

    .${PREFIX_CLS}-modal-close {
      outline: none;
    }

    .error_msg {
      position: absolute;
      top: 5px;
      left: 24px;
    }
  }
`;

export const StyledButton = styled(({ ...props }) => <Button {...props} />)`
  ${({ width }) => width && `width: ${width}`};
  ${({ height }) => height && `height: ${height}`};
  ${({ radius }) => radius && `border-radius: ${radius}`};
  font-size: ${({ fontSize }) => fontSize || "14px"};
  color: ${({ color }) => color || THEME.colors.gray5};
  border: none !important;
  margin: ${({ margin }) => margin || "none"};
  padding: ${({ padding }) => padding || "none"};
  pointer-events: ${({ isDisabled }) => (isDisabled ? "none" : "auto")};
  opacity: ${({ isDisabled }) => (isDisabled ? THEME.disabledOpacity : 1)};
  overflow-wrap: break-word;
  max-width: 100%;
  white-space: inherit;

  &,
  :focus,
  :hover {
    background-color: ${({ backgroundColor }) =>
      `${backgroundColor} !important`};
  }

  &:focus {
    outline: ${({ backgroundColor }) =>
      `2px solid ${utils.convertToRgba(backgroundColor, 0.85)} !important`};
  }
`;

export const StyledRow = styled(Row)``;

export const StyledWrapper = styled.div`
  ${({ width }) => width && `width: ${width}`};
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}`};
  ${({ height }) => height && `height: ${height}`};
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}`};
  ${({ padding }) => padding && `padding: ${padding}`};
  ${({ margin }) => margin && `margin: ${margin}`};
  ${({ color }) => color && `color: ${color}`};
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}`};
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight}`};
  ${({ background }) => background && `background: ${background}`};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign}`};
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}`};
  ${({ overflow }) => overflow && `overflow: ${overflow}`};
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}`};
  ${({ boxShadow }) => boxShadow && `box-shadow: ${boxShadow}`};
  ${({ display }) => display && `display: ${display}`};
  ${({ alignItems }) => alignItems && `align-items: ${alignItems}`};
  ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent}`};
  ${({ position }) => position && `position: ${position}`};
  ${({ top }) => top && `top: ${top}`};
  ${({ bottom }) => bottom && `bottom: ${bottom}`};
  ${({ right }) => right && `right: ${right}`};
  ${({ left }) => left && `left: ${left}`};
  ${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap}`};
  ${({ flexDirection }) => flexDirection && `flex-direction: ${flexDirection}`};
  ${({ pointerEvents }) => pointerEvents && `pointer-events: ${pointerEvents}`};
  ${({ cursor }) => cursor && `cursor: ${cursor}`};
  ${({ border }) => border && `border: ${border}`};
  ${({ fontFamily }) => fontFamily && `font-family: ${fontFamily}`};

  * {
    ${({ fontSize }) => fontSize && `font-size: ${fontSize}`};
  }
`;
