import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { KEYBOARD } from "../../../constants/common";
import { StyledFlex } from "../../../styled-components/Common/CommonStyled";
import {
  StyledButton,
  StyledCancelUpload,
  StyledCancelUploadOverlay,
} from "../../../styled-components/WidgetBuilder/UploadProgressStyled";
import { StyledGeminiSubmitButton } from "styled-components/CustomWidget/GeminiStyled";

const CancelUpload = (props) => {
  const { visible, onCancel, onConfirm, bgSubmit, isCustomWidget } = props;
  const { t } = useTranslation();
  const cancelUploadRef = useRef();

  useEffect(() => {
    if (visible) {
      if (cancelUploadRef.current) {
        onFocus();
        window.addEventListener("keydown", handleInitFocus);

        return () => {
          window.removeEventListener("keydown", handleInitFocus);
        };
      }
    }
  }, [visible, cancelUploadRef.current]);

  const handleInitFocus = (e) => {
    if (e.key === KEYBOARD.TAB.STR) {
      if (e.target && e.target.getAttribute("id") === "cancel-upload-btn") {
        onFocus();
      }
    }
  };

  const onFocus = () => {
    const cancelUploadElement = document.getElementById("cancel-upload");
    if (cancelUploadElement) {
      cancelUploadElement.setAttribute("tabindex", "0");
      cancelUploadElement.focus();
    }
  };

  return visible ? (
    <>
      <StyledCancelUploadOverlay />
      <StyledCancelUpload
        id="cancel-upload"
        className={isCustomWidget ? "gemini-cancel-upload" : ""}
        ref={cancelUploadRef}
      >
        {t("message.cancel_upload")}
        <StyledFlex justify="flex-end" margin="12px 0 4px 0">
          <StyledButton
            tabIndex={0}
            onClick={onCancel}
            key={t("button.cancel")}
            margin="0 8px"
          >
            {t("button.cancel")}
          </StyledButton>
          {!isCustomWidget && (
            <StyledButton
              id="cancel-upload-btn"
              type="primary"
              tabIndex={0}
              bgColor={bgSubmit}
              onClick={onConfirm}
              key={t("button.yes")}
            >
              {t("button.yes")}
            </StyledButton>
          )}
          {isCustomWidget && (
            <StyledGeminiSubmitButton
              id="cancel-upload-btn"
              tabIndex={0}
              onClick={onConfirm}
              key={t("button.yes")}
            >
              {t("button.yes")}
            </StyledGeminiSubmitButton>
          )}
        </StyledFlex>
      </StyledCancelUpload>
    </>
  ) : null;
};

CancelUpload.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  bgSubmit: PropTypes.string,
};
export default CancelUpload;
