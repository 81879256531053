import { IconCheckCircle, IconError } from "assets/img/icons";
import { SUCCEEDED } from "constants/common";
import THEME from "constants/themes";
import { useSelector } from "react-redux";
import {
  StyledCol,
  StyledIcon,
  StyledText,
} from "styled-components/Common/CommonStyled";
import { StyledUploadStatusListWrapper } from "styled-components/TeamPixelStyled";
import { StyledUploadProgressItem } from "styled-components/WidgetBuilder/UploadProgressStyled";
import { StyledTooltip } from "styled-components/WidgetBuilder/WidgetBuilderStyled";

const UploadStatusList = (props) => {
  const { fileProgress, isMobile } = props;

  const isSuccessful =
    fileProgress && fileProgress.every((item) => item.success);

  return (
    !isSuccessful && (
      <StyledUploadStatusListWrapper
        margin="24px 0"
        maxWidth={window.innerWidth - 100}
      >
        <StyledUploadProgressItem
          width="100%"
          fontWeight="normal"
          padding="6px 8px"
          gutter={[0, 2]}
        >
          {fileProgress.map((item, index) => {
            return (
              <>
                <StyledCol span={2} key={index}>
                  {item.success ? (
                    <StyledIcon colorPath={THEME.colors.greenBase}>
                      <IconCheckCircle />
                    </StyledIcon>
                  ) : (
                    <StyledTooltip
                      overlayClassName="upload-status-error-tooltip"
                      title={
                        (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item?.errorMessage,
                            }}
                          />
                        ) || ""
                      }
                      placement="topRight"
                      trigger={["hover", "focus"]}
                    >
                      {/* Expand the click area in mobile mode */}
                      <StyledIcon
                        cursor="pointer"
                        colorPath={THEME.colors.redBase}
                        className="uploading-icon-error upload-status-item"
                      >
                        <IconError />
                      </StyledIcon>
                    </StyledTooltip>
                  )}
                </StyledCol>
                <StyledCol span={22}>
                  <StyledText
                    className="upload-status-file-name"
                    maxWidth="80%"
                    margin={isMobile ? "0 0 0 4px" : "0 0 0 -6px"}
                  >
                    {item.name || ""}
                  </StyledText>
                </StyledCol>
              </>
            );
          })}
        </StyledUploadProgressItem>
      </StyledUploadStatusListWrapper>
    )
  );
};

export default UploadStatusList;
