import { handleActions } from "redux-actions";
import { STATUS_CODE } from "../../constants/APIs";
import {
  CANCELLED,
  DEFAULT_ERROR,
  FAILED,
  IN_CAPTURATE,
  IN_PROGRESS,
  LIMITATION_ERROR_TYPE,
  NONE,
  SUCCEEDED,
} from "../../constants/common";
import utils from "../../services/utils";
import {
  cancelTypes,
  capturateContentTypes,
  createContentTypes,
  getClientDetailTypes,
  getClientSettingTypes,
  getCreatorTypes,
  getGeolocationTypes,
  getInstagramAccessTokenTypes,
  getInstagramMediaTypes,
  getLocationTypes,
  getTermsTypes,
  setUploadProgressTypes,
  storeDataTypes,
  uploadContentTypes,
  uploadGoogleDriveContentTypes,
  uploadInstagramContentTypes,
  uploadTikTokContentTypes,
  uploadYouTubeContentTypes,
  validateCampaignTypes,
} from "./widgets.types";

const initialState = {
  fileProgress: [], // is used to track the progress of uploading contents
  uploadedContentList: [], // contains content uploaded successfully
  createdContentError: null,
  error: "",
  uploadSocialContentError: null,
  uploadSocialContentStatus: NONE,
};

const updateList = (updateInfo, dataList, key) => {
  const dataListTemp = [...dataList];
  const index = dataListTemp.findIndex((item) =>
    key ? item[key] === updateInfo[key] : item.id === updateInfo.id
  );

  if (index === -1) {
    dataListTemp.push(updateInfo);
  } else {
    dataListTemp[index] = {
      ...dataListTemp[index],
      ...updateInfo,
    };
  }

  return dataListTemp;
};

const setUploadProgress = (state, { payload }) => {
  let { fileProgress } = state;
  fileProgress = updateList(payload, fileProgress, "fileProgressId");

  return {
    ...state,
    fileProgress,
  };
};

const cancelRequest = (state, { payload }) => {
  let { fileProgress } = state;
  const { fileProgressId, isNotRemove } = payload;
  const fileIndex = fileProgress.findIndex(
    (item) => item.fileProgressId === fileProgressId
  );

  if (!isNotRemove) {
    fileProgress = fileProgress.filter((item) => {
      return item.fileProgressId !== fileProgressId;
    });
  } else {
    if (
      fileProgress[fileIndex].status !== SUCCEEDED &&
      fileProgress[fileIndex].status !== IN_CAPTURATE
    )
      fileProgress[fileIndex].status = CANCELLED;
  }

  return {
    ...state,
    fileProgress,
  };
};

const uploadContentRequest = (state, { payload }) => {
  let { fileProgress, contentInfoList } = state;
  if (payload) {
    const { fileProgressId, formData } = payload;
    const fileProgressData = {
      fileProgressId,
      progress: 0,
      remainingTime: 0,
      status: IN_PROGRESS,
      ...utils.convertFormDataToObject(formData),
    };

    fileProgress = updateList(fileProgressData, fileProgress, "fileProgressId");
  }

  return {
    ...state,
    fileProgress,
    contentInfoList,
    uploadContentStatus: IN_PROGRESS,
  };
};

const uploadContentSucceeded = (state, { payload }) => {
  if (payload) {
    const { fileProgressId, result } = payload || {};
    const { uploadedContentList, fileProgress } = state;

    // TODO, will be update once we have offical response data format from BE

    if (result) {
      uploadedContentList.push(result);
    }

    const stateTemp = {
      ...state,
      uploadContentStatus: SUCCEEDED,
      fileProgress: fileProgress.length
        ? updateList(
            { fileProgressId, status: SUCCEEDED },
            fileProgress,
            "fileProgressId"
          )
        : [],
      uploadedContentList,
    };

    return {
      ...stateTemp,
    };
  }
};

const uploadContentFailed = (state, { payload = DEFAULT_ERROR }) => {
  if (payload) {
    const { responseError, fileProgressId } = payload || {};
    const { fileProgress } = state;

    const limitationError = {
      msg: responseError,
      type: LIMITATION_ERROR_TYPE.FAILED,
    };

    return {
      ...state,
      uploadContentStatus: FAILED,
      fileProgress: updateList(
        { fileProgressId, status: FAILED, limitationError },
        fileProgress,
        "fileProgressId"
      ),
    };
  }

  return {
    ...state,
    uploadContentStatus: FAILED,
  };
};

const uploadContentEnd = (state) => {
  return {
    ...state,
    uploadContentStatus: NONE,
    contentInfoList: [],
    firstContentInfo: null,
  };
};

const createContentRequest = (state) => {
  return {
    ...state,
    createContentStatus: IN_PROGRESS,
  };
};

const createContentSucceeded = (state, { payload }) => {
  if (payload) {
    const {
      status: { code = NONE },
      result: createdContent = null,
    } = payload.data;

    // TODO, will be update once we have offical response data format from BE
    if (code === STATUS_CODE.SUCCESS) {
      return {
        ...state,
        createContentStatus: SUCCEEDED,
        createdContent,
      };
    }
  }

  return {
    ...state,
    createContentStatus: FAILED,
  };
};

const createContentFailed = (state, { payload = DEFAULT_ERROR }) => {
  if (payload.data) {
    const { error } = payload.data;

    return {
      ...state,
      createContentStatus: FAILED,
      createdContentError: error,
    };
  }

  return {
    ...state,
    createContentStatus: FAILED,
    createdContentError: DEFAULT_ERROR,
  };
};

const createContentEnd = (state) => {
  return {
    ...state,
    createContentStatus: NONE,
    firstUploadedContent: null,
    uploadedContentList: [],
  };
};

const uploadTikTokContentRequest = (state) => {
  return {
    ...state,
    uploadTikTokContentStatus: IN_PROGRESS,
  };
};

const uploadTikTokContentSucceeded = (state, { payload }) => {
  if (payload) {
    const {
      status: { code = NONE },
      result: uploadSocialContentResult = [],
    } = payload.data;

    // TODO, will be update once we have offical response data format from BE
    if (code === STATUS_CODE.SUCCESS) {
      return {
        ...state,
        uploadTikTokContentStatus: SUCCEEDED,
        uploadSocialContentResult,
      };
    }
  }

  return {
    ...state,
    uploadTikTokContentStatus: FAILED,
  };
};

const uploadTikTokContentFailed = (state, { payload = DEFAULT_ERROR }) => {
  if (payload.data) {
    const { message = "", status = NONE, error = "" } = payload.data;

    return {
      ...state,
      uploadTikTokContentStatus: FAILED,
      error: error || `${status} ${message}`,
    };
  }

  return {
    ...state,
    uploadTikTokContentStatus: FAILED,
  };
};

const uploadTikTokContentEnd = (state) => {
  return {
    ...state,
    uploadTikTokContentStatus: NONE,
  };
};

const getClientSettingRequest = (state) => {
  return {
    ...state,
    getClientSettingStatus: IN_PROGRESS,
  };
};

const getClientSettingSucceeded = (state, { payload }) => {
  if (payload) {
    const {
      status: { code = NONE },
      result: clientSetting,
    } = payload.data;

    // TODO, will be update once we have offical response data format from BE
    if (code === STATUS_CODE.SUCCESS) {
      return {
        ...state,
        clientSetting,
        getClientSettingStatus: SUCCEEDED,
      };
    }
  }

  return {
    ...state,
    getClientSettingStatus: FAILED,
  };
};

const getClientSettingFailed = (state, { payload = DEFAULT_ERROR }) => {
  if (payload.data) {
    const { message = "", status = NONE, error = "" } = payload.data;

    return {
      ...state,
      getClientSettingStatus: FAILED,
      error: error || `${status} ${message}`,
    };
  }

  return {
    ...state,
    uploadTikTokContentStatus: FAILED,
  };
};

const getClientSettingEnd = (state) => {
  return {
    ...state,
    getClientSettingStatus: NONE,
  };
};

const getClientDetailRequest = (state) => {
  return {
    ...state,
    getClientDetailStatus: IN_PROGRESS,
  };
};

const getClientDetailSucceeded = (state, { payload }) => {
  if (payload) {
    const {
      status: { code = NONE },
      result: clientDetail,
    } = payload.data;

    const { id, brandName } = clientDetail || {};
    sessionStorage.setItem("clientId", id);
    sessionStorage.setItem("clientName", brandName);

    // TODO, will be update once we have offical response data format from BE
    if (code === STATUS_CODE.SUCCESS) {
      return {
        ...state,
        clientDetail,
        getClientDetailStatus: SUCCEEDED,
      };
    }
  }

  return {
    ...state,
    getClientDetailStatus: FAILED,
  };
};

const getClientDetailFailed = (state, { payload = DEFAULT_ERROR }) => {
  if (payload.data) {
    const { message = "", status = NONE, error = "" } = payload.data;

    return {
      ...state,
      getClientDetailStatus: FAILED,
      error: error || `${status} ${message}`,
    };
  }

  return {
    ...state,
    getClientDetailStatus: FAILED,
  };
};

const getClientDetailEnd = (state) => {
  return {
    ...state,
    getClientDetailStatus: NONE,
  };
};

const getLocationRequest = (state) => {
  return {
    ...state,
    getLocationStatus: IN_PROGRESS,
  };
};

const getLocationSucceeded = (state, { payload }) => {
  if (payload) {
    // TODO, will be update once we have offical response data format from BE
    return {
      ...state,
      uploadLocation: payload,
      getLocationStatus: SUCCEEDED,
    };
  }

  return {
    ...state,
    getLocationStatus: FAILED,
  };
};

const getLocationFailed = (state) => {
  return {
    ...state,
    getLocationStatus: FAILED,
  };
};

const getLocationEnd = (state) => {
  return {
    ...state,
    getLocationStatus: NONE,
  };
};

const validateCampaignRequest = (state) => {
  return {
    ...state,
    validateCampaignStatus: IN_PROGRESS,
  };
};

const validateCampaignSucceeded = (state, { payload }) => {
  if (payload) {
    const {
      status: { code = NONE },
      result: campaign,
    } = payload.data;

    // TODO, will be update once we have offical response data format from BE
    if (code === STATUS_CODE.SUCCESS) {
      return {
        ...state,
        campaign,
        validateCampaignStatus: SUCCEEDED,
      };
    }
  }

  return {
    ...state,
    validateCampaignStatus: FAILED,
  };
};

const validateCampaignFailed = (state, { payload }) => {
  if (payload.data) {
    const { message = "", status = NONE, error = "" } = payload.data;

    return {
      ...state,
      validateCampaignStatus: FAILED,
      error: error || `${status} ${message}`,
    };
  }

  return {
    ...state,
    validateCampaignStatus: FAILED,
  };
};

const validateCampaignEnd = (state) => {
  return {
    ...state,
    validateCampaignStatus: NONE,
  };
};

const capturateContentRequest = (state, { payload }) => {
  if (payload) {
    const { fileProgressId } = payload || {};
    const { fileProgress } = state;

    // TODO, will be update once we have offical response data format from BE

    const stateTemp = {
      ...state,
      fileProgress: fileProgress.length
        ? updateList(
            { fileProgressId, status: IN_CAPTURATE },
            fileProgress,
            "fileProgressId"
          )
        : [],
    };

    return {
      ...stateTemp,
    };
  }
};

const uploadSocialContentRequest = (state) => {
  return {
    ...state,
    uploadSocialContentStatus: IN_PROGRESS,
  };
};

const uploadSocialContentSucceeded = (state, { payload }) => {
  if (payload) {
    const {
      status: { code = NONE },
      result: uploadSocialContentResult = [],
    } = payload.data;

    // TODO, will be update once we have offical response data format from BE
    if (code === STATUS_CODE.SUCCESS) {
      return {
        ...state,
        uploadSocialContentStatus: SUCCEEDED,
        uploadSocialContentResult,
      };
    }
  }

  return {
    ...state,
    uploadSocialContentStatus: FAILED,
  };
};

const uploadSocialContentFailed = (state, { payload = DEFAULT_ERROR }) => {
  if (payload.data) {
    const { message = "", status = NONE, error = "" } = payload.data;

    return {
      ...state,
      uploadSocialContentStatus: FAILED,
      uploadSocialContentError: error || `${status} ${message}`,
    };
  }

  return {
    ...state,
    uploadSocialContentStatus: FAILED,
    uploadSocialContentError: DEFAULT_ERROR,
  };
};

const uploadSocialContentEnd = (state) => {
  return {
    ...state,
    uploadSocialContentStatus: NONE,
    uploadSocialContentError: null,
  };
};

const getCreatorRequest = (state) => {
  return {
    ...state,
    getCreatorStatus: IN_PROGRESS,
  };
};

const getCreatorSucceeded = (state, { payload }) => {
  if (payload) {
    const { result: creatorInformation } = payload;
    // TODO, will be update once we have offical response data format from BE
    return {
      ...state,
      creatorInformation,
      getCreatorStatus: SUCCEEDED,
    };
  }

  return {
    ...state,
    getCreatorStatus: FAILED,
  };
};

const getCreatorFailed = (state, { payload }) => {
  if (payload) {
    const { error } = payload;

    return {
      ...state,
      getCreatorStatus: FAILED,
      creatorError: error,
    };
  }

  return {
    ...state,
    getCreatorStatus: FAILED,
    gettingCreatorError: DEFAULT_ERROR,
  };
};

const getCreatorEnd = (state) => {
  return {
    ...state,
    getCreatorStatus: NONE,
  };
};

const getTermsRequest = (state) => {
  return {
    ...state,
    getTermsStatus: IN_PROGRESS,
  };
};

const getTermsSucceeded = (state, { payload }) => {
  if (payload) {
    const { result: termsResult } = payload;
    // TODO, will be update once we have offical response data format from BE
    return {
      ...state,
      termsResult,
      getTermsStatus: SUCCEEDED,
    };
  }

  return {
    ...state,
    getTermsStatus: FAILED,
  };
};

const getTermsFailed = (state, { payload }) => {
  if (payload) {
    const { error } = payload;

    return {
      ...state,
      getTermsStatus: FAILED,
    };
  }

  return {
    ...state,
    getTermsStatus: FAILED,
  };
};

const getTermsEnd = (state) => {
  return {
    ...state,
    getCreatorStatus: NONE,
  };
};

const getInstagramAccessTokenRequest = (state) => {
  return {
    ...state,
    getInstagramAccessTokenStatus: IN_PROGRESS,
  };
};

const getInstagramAccessTokenSucceeded = (state, { payload }) => {
  if (payload) {
    const { result: instagramAccessTokenResult } = payload;
    // TODO, will be update once we have offical response data format from BE
    return {
      ...state,
      instagramAccessTokenResult,
      getInstagramAccessTokenStatus: SUCCEEDED,
    };
  }

  return {
    ...state,
    getInstagramAccessTokenStatus: FAILED,
  };
};

const getInstagramAccessTokenFailed = (state, { payload }) => {
  if (payload) {
    const { error } = payload;

    return {
      ...state,
      error,
      getInstagramAccessTokenStatus: FAILED,
    };
  }

  return {
    ...state,
    getInstagramAccessTokenStatus: FAILED,
  };
};

const getInstagramAccessTokenEnd = (state) => {
  return {
    ...state,
    getCreatorStatus: NONE,
  };
};

const getInstagramMediaRequest = (state) => {
  return {
    ...state,
    getInstagramMediaStatus: IN_PROGRESS,
  };
};

const cleanInstagramContentItem = (item) => {
  if (item.children) {
    delete item.children;
  }
  delete item.media_type;
  delete item.media_url;
  delete item.thumbnail_url;
};

const getInstagramMediaSucceeded = (state, { payload }) => {
  if (payload) {
    // TODO, will be update once we have offical response data format from BE
    let instagramMediaResultTemp = { ...payload };
    const { instagramAccessTokenResult } = state;
    const dataTemp = [];
    instagramMediaResultTemp?.data?.forEach((item) => {
      if (item?.children) {
        item?.children.data.forEach((childItem) => {
          const childItemTemp = {
            ...item,
            ...childItem,
            mediaId: childItem.id,
            mediaType: childItem.media_type,
            mediaUrl: childItem.media_url,
            userId: instagramAccessTokenResult?.user_id,
          };
          if (childItem.thumbnail_url) {
            childItemTemp.thumbnailUrl = childItem.thumbnail_url;
          }
          cleanInstagramContentItem(childItemTemp);
          dataTemp.push(childItemTemp);
        });
      } else {
        const itemTemp = {
          ...item,
          mediaId: item.id,
          mediaType: item.media_type,
          mediaUrl: item.media_url,
          userId: instagramAccessTokenResult?.user_id,
        };
        if (itemTemp.thumbnail_url) {
          itemTemp.thumbnailUrl = itemTemp.thumbnail_url;
        }
        cleanInstagramContentItem(itemTemp);
        dataTemp.push(itemTemp);
      }
    }) || [];
    instagramMediaResultTemp.data = dataTemp;

    if (!state.instagramAccount) {
      state.instagramAccount = { username: dataTemp[0]?.username };
    }

    return {
      ...state,
      instagramMediaResult: instagramMediaResultTemp,
      getInstagramMediaStatus: SUCCEEDED,
    };
  }

  return {
    ...state,
    getInstagramMediaStatus: FAILED,
  };
};

const getInstagramMediaFailed = (state, { payload }) => {
  if (payload) {
    const { error } = payload;

    return {
      ...state,
      error,
      getInstagramMediaStatus: FAILED,
    };
  }

  return {
    ...state,
    getInstagramMediaStatus: FAILED,
  };
};

const getInstagramMediaEnd = (state) => {
  return {
    ...state,
    getInstagramMediaStatus: NONE,
  };
};

const storeData = (state, { payload }) => {
  return {
    ...state,
    ...payload,
  };
};

const widgetsReducers = handleActions(
  {
    [setUploadProgressTypes.SET_UPLOAD_PROGRESS]: setUploadProgress,
    [cancelTypes.CANCEL_REQUEST]: cancelRequest,
    [storeDataTypes.STORE_DATA]: storeData,

    [uploadContentTypes.UPLOAD_CONTENT_REQUEST]: uploadContentRequest,
    [uploadContentTypes.UPLOAD_CONTENT_SUCCEEDED]: uploadContentSucceeded,
    [uploadContentTypes.UPLOAD_CONTENT_FAILED]: uploadContentFailed,
    [uploadContentTypes.UPLOAD_CONTENT_END]: uploadContentEnd,

    [capturateContentTypes.CAPTURATE_CONTENT_REQUEST]: capturateContentRequest,

    [createContentTypes.CREATE_CONTENT_REQUEST]: createContentRequest,
    [createContentTypes.CREATE_CONTENT_SUCCEEDED]: createContentSucceeded,
    [createContentTypes.CREATE_CONTENT_FAILED]: createContentFailed,
    [createContentTypes.CREATE_CONTENT_END]: createContentEnd,

    [uploadTikTokContentTypes.UPLOAD_TIKTOK_CONTENT_REQUEST]:
      uploadTikTokContentRequest,
    [uploadTikTokContentTypes.UPLOAD_TIKTOK_CONTENT_SUCCEEDED]:
      uploadTikTokContentSucceeded,
    [uploadTikTokContentTypes.UPLOAD_TIKTOK_CONTENT_FAILED]:
      uploadTikTokContentFailed,
    [uploadTikTokContentTypes.UPLOAD_TIKTOK_CONTENT_END]:
      uploadTikTokContentEnd,

    [getClientSettingTypes.GET_CLIENT_SETTING_REQUEST]: getClientSettingRequest,
    [getClientSettingTypes.GET_CLIENT_SETTING_SUCCEEDED]:
      getClientSettingSucceeded,
    [getClientSettingTypes.GET_CLIENT_SETTING_FAILED]: getClientSettingFailed,
    [getClientSettingTypes.GET_CLIENT_SETTING_END]: getClientSettingEnd,

    [getClientDetailTypes.GET_CLIENT_DETAIL_REQUEST]: getClientDetailRequest,
    [getClientDetailTypes.GET_CLIENT_DETAIL_SUCCEEDED]:
      getClientDetailSucceeded,
    [getClientDetailTypes.GET_CLIENT_DETAIL_FAILED]: getClientDetailFailed,
    [getClientDetailTypes.GET_CLIENT_DETAIL_END]: getClientDetailEnd,

    [getLocationTypes.GET_LOCATION_REQUEST]: getLocationRequest,
    [getLocationTypes.GET_LOCATION_SUCCEEDED]: getLocationSucceeded,
    [getLocationTypes.GET_LOCATION_FAILED]: getLocationFailed,
    [getLocationTypes.GET_LOCATION_END]: getLocationEnd,

    [validateCampaignTypes.VALIDATE_CAMPAIGN_REQUEST]: validateCampaignRequest,
    [validateCampaignTypes.VALIDATE_CAMPAIGN_SUCCEEDED]:
      validateCampaignSucceeded,
    [validateCampaignTypes.VALIDATE_CAMPAIGN_FAILED]: validateCampaignFailed,
    [validateCampaignTypes.VALIDATE_CAMPAIGN_END]: validateCampaignEnd,

    [getGeolocationTypes.GET_GEOLOCATION_REQUEST]: getLocationRequest,

    [uploadGoogleDriveContentTypes.UPLOAD_GOOGLE_DRIVE_CONTENT_REQUEST]:
      uploadSocialContentRequest,
    [uploadGoogleDriveContentTypes.UPLOAD_GOOGLE_DRIVE_CONTENT_SUCCEEDED]:
      uploadSocialContentSucceeded,
    [uploadGoogleDriveContentTypes.UPLOAD_GOOGLE_DRIVE_CONTENT_FAILED]:
      uploadSocialContentFailed,
    [uploadGoogleDriveContentTypes.UPLOAD_GOOGLE_DRIVE_CONTENT_END]:
      uploadSocialContentEnd,

    [uploadYouTubeContentTypes.UPLOAD_YOUTUBE_CONTENT_REQUEST]:
      uploadSocialContentRequest,
    [uploadYouTubeContentTypes.UPLOAD_YOUTUBE_CONTENT_SUCCEEDED]:
      uploadSocialContentSucceeded,
    [uploadYouTubeContentTypes.UPLOAD_YOUTUBE_CONTENT_FAILED]:
      uploadSocialContentFailed,
    [uploadYouTubeContentTypes.UPLOAD_YOUTUBE_CONTENT_END]:
      uploadSocialContentEnd,

    [uploadInstagramContentTypes.UPLOAD_INSTAGRAM_CONTENT_REQUEST]:
      uploadSocialContentRequest,
    [uploadInstagramContentTypes.UPLOAD_INSTAGRAM_CONTENT_SUCCEEDED]:
      uploadSocialContentSucceeded,
    [uploadInstagramContentTypes.UPLOAD_INSTAGRAM_CONTENT_FAILED]:
      uploadSocialContentFailed,
    [uploadInstagramContentTypes.UPLOAD_INSTAGRAM_CONTENT_END]:
      uploadSocialContentEnd,

    [getCreatorTypes.GET_CREATOR_REQUEST]: getCreatorRequest,
    [getCreatorTypes.GET_CREATOR_SUCCEEDED]: getCreatorSucceeded,
    [getCreatorTypes.GET_CREATOR_FAILED]: getCreatorFailed,
    [getCreatorTypes.GET_CREATOR_END]: getCreatorEnd,

    [getTermsTypes.GET_TERMS_REQUEST]: getTermsRequest,
    [getTermsTypes.GET_TERMS_SUCCEEDED]: getTermsSucceeded,
    [getTermsTypes.GET_TERMS_FAILED]: getTermsFailed,
    [getTermsTypes.GET_TERMS_END]: getTermsEnd,

    [getInstagramAccessTokenTypes.GET_INSTAGRAM_ACCESS_TOKEN_REQUEST]:
      getInstagramAccessTokenRequest,
    [getInstagramAccessTokenTypes.GET_INSTAGRAM_ACCESS_TOKEN_SUCCEEDED]:
      getInstagramAccessTokenSucceeded,
    [getInstagramAccessTokenTypes.GET_INSTAGRAM_ACCESS_TOKEN_FAILED]:
      getInstagramAccessTokenFailed,
    [getInstagramAccessTokenTypes.GET_INSTAGRAM_ACCESS_TOKEN_END]:
      getInstagramAccessTokenEnd,

    [getInstagramMediaTypes.GET_INSTAGRAM_MEDIA_REQUEST]:
      getInstagramMediaRequest,
    [getInstagramMediaTypes.GET_INSTAGRAM_MEDIA_SUCCEEDED]:
      getInstagramMediaSucceeded,
    [getInstagramMediaTypes.GET_INSTAGRAM_MEDIA_FAILED]:
      getInstagramMediaFailed,
    [getInstagramMediaTypes.GET_INSTAGRAM_MEDIA_END]: getInstagramMediaEnd,
  },
  initialState
);

export default widgetsReducers;
