import React, { Component } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { debounce as _debounce, isString as _isString } from "lodash";
import { faCamera, faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import configUrls from "../../../../constants/configUrls";
import errorMsgs from "../../../../constants/errorMsgs";
import utils from "../../../../services/utils";
import Select from "react-select/creatable";
import "./CirclekCanada.scss";
import logoImg from "../../../../assets/img/logo-white.png";
import circlekcanadaLogo from "../../../../assets/img/circlekcanada/circlekcanadaLogo.png";
import fields from "./Fields";
import Form from "../../FormValidate";
import {
  DEFAULT_WIDGET_ID,
  SUPPORTED_FILE,
} from "../../../../constants/common";

class CirclekCanada extends Component {
  static propTypes = {
    globalProps: PropTypes.shape({
      setIsShowLoading: PropTypes.func,
      requestAPI: PropTypes.func,
      didMountCallback: PropTypes.func,
    }),
  };

  static defaultProps = {
    globalProps: {
      setIsShowLoading: () => {},
      requestAPI: () => {},
      didMountCallback: () => {},
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      isDisabledWidget: false,
      formState: 2, // 1 - Welcome, 2 - Upload, 3 - Thankyou

      client: null,
      challenge: null,
      selectId: null,
      location: null,

      takenLocationSelectField: [],
      agreementSelectField: false,
      errorMsg: "",
      uploadedFiles: [],
      uploadedFileType: "",
      previewTxt: "",
      previewImg: "",

      generalMessage: {
        message: "",
        type: "success",
      },

      uploadResults: null,
    };

    this.input = {
      hashtagInput: React.createRef(),
    };
  }

  componentWillReceiveProps(nextProps) {
    const {
      client = null,
      challenge = null,
      selectId = null,
      uploadLocation = null,
    } = nextProps;
    this.checkChallenge(challenge);

    this.setState({
      client,
      selectId,
      location: uploadLocation,
    });
  }

  componentWillMount() {
    this.setOnChangeEmailEvent();
  }

  componentDidMount() {
    const {
      globalProps: { didMountCallback },
    } = this.props;
    didMountCallback();
  }

  checkChallenge(challenge) {
    const { selectId = null, client = null } = this.props;

    if (challenge) {
      this.setState({ challenge, isDisabledWidget: false });
      this.clearMessage();

      // Only validate challenge and show error if selectId is empty
    } else if (!challenge && !selectId && !client) {
      this.setState({ isDisabledWidget: true });
      this.setGeneralError("Invalid data", "error");
    }
  }

  setOnChangeEmailEvent() {
    this.onChangeEmailCallback = _debounce((e, setFieldValue) => {
      const email = e.target.value.trim();
      if (email && utils.validateEmail(email)) {
        this.getCreatorAPI(email, setFieldValue);
      }
    }, 1000);
  }

  getCreatorAPI = (email, setFieldValue) => {
    const {
      globalProps: { requestAPI },
    } = this.props;

    const successCallback = (resp) => {
      const {
        firstName = "",
        lastName = "",
        instagramUsername = "",
        twitterUsername = "",
      } = resp || {};

      setFieldValue("firstName", utils.capitalizeFirstLetter(firstName));
      setFieldValue("lastName", utils.capitalizeFirstLetter(lastName));
      setFieldValue("instagramUsername", instagramUsername);
      setFieldValue("twitterUsername", twitterUsername);
    };

    const failedCallback = (resp) => {
      const errorMsg = resp.error
        ? resp.error
        : resp.status
        ? resp.status.status
        : errorMsgs["en"].callAPIFailed;
      this.setState({ errorMsg });
      this.resetAutoFilledFields(setFieldValue);
    };

    const clientId = sessionStorage.getItem("clientId");
    const url = `${configUrls.API.getCreator}?clientId=${clientId}&email=${email}`;
    requestAPI("get", url, {}, successCallback, failedCallback);
  };

  resetAutoFilledFields = (setFieldValue) => {
    setFieldValue("firstName", "");
    setFieldValue("lastName", "");
    setFieldValue("instagramUsername", "");
    setFieldValue("twitterUsername", "");
  };

  uploadAPI(values, resetForm, setIsAgreed) {
    const {
      globalProps: { requestAPI },
    } = this.props;

    const {
      client: { id: clientId },
      challenge,
      selectId,
      uploadedFiles,
      uploadedFileType,
      selectedHashtags,
      location,
      agreementSelectField,
      takenLocationSelectField,
    } = this.state;
    const {
      email = "",
      lastName = "",
      firstName = "",
      instagramUsername = "",
      twitterUsername = "",
      tiktokUsername = "",
    } = values;

    const additional = {
      custom: [
        {
          name: "Where In Canada was this photo taken?",
          values: takenLocationSelectField.value
            ? [takenLocationSelectField.value]
            : [],
          isTaggable: true,
          isSearchable: true,
        },
        {
          name: "Agree to the CircleK Candana Terms & Conditions?",
          values: agreementSelectField ? ["Yes"] : ["No"],
          isTaggable: false,
          isSearchable: true,
        },
      ],
    };

    const currentTimeStamp = new Date().valueOf();

    const data = {
      clientId,
      challengeId: (challenge && challenge.id) || 0,
      selectId,
      uploadedFiles,
      uploadedFileType,
      selectedHashtags,
      location,
      email,
      firstName,
      lastName,
      instagramUsername,
      twitterUsername,
      tiktokUsername,
      additional,
    };

    const formData = utils.parseUploadFormData(data);
    let url = `${configUrls.API.upload}`;

    const successCallback = (resp) => {
      this.setState(
        {
          formState: 3,
          uploadResults: resp,
        },
        () => {
          resetForm();
          setIsAgreed(false);
          this.setState({ ...utils.getEmptyFormData() });
        }
      );
    };

    const failedCallback = (resp) => {
      const errorMsg = resp.error ? resp.error : errorMsgs["en"].uploadFailed;
      this.setGeneralError(errorMsg, "error");
    };

    url = `${url}?access_time=${currentTimeStamp}`;
    if (data.selectId) {
      url = `${url}&s=${data.selectId}`;
    }
    if (data.challengeId) {
      url = `${url}&c=${data.challengeId}`;
    }
    url = `${url}&widgetId=${DEFAULT_WIDGET_ID}`;
    requestAPI("post", url, formData, successCallback, failedCallback);
  }

  setServerErrMessage = () => {
    this.setState({
      isDisabledWidget: true,
      generalMessage: { type: "error", message: "Oops, something went wrong!" },
    });
  };

  clearMessage = () => {
    this.setState({ generalMessage: { message: "", type: "success" } });
  };

  setGeneralError = (message, type = "success") => {
    this.setState({ generalMessage: { message, type } });
  };

  onSubmit = async (values = {}, resetForm, setIsAgreed) => {
    const { uploadedFiles } = this.state;
    const isValidUploadedFiles = await this.validateUploadedFiles(
      uploadedFiles
    );

    Object.keys(values).forEach((key) => {
      /*if (key !== "description" && key !== "hashtags" && _isString(values[key])) {
        values[key] = values[key].toLowerCase();
      }*/

      values[key] = _isString(values[key]) && values[key].trim();
      !values[key] && delete values[key];
    });

    if (isValidUploadedFiles) {
      this.uploadAPI(values, resetForm, setIsAgreed);
    }
  };

  onUploadMore = () => {
    this.setState({
      formState: 2,
      takenLocationSelectField: [],
      agreementSelectField: false,
    });
  };

  onDrop = async (files, setFieldValue = () => {}) => {
    const { setIsShowLoading } = this.props.globalProps;
    files = await utils.convertFileUploads(files, setIsShowLoading);

    if (this.validateUploadedFiles(files)) {
      this.renderPreviewTxt(files);
      this.renderPreviewImg(files);
      this.setState({ uploadedFiles: files });
      setFieldValue("fileUploads", files);
    } else {
      this.setState({ uploadedFiles: [] });
    }
  };

  validateSocialUsernames = () => {};

  validateUploadedFiles = async (files) => {
    const { errorMsg, isValidFiles, previewImg, previewTxt } =
      await utils.validateUploadedFiles(files);

    if (!isValidFiles) {
      this.setState({
        uploadedFiles: [],
        previewImg,
        previewTxt,
      });
      this.setGeneralError(errorMsg, "error");

      return false;
    }

    this.setState({ uploadedFiles: files });
    this.clearMessage();
    return true;
  };

  renderPreviewTxt = (files) => {
    this.setState({ previewTxt: utils.getPreviewTxt(files) });
  };

  renderPreviewImg(files) {
    utils.getPreviewImgAndType(files).then((data) => {
      const { previewImg, uploadedFileType } = data;
      this.setState({ previewImg, uploadedFileType });
    });
  }

  moveToUploadWidget = () => {
    this.setState({ formState: 2 });
  };

  onChangeEmail = (e, setFieldValue) => {
    e.persist();
    this.onChangeEmailCallback(e, setFieldValue);
  };

  formatGroupLabel = (data) => {
    return (
      <div className="groupStyles">
        <span>{data.label}</span>
        <span className="groupBadgeStyles">{data.options.length}</span>
      </div>
    );
  };

  onChangeAgreement = (e) => {
    this.setState({ agreementSelectField: e.target.checked });
  };

  onChangeTakenLocationSelect = (e) => {
    this.setState({ takenLocationSelectField: e });
  };

  renderField = (field) => {
    const {
      placeholder = "",
      label = "",
      touched = {},
      errors = {},
      getFieldProps = () => {},
      type = "text",
      isSocialMedia = false,
      setFieldValue = () => {},
    } = field;
    const { uploadedFiles, previewTxt, previewImg } = this.state;
    const dropzoneStyles = utils.getDropzoneStyles(uploadedFiles, previewImg);
    const previewImgStyles = utils.getPreviewImgStyles(previewImg);
    let formContainer = "form-group";
    let dropzoneClassName =
      uploadedFiles.length > 0 && uploadedFiles[0].type.includes("video")
        ? "dropzone video"
        : "dropzone";

    if (type === "file") {
      formContainer = "widget-media-wrapper w-50 circlekcanada-media";
    }

    const isEmail = type === "email";

    if (previewImg) {
      dropzoneStyles.backgroundColor = "transparent";
      previewImgStyles.backgroundImage = `url(${previewImg})`;

      if (uploadedFiles && uploadedFiles.length > 1) {
        dropzoneStyles.backgroundColor = "#343a40";
      }
    } else {
      previewImgStyles.backgroundImage = "none";
    }

    let customizeOnChange = () => {};

    if (isEmail) {
      const { onChange = () => {} } = field.getFieldProps(label); // get Formik Input onChange Event
      customizeOnChange = (e) => {
        onChange(e);
        this.onChangeEmail(e, setFieldValue);
      };
    }

    return (
      <div className={formContainer}>
        {type === "file" && (
          <div>
            <div className="text-wrapper">
              <h4>
                Hello, <br />
                CircleK Canada Community
              </h4>
              <p>
                We want to hear from you! <br /> Share your favorite
                <br /> pictures and videos.{" "}
              </p>
            </div>
            <div className="widget-media">
              <div className="preview-img" style={previewImgStyles}>
                <Dropzone
                  onDrop={(files) => this.onDrop(files, setFieldValue)}
                  accept={SUPPORTED_FILE.ACCEPTED}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div
                      {...getRootProps()}
                      className={dropzoneClassName}
                      style={dropzoneStyles}
                      title={utils.getFileUploadToolTip()}
                    >
                      <input {...getInputProps()} />
                    </div>
                  )}
                </Dropzone>
                {uploadedFiles.length >= 1 && <p>{previewTxt}</p>}
                {uploadedFiles.length === 0 && (
                  <div className="preview-txt-default">
                    <FontAwesomeIcon icon={faCamera} size="lg" />
                  </div>
                )}
              </div>
            </div>
            <p className={uploadedFiles.length >= 1 ? "text hide" : "text"}>
              Drag and Drop or Click here <br />
              to upload your media file
            </p>
          </div>
        )}

        {(type === "text" || type === "email") && !isSocialMedia && (
          <div className="input-wrapper">
            <input
              placeholder={placeholder}
              className="form-control form-control-sm"
              type={type}
              name={label}
              id={label}
              key={label}
              {...getFieldProps(label)}
              {...(isEmail && { onChange: (e) => customizeOnChange(e) })}
              {...(isEmail && { onKeyUp: (e) => customizeOnChange(e) })}
            />
            <small className="error-message uploader-error-message">
              {touched[label] && errors[label]}
            </small>
          </div>
        )}

        {isSocialMedia && (
          <div className="input-wrapper">
            <div className="social-media-input">
              <span>@</span>
              <input
                name={label}
                id={label}
                key={label}
                placeholder={placeholder}
                type={type}
                className="form-control form-control-sm"
                {...getFieldProps(label)}
              />
            </div>
            <small className="error-message uploader-error-message">
              {touched[label] && errors[label]}
            </small>
          </div>
        )}

        {type === "select" && (
          <div className="input-wrapper">
            <Select
              name={label}
              id={label}
              key={label}
              isClearable
              classNamePrefix="react-select"
              options={
                fields.find(
                  (field) => field.type === "select" && field.label === label
                ).selectOption
              }
              onChange={this.onChangeTakenLocationSelect}
              placeholder={placeholder}
            />
            <small className="error-message uploader-error-message">
              {touched[label] && errors[label]}
            </small>
          </div>
        )}

        {type === "checkbox" && (
          <div className="widget-agreement px-0">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id={label}
                onChange={this.onChangeAgreement}
              />
              <label className="form-check-label" htmlFor={label}>
                I confirm that the creation of this photo did not disrupt the
                customer experience at CircleK, did not harm the customers or
                team members of CircleK, and did not violate government health
                and safety mandated regulations
              </label>
            </div>
          </div>
        )}
      </div>
    );
  };

  render() {
    const {
      isDisabledWidget,
      formState,
      uploadResults,
      uploadedFiles,
      generalMessage,
      uploadedFileType,
    } = this.state;

    const widgetContainerClasses = isDisabledWidget
      ? "widget-container disabled"
      : "widget-container";

    const lastUploadedFile = utils.getLastUploadedFile(uploadResults);

    return (
      <div className={`widget-circlekcanada ${widgetContainerClasses}`}>
        {formState === 2 && (
          <div className="container">
            <div className="widget-block widget-upload">
              <div className="widget-header px-5">
                <img src={circlekcanadaLogo} alt="circlekcanadaLogo" />
              </div>
              <Form
                fields={fields}
                logo={logoImg}
                uploadedFiles={uploadedFiles}
                generalMessage={generalMessage}
                renderField={this.renderField}
                clearMessage={this.clearMessage}
                onSubmit={this.onSubmit}
                setGeneralError={this.setGeneralError}
                isNewForm={true}
                isNewFormResponsive={true}
                clientName="circlekcanada"
              />
              <div className="widget-footer">
                <div className="signature text-center">
                  Powered By{" "}
                  <a
                    href="https://www.entribe.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={logoImg} alt="logo" className="logo" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Start Thank You Form */}
        {formState === 3 && (
          <div className="container">
            <div className="widget-block widget-thankyou">
              <div className="widget-header">
                <img src={circlekcanadaLogo} alt="circlekcanadaLogo" />
              </div>
              {/* Start Last Upload */}
              <div className="widget-body">
                <h3>Thank you for the upload!</h3>
                <div className="uploaded-media">
                  {lastUploadedFile && (
                    <img
                      src={lastUploadedFile}
                      alt="widget upload"
                      onError={(e) =>
                        utils.handleThumbnailLoadedError(
                          e,
                          uploadedFileType || ""
                        )
                      }
                    />
                  )}
                  {uploadResults && uploadResults.type === "video" && (
                    <FontAwesomeIcon icon={faPlayCircle} size="lg" />
                  )}
                </div>
                {/* End Last Upload */}
              </div>
              <div className="widget-footer">
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={() => this.onUploadMore()}
                >
                  Upload More
                </button>

                <div className="widget-footer">
                  <div className="signature text-center">
                    Powered By{" "}
                    <a
                      href="https://www.entribe.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      <img src={logoImg} alt="logo" className="logo" />
                    </a>
                  </div>
                </div>
              </div>
              {/* End Footer */}
            </div>
          </div>
        )}
        {/* End Thank You Form */}
      </div>
    );
  }
}

export default withRouter(CirclekCanada);
