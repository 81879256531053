import { Select } from "antd";
import { useEffect, useState } from "react";
import { StyledSelect } from "styled-components/WidgetBuilder/WidgetBuilderStyled";

const CustomizedSelect = (props) => {
  const { options, isMobile, ...otherProps } = props;
  const [newOption, setNewOption] = useState();

  useEffect(() => {
    return () => {
      setNewOption(undefined);
    };
  }, [options]);

  const handleSearch = (value) => {
    if (value) {
      setNewOption(value);
    }
  };

  return (
    <StyledSelect
      className="dropdown-select_detail_widget"
      onSearch={handleSearch}
      showSearch
      showArrow
      allowClear
      isMobile={isMobile}
      {...otherProps}
    >
      {newOption &&
        options.filter((item) => item.value === newOption).length === 0 && (
          <Select.Option key={newOption} value={newOption}>
            {newOption}
          </Select.Option>
        )}
      {options.map((item) => {
        return (
          <Select.Option key={item.value} value={item.value}>
            {item.label}
          </Select.Option>
        );
      })}
    </StyledSelect>
  );
};

export default CustomizedSelect;
