import * as React from "react";

// Default context values
const values = {
  uploadedFiles: [],
  setUploadedFiles: () => {},
  setActiveTerm: () => {},
  setTerms: () => {},
  isSubmitting: false,
  setIsSubmitting: () => {},
};

const GeminiBodyContext = React.createContext(values);
export default GeminiBodyContext;
