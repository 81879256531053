import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  CANCELLED,
  FAILED,
  IN_CAPTURATE,
  IN_PROGRESS,
  MAX_PERCENT,
  SUCCEEDED,
} from "../../../constants/common";
import { cancelRequest } from "../../../stores/actions";
import { StyledModal } from "../../../styled-components/WidgetBuilder/UploadProgressStyled";
import ModalTitle from "../../shared/ModalTitle";
import UploadProgressItem from "./UploadProgressItem";

const UploadProgress = (props) => {
  const { onCancelUpload, widgetInfo } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { widgetSettingsDTO: { isNewFlow = false } = {} } = widgetInfo || {};

  const { fileProgress } = useSelector((state) => state.widgets);
  const [visible, setVisible] = useState(false);

  const additionalInfo = useMemo(() => {
    let maximumRemainingTime = 0;
    fileProgress.forEach((item) => {
      const { remainingTime } = item;
      maximumRemainingTime = remainingTime;
      if (remainingTime > maximumRemainingTime) {
        maximumRemainingTime = remainingTime;
      }
    });
    const validFileProgress = fileProgress.filter(
      (item) => !item.limitationError
    );

    // If calling capurate content API is executing or successful at all files => prevent user cancels uploading
    const isCapturatingContentProgress =
      !!validFileProgress.length &&
      validFileProgress.every(
        (item) => item.status === IN_CAPTURATE || item.status === SUCCEEDED
      );

    return {
      remainingTime: maximumRemainingTime / 60,
      isCapturatingContentProgress,
    };
  }, [fileProgress]);

  useEffect(() => {
    if (fileProgress.length) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [fileProgress]);

  const cancelAPIRequest = ({ fileProgressId, isNotRemove, actionType }) => {
    dispatch(
      cancelRequest({
        fileProgressId,
        isNotRemove,
        actionType,
      })
    );
  };

  const renderUploadProgressItem = () => {
    return fileProgress.map((item) => {
      return (
        <UploadProgressItem
          cancelAPIRequest={cancelAPIRequest}
          key={item.fileProgressId}
          progressInfo={item}
          widgetInfo={widgetInfo}
        />
      );
    });
  };

  const renderTitle = useMemo(() => {
    const { remainingTime } = additionalInfo || {};
    let title = `${t("widget_builder.upload_in_progress")} `;

    const isInProgress = fileProgress.some(
      (file) =>
        file.status === IN_PROGRESS &&
        !file.limitationError &&
        file.progress !== MAX_PERCENT
    );

    const isCanceled = fileProgress.every(
      (file) =>
        (file.status === IN_PROGRESS ||
          file.status === FAILED ||
          file.status === CANCELLED) &&
        file.limitationError
    );

    const isSucceeded = fileProgress
      .filter((item) => !item.limitationError)
      .every((file) => file.progress === MAX_PERCENT);

    if (isInProgress) {
      if (remainingTime > 0) {
        title +=
          remainingTime.toFixed() > 1
            ? ` (${remainingTime.toFixed()} ${t("widget_builder.min_left", {
                min: remainingTime > 1 ? "mins" : "min",
              })})`
            : ` (${t("widget_builder.second_left")})`;
      }
    } else if (isCanceled) {
      title = t("widget_builder.upload_cancelled");
    } else if (isSucceeded) {
      title = t("widget_builder.finalizing");
    }

    return title;
  }, [fileProgress]);

  const titleProps = {
    title: renderTitle,
    onClose: onCancelUpload,
    tabIndex: true,
    disabledCloseIcon:
      additionalInfo?.isCapturatingContentProgress && isNewFlow,
  };

  return (
    <StyledModal
      visible={visible}
      title={<ModalTitle {...titleProps} />}
      footer={false}
      onCancel={onCancelUpload}
      centered
      closable={false}
      maskClosable={
        !(additionalInfo?.isCapturatingContentProgress && isNewFlow)
      }
    >
      {renderUploadProgressItem()}
    </StyledModal>
  );
};

UploadProgress.propTypes = {
  widgetInfo: PropTypes.object,
  onCancelUpload: PropTypes.func,
};

export default UploadProgress;
