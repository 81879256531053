import { Skeleton } from "antd";
import { PREFIX_CLS } from "constants/common";
import styled, { css } from "styled-components";

const StyledSkeleton = styled(Skeleton)`
  * {
    ${({ margin }) => margin && `margin: ${margin} !important`};
    ${({ width }) => width && `width: ${width} !important`};
  }

  .${PREFIX_CLS}-skeleton-title, li {
    ${({ rowHeight }) => rowHeight && `height: ${rowHeight} !important`};
  }
`;

const StyledSkeletonButton = styled(Skeleton.Button)`
  width: 100% !important;
  height: 42px !important;
  margin: 0 0 28px 0 !important;
`;

const StyledImageSkeleton = styled(Skeleton.Image)`
  ${({ margin }) => margin && `margin: ${margin} !important`};
  ${({ width }) => width && `width: ${width} !important`};

  .${PREFIX_CLS}-skeleton-image {
    width: 100% !important;
    margin: 0 !important;
    ${({ height }) => height && `height: ${height} !important`};

    ${({ hideSvgImage }) =>
      hideSvgImage &&
      css`
        svg {
          display: none;
        }
      `}
  }
`;

export { StyledImageSkeleton, StyledSkeleton, StyledSkeletonButton };
