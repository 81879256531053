import { DownOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import TermConditions from "components/widgets/WidgetBuilder/TermConditions";
import { FILE_NAME } from "constants/Custom/gemini";
import {
  BRAND_NAME,
  FAILED,
  FIELD_NAME,
  IN_PROGRESS,
  SUCCEEDED,
} from "constants/common";
import THEME from "constants/themes";
import GeminiContext from "contexts/Gemini";
import GeminiBodyContext from "contexts/GeminiBody";
import _ from "lodash";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getImageFileFromUrl } from "services/funcHelper";
import { getBrowserGeolocation } from "services/location";
import utils from "services/utils";
import {
  checkedAllTerms,
  convertUploadPayloadToFormData,
  getSocialUsernameFromURL,
  getTermsIds,
} from "services/widgetBuilder";
import {
  createContentRequest,
  getCreatorRequest,
  getGeoLocationRequest,
  getLocationEnd,
  storeData,
  validateCampaignRequest,
} from "stores/actions";
import {
  StyledCol,
  StyledForm,
  StyledIcon,
  StyledRow,
  StyledWrapper,
} from "styled-components/Common/CommonStyled";
import {
  StyledGeminiSubTitle,
  StyledGeminiSubmitButton,
  StyledGeminiTitle,
} from "styled-components/CustomWidget/GeminiStyled";
import {
  StyledError,
  StyledGoogleTranslate,
} from "styled-components/WidgetBuilder/WidgetBuilderStyled";
import NoMediaProvided from "../../../../../assets/img/gemini/gemini-no-media-thumbnail.png";
import GeminiForm from "./GeminiForm";
import GeminiTerm from "./GerminiTerm";
import UploadSource from "./UploadSource";

const GeminiBody = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = useForm();
  const pathLocation = useLocation();

  const { globalProps, setUploadContentPayloadList } = props;
  const { setIsShowLoading } = globalProps;

  const { search } = pathLocation;
  const isMobile = utils.useDevice();

  const clientId = sessionStorage.getItem("clientId");
  const campaignId = utils.getChallengeId(search); // campaignId
  const selectId = utils.getSelectId(search);

  const {
    creatorInformation,
    getCreatorStatus,
    uploadLocation,
    getLocationStatus,
    isCancelledProgressModal = false,
    fileProgress = [],
    uploadedContentList = [],
    createContentStatus,
    createdContentError,
    creatorError,
    validateCampaignStatus,
    campaign: campaignResult,
    termsResult,
  } = useSelector((state) => state.widgets);
  const {
    errorMsg,
    setUploadPayload,
    handleErrorMsg,
    setIsNoMediaProvided,
    widgetInfo,
  } = useContext(GeminiContext);

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [activeTerm, setActiveTerm] = useState();
  const [terms, setTerms] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [campaign, setCampaign] = useState();

  const socialUsernameFromURL = () => {
    const socialUsernameTemp = getSocialUsernameFromURL(search);
    return utils.removeEmptyValueObj(socialUsernameTemp);
  };

  useEffect(() => {
    if (campaignId) {
      dispatch(
        validateCampaignRequest({
          clientId,
          campaignId,
        })
      );
    }
  }, []);

  useEffect(() => {
    utils.googleTranslateScript();
  }, []);

  useEffect(() => {
    if (termsResult) {
      initTerms(termsResult);
    }
  }, [termsResult]);

  const initTerms = (termsResult = []) => {
    let termsTemp = [];
    const termsConditionsTemp = [...termsResult];

    termsTemp = termsConditionsTemp.map((item) => {
      return {
        ...item,
        required: item.isTermsMandated || false,
        checked: false,
        isAgreed: false,
      };
    });

    setTerms([...termsTemp]);
  };

  useEffect(() => {
    if (getCreatorStatus === SUCCEEDED) {
      if (creatorInformation) {
        setUpCreatorInformation(creatorInformation);
      } else {
        form.setFieldsValue({
          [FIELD_NAME.firstName]: "",
          [FIELD_NAME.lastName]: "",
          [FIELD_NAME.instagram]: "",
          [FIELD_NAME.tiktok]: "",
          [FIELD_NAME.twitter]: "",
        });
      }
    }
    if (getCreatorStatus === IN_PROGRESS) {
      setIsShowLoading(true);
    }

    if (getCreatorStatus === SUCCEEDED || getCreatorStatus === FAILED) {
      setIsShowLoading(false);
    }

    if (creatorError) {
      handleErrorMsg(creatorError);
    }
  }, [getCreatorStatus, creatorInformation, creatorError]);

  useEffect(() => {
    if (getLocationStatus === SUCCEEDED) {
      form.submit();
    }

    if (getLocationStatus === SUCCEEDED || getLocationStatus === FAILED) {
      dispatch(getLocationEnd());
    }
  }, [getLocationStatus]);

  useEffect(() => {
    if (createContentStatus === FAILED && createdContentError) {
      setIsShowLoading(false);
      handleErrorMsg(createdContentError);
    }
  }, [createContentStatus, createdContentError]);

  useEffect(() => {
    if (validateCampaignStatus === SUCCEEDED && campaignResult) {
      setCampaign(utils.parseChallengeData(campaignResult));
    }
  }, [validateCampaignStatus, campaignResult]);

  const setUpCreatorInformation = (creatorInformation) => {
    const basicInfo = _.cloneDeep(creatorInformation) || {};
    setUploadPayload({ ...basicInfo });
    form.setFieldsValue(basicInfo);
  };

  const renderText = () => {
    return (
      <StyledWrapper textAlign="center">
        <StyledGeminiTitle isMobile={isMobile}>
          {t("gemini.title")}
        </StyledGeminiTitle>
        <StyledGeminiSubTitle
          dangerouslySetInnerHTML={{ __html: t("gemini.sub_title") }}
        />
      </StyledWrapper>
    );
  };

  const handleDebounceFn = (email) => {
    const payload = {
      clientId,
      email,
    };
    dispatch(getCreatorRequest(payload));
  };

  const debounceFn = useCallback(_.debounce(handleDebounceFn, 1000), []);

  const onValuesChange = (changedValue) => {
    const fieldName = Object.keys(changedValue)[0];
    if (fieldName.includes(FIELD_NAME.email)) {
      let { email } = changedValue;
      email = email.trim();

      if (email && utils.validateEmail(email)) {
        debounceFn(email);
      }
    }
  };

  const resetStoredData = () => {
    if (
      isCancelledProgressModal ||
      fileProgress.length ||
      uploadedContentList.length
    ) {
      dispatch(
        storeData({
          isCancelledProgressModal: false,
          fileProgress: [],
          uploadedContentList: [],
        })
      );
    }
  };

  const onSubmitUpload = async (changedValue) => {
    resetStoredData();

    const payloadData = {
      widgetId: widgetInfo?.id,
      clientId,
      selectId,
      challengeId: campaign?.id || 0,
      uploadWidgetLink: window.location.href,
      hasCustomTerms: true,
      hasEntribeStandardTerms: true,
      termsIds: getTermsIds(terms),
      termsTemplateId: 2,
      brandName: BRAND_NAME.GEMINI,
      ...changedValue,
      ...socialUsernameFromURL(),
    };

    if (uploadLocation && !_.isEmpty(uploadLocation)) {
      payloadData.ipStack = uploadLocation;
      payloadData.location = uploadLocation;
    }

    const parsedData = utils.parseUploadData(payloadData);
    const uploadedFileList = [...uploadedFiles];

    // Temporarily unused
    if (!uploadedFileList.length) {
      const placeholderFile = await getImageFileFromUrl(
        NoMediaProvided,
        FILE_NAME.NO_MEDIA_PROVIDED
      );
      if (uploadedFileList) {
        uploadedFileList.push(placeholderFile);
      }
      setIsNoMediaProvided(true);
    }

    const uploadContentPayloadListTemp = convertUploadPayloadToFormData(
      parsedData,
      uploadedFileList,
      uploadLocation
    );

    setUploadPayload({ ...payloadData });
    setUploadContentPayloadList(uploadContentPayloadListTemp);
    // Create content
    setIsShowLoading(true);
    dispatch(createContentRequest(parsedData));
  };

  const closeTermConditions = () => {
    setActiveTerm();

    // focus after closing term modal
    const terms = document.getElementById("terms");
    if (terms) {
      terms.setAttribute("tabindex", "0");
      terms.focus();
    }
  };

  const onClickSubmitButton = () => {
    const isExistUploadLocation = uploadLocation && !_.isEmpty(uploadLocation);
    if (!uploadedFiles.length) {
      handleErrorMsg(t("message.no_file_attached"));
    } else {
      if (checkedAllTerms(terms)) {
        form
          .validateFields()
          .then(() => {
            getBrowserGeolocation(
              (position) => {
                isExistUploadLocation
                  ? form.submit()
                  : dispatch(getGeoLocationRequest(position));
              },
              () => form.submit()
            );
          })
          .catch(() => {});
      } else {
        setIsSubmitting(true);
      }
    }
  };

  const renderGoogleTranslate = () => {
    return (
      <StyledGoogleTranslate
        style={{ minHeight: "60px" }}
        id="google_translate_element"
      >
        <StyledIcon color="rgba(0, 0, 0, 0.25)">
          <DownOutlined style={{ fontSize: "13px" }} />
        </StyledIcon>
      </StyledGoogleTranslate>
    );
  };

  const contextValues = {
    uploadedFiles,
    setUploadedFiles,
    setActiveTerm,
    terms,
    setTerms,
    isSubmitting,
    setIsSubmitting,
  };

  return (
    <GeminiBodyContext.Provider value={contextValues}>
      <StyledForm
        form={form}
        onValuesChange={onValuesChange}
        onFinish={onSubmitUpload}
      >
        <StyledRow
          gutter={isMobile ? [] : [32, 16]}
          style={{ marginTop: "42px" }}
        >
          <StyledCol span={isMobile ? 24 : 12}>
            {renderText()}
            {!isMobile && <UploadSource globalProps={globalProps} />}
            {!isMobile && <GeminiTerm />}
          </StyledCol>
          <StyledCol span={isMobile ? 24 : 12}>
            <GeminiForm />
            {isMobile && <UploadSource globalProps={globalProps} />}
            {isMobile && <GeminiTerm />}
            <StyledGeminiSubmitButton
              className="gemini-submit-button"
              type="default"
              onClick={onClickSubmitButton}
            >
              {t("button.submit")}
            </StyledGeminiSubmitButton>
            {errorMsg && (
              <StyledError
                isMobile={isMobile}
                margin="12px 0"
                color={THEME.colors.redBase}
                dangerouslySetInnerHTML={{ __html: errorMsg }}
              />
            )}
            {renderGoogleTranslate()}
          </StyledCol>
        </StyledRow>
      </StyledForm>
      {activeTerm && (
        <TermConditions
          className="gemini-term"
          isMobile={isMobile}
          onClose={closeTermConditions}
          terms={activeTerm}
        />
      )}
    </GeminiBodyContext.Provider>
  );
};

export default GeminiBody;
