const fields = [
  { label: "fileUploads", order: 1, type: "file" },
  {
    label: "email",
    placeholder: "Email",
    order: 2,
    type: "email",
    isRequired: true,
  },
  {
    label: "firstName",
    placeholder: "First name",
    order: 3,
    type: "text",
    isRequired: true,
  },
  {
    label: "lastName",
    placeholder: "Last name",
    order: 4,
    type: "text",
    isRequired: true,
  },
  {
    label: "instagramUsername",
    placeholder: "Instagram username",
    order: 5,
    type: "text",
    isSocialMedia: true,
    isRequired: true,
  },
  {
    label: "twitterUsername",
    placeholder: "Twitter username",
    order: 6,
    type: "text",
    isSocialMedia: true,
    isRequired: false,
  },
];

export default fields;
