export default {
  tl: {
    circlek: 913,
    teampixel: 1163, // Account: cl1, Challenge: #teampixel,
    inkediblescustom: 1163, // Account: cl1, Challenge: #teampixel,
    newgeneration: 0, // No default challenge
    masks4all: 0, // No default challenge
    hudson303: 0, // No default challenge
  },
  qa: {
    circlek: 913,
    teampixel: 1257, // Account: teampixel, Challenge: #teampixel,
    inkediblescustom: 1256, // Account: inkedibles, Challenge: #inkedibles,
    newgeneration: 0, // No default challenge
    masks4all: 0, // No default challenge
    hudson303: 0, // No default challenge
  },
  qav2: {
    circlek: 913,
    teampixel: 1257, // Account: teampixel, Challenge: #teampixel,
    inkedibles: 1256, // Account: inkedibles, Challenge: #inkedibles,
    newgeneration: 0, // No default challenge
    masks4all: 0, // No default challenge
    hudson303: 0, // No default challenge
  },
  prod: {
    circlek: 299,
    teampixel: 434,
    inkediblescustom: 446,
    newgeneration: 0, // No default challenge
    masks4all: 0, // No default challenge
    hudson303: 0, // No default challenge
  },
};
