import React, { Component } from "react";
import "./NotFound.scss";

class NotFound extends Component {
  componentDidMount() {
    const {
      globalProps: { didMountCallback },
    } = this.props;
    didMountCallback();
  }

  componentWillUnmount() {}

  componentDidUpdate() {}

  render() {
    return (
      <div className="page-not-found">
        <h1> Page Not Found </h1>
      </div>
    );
  }
}

export default NotFound;
