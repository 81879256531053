const affiliationOptions = [
  {
    options: [
      { value: "Student", label: "Student" },
      { value: "Employee", label: "Employee" },
      { value: "Alumni", label: "Alumni" },
      { value: "Parent", label: "Parent" },
      { value: "Visitor", label: "Visitor" },
    ],
  },
];

const fields = [
  { label: "fileUploads", order: 1, type: "file" },
  {
    label: "email",
    placeholder: "Email",
    order: 2,
    type: "email",
    isRequired: true,
  },
  {
    label: "firstName",
    placeholder: "First name",
    order: 3,
    type: "text",
    isRequired: true,
  },
  {
    label: "lastName",
    placeholder: "Last name",
    order: 4,
    type: "text",
    isRequired: true,
  },
  {
    label: "instagramUsername",
    placeholder: "Instagram username",
    order: 5,
    type: "text",
    isSocialMedia: true,
    isRequired: false,
  },
  {
    label: "twitterUsername",
    placeholder: "Twitter username",
    order: 6,
    type: "text",
    isSocialMedia: true,
    isRequired: false,
  },
  {
    label: "description",
    placeholder: "Image description and/or hashtags",
    order: 7,
    type: "text",
    isRequired: false,
    requireTdext: "Image description",
  },
  {
    label: "affiliation",
    placeholder: "What is your affiliation with UIW?",
    order: 8,
    type: "select",
    selectOption: affiliationOptions,
    isRequired: false,
    requireTdext: "Your affiliation with UIW",
  },
];

export default fields;
