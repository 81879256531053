import { useGoogleLogin } from "@react-oauth/google";
import { IconYoutube } from "assets/img/icons";
import { GOOGLE_YOUTUBE_API } from "constants/APIs";
import {
  DEFAULT_GETTING_CHANNEL_ERROR,
  FAILED,
  IN_PROGRESS,
  SUCCEEDED,
  UPLOADER_TYPE,
} from "constants/common";
import { GOOGLE } from "constants/widgetBuilder";
import WidgetBuilderContext from "contexts/WidgetBuilder";
import { useContext, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRequest } from "services/api";
import utils from "services/utils";
import {
  getYoutubeVideoListRequest,
  storeGoogleDataRequest,
} from "stores/actions";
import { StyledIcon } from "styled-components/Common/CommonStyled";

const YouTube = () => {
  const dispatch = useDispatch();
  const iconRef = useRef();
  const isCalledRef = useRef(false);

  const { setModalType, modalType } = useContext(WidgetBuilderContext);

  const { playlistId, googleAccessToken, getYoutubeVideoListStatus } =
    useSelector((state) => state.googleStore);

  useEffect(() => {
    if (getYoutubeVideoListStatus && getYoutubeVideoListStatus === SUCCEEDED) {
      if (modalType !== UPLOADER_TYPE.YOUTUBE) {
        setModalType(UPLOADER_TYPE.YOUTUBE);
      }
    } else if (getYoutubeVideoListStatus === FAILED && iconRef.current) {
      iconRef.current?.click();
      isCalledRef.current = false;
    }
  }, [getYoutubeVideoListStatus]);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      const { access_token } = codeResponse;
      const url = `${GOOGLE_YOUTUBE_API}/channels?part=snippet,contentDetails&mine=true`;
      getRequest(url, {}, undefined, undefined, access_token, true)
        .then((response) => {
          if (response.status === 200) {
            const youTubeChannelInfo = response.body.data;
            const playlistId =
              youTubeChannelInfo.items[0].contentDetails.relatedPlaylists
                .uploads;
            const payload = {
              playlistId,
              googleAccessToken: access_token,
            };
            let youtubeUsername = youTubeChannelInfo.items[0].snippet.customUrl;
            if (youtubeUsername.charAt(0) === "@") {
              youtubeUsername = youtubeUsername.substring(1);
            }
            setModalType(UPLOADER_TYPE.YOUTUBE);
            dispatch(getYoutubeVideoListRequest(payload));
            dispatch(
              storeGoogleDataRequest({
                ...payload,
                youTubeAccount: {
                  username: youtubeUsername,
                },
              })
            );
          }
        })
        .catch(() => {
          dispatch(
            storeGoogleDataRequest({
              error: DEFAULT_GETTING_CHANNEL_ERROR,
            })
          );
        });
    },
    onError: (error) => console.log("Login Failed:", error),
    overrideScope: true,
    prompt: "consent",
    scope: GOOGLE.YOUTUBE_READONLY_SCOPE,
  });

  const fetchYouTubeVideoList = (nextPageToken) => {
    if (playlistId && googleAccessToken) {
      const payload = {
        playlistId,
        googleAccessToken,
      };
      if (nextPageToken) {
        payload.pageToken = nextPageToken;
      }
      dispatch(getYoutubeVideoListRequest(payload));
    }
  };

  const handleClickOnYouTube = () => {
    if (
      googleAccessToken &&
      playlistId &&
      getYoutubeVideoListStatus !== IN_PROGRESS
    ) {
      isCalledRef.current = true;
      fetchYouTubeVideoList();
    } else {
      login();
    }
  };

  const onKeyDown = () => {
    if (iconRef.current) {
      iconRef.current?.click();
    }
  };

  return (
    <>
      <StyledIcon
        ref={iconRef}
        tabIndex={0}
        onClick={() => handleClickOnYouTube()}
        size={42}
        cursor="pointer"
        onKeyDown={(e) => {
          utils.onKeyDown(e, onKeyDown);
        }}
      >
        <IconYoutube />
      </StyledIcon>
    </>
  );
};

export default YouTube;
