import { StyledTermsModal } from "styled-components/TeamPixelStyled";

const GoogleTermsModal = (props) => {
  const { isShowGoogleTerms, googleTerms, handleGoogleTerms } = props;

  return (
    <StyledTermsModal
      centered
      visible={isShowGoogleTerms}
      onCancel={handleGoogleTerms}
      footer={false}
      title={
        <h4 className="google-terms-title">Google Content Licensing Terms</h4>
      }
    >
      <div
        translate="yes"
        dangerouslySetInnerHTML={{ __html: googleTerms.opening }}
      />
      <div
        className="my-4"
        dangerouslySetInnerHTML={{ __html: googleTerms.body }}
      />
      <div dangerouslySetInnerHTML={{ __html: googleTerms.ending }} />
    </StyledTermsModal>
  );
};

export default GoogleTermsModal;
