import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// Import i18n
import { I18nextProvider } from "react-i18next";
import i18n from "./services/i18n";

import store from "./stores";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { google as googleConfig } from "config";
import { ConfigProvider } from "antd";
import { PREFIX_CLS } from "constants/common";

ReactDOM.render(
  <GoogleOAuthProvider clientId={googleConfig.youtubeClientId}>
    <React.StrictMode>
      <Provider store={store()}>
        <I18nextProvider i18n={i18n}>
          <ConfigProvider
            prefixCls={PREFIX_CLS}
            theme={{
              components: {
                Modal: { wireframe: true },
              },
            }}
          >
            <App />
          </ConfigProvider>
        </I18nextProvider>
      </Provider>
    </React.StrictMode>{" "}
  </GoogleOAuthProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
