import heic2any from "heic2any";
import _ from "lodash";
import { useLayoutEffect, useState } from "react";

import { MAX_FILE_SIZE } from "constants/widgetBuilder";
import Cookies from "js-cookie";
import { Env, PendoKey } from "../config";
import challengeIdByClient from "../constants/challengeIdByClient";
import clientIdByClientName from "../constants/clientIdByClientName";
import {
  BYTE_TO_MB,
  DEFAULT_TIMEOUT,
  GOOGLE_TRANSLATE,
  KEYBOARD,
  LIMITATION_ERROR_TYPE,
  LIMIT_ERROR_VARIABLES,
  MEDIA_TYPE,
  PENDO_TRACKING_TYPE,
  PLACEHOLDER_IN_BASE64,
  RESOLUTION,
  SOCIAL_USERNAME,
  SUPPORTED_FILE,
  UNSUPPORTED_FILE,
  UPLOADER_TYPE,
} from "../constants/common";
import configUrls from "../constants/configUrls";
import errorMsgs from "../constants/errorMsgs";
import migratedCampaignByClient from "../constants/migratedCampaignByClient";

const formatDate = (input) => {
  const date = new Date(input);
  const month = date.toLocaleString("default", { month: "long" });
  return `${month}, ${date.getFullYear()}`;
};

const addScript = (path) => {
  const jsEle = document.createElement("script");
  jsEle.type = "text/javascript";
  jsEle.src = path;

  document.body.appendChild(jsEle);
};

const validateEmail = (email) => {
  const reg =
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(String(email).toLowerCase());
};

const validateInput = (data, lang = "en") => {
  const { email = "", firstname = "", lastName = "" } = data;

  let validInput = true;
  let isValidEmail = true;
  let isValidFirstName = true;
  let isValidLastName = true;
  let emailErrMsg = "";
  let firstNameErrMsg = "";
  let lastNameErrMsg = "";

  if (!email) {
    validInput = false;
    isValidEmail = false;
    emailErrMsg = errorMsgs[lang].requiredEmail;
  } else {
    if (!validateEmail(email)) {
      validInput = false;
      isValidEmail = false;
      emailErrMsg = errorMsgs[lang].invalidEmail;
    } else {
      isValidEmail = true;
    }
  }

  if (!firstname) {
    validInput = false;
    isValidFirstName = false;
    firstNameErrMsg = errorMsgs[lang].requiredFirstName;
  } else {
    isValidFirstName = true;
  }

  if (!lastName) {
    validInput = false;
    isValidLastName = false;
    lastNameErrMsg = errorMsgs[lang].requiredLastname;
  } else {
    isValidLastName = true;
  }

  return {
    validInput, // validate success or failed
    isValidEmail,
    isValidFirstName,
    isValidLastName,
    emailErrMsg,
    firstNameErrMsg,
    lastNameErrMsg,
  };
};

const countMedia = (data, mediaType = MEDIA_TYPE.IMAGE, fieldName = "type") => {
  let count = 0;
  data.forEach((file) => {
    const fileType = file[fieldName];
    if (
      (fileType && fileType.toLowerCase().includes(mediaType.toLowerCase())) ||
      (!fileType && isSupportedFile(file, mediaType))
    ) {
      count += 1;
    }
  });

  return count;
};

const validateUploadedFiles = async (data, lang = "en", widgetSettingsDTO) => {
  let isValidFiles = true;
  let errorMsg = "";
  let previewImg = "";
  let previewTxt = "";

  const { maxPhotoPerUpload, maxVideoPerUpload } = widgetSettingsDTO || {};
  data = _.toArray(data);

  const amountOfVideos = countMedia(data, MEDIA_TYPE.VIDEO);
  const amountOfPhotos = countMedia(data, MEDIA_TYPE.IMAGE);

  if (data.length === 0) {
    errorMsg = errorMsgs[lang].invalidFileType;
  } else if (
    widgetSettingsDTO &&
    (amountOfPhotos > maxPhotoPerUpload || amountOfVideos > maxVideoPerUpload)
  ) {
    if (amountOfPhotos > maxPhotoPerUpload) {
      errorMsg = errorMsgs[lang].maxPhotoPerUpload.replace(
        LIMIT_ERROR_VARIABLES.PHOTO_PER_UPLOAD,
        maxPhotoPerUpload
      );
    }

    if (amountOfVideos > maxVideoPerUpload) {
      errorMsg +=
        `${errorMsg ? "\n" : ""}` +
        errorMsgs[lang].maxVideoPerUpload.replace(
          LIMIT_ERROR_VARIABLES.VIDEO_PER_UPLOAD,
          maxVideoPerUpload
        );
    }
  } else if (existExceptionFile(data)) {
    errorMsg = ""; // Ignore error as BE is able to process this kind of image
  } else if (data.length > 20 && !widgetSettingsDTO) {
    errorMsg = errorMsgs[lang].maxFiles;
  } else {
    for (let i = 0; i < data.length; i++) {
      if (data[i].type) {
        const fileType = data[i].type.split("/")[0];
        if (
          fileType.includes(MEDIA_TYPE.VIDEO) ||
          fileType.includes(MEDIA_TYPE.IMAGE)
        ) {
          if (fileType.includes(MEDIA_TYPE.IMAGE)) {
            const isValid = await isValidSizeImg(data[i]);
            if (!isValid) {
              errorMsg = errorMsgs[lang].invalidFilesize;
              break;
            }
          } else {
            const isValid = await isValidSizeVideo(data[i]);
            if (!isValid) {
              errorMsg = errorMsgs[lang].invalidFilesize;
              break;
            }
          }
        } else if (!SUPPORTED_FILE.GOOGLE_VIDEO.includes(data[i].type)) {
          errorMsg = errorMsgs[lang].invalidFileType;
          break;
        }
      }
    }
  }

  if (errorMsg) {
    isValidFiles = false;
  }

  return {
    isValidFiles,
    errorMsg,
    previewImg,
    previewTxt,
  };
};

const existExceptionFile = (data) =>
  data.some((item) => item.type.includes(UNSUPPORTED_FILE.TIFF));

const isValidSizeImg = (file) => {
  const MAX_WIDTH = 16000;
  const MAX_HEIGHT = 16000;
  if (!file.uploadType) {
    let img = new Image();
    const promise = new Promise((resolve, reject) => {
      img.onload = () => {
        if (
          file.size > MAX_FILE_SIZE.BYTE ||
          img.width > MAX_WIDTH ||
          img.height > MAX_HEIGHT
        ) {
          resolve(false);
        }
        resolve(true);
      };
      // Reject promise on error
      img.onerror = reject;
    });
    img.src = window.URL.createObjectURL(file);
    return promise;
  } else if (file.uploadType === UPLOADER_TYPE.GOOGLE_DRIVE) {
    return file.sizeBytes <= MAX_FILE_SIZE.BYTE;
  }
};

const isValidSizeVideo = (file) => {
  return file.size <= MAX_FILE_SIZE.BYTE;
};

const getPreviewTxt = (data, lang = "en") => {
  let previewTxt = "";

  if (data && data.length === 1) {
    const fileType = data[0]["type"].split("/")[0];

    if (fileType === MEDIA_TYPE.VIDEO || !fileType) {
      let fileName = data[0].name;

      if (fileName.length >= 40) {
        fileName = `${fileName.slice(0, 20)} ... ${fileName.slice(
          fileName.length - 10,
          fileName.length
        )}`;
      }

      previewTxt = fileName;
    } else if (fileType === MEDIA_TYPE.IMAGE) {
      previewTxt = "";
    }
  } else if (lang === "es") {
    previewTxt = `${data.length} imagens selecionadas`;
  } else if (lang === "mx") {
    previewTxt = `${data.length} imágenes seleccionadas `;
  } else {
    previewTxt = `${data.length} images/videos selected`;
  }

  return previewTxt;
};

const getPreviewImgAndType = (data) => {
  return new Promise((resolve) => {
    if (data.length === 1 || data.length > 1) {
      const fileType = data[0]["type"].split("/")[0];

      if (fileType === MEDIA_TYPE.IMAGE) {
        const reader = new FileReader();

        reader.onloadend = () => {
          resolve({
            previewImg: reader.result,
            uploadedFileType: fileType,
          });
        };

        reader.readAsDataURL(data[0]);
      } else if (fileType === MEDIA_TYPE.VIDEO) {
        resolve({
          previewImg: "",
          uploadedFileType: fileType,
        });
      }
    }
  });
};

const getMediaType = (file) => {
  const fileType = file && file["type"] ? file["type"].split("/")[0] : "";
  return fileType;
};

const getFileExtension = (file) => {
  return file[file.path ? "path" : "name"].split(".").pop() || "";
};

const getEmptyFormData = () => {
  return {
    email: "",
    firstName: "",
    lastName: "",
    instagramUsername: "",
    uploadedFiles: [],
    previewImg: "",
    previewTxt: "",
    isAgreed: false,
    selectedHashtags: [],
    isValidEmail: true,
    isValidFirstName: true,
    isValidLastName: true,
    emailErrMsg: "",
    firstNameErrMsg: "",
    lastNameErrMsg: "",
  };
};

const getDropzoneStyles = (uploadedFiles, previewImg) => {
  let dropzoneStyles = {};

  if (previewImg) {
    dropzoneStyles.backgroundColor = "transparent";

    if (uploadedFiles && uploadedFiles.length > 1) {
      dropzoneStyles.backgroundColor = "#343a40";
    }
  }

  return dropzoneStyles;
};

const getPreviewImgStyles = (previewImg) => {
  let previewImgStyles = {};

  if (previewImg) {
    previewImgStyles.backgroundImage = `url(${previewImg})`;
  } else {
    previewImgStyles.backgroundImage = "none";
  }

  return previewImgStyles;
};

const getLastUploadedFile = (uploadResults) => {
  let lastUploadedFile = "";

  if (uploadResults) {
    const { thumbnail = "" } = uploadResults;
    if (thumbnail.includes("https://")) {
      return thumbnail;
    }
    return `${configUrls.S3Server}${thumbnail}`;
  }

  return lastUploadedFile;
};

const getImgUrl = (data) => {
  const {
    type = MEDIA_TYPE.IMAGE,
    thumbnail = "",
    contentUrl = "",
  } = data.content;
  let imgUrl = "";

  if (thumbnail.includes("https://")) {
    imgUrl = thumbnail;
  } else {
    imgUrl =
      type === MEDIA_TYPE.VIDEO
        ? `${configUrls.S3Server}${thumbnail}`
        : `${configUrls.S3Server}${contentUrl}`;
  }

  return imgUrl;
};

const getChallengeIdByPathName = (pathName) => {
  return (
    challengeIdByClient[Env][removeFirstSlash(pathName).toLowerCase()] || 0
  );
};

const getClientIdByPathName = (pathName) => {
  return (
    clientIdByClientName[Env][removeFirstSlash(pathName).toLowerCase()] || 0
  );
};

const getClientIdByName = (name) => {
  return clientIdByClientName[Env][name.toLowerCase()] || 0;
};

const getChallengeIdByClient = (name) => {
  return challengeIdByClient[Env][name.toLowerCase()] || 0;
};

const parseChallengeData = (data) => {
  const {
    id = 0,
    imageUri = "",
    name = "",
    highContents,
    hashtags = "",
  } = data;

  return {
    id,
    imageUri,
    name,
    highContents,
    hashtags: hashtags ? hashtags?.split(",") : [],
  };
};

const convertTimeout = (timeout) => timeout * 60 * 1000;

const parseUploadFormData = (data) => {
  const {
    selectId = null,
    challengeId = null,
    uploadedFiles = [],
    selectedHashtags = [],
    location = null,
    email = "",
    firstName = "",
    lastName = "",
    description = "",
    instagramUsername = "",
    twitterUsername = "",
    tiktokUsername = "",
    additional = "",
    phone = "",
    timeout = DEFAULT_TIMEOUT * 60 * 1000,
    termsIds = null,
    id,
    startTime,
    file,
    uploadWidgetLink,
    mimeType = "",
    fileName = "",
    fileSize = 0,
    orgType = "",
    brandName = "",
    videoDuration = 0,
    ipStack,
    termsTemplateId,
    uploadLocation = null,
  } = data;
  let formData = new FormData();
  const locationStr = location ? getLocationStr(location) : uploadLocation;

  if (uploadedFiles.length) {
    uploadedFiles.forEach((file) => {
      formData.append("files", file);
    });
  }

  if (file) {
    formData.append("file", file);
  }

  if (location) {
    formData.append("ipAddress", location.ip);
    formData.append("zipCode", location.zip);
  }

  if (locationStr) {
    formData.append("uploadLocation", locationStr);
  }

  if (challengeId) {
    formData.append("campaignId", challengeId);
  }

  if (selectId) {
    formData.append("selectId", selectId);
  }

  if (id) {
    formData.append("id", id);
  }

  if (uploadWidgetLink) {
    formData.append("uploadWidgetLink", uploadWidgetLink);
  }

  formData.append("email", email.trim().toLowerCase());
  formData.append("firstName", firstName.trim());
  formData.append("lastName", lastName.trim());
  formData.append("description", description.trim());
  formData.append("googleTerms", JSON.stringify(null));
  formData.append("startTime", startTime);

  if (termsIds) formData.append("termsIds", termsIds.toString());

  if (selectedHashtags) {
    formData.append("hashtags", selectedHashtags.join(","));
  }

  if (instagramUsername) {
    formData.append(
      "instagramUsername",
      instagramUsername.trim().toLowerCase()
    );
  }

  if (twitterUsername) {
    formData.append("twitterUsername", twitterUsername.trim().toLowerCase());
  }

  if (tiktokUsername) {
    formData.append("tiktokUsername", tiktokUsername.trim().toLowerCase());
  }

  if (phone) {
    formData.append("phone", phone.trim().toLowerCase());
  }

  if (additional) {
    formData.append("additionalInfo", JSON.stringify(additional));
  }

  if (mimeType) {
    formData.append("mimeType", mimeType);
  }

  if (fileName) {
    formData.append("fileName", fileName);
  }

  if (fileSize) {
    formData.append("fileSize", fileSize);
  }

  if (orgType) {
    formData.append("orgType", orgType);
  }

  if (brandName) {
    formData.append("brandName", brandName);
  }

  if (videoDuration) {
    formData.append("videoDuration", parseInt(videoDuration).toString());
  }

  if (ipStack) {
    formData.append("ipStack", JSON.stringify(ipStack));
  }

  if (termsTemplateId) {
    formData.append("termsTemplateId", termsTemplateId);
  }

  formData.timeout = timeout;
  return formData;
};

const parseUploadData = (data) => {
  let {
    selectId = null,
    challengeId = null,
    selectedHashtags = [],
    location = null,
    email = "",
    firstName = "",
    lastName = "",
    description = "",
    instagramUsername = "",
    twitterUsername = "",
    tiktokUsername = "",
    youtubeUsername = "",
    phone = "",
    timeout,
    termsIds = null,
    widgetId,
    uploadWidgetLink,
    contentCustomProperties = [],
    creatorCustomProperties = [],
    creatorOptInEmail = {},
    mailingAddress = {},
    zipCode = "",
    orgType = "",
    brandName = "",
    ipStack = null,
    hasEntribeStandardTerms = false,
    hasCustomTerms = false,
    termsTemplateId,
  } = data;
  let locationStr = "";
  let payload = {};

  if (location) {
    locationStr = getLocationStr(location);
    payload.uploadLocation = locationStr;
  }

  if (challengeId) {
    payload.campaignId = challengeId;
  }

  if (selectId) {
    payload.selectId = selectId;
  }

  payload.email = email.trim().toLowerCase();
  payload.firstName = firstName.trim();
  payload.lastName = lastName.trim();
  payload.description = description.trim();
  payload.googleTerms = JSON.stringify(null);
  payload.widgetId = widgetId;

  if (!_.isEmpty(mailingAddress)) {
    payload.mailingAddress = JSON.stringify(mailingAddress);
  }

  if (termsIds) payload.termsIds = termsIds.toString();

  if (selectedHashtags) payload.hashtags = selectedHashtags.join(",");

  if (instagramUsername)
    payload.instagramUsername = instagramUsername.trim().toLowerCase();

  if (twitterUsername)
    payload.twitterUsername = twitterUsername.trim().toLowerCase();

  if (tiktokUsername)
    payload.tiktokUsername = tiktokUsername.trim().toLowerCase();

  if (youtubeUsername)
    payload.youtubeUsername = youtubeUsername.trim().toLowerCase();

  if (phone) payload.phone = phone.trim().toLowerCase();

  if (contentCustomProperties)
    payload.contentCustomProperties = contentCustomProperties;
  if (creatorCustomProperties)
    payload.creatorCustomProperties = creatorCustomProperties;

  if (timeout) payload.timeout = timeout;

  if (uploadWidgetLink) payload.uploadWidgetLink = uploadWidgetLink;

  if (creatorOptInEmail && !_.isEmpty(creatorOptInEmail)) {
    payload.creatorOptInEmail = creatorOptInEmail;
  }

  if (zipCode) payload.zipCode = zipCode.trim();

  if (uploadWidgetLink) payload.uploadWidgetLink = uploadWidgetLink;

  if (termsTemplateId) payload.termsTemplateId = termsTemplateId;

  payload.orgType = orgType;
  payload.brandName = brandName;

  if (ipStack) {
    payload.ipStack = ipStack;
  }

  payload.hasEntribeStandardTerms = hasEntribeStandardTerms;
  payload.hasCustomTerms = hasCustomTerms;

  return payload;
};

const getLocationStr = (location) => {
  let locationStr = "nodata nodata-ND-ND";
  let country = "ND";
  let regionCode = "ND";
  let city = "nodata";
  let region = "nodata";

  if (location.country_code !== "null" || location.countryCode !== "null") {
    country = location.country_code || location.countryCode;
  }

  if (location.region_code !== "null" || location.regionCode !== "null") {
    regionCode = location.region_code || location.regionCode;
  }

  if (location.city !== "null") {
    city = location.city;
  }

  if (location.region_name !== "null" || location.regionName !== "null") {
    region = location.region_name || location.regionName;
  }

  if (city) {
    if (city.toLowerCase() !== region.toLowerCase()) {
      city += ", " + region;
    }
  }

  locationStr = city + "-" + country + "-" + regionCode;
  return locationStr;
};

const addHashtag = (challenge, selectedHashtags, newHashtag) => {
  let isAdded = false;

  // Create empty challenge object if current upload widget doesn't have challenge.
  if (!challenge) {
    challenge = {
      hashtags: [],
    };
  }

  if (!challenge.hashtags.includes(newHashtag)) {
    isAdded = true;
    selectedHashtags.push(newHashtag);
    challenge.hashtags.push(newHashtag);
  }

  return { challenge, selectedHashtags, isAdded };
};

const removeFirstSlash = (str) => {
  return str.replace(/^\/+/g, "");
};

const capitalizeFirstLetter = (str) => {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : "";
};

const isInteger = (value) => /^[0-9]+$/.test(value);

const validYear = /^[1][9][0-9]{2}$|^[2-9][0-9]{3}$/;

const getChallengeId = (URLQueryParams) =>
  new URLSearchParams(URLQueryParams).get("c") || 0;
const getSelectId = (URLQueryParams) =>
  new URLSearchParams(URLQueryParams).get("s") || 0;
const getClientId = (URLQueryParams) =>
  new URLSearchParams(URLQueryParams).get("cl") || 0;

const getSearchValue = (URLQueryParams, key) => {
  return new URLSearchParams(URLQueryParams).get(key) || null;
};

const handleThumbnailLoadedError = (e, type = MEDIA_TYPE.VIDEO) => {
  e.target.onerror = null;
  e.target.src =
    type === MEDIA_TYPE.VIDEO
      ? `${configUrls.S3Server}/videoPlaceholder.jpg`
      : PLACEHOLDER_IN_BASE64.IMAGE;
};

const getFileUploadToolTip = () => {
  return "Supported file types: \n\n.jpg, .png, .jpeg, .gif, .tif, .webm, .mpg, .mp2, .mpeg, .mpe, .mpv, .ogg, .mp4, .m4p, .mov, .m4v, .avi, .wmv.\n\nOnly one (1) video or twenty (20) photos with maximum dimensions of 16000 x 16000 up to 150MB in size may be uploaded at a time.";
};

const mgratedCampaignIdInV2 = (env, clientName, campaignId) => {
  let clientNames = migratedCampaignByClient[env];
  let clientCampaignIds = clientNames[clientName];
  if (clientCampaignIds) {
    for (const [key, value] of Object.entries(clientCampaignIds)) {
      if (value === campaignId) {
        return migratedCampaignByClient[env + "v2"][clientName][key];
      }
    }
  }
  return campaignId;
};

const isMobileWidth = () => window.innerWidth <= RESOLUTION.mobile;

const useDevice = () => {
  const [isMobile, setIsMobile] = useState(false);

  useLayoutEffect(() => {
    const updateDevice = () => {
      if (window.innerWidth <= RESOLUTION.midTablet - 100) setIsMobile(true);
      // -100 to compatible with uploader opened in the modal
      else setIsMobile(false);
    };
    window.addEventListener("resize", updateDevice);
    updateDevice();
    return () => window.removeEventListener("resize", updateDevice);
  }, []);

  return isMobile;
};

const convertURLToBase64 = async (url) =>
  await fetch(url).then((response) => response.blob());

const removeEmptyValueObj = (object) => {
  Object.keys(object).forEach(
    (key) => (object[key] === null || object[key] === "") && delete object[key]
  );

  return object;
};

/* eslint-disable */
const convertToRgba = (hex, opacity) => {
  if (hex) {
    if (hex.length > 8) {
      const endKey = hex.lastIndexOf(",");
      const result = hex.slice(0, endKey);
      return result + `, ${typeof opacity === "number" ? opacity : 1})`;
    }
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgba(" +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
        `,${typeof opacity === "number" ? opacity : 1})`
      );
    }
    throw new Error("Bad Hex");
  }
};
/* eslint-enable */

const convertFormDataToObject = (data) => {
  return Object.fromEntries(data.entries());
};

const convertByteToMb = (number) => {
  const originalData = number / BYTE_TO_MB;
  return originalData > 1
    ? (number / BYTE_TO_MB).toFixed()
    : (number / BYTE_TO_MB).toFixed(2);
};

const convertSearchParams = (params) => {
  if (typeof params !== "object") {
    return "";
  }
  const searchParams = new URLSearchParams("");
  Object.keys(params).forEach((key) => {
    if (params[key] || typeof params[key] === "boolean" || params[key] === 0) {
      searchParams.append(key, params[key]);
    }
  });
  return searchParams;
};

const randomId = () => {
  return new Date().getTime();
};

const convertGGTranslateURL = ({ search }) => {
  if (search) {
    search += `&${GOOGLE_TRANSLATE.QUERY_STRING}`;
  } else {
    search = `?${GOOGLE_TRANSLATE.QUERY_STRING}`;
  }

  return search;
};

const getVideoDuration = async (file) =>
  new Promise((resolve, reject) => {
    try {
      let video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = function () {
        resolve(+video.duration.toFixed(2));
      };
      video.onerror = function () {
        resolve(undefined);
      };
      video.src = window.URL.createObjectURL(file);
    } catch (e) {
      reject(e);
    }
  });

const convertHeicToJpg = (fileUpload) =>
  new Promise((resolve, reject) => {
    try {
      heic2any({ blob: fileUpload, toType: "image/jpeg" }).then((fileBlob) => {
        const convertedFile = new File([fileBlob], fileUpload.name, {
          type: "image/jpeg",
        });

        resolve(convertedFile);
      });
    } catch (e) {
      reject(e);
    }
  });

const validateFileLimitation = async ({
  file,
  widgetInfo,
  t,
  uploadType,
  videoLengthErrorMsg,
}) => {
  const { widgetSettingsDTO: { maxFileSize, maxVideoLength } = {} } =
    widgetInfo || {};
  let error = {};

  if (file.size / BYTE_TO_MB > maxFileSize) {
    error.msg = t("message.max_file_size", { maxFileSize });
    error.type = LIMITATION_ERROR_TYPE.FILE_SIZE;
  }

  if (file.type.includes(MEDIA_TYPE.VIDEO) && !uploadType) {
    const duration = await getVideoDuration(file);

    if (duration && duration > maxVideoLength) {
      error.msg = error.msg || "";
      error.msg += `${error.msg ? "<br />" : ""} ${
        videoLengthErrorMsg ||
        t("message.max_video_length", {
          maxVideoLength,
        })
      }`;

      error.type += ` ${LIMITATION_ERROR_TYPE.VIDEO_LENGTH}`;
    }
  }

  return { ...error };
};

const convertFileUploads = (fileUploads, setIsShowLoading) => {
  return Promise.all(
    fileUploads.map(async (file) => {
      const fileName = file.name.toLowerCase();
      const isHeic = fileName.includes(SUPPORTED_FILE.HEIC);
      const extensionIndex = fileName.lastIndexOf(
        `.${isHeic ? SUPPORTED_FILE.HEIC : SUPPORTED_FILE.HEIF}`
      );
      if (
        extensionIndex > -1 &&
        (!file.type ||
          file.type.includes(SUPPORTED_FILE.HEIC) ||
          file.type.includes(SUPPORTED_FILE.HEIF))
      ) {
        setIsShowLoading(true);
        return await convertHeicToJpg(file)
          .then((result) => {
            return result;
          })
          .finally(() => {
            setIsShowLoading(false);
          });
      }

      return file;
    })
  );
};

const isSupportedFile = (file, mediaType) => {
  const fileName = file.name.split(".");
  const fileType = fileName.pop();

  return mediaType === MEDIA_TYPE.VIDEO
    ? SUPPORTED_FILE.VIDEO.includes(fileType)
    : SUPPORTED_FILE.IMAGE.includes(fileType);
};

const formatUploadWidgetLink = (url, data, socialUsernameFromURL) => {
  let searchParams = {};

  if (data.selectId) {
    searchParams.s = data.selectId;
  }

  if (data.challengeId) {
    searchParams.c = data.challengeId;
  }

  if (socialUsernameFromURL) {
    const {
      instagramUsername,
      twitterUsername,
      tiktokUsername,
      youtubeUsername,
    } = socialUsernameFromURL || {};
    let socialUsername = {};

    if (instagramUsername) {
      socialUsername[SOCIAL_USERNAME.INSTAGRAM] = instagramUsername;
    }
    if (twitterUsername) {
      socialUsername[SOCIAL_USERNAME.TWITTER] = twitterUsername;
    }
    if (tiktokUsername) {
      socialUsername[SOCIAL_USERNAME.TIKTOK] = tiktokUsername;
    }
    if (youtubeUsername) {
      socialUsername[SOCIAL_USERNAME.YOUTUBE] = youtubeUsername;
    }

    if (!_.isEmpty(socialUsername))
      searchParams = { ...searchParams, ...socialUsername };
  }

  if (!_.isEmpty(searchParams)) {
    searchParams = convertSearchParams(searchParams);
    url = `${url}${url.includes("?") ? "&" : "?"}${searchParams}`;
  }

  return url;
};

const pendoTrackingEvent = ({ clientId, widgetId, trackType }) => {
  switch (trackType.label) {
    case PENDO_TRACKING_TYPE.VISITED.label:
      return `Client Id: ${clientId} - Uploader Id: ${widgetId} - ${PENDO_TRACKING_TYPE.VISITED.description}`;

    case PENDO_TRACKING_TYPE.VISITED_ID.label:
      return `Client Id: ${clientId} - ${PENDO_TRACKING_TYPE.VISITED.description}`;

    default:
      return PENDO_TRACKING_TYPE.ALL_VISITED.description;
  }
};

const pendoTracking = (data) => {
  const { clientId, widgetId, trackType } = data;
  const { pendo = {} } = window;

  try {
    if (pendo && !_.isEmpty(pendo)) {
      setTimeout(() => {
        const initData = {
          visitor: {
            id: "",
          },
          account: {
            id: clientId,
          },
        };
        pendo.initialize(initData);
        const trackingData = {
          visitorId: "",
          accountId: clientId,
          timestamp: new Date().getTime(),
          properties: "",
        };
        pendo.track(
          pendoTrackingEvent({ clientId, widgetId, trackType }),
          trackingData
        );
        console.info(`[Pendo - ${trackType.description}] Sent tracking data!`);
      }, 2000);
    }
  } catch (error) {
    console.info(`[Pendo - ${trackType.description}] Sent tracking data!`);
  }
};

/* eslint-disable */
const pendoFunc = (p, e, n, d) => (pendoKey) => {
  const v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];

  let w;
  let x;
  const o = (p[d] = p[d] || {});
  o._q = o._q || [];
  for (w = 0, x = v.length; w < x; ++w)
    (function (m) {
      o[m] =
        o[m] ||
        function () {
          o._q[m === v[0] ? "unshift" : "push"](
            [m].concat([].slice.call(arguments, 0))
          );
        };
    })(v[w]);
  const y = e.createElement(n);
  y.async = !0;
  y.src = "https://cdn.pendo.io/agent/static/" + pendoKey + "/pendo.js";
  const z = e.getElementsByTagName(n)[0];

  z.parentNode.insertBefore(y, z);
};
/* eslint-enable */

const loadPendoScript = () => {
  return pendoFunc(window, document, "script", "pendo")(PendoKey);
};

const onKeyDown = (event, func, keyCode) => {
  if (event.key === (keyCode || KEYBOARD.ENTER.STR)) {
    if (typeof func === "function") func();
  }
};

const setCookie = (name, value, domain, expires) => {
  const options = { domain };
  if (expires) {
    options.expires = expires;
  }
  // sameSite: "None" => In case, an uploader is embedded in a page having a different origin (ex: https://www.circlek.com/my-circle-k)
  Cookies.set(name, value, { ...options, sameSite: "None", secure: true });
};

const initializeTranslator = (targetElementId) => {
  window.googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        autoDisplay: false,
        disableAutoTranslation: true,
      },
      targetElementId
    );
  };
};

const eraseCookie = (name) => {
  document.cookie = name + "=; Max-Age=-99999999;";
};

const googleTranslateScript = (
  targetElementId = "google_translate_element"
) => {
  const addScript = document.createElement("script");
  addScript.setAttribute("src", GOOGLE_TRANSLATE.SCRIPT_SRC);
  addScript.setAttribute("type", "text/javascript");
  document.body.appendChild(addScript);
  initializeTranslator(targetElementId);
};

const isScriptLoaded = (url) => {
  var scripts = document.getElementsByTagName("script");
  for (var i = scripts.length; i--; ) {
    if (scripts[i].src == url) return true;
  }
  return false;
};

const removeQueryStringParameter = (url, fields) => {
  const urlParts = url.split("?");
  var params = new URLSearchParams(urlParts[1]);
  if (fields.length) {
    fields.forEach((field) => {
      params.delete(field);
    });
  }
  return params.toString()
    ? urlParts[0] + "?" + params.toString()
    : urlParts[0];
};

export default {
  formatDate,
  addScript,
  validateEmail,
  validateInput,
  validateUploadedFiles,
  getPreviewTxt,
  getPreviewImgAndType,
  getEmptyFormData,
  getDropzoneStyles,
  getPreviewImgStyles,
  getLastUploadedFile,
  getImgUrl,
  getChallengeIdByPathName,
  getClientIdByPathName,
  getClientIdByName,
  getChallengeIdByClient,
  parseChallengeData,
  parseUploadFormData,
  addHashtag,
  removeFirstSlash,
  isInteger,
  getChallengeId,
  getSelectId,
  getClientId,
  capitalizeFirstLetter,
  validYear,
  handleThumbnailLoadedError,
  getFileUploadToolTip,
  mgratedCampaignIdInV2,
  isValidSizeImg,
  isValidSizeVideo,
  isMobileWidth,
  useDevice,
  convertURLToBase64,
  getSearchValue,
  removeEmptyValueObj,
  getLocationStr,
  convertTimeout,
  parseUploadData,
  convertToRgba,
  convertFormDataToObject,
  convertByteToMb,
  convertSearchParams,
  randomId,
  getMediaType,
  getFileExtension,
  convertGGTranslateURL,
  getVideoDuration,
  convertHeicToJpg,
  validateFileLimitation,
  convertFileUploads,
  formatUploadWidgetLink,
  loadPendoScript,
  onKeyDown,
  googleTranslateScript,
  pendoTracking,
  setCookie,
  eraseCookie,
  isScriptLoaded,
  removeQueryStringParameter,
  countMedia,
};
