import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  IconCheckCircle,
  IconError,
  IconXCircle,
} from "../../../assets/img/icons";
import {
  DEFAULT_ERROR,
  LIMITATION_ERROR_TYPE,
  MAX_PERCENT,
  TEXT,
} from "../../../constants/common";
import THEME from "../../../constants/themes";
import utils from "../../../services/utils";
import { sendSlackRequest } from "../../../stores/actions";
import {
  StyledCol,
  StyledFlex,
  StyledIcon,
} from "../../../styled-components/Common/CommonStyled";
import {
  StyledFileName,
  StyledProgressBar,
  StyledProgressBarInner,
  StyledUploadProgressItem,
} from "../../../styled-components/WidgetBuilder/UploadProgressStyled";
import {
  StyledText,
  StyledTooltip,
} from "../../../styled-components/WidgetBuilder/WidgetBuilderStyled";

const UploadProgressItem = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const iconRef = useRef();
  const { progressInfo, cancelAPIRequest, widgetInfo } = props;
  const { file, fileProgressId, progress } = progressInfo || {};
  const { widgetSettingsDTO: { isNewFlow = true } = {} } = widgetInfo || {};

  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState();

  useEffect(() => {
    if (!_.isEmpty(progressInfo.limitationError)) {
      handleFileValidation(progressInfo);
    }
  }, [progressInfo]);

  const handleFileValidation = (progressInfo) => {
    const {
      limitationError,
      email,
      uploadLocation,
      uploadWidgetLink,
      file,
      requestId,
      startTime,
    } = progressInfo;

    setIsError(true);

    let errorMsgTemp = "";
    if (typeof limitationError.msg === "object") {
      if (
        limitationError.msg.message &&
        limitationError.msg.message.includes(TEXT.TIMEOUT)
      )
        errorMsgTemp = t("message.timeout");
      else errorMsgTemp = limitationError.msg.error || DEFAULT_ERROR;
    } else {
      errorMsgTemp = limitationError.msg || DEFAULT_ERROR;
    }
    setErrorMsg(errorMsgTemp);

    const payload = {
      requestId,
      creatorEmail: email,
      mediaType: utils.getMediaType(file),
      timestampRequestReceived: +startTime,
      timestampTimeout: new Date().getTime(),
      uploadLocation,
      fileSize: file.size,
      fileExtension: utils.getFileExtension(file),
      uploadWidgetLink,
    };

    sendSlack(file, payload, limitationError.type);
    if (!limitationError.type.includes(LIMITATION_ERROR_TYPE.FAILED)) {
      cancelAPIRequest({
        fileProgressId,
        isNotRemove: true,
      });
    }
  };

  const sendSlack = async (file, payload, errorType) => {
    payload = {
      ...payload,
      searchParams: {
        widgetId: widgetInfo.id || widgetInfo.widgetId,
      },
      isNewFlow,
    };

    if (errorType.includes(LIMITATION_ERROR_TYPE.FAILED)) {
      // BE handles when uploading fail from the BE side
      return;
    }

    if (errorType.includes(LIMITATION_ERROR_TYPE.FILE_SIZE)) {
      payload.searchParams.failType = LIMITATION_ERROR_TYPE.FILE_SIZE;
    }

    if (errorType.includes(LIMITATION_ERROR_TYPE.VIDEO_LENGTH)) {
      payload.videoLengthBySecond = await utils.getVideoDuration(file);
      payload.searchParams.failType = LIMITATION_ERROR_TYPE.VIDEO_LENGTH;
    }

    dispatch(sendSlackRequest(payload));
  };

  const cancelItemRequest = () => {
    cancelAPIRequest({
      fileProgressId,
      isNotRemove: false,
      actionType: LIMITATION_ERROR_TYPE.CANCEL,
    });
  };

  const renderIcon = () => {
    if (isError) {
      return <IconError tabIndex={0} />;
    } else {
      if (progress >= 0 && progress < MAX_PERCENT) {
        return (
          <IconXCircle
            tabIndex={0}
            onKeyDown={(e) => utils.onKeyDown(e, cancelItemRequest)}
            onClick={cancelItemRequest}
          />
        );
      } else if (progress === MAX_PERCENT) {
        return <IconCheckCircle />;
      }
    }
  };

  const iconColor = useMemo(() => {
    let color = undefined;

    if (progress === MAX_PERCENT) {
      color = THEME.colors.greenBase;
    }

    if (isError) {
      color = THEME.colors.redBase;
    }

    return color;
  }, [progress, isError]);

  const progressColor = useMemo(() => {
    let color = THEME.colors.greenBase;

    if (progress === MAX_PERCENT) {
      color = THEME.colors.greenBase;
    }

    if (isError) {
      color = THEME.colors.redBase;
    }

    return color;
  }, [progress, isError]);

  const iconProps = {
    cursor: "pointer",
    margin: "-2px 4px 0 0",
    display: "inherit",
    width: "18px",
    height: "18px",
    color: iconColor,
    colorPath: iconColor,
  };

  const renderFileInfo = useMemo(() => {
    if (file) {
      return (
        <StyledCol xs={14} md={12}>
          <StyledFlex width="85%">
            <StyledFileName>
              <StyledText>{file.name || ""}</StyledText>
            </StyledFileName>
            {` (${utils.convertByteToMb(file.size)}MB)`}
          </StyledFlex>
        </StyledCol>
      );
    }

    return null;
  }, [file]);

  return (
    <StyledUploadProgressItem align="middle" gutter={12}>
      <StyledCol span={2}>
        {isError ? (
          <StyledTooltip
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            title={<div dangerouslySetInnerHTML={{ __html: errorMsg }} /> || ""}
            trigger={["hover", "focus"]}
          >
            {/* Expand the click area in mobile mode */}
            <StyledIcon
              ref={iconRef}
              className="uploading-icon-error"
              {...iconProps}
            >
              {renderIcon()}
            </StyledIcon>
          </StyledTooltip>
        ) : (
          <StyledIcon className="uploading-icon" {...iconProps}>
            {renderIcon()}
          </StyledIcon>
        )}
      </StyledCol>
      {renderFileInfo}
      <StyledCol className="customized-progress-bar" xs={8} md={10}>
        <StyledProgressBar>
          <StyledProgressBarInner color={progressColor} width={progress} />
        </StyledProgressBar>
      </StyledCol>
    </StyledUploadProgressItem>
  );
};

UploadProgressItem.propTypes = {
  progressInfo: PropTypes.object,
  cancelAPIRequest: PropTypes.func,
  error: PropTypes.string,
  widgetInfo: PropTypes.object,
};

export default UploadProgressItem;
