import { IconPracticalTiktok } from "assets/img/icons";
import { HomeUrl, TikTok as TikTokConfig } from "config";
import { FAILED, UPLOADER_TYPE } from "constants/common";
import configUrls from "constants/configUrls";
import WidgetBuilderContext from "contexts/WidgetBuilder";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import utils from "services/utils";
import { popupWindow } from "services/widgetBuilder";
import { storeData } from "stores/actions";
import { StyledSourceIcon } from "styled-components/WidgetBuilder/WidgetUploadSourceStyled";

const TikTok = () => {
  const { setModalType, setAuthorizeWindow, setVideoList } =
    useContext(WidgetBuilderContext);
  let { authorizeWindow } = useContext(WidgetBuilderContext);
  const dispatch = useDispatch();

  const responseType = "code";
  const { tikTokAccessToken, getTikTokVideoListStatus } = useSelector(
    (state) => state.widgets
  );

  const connectTikTok = () => {
    if (!tikTokAccessToken || getTikTokVideoListStatus === FAILED) {
      let urlConnect = `${configUrls.externalAPI.tikTok.authorize}`;
      const csrfState = Math.random().toString(36).substring(2);

      const params = {
        client_key: TikTokConfig.clientKey,
        redirect_uri: window.location.href,
        csrfState,
      };

      const searchParams = {
        client_key: params.client_key,
        scope: "user.info.basic,video.list",
        response_type: responseType,
        state: csrfState,
        redirect_uri: HomeUrl,
      };
      resetData();
      urlConnect += `?${utils.convertSearchParams(searchParams)}`;
      authorizeWindow = popupWindow(urlConnect);
      setAuthorizeWindow(authorizeWindow);
    } else {
      setModalType(UPLOADER_TYPE.TIKTOK);
    }
  };

  const resetData = () => {
    setVideoList([]);
    dispatch(
      storeData({
        storedCode: "",
      })
    );
  };

  const iconProps = {
    size: 34,
  };

  return (
    <>
      <StyledSourceIcon
        tabIndex={0}
        cursor="pointer"
        onClick={connectTikTok}
        onKeyDown={(e) => utils.onKeyDown(e, connectTikTok)}
        {...iconProps}
      >
        <IconPracticalTiktok />
      </StyledSourceIcon>
    </>
  );
};

export default TikTok;
