import { IN_PROGRESS, NONE, UPLOADER_TYPE } from "constants/common";
import { YOUTUBE_UPLOADING } from "constants/widgetBuilder";
import WidgetBuilderContext from "contexts/WidgetBuilder";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getYoutubeVideoListRequest,
  storeGoogleDataRequest,
} from "stores/actions";
import SocialUploadModal from "../SocialUploadModal";

const WidgetYouTube = () => {
  const dispatch = useDispatch();

  const {
    widgetInfo,
    setSelectedVideoList,
    setActiveUploaderType,
    setModalType,
    modalType,
    isMobile,
  } = useContext(WidgetBuilderContext);

  const {
    playlistId,
    googleAccessToken,
    youTubeVideoData,
    getYoutubeVideoListStatus,
  } = useSelector((state) => state.googleStore);

  const [videoList, setVideoList] = useState([]);

  useEffect(() => {
    if (youTubeVideoData) {
      const newList = _.concat(videoList, youTubeVideoData.list);
      setVideoList(newList);
    }
  }, [youTubeVideoData]);

  const onCancel = () => {
    setVideoList([]);
    resetData();
  };

  const fetchYouTubeVideoList = (nextPageToken) => {
    if (playlistId && googleAccessToken) {
      const payload = {
        playlistId,
        googleAccessToken,
      };
      if (nextPageToken) {
        payload.pageToken = nextPageToken;
      }
      dispatch(getYoutubeVideoListRequest(payload));
    }
  };

  const onConfirm = (selectedVideoList) => {
    setModalType("");
    setActiveUploaderType(UPLOADER_TYPE.YOUTUBE);
    setSelectedVideoList(selectedVideoList);
    resetData();
  };

  const resetData = () => {
    setVideoList([]);
    setModalType("");
    dispatch(
      storeGoogleDataRequest({
        getYoutubeVideoListStatus: NONE,
        youTubeVideoData: null,
      })
    );
  };

  const handleLoadMore = () => {
    const { resultsPerPage, nextPageToken } = youTubeVideoData?.pageInfo || {};
    if (
      resultsPerPage >= YOUTUBE_UPLOADING.MAX_RESULTS &&
      nextPageToken &&
      getYoutubeVideoListStatus !== IN_PROGRESS
    ) {
      fetchYouTubeVideoList(nextPageToken);
    }
  };

  return (
    <>
      {videoList && modalType === UPLOADER_TYPE.YOUTUBE && (
        <SocialUploadModal
          isMobile={isMobile}
          visible={videoList && modalType === UPLOADER_TYPE.YOUTUBE}
          widgetInfo={widgetInfo}
          videoList={videoList}
          setSelectedVideoList={setSelectedVideoList}
          onConfirm={onConfirm}
          onCancel={onCancel}
          handleLoadMore={handleLoadMore}
          setVideoList={setVideoList}
        />
      )}
    </>
  );
};

export default WidgetYouTube;
