export default {
  tl: {
    teampixel: {},
  },
  qa: {
    teampixel: {},
  },
  prod: {
    teampixel: {
      c1: 417,
      c2: 434,
      c3: 435,
      c4: 436,
      c5: 451,
      c6: 464,
      c7: 484,
      c8: 485,
      c9: 486,
      c10: 487,
      c11: 488,
      c12: 489,
      c13: 540,
      c14: 549,
      c15: 553,
      c16: 561,
      c17: 580,
      c18: 581,
      c19: 624,
      c20: 746,
      c21: 749,
      c22: 763,
      c23: 843,
      c24: 863,
      c25: 891,
      c26: 894,
      c27: 899,
      c28: 958,
      c29: 975,
      c30: 989,
      c31: 1007,
      c32: 1016,
      c33: 1060,
      c34: 1061,
      c35: 1078,
      c36: 1156,
      c37: 1160,
      c38: 1163,
      c59: 1174,
      c60: 1175,
    },
  },
  prodv2: {
    teampixel: {
      c1: 1,
      c2: 2,
      c3: 3,
      c4: 4,
      c5: 5,
      c6: 6,
      c7: 7,
      c8: 8,
      c9: 9,
      c10: 10,
      c11: 11,
      c12: 12,
      c13: 13,
      c14: 14,
      c15: 15,
      c16: 16,
      c17: 17,
      c18: 18,
      c19: 19,
      c20: 20,
      c21: 21,
      c22: 22,
      c23: 23,
      c24: 24,
      c25: 25,
      c26: 26,
      c27: 27,
      c28: 28,
      c29: 29,
      c30: 30,
      c31: 31,
      c32: 32,
      c33: 33,
      c34: 34,
      c35: 35,
      c36: 36,
      c37: 37,
      c38: 38,
      c59: 50,
      c60: 49,
    },
  },
};
