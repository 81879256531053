import { createAction } from "redux-actions";
import {
  cancelTypes,
  capturateContentTypes,
  createContentTypes,
  getClientDetailTypes,
  getClientSettingTypes,
  getCreatorTypes,
  getGeolocationTypes,
  getInstagramAccessTokenTypes,
  getInstagramMediaTypes,
  getLocationTypes,
  getTermsTypes,
  sendEmailTypes,
  sendSlackSuccessfulUploadTypes,
  sendSlackTypes,
  setUploadProgressTypes,
  storeDataTypes,
  uploadContentTypes,
  uploadGoogleDriveContentTypes,
  uploadInstagramContentTypes,
  uploadTikTokContentTypes,
  uploadYouTubeContentTypes,
  validateCampaignTypes,
} from "./widgets.types";

// Action function
export const uploadContentRequest = createAction(
  uploadContentTypes.UPLOAD_CONTENT_REQUEST
);
export const uploadContentSucceeded = createAction(
  uploadContentTypes.UPLOAD_CONTENT_SUCCEEDED
);
export const uploadContentFailed = createAction(
  uploadContentTypes.UPLOAD_CONTENT_FAILED
);
export const uploadContentEnd = createAction(
  uploadContentTypes.UPLOAD_CONTENT_END
);

export const uploadTikTokContentRequest = createAction(
  uploadTikTokContentTypes.UPLOAD_TIKTOK_CONTENT_REQUEST
);
export const uploadTikTokContentSucceeded = createAction(
  uploadTikTokContentTypes.UPLOAD_TIKTOK_CONTENT_SUCCEEDED
);
export const uploadTikTokContentFailed = createAction(
  uploadTikTokContentTypes.UPLOAD_TIKTOK_CONTENT_FAILED
);
export const uploadTikTokContentEnd = createAction(
  uploadTikTokContentTypes.UPLOAD_TIKTOK_CONTENT_END
);

// Set upload progress
export const setUploadProgress = createAction(
  setUploadProgressTypes.SET_UPLOAD_PROGRESS
);

export const cancelRequest = createAction(cancelTypes.CANCEL_REQUEST);

export const createContentRequest = createAction(
  createContentTypes.CREATE_CONTENT_REQUEST
);
export const createContentSucceeded = createAction(
  createContentTypes.CREATE_CONTENT_SUCCEEDED
);
export const createContentFailed = createAction(
  createContentTypes.CREATE_CONTENT_FAILED
);
export const createContentEnd = createAction(
  createContentTypes.CREATE_CONTENT_END
);

export const sendEmailRequest = createAction(sendEmailTypes.SEND_EMAIL_REQUEST);
export const sendEmailSucceeded = createAction(
  sendEmailTypes.SEND_EMAIL_SUCCEEDED
);
export const sendEmailFailed = createAction(sendEmailTypes.SEND_EMAIL_FAILED);
export const sendEmailEnd = createAction(sendEmailTypes.SEND_EMAIL_END);

export const sendSlackRequest = createAction(sendSlackTypes.SEND_SLACK_REQUEST);
export const sendSlackSucceeded = createAction(
  sendSlackTypes.SEND_SLACK_SUCCEEDED
);
export const sendSlackFailed = createAction(sendSlackTypes.SEND_SLACK_FAILED);
export const sendSlackEnd = createAction(sendSlackTypes.SEND_SLACK_END);

export const sendSlackSuccessfulUploadRequest = createAction(
  sendSlackSuccessfulUploadTypes.SEND_SLACK_SUCCESSFUL_UPLOAD_REQUEST
);
export const sendSlackSuccessfulUploadSucceeded = createAction(
  sendSlackSuccessfulUploadTypes.SEND_SLACK_SUCCESSFUL_UPLOAD_SUCCEEDED
);
export const sendSlackSuccessfulUploadFailed = createAction(
  sendSlackSuccessfulUploadTypes.SEND_SLACK_SUCCESSFUL_UPLOAD_FAILED
);
export const sendSlackSuccessfulUploadEnd = createAction(
  sendSlackSuccessfulUploadTypes.SEND_SLACK_SUCCESSFUL_UPLOAD_END
);

export const getClientSettingRequest = createAction(
  getClientSettingTypes.GET_CLIENT_SETTING_REQUEST
);
export const getClientSettingSucceeded = createAction(
  getClientSettingTypes.GET_CLIENT_SETTING_SUCCEEDED
);
export const getClientSettingFailed = createAction(
  getClientSettingTypes.GET_CLIENT_SETTING_FAILED
);
export const getClientSettingEnd = createAction(
  getClientSettingTypes.GET_CLIENT_SETTING_END
);

export const getClientDetailRequest = createAction(
  getClientDetailTypes.GET_CLIENT_DETAIL_REQUEST
);
export const getClientDetailSucceeded = createAction(
  getClientDetailTypes.GET_CLIENT_DETAIL_SUCCEEDED
);
export const getClientDetailFailed = createAction(
  getClientDetailTypes.GET_CLIENT_DETAIL_FAILED
);
export const getClientDetailEnd = createAction(
  getClientDetailTypes.GET_CLIENT_DETAIL_END
);

export const getLocationRequest = createAction(
  getLocationTypes.GET_LOCATION_REQUEST
);
export const getLocationSucceeded = createAction(
  getLocationTypes.GET_LOCATION_SUCCEEDED
);
export const getLocationFailed = createAction(
  getLocationTypes.GET_LOCATION_FAILED
);
export const getLocationEnd = createAction(getLocationTypes.GET_LOCATION_END);

// Validate Campaign
export const validateCampaignRequest = createAction(
  validateCampaignTypes.VALIDATE_CAMPAIGN_REQUEST
);
export const validateCampaignSucceeded = createAction(
  validateCampaignTypes.VALIDATE_CAMPAIGN_SUCCEEDED
);
export const validateCampaignFailed = createAction(
  validateCampaignTypes.VALIDATE_CAMPAIGN_FAILED
);
export const validateCampaignEnd = createAction(
  validateCampaignTypes.VALIDATE_CAMPAIGN_END
);

export const uploadGoogleDriveContentRequest = createAction(
  uploadGoogleDriveContentTypes.UPLOAD_GOOGLE_DRIVE_CONTENT_REQUEST
);
export const uploadGoogleDriveContentSucceeded = createAction(
  uploadGoogleDriveContentTypes.UPLOAD_GOOGLE_DRIVE_CONTENT_SUCCEEDED
);
export const uploadGoogleDriveContentFailed = createAction(
  uploadGoogleDriveContentTypes.UPLOAD_GOOGLE_DRIVE_CONTENT_FAILED
);
export const uploadGoogleDriveContentEnd = createAction(
  uploadGoogleDriveContentTypes.UPLOAD_GOOGLE_DRIVE_CONTENT_END
);

export const uploadInstagramContentRequest = createAction(
  uploadInstagramContentTypes.UPLOAD_INSTAGRAM_CONTENT_REQUEST
);
export const uploadInstagramContentSucceeded = createAction(
  uploadInstagramContentTypes.UPLOAD_INSTAGRAM_CONTENT_SUCCEEDED
);
export const uploadInstagramContentFailed = createAction(
  uploadInstagramContentTypes.UPLOAD_INSTAGRAM_CONTENT_FAILED
);
export const uploadInstagramContentEnd = createAction(
  uploadInstagramContentTypes.UPLOAD_INSTAGRAM_CONTENT_END
);

export const capturateContentRequest = createAction(
  capturateContentTypes.CAPTURATE_CONTENT_REQUEST
);
export const capturateContentSucceeded = createAction(
  capturateContentTypes.CAPTURATE_CONTENT_SUCCEEDED
);
export const capturateContentFailed = createAction(
  capturateContentTypes.CAPTURATE_CONTENT_FAILED
);
export const capturateContentEnd = createAction(
  capturateContentTypes.CAPTURATE_CONTENT_END
);

export const getGeoLocationRequest = createAction(
  getGeolocationTypes.GET_GEOLOCATION_REQUEST
);
export const getGeoLocationSucceeded = createAction(
  getGeolocationTypes.GET_GEOLOCATION_SUCCEEDED
);
export const getGeoLocationFailed = createAction(
  getGeolocationTypes.GET_GEOLOCATION_FAILED
);
export const getGeoLocationEnd = createAction(
  getGeolocationTypes.GET_GEOLOCATION_END
);

export const uploadYouTubeContentRequest = createAction(
  uploadYouTubeContentTypes.UPLOAD_YOUTUBE_CONTENT_REQUEST
);
export const uploadYouTubeContentSucceeded = createAction(
  uploadYouTubeContentTypes.UPLOAD_YOUTUBE_CONTENT_SUCCEEDED
);
export const uploadYouTubeContentFailed = createAction(
  uploadYouTubeContentTypes.UPLOAD_YOUTUBE_CONTENT_FAILED
);
export const uploadYouTubeContentEnd = createAction(
  uploadYouTubeContentTypes.UPLOAD_YOUTUBE_CONTENT_END
);

export const getCreatorRequest = createAction(
  getCreatorTypes.GET_CREATOR_REQUEST
);
export const getCreatorSucceeded = createAction(
  getCreatorTypes.GET_CREATOR_SUCCEEDED
);
export const getCreatorFailed = createAction(
  getCreatorTypes.GET_CREATOR_FAILED
);
export const getCreatorEnd = createAction(getCreatorTypes.GET_CREATOR_END);

export const getTermsRequest = createAction(getTermsTypes.GET_TERMS_REQUEST);
export const getTermsSucceeded = createAction(
  getTermsTypes.GET_TERMS_SUCCEEDED
);
export const getTermsFailed = createAction(getTermsTypes.GET_TERMS_FAILED);
export const getTermsEnd = createAction(getTermsTypes.GET_TERMS_END);

export const getInstagramAccessTokenRequest = createAction(
  getInstagramAccessTokenTypes.GET_INSTAGRAM_ACCESS_TOKEN_REQUEST
);
export const getInstagramAccessTokenSucceeded = createAction(
  getInstagramAccessTokenTypes.GET_INSTAGRAM_ACCESS_TOKEN_SUCCEEDED
);
export const getInstagramAccessTokenFailed = createAction(
  getInstagramAccessTokenTypes.GET_INSTAGRAM_ACCESS_TOKEN_FAILED
);
export const getInstagramAccessTokenEnd = createAction(
  getInstagramAccessTokenTypes.GET_INSTAGRAM_ACCESS_TOKEN_END
);

export const getInstagramMediaRequest = createAction(
  getInstagramMediaTypes.GET_INSTAGRAM_MEDIA_REQUEST
);
export const getInstagramMediaSucceeded = createAction(
  getInstagramMediaTypes.GET_INSTAGRAM_MEDIA_SUCCEEDED
);
export const getInstagramMediaFailed = createAction(
  getInstagramMediaTypes.GET_INSTAGRAM_MEDIA_FAILED
);
export const getInstagramMediaEnd = createAction(
  getInstagramMediaTypes.GET_INSTAGRAM_MEDIA_END
);

export const storeData = createAction(storeDataTypes.STORE_DATA);
