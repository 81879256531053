import * as React from "react";

// Default context values
const values = {
  handleErrorMsg: () => {},
  errorMsg: "",
  setIsNoMediaProvided: () => {},
  widgetInfo: {},
};

const GeminiContext = React.createContext(values);
export default GeminiContext;
