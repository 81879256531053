import { IconArrowLeft } from "assets/img/icons";
import CheckboxItem from "components/shared/CheckboxItem";
import GeminiBodyContext from "contexts/GeminiBody";
import { useContext, useMemo } from "react";
import { StyledIcon } from "styled-components/Common/CommonStyled";
import { StyledGeminiFormItem } from "styled-components/CustomWidget/GeminiStyled";
import { StyledListTermCondition } from "styled-components/WidgetBuilder/WidgetBuilderStyled";

const GeminiTerm = () => {
  const { setActiveTerm, terms, setTerms, isSubmitting, setIsSubmitting } =
    useContext(GeminiBodyContext);

  const updateTerms = (data, id, updatedValue) => {
    let dataTemp = [...data];

    const termIndex = dataTemp.findIndex((item) => item.id === id);
    if (termIndex > -1) {
      dataTemp[termIndex] = {
        ...dataTemp[termIndex],
        ...updatedValue,
      };
    }

    dataTemp = dataTemp.map((item) => {
      if (item.id === id) {
        item = { ...item, isFocusing: true };
      } else {
        item = { ...item, isFocusing: false };
      }
      return item;
    });

    return dataTemp;
  };

  const handleTerms = (checked, params) => {
    const { isTermsMandated } = params || {};

    let value = {
      required: !!isTermsMandated,
      checked,
    };
    const termsTemp = updateTerms(terms, params.id, value);
    const isCheckedAll = termsTemp.every((item) => item.checked);
    if (isCheckedAll) {
      setIsSubmitting(false);
    }
    setTerms([...termsTemp]);
  };

  const renderTerms = useMemo(() => {
    return terms.map((term, index) => {
      const isNotChecked = isSubmitting && term.required && !term.checked;

      return (
        <StyledGeminiFormItem className="gemini-term" margin="0" key={index}>
          <CheckboxItem
            tabIndex={0}
            data={term}
            handleTerms={handleTerms}
            highlight="#217BFE"
            setActiveTerms={setActiveTerm}
            isNotChecked={isNotChecked}
          />
          {isNotChecked && (
            <StyledIcon colorPath="#217BFE" width="16px" height="16px">
              <IconArrowLeft />
            </StyledIcon>
          )}
        </StyledGeminiFormItem>
      );
    });
  }, [isSubmitting, terms]);

  return <StyledListTermCondition>{renderTerms}</StyledListTermCondition>;
};

export default GeminiTerm;
