import styled from "styled-components";

const StyledPDFViewer = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({ isMobile }) => (isMobile ? "55vh" : "68vh")};
  border: 1px solid rgba(0, 0, 0, 0.3);
`;

const StyledToolbar = styled.div`
  padding: 0px 2px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #eeeeee;
  margin: ${({ margin }) => margin || "unset"};
`;

const StyledToolbarItem = styled.div`
  padding: 0px 2px;
  ${({ isHidden }) => isHidden && "visibility: hidden"};

  .rpv-current-page-input {
    padding: 2px 4px;
    width: 35px;
  }
`;

const StyledPDFContainer = styled.div`
  flex: 1;
  overflow: hidden;
`;

export {
  StyledPDFViewer,
  StyledToolbarItem,
  StyledToolbar,
  StyledPDFContainer,
};
