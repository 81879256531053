import { HomeUrl } from "config";
import { FAILED, TIKTOK_STORAGE, UPLOADER_TYPE } from "constants/common";
import configUrls from "constants/configUrls";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import WidgetBuilderContext from "../../../../contexts/WidgetBuilder";
import SocialUploadModal from "../SocialUploadModal";
import { storeData } from "stores/actions";

const WidgetTikTok = (props) => {
  const dispatch = useDispatch();
  const { isMobile, globalProps } = props;
  const { requestAPI } = globalProps;
  const {
    videoList,
    setSelectedVideoList,
    uploadInfo,
    setUploadInfo,
    widgetInfo,
    setActiveUploaderType,
    modalType,
    setModalType,
    socialSource,
    setVideoList,
    setTikTokUser,
  } = useContext(WidgetBuilderContext);

  const { storedCode, tikTokAccessToken } = useSelector(
    (state) => state.widgets
  );

  useEffect(() => {
    if (!modalType && uploadInfo && uploadInfo.cursor) {
      delete uploadInfo.cursor;
      delete uploadInfo.has_more;
      setUploadInfo({ ...uploadInfo });
    }
  }, [modalType]);

  useEffect(() => {
    if (widgetInfo) {
      const { metadata } = widgetInfo;
      const { colors } = metadata || {};

      if (storedCode && socialSource.isTikTok && colors.enableSharing) {
        getAccessToken(storedCode);
      }
    }
  }, [widgetInfo, storedCode, socialSource]);

  const onCancel = () => {
    setModalType("");
    setSelectedVideoList([]);
  };

  const onConfirm = (selectedVideoList) => {
    setModalType("");
    setActiveUploaderType(UPLOADER_TYPE.TIKTOK);
    setSelectedVideoList(selectedVideoList);
  };

  const successCallbackToken = (resp) => {
    const { accessToken } = resp;
    if (accessToken) {
      dispatch(
        storeData({
          tikTokAccessToken: accessToken,
        })
      );
      setModalType(UPLOADER_TYPE.TIKTOK);
    }
  };

  const failedCallbackToken = () => {};

  // If the code exists, call API getting access token
  const getAccessToken = (code) => {
    let urlAccessToken = `${configUrls.API.accessTokenTikTok}`;
    urlAccessToken += `?code=${code}&redirectUri=${HomeUrl}`;
    tokenAPI(urlAccessToken);
  };

  const tokenAPI = (url) => {
    requestAPI(
      "get",
      url,
      undefined,
      successCallbackToken,
      failedCallbackToken
    );
  };

  const getVideoList = (accessToken) => {
    let urlVideoList = configUrls.externalAPI.tikTok.videoList;
    urlVideoList += `?fields=cover_image_url,id,title,embed_link,share_url,create_time`;

    const params = { ...uploadInfo };
    const customHeader = {
      Authorization: "Bearer " + accessToken,
    };

    const successCallback = (resp) => {
      const { data: { videos = [], has_more, cursor } = {} } = resp;
      if (videos) {
        setVideoList(videoList ? [...videoList, ...videos] : videos);

        if (videos.length) {
          const shareUrl = videos[0].share_url;
          const startIndex = shareUrl.indexOf("@") + 1;
          const endIndex = shareUrl.indexOf("/", startIndex);
          const username = shareUrl.substring(startIndex, endIndex);
          setTikTokUser({ username });
        }
      }
      setUploadInfo({ ...uploadInfo, cursor, has_more });
    };

    const failedCallback = () => {
      dispatch(
        storeData({
          getTikTokVideoListStatus: FAILED,
        })
      );
    };

    delete params.has_more;
    requestAPI(
      "post",
      urlVideoList,
      params,
      successCallback,
      failedCallback,
      undefined,
      true,
      false,
      customHeader
    );
  };

  const handleLoadMoreTikTok = () => {
    if (uploadInfo && uploadInfo.has_more && tikTokAccessToken) {
      getVideoList(tikTokAccessToken);
    }
  };

  return (
    <>
      {modalType === UPLOADER_TYPE.TIKTOK && (
        <SocialUploadModal
          isMobile={isMobile}
          visible={videoList && modalType === UPLOADER_TYPE.TIKTOK}
          widgetInfo={widgetInfo}
          videoList={videoList}
          setSelectedVideoList={setSelectedVideoList}
          onConfirm={onConfirm}
          onCancel={onCancel}
          handleLoadMore={handleLoadMoreTikTok}
          setVideoList={setVideoList}
          handleFetchContent={() => {
            getVideoList(tikTokAccessToken);
          }}
        />
      )}
    </>
  );
};

WidgetTikTok.propTypes = {
  isMobile: PropTypes.bool,
  handleErrorMsg: PropTypes.func,
};

export default WidgetTikTok;
