import axios from "axios";
import { uploadContentCloudFontUrl } from "config";
import { CANCEL } from "redux-saga";
import {
  call,
  put,
  race,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  CAPTURATE_CONTENT,
  CLIENT_SETTING,
  CREATE_CONTENT,
  GET_ACCESS_TOKEN_INS,
  GET_GEOLOCATION,
  GET_MEDIA_INS,
  GET_TERMS,
  PUBLIC_CLIENT,
  SEND_EMAIL,
  SEND_SLACK,
  SEND_SLACK_SUCCESSFUL_UPLOAD,
  UPLOAD_CONTENT,
  UPLOAD_GOOGLE_DRIVE_CONTENT,
  UPLOAD_INSTAGRAM_CONTENT,
  UPLOAD_YOUTUBE_CONTENT,
} from "../../constants/APIs";
import {
  DEFAULT_ERROR,
  LIMITATION_ERROR_TYPE,
  MEDIA_TYPE,
  TEXT,
} from "../../constants/common";
import configUrls from "../../constants/configUrls";
import { genericApiSaga, getRequest, postRequest } from "../../services/api";
import utils from "../../services/utils";
import * as actions from "../actions";
import {
  cancelTypes,
  capturateContentTypes,
  createContentTypes,
  getClientDetailTypes,
  getClientSettingTypes,
  getCreatorTypes,
  getGeolocationTypes,
  getInstagramAccessTokenTypes,
  getInstagramMediaTypes,
  getLocationTypes,
  getTermsTypes,
  sendEmailTypes,
  sendSlackSuccessfulUploadTypes,
  sendSlackTypes,
  uploadContentTypes,
  uploadGoogleDriveContentTypes,
  uploadInstagramContentTypes,
  uploadTikTokContentTypes,
  uploadYouTubeContentTypes,
  validateCampaignTypes,
} from "./widgets.types";

export default function uploadContentAPI(data, url, uploadProgress, isNewFlow) {
  let headers = {
    "Content-Type": isNewFlow ? "*/*" : "multipart/form-data",
  };
  const dataObject = utils.convertFormDataToObject(data);
  const { file } = dataObject || {};
  const requestData = isNewFlow ? file : data;
  const source = axios.CancelToken.source();
  const clientId = sessionStorage.getItem("clientId");

  if (clientId) {
    headers = {
      ...headers,
      "x-client-id": +clientId,
    };
  }
  const onUploadProgress = (e) => {
    if (uploadProgress) {
      uploadProgress(e);
    }
  };

  let config = {
    method: isNewFlow ? "put" : "post",
    url,
    data: requestData,
    headers,
    cancelToken: source.token,
    onUploadProgress,
    validateStatus: () => true,
  };

  if (data.timeout) {
    config = { ...config, timeout: data.timeout };
  }

  const request = axios(config);
  request[CANCEL] = () => source.cancel();
  return request;
}

const formatSendSlackPayload = (payload, type) => {
  const { formData, searchParams, widgetInfo, isNewFlow } = payload;
  const data = utils.convertFormDataToObject(formData);
  const { email, file, uploadLocation, startTime, uploadWidgetLink } = data;
  const payloadTemp = {
    searchParams: {
      widgetId: widgetInfo.id,
      failType: type,
    },
    requestId: searchParams.requestId,
    creatorEmail: email,
    mediaType: utils.getMediaType(file),
    timestampRequestReceived: +startTime,
    timestampTimeout: new Date().getTime(),
    uploadLocation,
    fileSize: file.size,
    fileExtension: utils.getFileExtension(file),
    uploadWidgetLink,
    isNewFlow,
  };

  return payloadTemp;
};

export function* uploadContentRequest({ payload }) {
  const {
    fileProgressId,
    formData,
    searchParams,
    onUploadProgress,
    widgetInfo,
    isNewFlow = false,
  } = payload;
  const data = utils.convertFormDataToObject(formData);
  const { file, email } = data || {};
  const { startTime } = data;
  const { requestId } = searchParams;
  let url = `${UPLOAD_CONTENT}?${utils.convertSearchParams(searchParams)}`;
  let capturateContentURL = `${CAPTURATE_CONTENT}?${utils.convertSearchParams(
    searchParams
  )}`;
  const fileName = data?.file.name.replaceAll(/[^a-z0-9.]/gi, "_");

  if (isNewFlow) {
    const date = new Date();
    const convertedTime =
      String(date.getUTCMonth() + 1) + date.getUTCFullYear();
    url = `${uploadContentCloudFontUrl}/content/${
      widgetInfo?.clientId
    }/${convertedTime}/${
      file?.type.includes(MEDIA_TYPE.IMAGE)
        ? MEDIA_TYPE.IMAGE
        : MEDIA_TYPE.VIDEO
    }/${requestId}_${fileName.toLowerCase()}`;

    if (startTime) {
      capturateContentURL += `&startTime=${startTime}`;
    }
  } else {
    if (startTime) {
      url += `&startTime=${startTime}`;
    }
  }

  yield genericApiSaga({
    gatewayCall: () =>
      uploadContentAPI(
        formData,
        url,
        (progress) =>
          onUploadProgress({
            progress,
            data: formData,
            fileProgressId,
            requestId,
          }),
        isNewFlow
      ),
    *completed(response) {
      const result = { requestId, file, email };
      const uploadedContentId = response?.result?.id;
      const timeDuration = ((new Date().valueOf() - startTime) / 1000).toFixed(
        2
      );
      const clientId = sessionStorage.getItem("clientId");
      const shortcode = window.btoa(`${clientId}_${uploadedContentId}`);
      const sendSlackPayload = {
        requestId,
        timeDuration,
        shortcode,
        isNewFlow,
      };

      if (isNewFlow) {
        const capturateContentPayload = {
          ...data,
          mimeType: file?.type,
          fileName,
          fileSize: file?.size,
        };

        delete capturateContentPayload.file;
        yield put(
          actions.capturateContentRequest({
            url: capturateContentURL,
            params: utils.parseUploadFormData(capturateContentPayload),
            sendSlackPayload,
            fileProgressId,
            result,
            startTime, // is sent to calculate timeDuration again
          })
        );
      } else {
        const returnedRequestId = response?.result?.requestId; // is used for flow 1
        yield put(
          actions.uploadContentSucceeded({
            ...response,
            fileProgressId,
            result: { ...result, requestId: returnedRequestId },
          })
        );

        yield put(
          actions.sendSlackSuccessfulUploadRequest({
            ...sendSlackPayload,
            requestId: returnedRequestId, // is retrieved from the response to ensure accuracy
          })
        );
      }
    },
    *failed(response) {
      yield put(
        actions.uploadContentFailed({
          responseError: response || DEFAULT_ERROR,
          fileProgressId,
        })
      );

      if (
        typeof response === "object" &&
        response.message &&
        response.message.includes(TEXT.TIMEOUT)
      ) {
        yield put(
          actions.sendSlackRequest(
            formatSendSlackPayload(payload, LIMITATION_ERROR_TYPE.TIMEOUT)
          )
        );
      }
    },
  });
}

function* capturateContent({ payload }) {
  const { url, params, sendSlackPayload, fileProgressId, result, startTime } =
    payload;

  yield genericApiSaga({
    gatewayCall: () => postRequest(url, params, "multipart/form-data"),
    *completed(response) {
      const { requestId } = response.data?.result || {};
      const resultTemp = { ...result, requestId };

      yield put(
        actions.uploadContentSucceeded({
          ...response,
          fileProgressId,
          result: resultTemp,
          isNewFlow: true,
        })
      );
      const timeDuration = ((new Date().valueOf() - startTime) / 1000).toFixed(
        2
      );
      yield put(
        actions.sendSlackSuccessfulUploadRequest({
          ...sendSlackPayload,
          requestId,
          timeDuration,
        })
      );
    },
    *failed(response) {
      yield put(
        actions.uploadContentFailed({
          responseError: response.data || DEFAULT_ERROR,
          fileProgressId,
        })
      );
    },
  });
}

export function* uploadTikTokContentRequest({ payload }) {
  const { url, data } = payload;

  yield genericApiSaga({
    gatewayCall: () => postRequest(url, data),
    *completed(response) {
      yield put(actions.uploadTikTokContentSucceeded(response));
    },
    *failed(response) {
      yield put(actions.uploadTikTokContentFailed(response));
    },
  });
}

export function* uploadGoogleDriveContent({ payload }) {
  yield genericApiSaga({
    gatewayCall: () => postRequest(UPLOAD_GOOGLE_DRIVE_CONTENT, payload),
    *completed(response) {
      yield put(actions.uploadGoogleDriveContentSucceeded(response));
    },
    *failed(response) {
      yield put(actions.uploadGoogleDriveContentFailed(response));
    },
  });
}

export function* uploadYouTubeContent({ payload }) {
  yield genericApiSaga({
    gatewayCall: () => postRequest(UPLOAD_YOUTUBE_CONTENT, payload),
    *completed(response) {
      yield put(actions.uploadYouTubeContentSucceeded(response));
    },
    *failed(response) {
      yield put(actions.uploadYouTubeContentFailed(response));
    },
  });
}

export function* uploadInstagramContent({ payload }) {
  yield genericApiSaga({
    gatewayCall: () => postRequest(UPLOAD_INSTAGRAM_CONTENT, payload),
    *completed(response) {
      yield put(actions.uploadInstagramContentSucceeded(response));
    },
    *failed(response) {
      yield put(actions.uploadInstagramContentFailed(response));
    },
  });
}

export function* createContentRequest({ payload }) {
  delete payload.widgetId;
  delete payload.timeout;

  yield genericApiSaga({
    gatewayCall: () => postRequest(CREATE_CONTENT, payload),
    *completed(response) {
      yield put(actions.createContentSucceeded(response));
    },
    *failed(response) {
      yield put(actions.createContentFailed(response));
    },
  });
}

export function* sendEmailRequest({ payload }) {
  const { requestIds = [], termsIds = [], ...otherParams } = payload;
  const url = `${SEND_EMAIL}?${utils.convertSearchParams(otherParams)}`;

  yield genericApiSaga({
    gatewayCall: () =>
      postRequest(url, {
        requestIds,
        termsIds,
      }),
    *completed(response) {
      yield put(actions.sendEmailSucceeded(response));
    },
    *failed(response) {
      yield put(actions.sendEmailFailed(response));
    },
  });
}

export function* sendSlackRequest({ payload }) {
  const { searchParams, ...otherProps } = payload;
  const url = `${SEND_SLACK}?${utils.convertSearchParams(searchParams)}`;

  yield genericApiSaga({
    gatewayCall: () => postRequest(url, otherProps),
    *completed(response) {
      yield put(actions.sendSlackSucceeded(response));
    },
    *failed(response) {
      yield put(actions.sendSlackFailed(response));
    },
  });
}

export function* sendSlackSuccessfulUpload({ payload }) {
  let url = `${SEND_SLACK_SUCCESSFUL_UPLOAD}?${utils.convertSearchParams(
    payload
  )}`;

  yield genericApiSaga({
    gatewayCall: () => postRequest(url),
    *completed(response) {
      yield put(actions.sendSlackSuccessfulUploadSucceeded(response));
    },
    *failed(response) {
      yield put(actions.sendSlackSuccessfulUploadFailed(response));
    },
  });
}

const sendSlackUploadCancelled = (payload) => {
  const payloadTemp = formatSendSlackPayload(
    payload,
    LIMITATION_ERROR_TYPE.CANCEL
  );
  const { searchParams: params, ...otherProps } = payloadTemp;
  const url = `${SEND_SLACK}?${utils.convertSearchParams(params)}`;
  postRequest(url, otherProps);
};

function* uploadContentSaga({ payload }) {
  yield race([
    call(uploadContentRequest, { payload }),
    take((action) => {
      const { payload: { fileProgressId, actionType } = {}, type } = action;
      const isCancelled =
        type === cancelTypes.CANCEL_REQUEST &&
        fileProgressId === payload.fileProgressId;

      // actionType is used to distinguish a cancelled action from users and a cancelled action is called through other actions (ex: limit file size, ...)
      if (
        isCancelled &&
        actionType &&
        actionType === LIMITATION_ERROR_TYPE.CANCEL
      ) {
        sendSlackUploadCancelled(payload);
      }

      return isCancelled;
    }),
  ]);
}

export function* getClientSetting({ payload }) {
  yield genericApiSaga({
    gatewayCall: () =>
      getRequest(`${CLIENT_SETTING}?uploaderLink=${payload.uploaderLink}`),
    *completed(response) {
      yield put(actions.getClientSettingSucceeded(response));
    },
    *failed(response) {
      yield put(actions.getClientSettingFailed(response));
    },
  });
}

export function* getClientDetail({ payload }) {
  yield genericApiSaga({
    gatewayCall: () =>
      getRequest(`${PUBLIC_CLIENT}?uploaderLink=${payload.uploaderLink}`),
    *completed(response) {
      yield put(actions.getClientDetailSucceeded(response));
    },
    *failed(response) {
      yield put(actions.getClientDetailFailed(response));
    },
  });
}

export function* getLocation() {
  yield genericApiSaga({
    gatewayCall: () => getRequest(configUrls.externalAPI.getLocation),
    *completed(response) {
      yield put(actions.getLocationSucceeded(response.data));
    },
    *failed(response) {
      yield put(actions.getLocationFailed(response.data));
    },
  });
}

function* validateCampaign({ payload }) {
  const { clientId, campaignId } = payload;
  yield genericApiSaga({
    gatewayCall: () =>
      getRequest(
        `${configUrls.API.getChallengeById}?clientId=${clientId}&campaignId=${campaignId}`
      ),
    *completed(response) {
      yield put(actions.validateCampaignSucceeded(response));
    },
    *failed(response) {
      yield put(actions.validateCampaignFailed(response));
    },
  });
}

export function* getGeoLocation({ payload }) {
  const searchParams = utils.convertSearchParams(payload);
  yield genericApiSaga({
    gatewayCall: () => getRequest(`${GET_GEOLOCATION}?${searchParams}`),
    *completed(response) {
      yield put(actions.getLocationSucceeded(response.data.result));
    },
    *failed(response) {
      yield put(actions.getLocationEnd(response.data));
    },
  });
}

export function* getCreator({ payload }) {
  const searchParams = utils.convertSearchParams(payload);
  yield genericApiSaga({
    gatewayCall: () =>
      getRequest(`${configUrls.API.getCreator}?${searchParams}`),
    *completed(response) {
      yield put(actions.getCreatorSucceeded(response.data));
    },
    *failed(response) {
      yield put(actions.getCreatorFailed(response.data));
    },
  });
}

export function* getTerms({ payload }) {
  const searchParams = utils.convertSearchParams(payload);
  yield genericApiSaga({
    gatewayCall: () => getRequest(`${GET_TERMS}?${searchParams}`),
    *completed(response) {
      yield put(actions.getTermsSucceeded(response.data));
    },
    *failed(response) {
      yield put(actions.getTermsFailed(response.data));
    },
  });
}

export function* getInstagramAccessToken({ payload }) {
  yield genericApiSaga({
    gatewayCall: () =>
      getRequest(`${GET_ACCESS_TOKEN_INS}?code=${payload.code}`),
    *completed(response) {
      yield put(actions.getInstagramAccessTokenSucceeded(response.data));
    },
    *failed(response) {
      yield put(actions.getInstagramAccessTokenFailed(response.data));
    },
  });
}

export function* getInstagramMedia({ payload }) {
  const searchParams = utils.convertSearchParams(payload);
  const url = `${GET_MEDIA_INS}?${searchParams}`;

  yield genericApiSaga({
    gatewayCall: () => getRequest(url),
    *completed(response) {
      yield put(actions.getInstagramMediaSucceeded(response.data));
    },
    *failed(response) {
      yield put(actions.getInstagramMediaFailed(response.data));
    },
  });
}

export function* watcherWidgetsSaga() {
  yield takeEvery(uploadContentTypes.UPLOAD_CONTENT_REQUEST, uploadContentSaga);
  yield takeEvery(
    createContentTypes.CREATE_CONTENT_REQUEST,
    createContentRequest
  );
  yield takeLatest(sendEmailTypes.SEND_EMAIL_REQUEST, sendEmailRequest);
  yield takeLatest(
    uploadTikTokContentTypes.UPLOAD_TIKTOK_CONTENT_REQUEST,
    uploadTikTokContentRequest
  );
  yield takeEvery(sendSlackTypes.SEND_SLACK_REQUEST, sendSlackRequest);
  yield takeEvery(
    sendSlackSuccessfulUploadTypes.SEND_SLACK_SUCCESSFUL_UPLOAD_REQUEST,
    sendSlackSuccessfulUpload
  );
  yield takeLatest(
    getClientSettingTypes.GET_CLIENT_SETTING_REQUEST,
    getClientSetting
  );
  yield takeLatest(
    getClientDetailTypes.GET_CLIENT_DETAIL_REQUEST,
    getClientDetail
  );
  yield takeLatest(getLocationTypes.GET_LOCATION_REQUEST, getLocation);
  yield takeLatest(
    validateCampaignTypes.VALIDATE_CAMPAIGN_REQUEST,
    validateCampaign
  );
  yield takeLatest(
    uploadGoogleDriveContentTypes.UPLOAD_GOOGLE_DRIVE_CONTENT_REQUEST,
    uploadGoogleDriveContent
  );
  yield takeLatest(
    uploadYouTubeContentTypes.UPLOAD_YOUTUBE_CONTENT_REQUEST,
    uploadYouTubeContent
  );
  yield takeLatest(
    uploadInstagramContentTypes.UPLOAD_INSTAGRAM_CONTENT_REQUEST,
    uploadInstagramContent
  );
  yield takeEvery(
    capturateContentTypes.CAPTURATE_CONTENT_REQUEST,
    capturateContent
  );
  yield takeLatest(getGeolocationTypes.GET_GEOLOCATION_REQUEST, getGeoLocation);
  yield takeLatest(getCreatorTypes.GET_CREATOR_REQUEST, getCreator);
  yield takeLatest(getTermsTypes.GET_TERMS_REQUEST, getTerms);
  yield takeLatest(
    getInstagramAccessTokenTypes.GET_INSTAGRAM_ACCESS_TOKEN_REQUEST,
    getInstagramAccessToken
  );
  yield takeLatest(
    getInstagramMediaTypes.GET_INSTAGRAM_MEDIA_REQUEST,
    getInstagramMedia
  );
}
