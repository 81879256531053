const fields = [
  { label: "fileUploads", order: 1, type: "file" },
  {
    label: "email",
    placeholder: "Email",
    order: 2,
    type: "email",
    isRequired: true,
  },
  {
    label: "firstName",
    placeholder: "Nome",
    order: 3,
    type: "text",
    isRequired: true,
  },
  {
    label: "lastName",
    placeholder: "Sobrenome",
    order: 4,
    type: "text",
    isRequired: true,
  },
  {
    label: "instagramUsername",
    placeholder: "Seu @ no instagram",
    order: 5,
    type: "text",
    isSocialMedia: true,
    isRequired: true,
  },
  {
    label: "twitterUsername",
    placeholder: "Seu @ no Twitter",
    order: 6,
    type: "text",
    isSocialMedia: true,
    isRequired: true,
  },
];

export default fields;
