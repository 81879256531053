import { IconCheckCircle, IconPlayVideo } from "assets/img/icons";
import ScrollableWrapper from "components/shared/ScrollableWrapper";
import {
  CLASS_NAME,
  DURATION,
  HEIGHT_DRAG_AND_DROP,
  MEDIA_TYPE,
  UPLOADER_TYPE,
} from "constants/common";
import configUrls from "constants/configUrls";
import THEME from "constants/themes";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import utils from "services/utils";
import {
  StyledFlex,
  StyledIcon,
  StyledModal,
  StyledText,
} from "styled-components/Common/CommonStyled";
import { StyledButton } from "styled-components/WidgetBuilder/UploadProgressStyled";
import { StyledError } from "styled-components/WidgetBuilder/WidgetBuilderStyled";
import {
  StyledImg,
  StyledThumbnail,
  StyledVideoIcon,
} from "styled-components/WidgetBuilder/WidgetTikTokStyled";

const SocialUploadModal = (props) => {
  const { t } = useTranslation();
  const {
    videoList,
    onCancel,
    onConfirm,
    widgetInfo,
    visible,
    isMobile,
    handleLoadMore,
    modalType,
    setVideoList,
    handleFetchContent,
  } = props;
  const {
    widgetSettingsDTO: { maxVideoPerUpload, maxPhotoPerUpload },
    metadata = {},
  } = widgetInfo || {};
  const { colors } = metadata;
  const { bgSubmit } = colors;

  const [errorMsg, setErrorMsg] = useState("");
  const [selectedSocialMedia, setSelectedSocialMedia] = useState([]);

  useEffect(() => {
    if (handleFetchContent) {
      handleFetchContent();
    }
    return () => {
      if (setVideoList) {
        setVideoList([]);
      }
    };
  }, []);

  const activeColor = (video) => {
    if (selectedSocialMedia?.length > 0) {
      const index = selectedSocialMedia?.findIndex(
        (item) => item.id === video.id
      );
      if (index > -1) return true;
    }
    return false;
  };

  const handleErrorMsg = (error) => {
    setErrorMsg(error);

    setTimeout(
      () => {
        setErrorMsg("");
      },
      modalType === UPLOADER_TYPE.INSTAGRAM ? DURATION.S_3 : DURATION.S_10
    );
  };

  const selectVideo = (video) => {
    let tempList = [...selectedSocialMedia];
    if (modalType === UPLOADER_TYPE.INSTAGRAM) {
      const amountOfVideos = utils.countMedia(
        tempList,
        MEDIA_TYPE.VIDEO,
        "mediaType"
      );
      const amountOfPhotos = utils.countMedia(
        tempList,
        MEDIA_TYPE.IMAGE,
        "mediaType"
      );
      const index = tempList.findIndex((item) => item.id === video.id);
      if (index === -1) {
        if (
          amountOfVideos + 1 > maxVideoPerUpload &&
          video.mediaType.toLowerCase() === MEDIA_TYPE.VIDEO
        ) {
          handleErrorMsg(
            t("message.limit_tiktok_video", {
              maxVideoPerUpload,
            })
          );
          return;
        }
        if (
          amountOfPhotos + 1 > maxPhotoPerUpload &&
          video.mediaType.toLowerCase() === MEDIA_TYPE.IMAGE
        ) {
          handleErrorMsg(
            t("message.limit_photo", {
              maxPhotoPerUpload,
            })
          );
          return;
        }

        tempList.push(video);
      } else {
        tempList = tempList.filter((item) => item.id !== video.id);
      }
    } else {
      const index = tempList.findIndex((item) => item.id === video.id);
      const customizedVideo = { ...video, shareUrl: video.share_url };

      if (index === -1) {
        if (tempList.length + 1 > maxVideoPerUpload) {
          handleErrorMsg(
            t("message.limit_tiktok_video", {
              maxVideoPerUpload,
            })
          );
        } else tempList.push(customizedVideo);
      } else {
        tempList = tempList.filter((item) => item.id !== video.id);
      }
    }

    setSelectedSocialMedia(tempList);
  };

  const renderVideoList = () => {
    return videoList && videoList.length > 0 ? (
      videoList.map((video) => {
        const { id, cover_image_url, thumbnailUrl, mediaUrl, mediaType } =
          video || {};
        return (
          <StyledThumbnail
            tabIndex={0}
            displayedItem={3}
            isMobile={isMobile}
            key={id}
            onClick={() => selectVideo(video)}
            onKeyDown={(e) => utils.onKeyDown(e, () => selectVideo(video))}
          >
            <StyledImg src={cover_image_url || thumbnailUrl || mediaUrl} />
            <StyledIcon
              size={22}
              colorPath={
                activeColor(video) ? THEME.colors.greenBase : undefined
              }
            >
              <IconCheckCircle />
            </StyledIcon>
            {modalType === UPLOADER_TYPE.INSTAGRAM &&
              mediaType &&
              mediaType.toLowerCase() === MEDIA_TYPE.VIDEO && (
                <StyledVideoIcon size="32" colorPath="rgb(255, 255, 255)">
                  <IconPlayVideo />
                </StyledVideoIcon>
              )}
          </StyledThumbnail>
        );
      })
    ) : (
      <StyledFlex height="100%" justify="center" align="center">
        <StyledText color={THEME.colors.gray1} fontWeight={700}>
          {t("widget_builder.body.no_data")}
        </StyledText>
      </StyledFlex>
    );
  };

  const renderButton = () => {
    return (
      <>
        <StyledFlex justify="flex-end" margin="0">
          <StyledButton
            tabIndex={0}
            onClick={() => {
              setSelectedSocialMedia([]);
              if (onCancel) {
                onCancel();
              }
            }}
            key={t("button.cancel")}
            margin="0 8px"
          >
            {t("button.cancel")}
          </StyledButton>

          <StyledButton
            id="cancel-upload-btn"
            type="primary"
            tabIndex={0}
            bgColor={bgSubmit}
            onClick={() => {
              onConfirm(selectedSocialMedia);
            }}
            key={t("button.continue")}
          >
            {t("button.continue")}
          </StyledButton>
        </StyledFlex>
      </>
    );
  };

  const handleScroll = (event) => {
    const maxScrollLeft =
      event.currentTarget.scrollWidth - event.currentTarget.clientWidth;
    if (event.currentTarget.scrollLeft + 1 >= maxScrollLeft && handleLoadMore) {
      handleLoadMore();
    }
  };

  return (
    <StyledModal
      visible={visible}
      footer={renderButton()}
      centered
      padding="46px 20px 20px 20px"
      onCancel={onCancel}
      borderRadius="18px"
    >
      <ScrollableWrapper
        className={CLASS_NAME.TIKTOK_THUMBNAIL_WRAPPER}
        height={
          isMobile
            ? HEIGHT_DRAG_AND_DROP.desktop_i - 10
            : HEIGHT_DRAG_AND_DROP.desktop_i
        }
        onScroll={handleScroll}
        isMobile={isMobile}
        padding="10px 5px"
        overflow="auto hidden"
      >
        {renderVideoList()}
      </ScrollableWrapper>

      {errorMsg && (
        <StyledError
          className="error_msg"
          isMobile={isMobile}
          margin="12px 0"
          color={THEME.colors.redBase}
          dangerouslySetInnerHTML={{ __html: errorMsg }}
        />
      )}
    </StyledModal>
  );
};

export default SocialUploadModal;
