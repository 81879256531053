import { APIServer, AppServer, S3Server, ipstackKey } from "../config";

let API = {
  getChallengeById: "campaign/public/validate-campaign", // GET
  getCreator: "creator/public/info-creator", // GET
  registerCreator: "widget/register", // POST
  uploadContent: "widget/upload-content", // POST
  upload: "content/public/upload-content",
  widgetBuilder: "widget/design",
  accessTokenTikTok: "widget/tiktok/oauth/access-token",
  refreshTokenTikTok: "widget/tiktok/oauth/refresh-token",
  uploadTikTokContent: "widget/tiktok/content",
};

let App = {
  home: "",
  terms: "terms",
  privacy: "privacy",
};

const tikTokAPI = "https://open.tiktokapis.com/v2";

let externalAPI = {
  tikTok: {
    authorize: "https://www.tiktok.com/v2/auth/authorize/",
    accessToken: "https://open.tiktokapis.com/v2/oauth/token/",
    videoList: `${tikTokAPI}/video/list/`,
    userInfo: `${tikTokAPI}/user/info/`,
    videoInfo: "https://www.tiktok.com/node/share/video/",
  },
  getLocation: `${ipstackKey}`,
};

const localhostURL = "http://localhost:3000";

Object.keys(API).forEach((key) => {
  API[key] = `${APIServer}/${API[key]}`;
});

Object.keys(App).forEach((key) => {
  App[key] = `${AppServer}/${App[key]}`;
});

export default { API, App, S3Server, externalAPI, localhostURL };
