import styled from "styled-components";
import { HEIGHT_DRAG_AND_DROP } from "../../constants/common";
import THEME from "../../constants/themes";
import { StyledFlex, StyledIcon } from "../Common/CommonStyled";
import utils from "services/utils";

export const StyledTikTokWrapper = styled.div`
  position: relative;
  height: ${({ isMobile, height }) =>
    isMobile
      ? HEIGHT_DRAG_AND_DROP.mobile
      : height || HEIGHT_DRAG_AND_DROP.desktop}px;
  ${({ cursor }) => `cursor: ${cursor}`};
  padding: 14px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;

  ${StyledFlex} {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: max-content;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${THEME.colors.gray3};
    border-radius: 20px;
  }
`;

export const StyledThumbnail = styled.div`
  position: relative;
  display: inline-flex;
  width: ${({ isMobile, displayedItem = 2, subtractedValue = 20 }) =>
    isMobile
      ? "calc(100% / 2 - 5px)"
      : `calc((100% - ${subtractedValue}px) / ${displayedItem})`};
  height: 100%;
  &:not(:last-child) {
    margin: 0 10px 0 0;
  }
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    transform: all 0.5s;
    box-shadow: rgb(0 0 0 / 20%) 0px 0px 5px 0px;
    -webkit-box-shadow: rgb(0 0 0 / 20%) 0px 0px 5px 0px;
    -moz-box-shadow: rgb(0 0 0 / 20%) 0px 0px 5px 0px;
  }

  ${StyledIcon} {
    position: absolute;
    z-index: 99;
    top: -5.5px;
    right: -5.5px;
  }

  &:focus-visible {
    outline: ${({ focusColor }) =>
      `2px solid ${utils.convertToRgba(
        focusColor || THEME.colors.darkBlue4,
        0.75
      )}`};
    border-radius: 8px;
  }
`;

export const StyledVideoIcon = styled(StyledIcon)`
  position: absolute !important;
  z-index: 99;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  right: unset !important;
`;

export const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
export const StyledTikTok = styled(StyledFlex)`
  border: 1px solid #e3e3e5;
  border-radius: 4px;
  padding: 8px 20px;
  background-color: ${THEME.colors.white};

  &:focus {
    outline-color: ${(props) => props.color} !important;
  }
`;
