import { HomeUrl, instagram } from "config";
import { MAX_RECORDS, SUCCEEDED, UPLOADER_TYPE } from "constants/common";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getInstagramAccessTokenRequest,
  getInstagramMediaRequest,
} from "stores/actions";
import WidgetBuilderContext from "../../../../contexts/WidgetBuilder";
import SocialUploadModal from "../SocialUploadModal";

const WidgetInstagram = (props) => {
  const dispatch = useDispatch();
  const { isMobile } = props;
  const {
    widgetInfo,
    setActiveUploaderType,
    modalType,
    setModalType,
    videoList,
    setSelectedVideoList,
    socialSource,
    setVideoList,
  } = useContext(WidgetBuilderContext);
  const {
    getInstagramAccessTokenStatus,
    instagramAccessTokenResult,
    instagramMediaResult,
    getInstagramMediaStatus,
    storedCode,
  } = useSelector((state) => state.widgets);

  const DEFAULT_PAYLOAD = {
    fields:
      "id,caption,media_type,media_url,thumbnail_url,permalink,timestamp,username,children{id,media_url,thumbnail_url,media_type}",
    limit: MAX_RECORDS,
  };

  useEffect(() => {
    if (widgetInfo) {
      const { metadata } = widgetInfo;
      const { colors } = metadata || {};

      if (
        storedCode &&
        socialSource.isInstagram &&
        !instagramAccessTokenResult &&
        colors.enableSharingInstagram
      ) {
        getInstagramAccessToken(storedCode);
      }
    }
  }, [storedCode, widgetInfo, instagramAccessTokenResult, socialSource]);

  useEffect(() => {
    if (
      getInstagramAccessTokenStatus === SUCCEEDED &&
      instagramAccessTokenResult &&
      widgetInfo
    ) {
      setModalType(UPLOADER_TYPE.INSTAGRAM);
    }
  }, [getInstagramAccessTokenStatus, instagramAccessTokenResult, widgetInfo]);

  useEffect(() => {
    if (getInstagramMediaStatus === SUCCEEDED && instagramMediaResult) {
      if (instagramMediaResult.data) {
        setVideoList(
          videoList
            ? [...videoList, ...instagramMediaResult.data]
            : instagramMediaResult.data
        );
      }
      setModalType(UPLOADER_TYPE.INSTAGRAM);
    }
  }, [getInstagramMediaStatus, instagramMediaResult]);

  const getInstagramMediaList = (accessToken, otherParams) => {
    dispatch(
      getInstagramMediaRequest({
        ...DEFAULT_PAYLOAD,
        access_token: accessToken,
        ...otherParams,
      })
    );
  };

  const getInstagramAccessToken = (code) => {
    const params = {
      client_id: instagram.clientId,
      redirect_uri: HomeUrl,
      client_secret: instagram.clientSecret,
      grant_type: "authorization_code",
      code,
    };
    dispatch(getInstagramAccessTokenRequest(params));
  };

  const onCancel = () => {
    setModalType("");
    setSelectedVideoList([]);
  };

  const onConfirm = (selectedVideoList) => {
    setModalType("");
    setActiveUploaderType(UPLOADER_TYPE.INSTAGRAM);
    setSelectedVideoList(selectedVideoList);
  };

  const handleLoadMore = () => {
    if (
      instagramMediaResult.data.length > 0 &&
      instagramMediaResult?.paging?.cursors?.after
    ) {
      getInstagramMediaList(instagramAccessTokenResult.access_token, {
        after: instagramMediaResult?.paging?.cursors?.after,
      });
    }
  };

  return (
    <>
      {instagramAccessTokenResult && modalType === UPLOADER_TYPE.INSTAGRAM && (
        <SocialUploadModal
          isMobile={isMobile}
          visible={videoList && modalType === UPLOADER_TYPE.INSTAGRAM}
          widgetInfo={widgetInfo}
          videoList={videoList}
          setSelectedVideoList={setSelectedVideoList}
          onConfirm={onConfirm}
          onCancel={onCancel}
          handleLoadMore={handleLoadMore}
          modalType={UPLOADER_TYPE.INSTAGRAM}
          handleFetchContent={() => {
            getInstagramMediaList(instagramAccessTokenResult.access_token);
          }}
          setVideoList={setVideoList}
        />
      )}
    </>
  );
};

WidgetInstagram.propTypes = {
  isMobile: PropTypes.bool,
  handleErrorMsg: PropTypes.func,
};

export default WidgetInstagram;
