import React from "react";
import PropTypes from "prop-types";
import { IconClose } from "../../../assets/img/icons";
import THEME from "../../../constants/themes";
import {
  StyledFlex,
  StyledIcon,
} from "../../../styled-components/Common/CommonStyled";
import utils from "../../../services/utils";

const ModalTitle = (props) => {
  const { title, onClose, tabIndex, disabledCloseIcon = false } = props;

  return (
    <StyledFlex>
      {title}
      <StyledIcon
        className="close-icon"
        tabIndex={tabIndex ? 0 : -1}
        onKeyDown={(e) => utils.onKeyDown(e, onClose)}
        onClick={() => {
          if (onClose) onClose();
        }}
        cursor="pointer"
        color={THEME.colors.darkBlue5}
        width="16px"
        height="16px"
        disabled={disabledCloseIcon}
      >
        <IconClose />
      </StyledIcon>
    </StyledFlex>
  );
};

ModalTitle.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string,
  tabIndex: PropTypes.bool,
};

export default ModalTitle;
