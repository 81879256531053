import {
  StyledHeaderLogo,
  StyledHeaderLogoWrapper,
} from "styled-components/CustomWidget/GeminiStyled";
import geminiLogo from "../../../../../assets/img/gemini/gemini-logo.gif";
import utils from "services/utils";
import React from "react";

const GeminiHeader = () => {
  const isMobile = utils.useDevice();
  const timestamp = new Date().getTime();

  return (
    <StyledHeaderLogoWrapper>
      <StyledHeaderLogo
        isMobile={isMobile}
        src={`${geminiLogo}?t=${timestamp}`}
      />
    </StyledHeaderLogoWrapper>
  );
};

export default React.memo(GeminiHeader);
