const fields = [
  { label: "fileUploads", order: 1, type: "file" },
  {
    label: "email",
    placeholder: "Email",
    order: 2,
    type: "email",
    isRequired: true,
  },
  {
    label: "firstName",
    placeholder: "First name",
    order: 3,
    type: "text",
    isRequired: true,
  },
  {
    label: "lastName",
    placeholder: "Last name",
    order: 4,
    type: "text",
    isRequired: true,
  },
  {
    label: "description",
    placeholder: "Description",
    order: 5,
    type: "textarea",
    isRequired: false,
    rows: 2,
  },
  {
    label: "hashtag",
    placeholder: "Select hashtags or add your own",
    order: 6,
    type: "hashtag",
    isRequired: false,
  },
];

export default fields;
