import { faCamera, faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { debounce as _debounce } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Dropzone from "react-dropzone";
import { withRouter } from "react-router";

import logo from "../../../assets/img/logo-black.png";
import { DEFAULT_WIDGET_ID } from "../../../constants/common";
import configUrls from "../../../constants/configUrls";
import errorMsgs from "../../../constants/errorMsgs";
import utils from "../../../services/utils";

import "./Default.scss";

class Default extends Component {
  static propTypes = {
    globalProps: PropTypes.shape({
      setIsShowLoading: PropTypes.func,
      requestAPI: PropTypes.func,
      didMountCallback: PropTypes.func,
    }),
  };

  static defaultProps = {
    globalProps: {
      setIsShowLoading: () => {},
      requestAPI: () => {},
      didMountCallback: () => {},
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      isDisabledWidget: false,
      formState: 2, // 1 - Welcome, 2 - Upload, 3 - Thankyou

      clientId: null,
      selectId: null,
      challenge: null,
      location: null,

      selectedHashtags: [],
      isValidEmail: true,
      isValidFirstName: true,
      isValidLastName: true,
      isAgreed: false,
      emailErrMsg: "",
      firstNameErrMsg: "",
      lastNameErrMsg: "",
      errorMsg: "",
      uploadedFiles: [],
      uploadedFileType: "",
      previewTxt: "",
      previewImg: "",

      uploadResults: null,
    };

    this.input = {
      email: React.createRef(),
      firstName: React.createRef(),
      lastName: React.createRef(),
      description: React.createRef(),
      hashtag: React.createRef(),
      isAgreed: React.createRef(),
    };
  }

  componentWillMount() {
    this.setOnChangeEmailEvent();
    this.getChallengeAPI();
    this.getClientId();
    this.getSelectId();
    this.getLocationAPI();
  }

  componentDidMount() {
    const {
      globalProps: { didMountCallback },
    } = this.props;
    didMountCallback();
  }

  setOnChangeEmailEvent() {
    this.onChangeEmailCallback = _debounce((e) => {
      const email = this.getInputValue("email");

      if (email && utils.validateEmail(email)) {
        this.getCreatorAPI(email);
      }
    }, 1000);
  }

  getChallengeAPI() {
    const {
      globalProps: { requestAPI },
      location: { search = "" },
    } = this.props;

    const clientId = utils.getClientId(search);
    const challengeId = utils.getChallengeId(search);
    const selectId = utils.getSelectId(search);

    const successCallback = (resp) => {
      this.setState({ challenge: utils.parseChallengeData(resp) });
    };

    const failedCallback = (resp) => {
      this.setState({
        isDisabledWidget: true,
        errorMsg: resp.status.status,
      });
    };

    if (challengeId) {
      const url = `${configUrls.API.getChallengeById}?clientId=${clientId}&campaignId=${challengeId}`;
      requestAPI("get", url, {}, successCallback, failedCallback);

      // Only validate challenge and show error if selectId is empty
    } else if (!challengeId && !selectId && !clientId) {
      this.setState({
        isDisabledWidget: true,
        errorMsg: "Invalid data",
      });
    }
  }

  getClientId() {
    const {
      location: { search = "" },
    } = this.props;

    const clientId = utils.getClientId(search);
    sessionStorage.setItem("clientId", clientId);
    sessionStorage.setItem("clientName", "default");

    this.setState({ clientId });
  }

  getSelectId() {
    const {
      location: { search = "" },
    } = this.props;

    const selectId = utils.getSelectId(search);
    this.setState({ selectId });
  }

  getCreatorAPI = (email) => {
    const {
      globalProps: { requestAPI },
    } = this.props;

    const successCallback = (resp) => {
      const { firstName = "", lastName = "" } = resp || {};

      this.setState({
        firstName,
        lastName,
      });
    };

    const failedCallback = (resp) => {};
    const { clientId } = this.state;
    const url = `${configUrls.API.getCreator}?clientId=${clientId}&email=${email}`;
    requestAPI("get", url, {}, successCallback, failedCallback);
  };

  getLocationAPI = () => {
    const {
      globalProps: { requestAPI, initializePendo },
      location: { search = "" },
    } = this.props;

    const clientId = utils.getClientId(search);
    const url = configUrls.externalAPI.getLocation;
    const successCallback = (resp) => {
      initializePendo(resp, clientId);
      this.setState({ location: resp });
    };
    const failedCallback = (resp) => {};
    const errorCallback = () => {};

    requestAPI(
      "get",
      url,
      {},
      successCallback,
      failedCallback,
      errorCallback,
      true
    );
  };

  uploadAPI() {
    const {
      globalProps: { requestAPI },
    } = this.props;

    const {
      clientId,
      selectId,
      challenge,
      uploadedFiles,
      uploadedFileType,
      selectedHashtags,
      location,
    } = this.state;

    const currentTimeStamp = new Date().valueOf();
    const data = {
      clientId,
      selectId,
      challengeId: (challenge && challenge.id) || 0,
      uploadedFiles,
      uploadedFileType,
      selectedHashtags,
      location,
      email: this.getInputValue("email"),
      firstName: this.getInputValue("firstName"),
      lastName: this.getInputValue("lastName"),
      description: this.getInputValue("description"),
    };

    const formData = utils.parseUploadFormData(data);
    let url = `${configUrls.API.upload}`;

    const successCallback = (resp) => {
      this.setState(
        {
          formState: 3,
          uploadResults: resp,
        },
        () => {
          this.resetForm();
        }
      );
    };

    const failedCallback = (resp) => {
      const errorMsg = resp.error ? resp.error : errorMsgs["en"].uploadFailed;
      this.setState({ errorMsg });
    };

    url = `${url}?access_time=${currentTimeStamp}`;
    if (data.selectId) {
      url = `${url}&s=${data.selectId}`;
    }
    if (data.challengeId) {
      url = `${url}&c=${data.challengeId}`;
    }
    url = `${url}&widgetId=${DEFAULT_WIDGET_ID}`;
    requestAPI("post", url, formData, successCallback, failedCallback);
  }

  renderHashtagList() {
    const { challenge, selectedHashtags } = this.state;

    return (
      <div className="list-hashtag">
        {challenge &&
          challenge.hashtags.length > 0 &&
          challenge.hashtags.map((hashtag, i) => {
            const classes = selectedHashtags.includes(hashtag)
              ? "hashtag active"
              : "hashtag";

            return (
              <span
                className={classes}
                key={i}
                onClick={() => this.onSelectHashtag(hashtag)}
              >
                #{hashtag}
              </span>
            );
          })}
        {challenge && challenge.hashtags.length === 0 && (
          <small>No hashtags</small>
        )}
      </div>
    );
  }

  onSubmit = async () => {
    const { uploadedFiles } = this.state;
    const isValidInput = this.validateInput();
    const isValidUploadedFiles = await this.validateUploadedFiles(
      uploadedFiles
    );
    const { isAgreed } = this.state;

    if (isValidInput && isValidUploadedFiles && isAgreed) {
      this.uploadAPI();
    }
  };

  onUploadMore = () => {
    this.setState({ formState: 2 });
  };

  onChangeEmail = (e) => {
    if (e.keyCode === 13) {
      // Enter key
      this.onSubmit();
      return;
    }

    e.persist();
    this.onChangeEmailCallback(e);
    this.setState({ email: e.target.value });
  };

  onChangeFirstName = (e) => {
    this.setState({ firstName: e.target.value });
  };

  onChangeLastName = (e) => {
    this.setState({ lastName: e.target.value });
  };

  onToggleAgreement = (e) => {
    const isAgreed = e.target.checked;
    this.input.isAgreed.current.value = isAgreed ? "on" : "off";
    this.setState({ isAgreed });
  };

  onEnter = (e) => {
    if (e.keyCode === 13) {
      // Enter key
      this.onSubmit();
    }
  };

  onSelectHashtag = (hashtag) => {
    let { selectedHashtags } = this.state;
    const index = selectedHashtags.indexOf(hashtag);

    if (index < 0) {
      selectedHashtags.push(hashtag);
    } else {
      selectedHashtags.splice(index, 1);
    }

    this.setState({ selectedHashtags });
  };

  onHashtagInput = (e) => {
    this.input.hashtag.current.value = this.input.hashtag.current.value.replace(
      /[^\w]/g,
      ""
    );
  };

  onAddHashtag = (e) => {
    const { challenge, selectedHashtags } = this.state;

    if (e.keyCode === 13) {
      // Enter key
      const newHashtag = this.input.hashtag.current.value;

      if (newHashtag !== "") {
        const updatedHashtags = utils.addHashtag(
          challenge,
          selectedHashtags,
          newHashtag
        );
        const {
          challenge: newChallenge,
          selectedHashtags: newSelectedHashtags,
          isAdded,
        } = updatedHashtags;

        if (isAdded) {
          this.input.hashtag.current.value = "";
        }

        this.setState({
          challenge: newChallenge,
          selectedHashtags: newSelectedHashtags,
        });
      }
    }
  };

  onDrop = (files) => {
    if (this.validateUploadedFiles(files)) {
      this.renderPreviewTxt(files);
      this.renderPreviewImg(files);
      this.setState({ uploadedFiles: files });
    } else {
      this.setState({ uploadedFiles: [] });
    }
  };

  getInputValue = (name) => {
    if (!name) {
      return "";
    }

    return this.input[name].current ? this.input[name].current.value : "";
  };

  validateInput = () => {
    const email = this.getInputValue("email");
    const firstname = this.getInputValue("firstName");
    const lastName = this.getInputValue("lastName");

    const data = {
      email,
      firstname,
      lastName,
    };

    const {
      validInput,
      isValidEmail,
      isValidFirstName,
      isValidLastName,
      emailErrMsg,
      firstNameErrMsg,
      lastNameErrMsg,
    } = utils.validateInput(data);

    this.setState({
      isValidEmail,
      isValidFirstName,
      isValidLastName,
      emailErrMsg,
      firstNameErrMsg,
      lastNameErrMsg,
    });

    return validInput;
  };

  resetForm = () => {
    this.setState({ ...utils.getEmptyFormData() });
  };

  validateUploadedFiles = async (files) => {
    const { errorMsg, isValidFiles, previewImg, previewTxt } =
      await utils.validateUploadedFiles(files);

    if (!isValidFiles) {
      this.setState({
        uploadedFiles: [],
        errorMsg,
        previewImg,
        previewTxt,
      });

      return false;
    }

    this.setState({ uploadedFiles: files, errorMsg });
    return true;
  };

  renderPreviewTxt = (files) => {
    this.setState({ previewTxt: utils.getPreviewTxt(files) });
  };

  renderPreviewImg(files) {
    const { setIsShowLoading } = this.props.globalProps;
    setIsShowLoading(true);

    utils.getPreviewImgAndType(files).then((data) => {
      const { previewImg, uploadedFileType } = data;
      setIsShowLoading(false);
      this.setState({ previewImg, uploadedFileType });
    });
  }

  render() {
    const {
      isDisabledWidget,
      formState,

      challenge,
      email,
      firstName,
      lastName,

      isValidEmail,
      isValidFirstName,
      isValidLastName,
      isAgreed,

      emailErrMsg,
      firstNameErrMsg,
      lastNameErrMsg,
      errorMsg,

      uploadedFiles,
      previewTxt,
      previewImg,

      uploadResults,
      uploadedFileType,
    } = this.state;

    const widgetContainerClasses = isDisabledWidget
      ? "widget-container disabled"
      : "widget-container";
    const basicInputClasses = "form-control form-control-sm";
    const emailClasses = isValidEmail
      ? basicInputClasses
      : `${basicInputClasses} form-control-error`;
    const firstNameClasses = isValidFirstName
      ? basicInputClasses
      : `${basicInputClasses} form-control-error`;
    const lastNameClasses = isValidLastName
      ? basicInputClasses
      : `${basicInputClasses} form-control-error`;
    const dropzoneStyles = utils.getDropzoneStyles(uploadedFiles, previewImg);
    const previewImgStyles = utils.getPreviewImgStyles(previewImg);
    const lastUploadedFile = utils.getLastUploadedFile(uploadResults);

    if (previewImg) {
      dropzoneStyles.backgroundColor = "transparent";
      previewImgStyles.backgroundImage = `url(${previewImg})`;

      if (uploadedFiles && uploadedFiles.length > 1) {
        dropzoneStyles.backgroundColor = "#343a40";
      }
    } else {
      previewImgStyles.backgroundImage = "none";
    }

    return (
      <div className={widgetContainerClasses}>
        {formState === 2 && (
          <div className="container">
            <div className="widget-block widget-upload">
              <div className="widget-header">{challenge && challenge.name}</div>
              <div className="widget-body">
                <div className="widget-media">
                  <div className="preview-img" style={previewImgStyles}>
                    <Dropzone onDrop={this.onDrop} accept="image/*,video/*">
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps()}
                          className="dropzone"
                          style={dropzoneStyles}
                          title={utils.getFileUploadToolTip()}
                        >
                          <input {...getInputProps()} />
                        </div>
                      )}
                    </Dropzone>
                    {uploadedFiles.length >= 1 && <p>{previewTxt}</p>}
                    {uploadedFiles.length === 0 && (
                      <div className="preview-txt-default">
                        <FontAwesomeIcon icon={faCamera} size="lg" />
                        <p>
                          Drag and Drop or Click here <br />
                          to upload your media file
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                {/* End Upload Area */}

                <div className="widget-form">
                  <div className="error-msg">
                    <p>{errorMsg}</p>
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className={emailClasses}
                      ref={this.input.email}
                      onKeyUp={(e) => this.onChangeEmail(e)}
                      onChange={(e) => this.onChangeEmail(e)}
                      placeholder="Email"
                      value={email}
                    />
                    <small> {!isValidEmail && emailErrMsg} &nbsp;</small>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className={firstNameClasses}
                      ref={this.input.firstName}
                      onKeyDown={(e) => this.onEnter(e)}
                      onChange={(e) => this.onChangeFirstName(e)}
                      placeholder="First Name"
                      value={firstName}
                    />
                    <small>
                      {" "}
                      {!isValidFirstName && firstNameErrMsg} &nbsp;
                    </small>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className={lastNameClasses}
                      ref={this.input.lastName}
                      onKeyDown={(e) => this.onEnter(e)}
                      onChange={(e) => this.onChangeLastName(e)}
                      placeholder="Last Name"
                      value={lastName}
                    />
                    <small> {!isValidLastName && lastNameErrMsg} &nbsp;</small>
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control form-control-sm"
                      ref={this.input.description}
                      placeholder="Description"
                      rows="3"
                    ></textarea>
                  </div>
                  <div className="widget-hashtag">
                    <label>Select Hashtags</label>
                    {this.renderHashtagList()}
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      ref={this.input.hashtag}
                      onKeyDown={(e) => this.onAddHashtag(e)}
                      placeholder="Select hashtags or add your own"
                      onInput={(e) => this.onHashtagInput(e)}
                      maxLength={255}
                    />
                  </div>
                </div>
                {/* End Input Fields */}
              </div>
              {/* End Widget Body */}

              <div className="widget-agreement">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="on"
                    id="agreement"
                    checked={isAgreed}
                    ref={this.input.isAgreed}
                    onChange={(e) => {
                      this.onToggleAgreement(e);
                    }}
                  />
                  <label className="form-check-label" htmlFor="agreement">
                    I agree to the{" "}
                    <a
                      href={configUrls.App.terms}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms & Conditions*
                    </a>
                  </label>
                </div>
              </div>
              {/* End Agree Checkbox */}

              <div className="widget-footer">
                <button
                  type="button"
                  className="btn btn-sm btn-orange"
                  onClick={() => this.onSubmit()}
                  disabled={!isAgreed}
                >
                  Upload
                </button>

                <div className="signature">
                  Powered By{" "}
                  <a
                    href="https://www.entribe.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={logo} alt="logo" />
                  </a>
                </div>
              </div>
              {/* End Footer */}
            </div>
          </div>
        )}
        {/* End Upload Form */}

        {formState === 3 && (
          <div className="container">
            <div className="widget-block widget-thankyou">
              <div className="widget-header">{challenge && challenge.name}</div>
              <div className="widget-body">
                <h3>Thank you for the upload!</h3>
                <div className="uploaded-media">
                  {lastUploadedFile && (
                    <img
                      src={lastUploadedFile}
                      alt="widget upload"
                      onError={(e) =>
                        utils.handleThumbnailLoadedError(
                          e,
                          uploadedFileType || ""
                        )
                      }
                    />
                  )}
                  {uploadResults && uploadResults.type === "video" && (
                    <FontAwesomeIcon icon={faPlayCircle} size="lg" />
                  )}
                </div>
              </div>
              <div className="widget-footer">
                <button
                  type="button"
                  className="btn btn-sm btn-orange"
                  onClick={() => this.onUploadMore()}
                >
                  Upload More
                </button>

                <div className="signature">
                  Powered By{" "}
                  <a
                    href="https://www.entribe.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={logo} alt="logo" />
                  </a>
                </div>
              </div>
              {/* End Footer */}
            </div>
          </div>
        )}
        {/* End Thank You Form */}
      </div>
    );
  }
}

export default withRouter(Default);
