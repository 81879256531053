const affiliationOptions = [
  {
    options: [
      { value: "Current UIW Student", label: "Current UIW Student" },
      { value: "Incoming UIW Student", label: "Incoming UIW Student" },
      { value: "Parent of UIW Student", label: "Parent of UIW Student" },
      { value: "UIW Alumni ", label: "UIW Alumni" },
    ],
  },
];

const fields = [
  { label: "fileUploads", order: 1, type: "file" },
  {
    label: "email",
    placeholder: "Email",
    order: 2,
    type: "email",
    isRequired: true,
  },
  {
    label: "firstName",
    placeholder: "First name",
    order: 3,
    type: "text",
    isRequired: true,
  },
  {
    label: "lastName",
    placeholder: "Last name",
    order: 4,
    type: "text",
    isRequired: true,
  },
  {
    label: "instagramUsername",
    placeholder: "Instagram username",
    order: 5,
    type: "text",
    isSocialMedia: true,
    isRequired: true,
  },
  {
    label: "description",
    placeholder: "Image description and/or hashtags",
    order: 6,
    type: "text",
    isRequired: false,
    requireTdext: "Image description",
  },
  {
    label: "affiliation",
    placeholder: "What is your connection with UIW?",
    order: 7,
    type: "select",
    selectOption: affiliationOptions,
    isRequired: false,
  },
];

export default fields;
