import React from "react";
import { StyledTermsModal } from "styled-components/TeamPixelStyled";
import { standardTerms } from "./data";
import { useTranslation } from "react-i18next";

const StandardTermsModal = (props) => {
  const { isShowGoogleTerms, handleGoogleTerms } = props;
  const { t } = useTranslation();
  return (
    <StyledTermsModal
      centered
      visible={isShowGoogleTerms}
      onCancel={handleGoogleTerms}
      footer={false}
      title={
        <h4 className="google-terms-title">
          {t("teampixel.standard_terms.title")}
        </h4>
      }
    >
      <div dangerouslySetInnerHTML={{ __html: standardTerms }} />
    </StyledTermsModal>
  );
};

export default StandardTermsModal;
