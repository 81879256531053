import PropTypes from "prop-types";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IconQuestionMark } from "../../../assets/img/icons";
import logoImg from "../../../assets/img/logo-black.png";
import { AppServer } from "../../../config";
import { KEYBOARD } from "../../../constants/common";
import THEME from "../../../constants/themes";
import WidgetBuilderContext from "../../../contexts/WidgetBuilder";
import utils from "../../../services/utils";
import { StyledIcon } from "../../../styled-components/Common/CommonStyled";
import {
  StyledLink,
  StyledLogoFooter,
  StyledSubFooter,
  StyledUploadTerms,
  StyledWidgetFooter,
} from "../../../styled-components/WidgetBuilder/WidgetBuilderStyled";

const WidgetFooter = (props) => {
  const { clientDetail: { settingsDTO = {} } = {} } = useSelector(
    (state) => state.widgets
  );
  const { handleUploadLimitTerms, highlight, fontSize, margin, formState } =
    props;
  const isMobile = utils.useDevice();
  const { t } = useTranslation();

  const renderLabel = useMemo(() => {
    if (settingsDTO && !settingsDTO.whiteLabeling) {
      return (
        <StyledLink
          underline="none"
          href={AppServer}
          target="_blank"
          rel="noopener noreferrer"
        >
          <StyledSubFooter
            fontSize={fontSize}
            style={{
              color: THEME.colors.black,
              fontSize: fontSize,
            }}
          >
            {t("widget_builder.footer.powered_by")}
            <StyledLogoFooter
              src={logoImg}
              alt={t("widget_builder.entribe_logo")}
            />
          </StyledSubFooter>
        </StyledLink>
      );
    }

    return null;
  }, [settingsDTO]);

  const renderUploadLimitTerms = (highlight) => {
    return (
      <StyledUploadTerms isMobile={isMobile}>
        <StyledIcon
          tabIndex={0}
          onClick={handleUploadLimitTerms}
          colorPath={highlight}
          onKeyDown={(e) => {
            utils.onKeyDown(e, handleUploadLimitTerms, KEYBOARD.ENTER.STR);
          }}
          cursor="pointer"
        >
          <IconQuestionMark />
        </StyledIcon>
      </StyledUploadTerms>
    );
  };

  return (
    <StyledWidgetFooter margin={margin}>
      {renderLabel}
      {formState === 1 && renderUploadLimitTerms(highlight)}
    </StyledWidgetFooter>
  );
};

WidgetFooter.propTypes = {
  bgSubmit: PropTypes.string,
  handleUploadLimitTerms: PropTypes.func,
  formState: PropTypes.number,
};

export default WidgetFooter;
