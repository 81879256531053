import PropTypes from "prop-types";
import React, { useContext } from "react";
import { IconArrowLeft } from "../../../assets/img/icons";
import WidgetBuilderContext from "../../../contexts/WidgetBuilder";
import { StyledIcon } from "../../../styled-components/Common/CommonStyled";
import {
  StyledFormItem,
  StyledListTermCondition,
} from "../../../styled-components/WidgetBuilder/WidgetBuilderStyled";
import CheckboxItem from "../../shared/CheckboxItem";

const Terms = (props) => {
  const { terms, handleTerms, highlight, setActiveTerms, isSubmitting } = props;
  const { isMobile } = useContext(WidgetBuilderContext);

  return (
    <StyledListTermCondition id="terms">
      {terms.map((item, index) => {
        const isNotChecked = isSubmitting && item.required && !item.checked;

        return (
          <StyledFormItem
            className="form_checkbox"
            isMobile={isMobile}
            key={index}
            name={item.fieldName}
            margin="0"
          >
            <CheckboxItem
              tabIndex={0}
              data={item}
              handleTerms={handleTerms}
              isMobile={isMobile}
              highlight={highlight}
              setActiveTerms={setActiveTerms}
              isNotChecked={isNotChecked}
            />

            {isNotChecked && (
              <StyledIcon color={highlight} width="16px" height="16px">
                <IconArrowLeft />
              </StyledIcon>
            )}
          </StyledFormItem>
        );
      })}
    </StyledListTermCondition>
  );
};

Terms.propTypes = {
  terms: PropTypes.array,
  handleTerms: PropTypes.func,
  highlight: PropTypes.string,
  setActiveTerms: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

export default Terms;
