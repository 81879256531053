import { Carousel } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getUrlS3 } from "services/funcHelper";
import utils from "services/utils";
import { generateThumbnailList } from "services/widgetBuilder";
import { StyledGeminiSubmitButton } from "styled-components/CustomWidget/GeminiStyled";
import {
  IconCheckCircle,
  IconSocialFacebook,
  IconSocialInstagram,
  IconSocialPinterest,
  IconSocialTikTok,
  IconSocialTwitter,
} from "../../../assets/img/icons";
import {
  MEDIA_TYPE,
  SOCIAL_NETWORK_URL,
  UPLOADER_TYPE,
} from "../../../constants/common";
import THEME from "../../../constants/themes";
import {
  StyledFlex,
  StyledIcon,
} from "../../../styled-components/Common/CommonStyled";
import {
  StyledCarousel,
  StyledCarouselItem,
  StyledImg,
  StyledLink,
  StyledMessage,
  StyledSubmitButton,
  StyledText,
  StyledUploader,
  StyledWrapperDetailWidget,
} from "../../../styled-components/WidgetBuilder/WidgetBuilderStyled";
import UploadStatusList from "./UploadStatusList";
import WidgetFooter from "./WidgetFooter";
import WidgetHeader from "./WidgetHeader";

const UploaderConfirmation = (props) => {
  const { t } = useTranslation();

  const {
    uploadResults,
    metaData,
    handleUploadMore,
    isMobile,
    isCustomWidget,
    isNoMediaProvided,
    activeUploaderType,
    className,
  } = props;
  const { colors, header, body } = metaData || {};
  const { advancedSettings } = body || {};
  const {
    confirmationMessage = "",
    confirmationButtonText = "",
    visibleSocialButtons = false,
    socialMessage = "",
  } = advancedSettings || {};
  const { srcLogo = "", sizeLogo = {} } = header || {};
  const {
    bgUrl = "",
    bgColor = "",
    bgSubmit = "",
    buttonUrl,
    highlight,
  } = colors || {};
  const timestamp = new Date().getTime();

  const [thumbnailSrcList, setThumbnailSrcList] = useState([]);

  const socialList = [
    {
      id: 1,
      icon: <IconSocialInstagram />,
      href: SOCIAL_NETWORK_URL.INSTAGRAM,
    },
    {
      id: 2,
      icon: <IconSocialTikTok />,
      href: SOCIAL_NETWORK_URL.TIKTOK,
    },
    {
      id: 3,
      icon: <IconSocialTwitter />,
      href: SOCIAL_NETWORK_URL.TWITTER,
    },
    {
      id: 4,
      icon: <IconSocialFacebook />,
      href: SOCIAL_NETWORK_URL.FACEBOOK,
    },
    {
      id: 5,
      icon: <IconSocialPinterest />,
      href: SOCIAL_NETWORK_URL.PINTEREST,
    },
  ];

  useEffect(() => {
    if (uploadResults.length) {
      if (activeUploaderType === UPLOADER_TYPE.UPLOAD || !activeUploaderType) {
        handleThumbnailSrcList();
      } else if (
        activeUploaderType &&
        activeUploaderType !== UPLOADER_TYPE.UPLOAD
      ) {
        setThumbnailSrcList(uploadResults);
      }
    }
  }, [uploadResults]);

  const handleThumbnailSrcList = async () => {
    const fileList = uploadResults.map((item) => item.file);
    let convertedList = generateThumbnailList(fileList);
    convertedList = await Promise.all(convertedList).finally(() => {});
    setThumbnailSrcList(convertedList);
  };

  const renderThumbnailItem = () => {
    return thumbnailSrcList.map((upload) => {
      if (upload) {
        if (upload.mediaType && upload.mediaType === MEDIA_TYPE.VIDEO) {
          return null;
        }

        return (
          <StyledCarouselItem
            key={Math.random()}
            margin={uploadResults.length > 1 ? undefined : "0"}
          >
            <StyledImg
              activeUploaderType={activeUploaderType}
              isMobile={isMobile}
              count={uploadResults.length}
              className="thumbnail"
              src={
                activeUploaderType === UPLOADER_TYPE.UPLOAD ||
                !activeUploaderType
                  ? upload
                  : utils.getLastUploadedFile(upload)
              }
              alt={t("widget_builder.thumbnail_uploaded")}
              onError={(e) =>
                utils.handleThumbnailLoadedError(e, MEDIA_TYPE.IMAGE)
              }
            />
            {renderIconCheck()}
          </StyledCarouselItem>
        );
      }

      return null;
    });
  };

  const renderIconCheck = () => {
    return (
      activeUploaderType === UPLOADER_TYPE.TIKTOK && (
        <StyledIcon width="24px" height="24px" color={THEME.colors.greenBase}>
          <IconCheckCircle />
        </StyledIcon>
      )
    );
  };

  const renderThumbnail = () => (
    <StyledCarousel isMobile={isMobile} count={uploadResults.length}>
      <Carousel
        slidesToShow={isMobile || uploadResults.length <= 1 ? 1 : 2}
        dots={false}
        swipeToSlide
        draggable
      >
        {renderThumbnailItem()}
      </Carousel>
    </StyledCarousel>
  );

  const renderSocialUploadConfirmation = () => {
    return (
      <div>
        <UploadStatusList fileProgress={uploadResults} isMobile={isMobile} />
      </div>
    );
  };

  return (
    <StyledWrapperDetailWidget
      className={`${className} ${isCustomWidget ? "gemini" : ""}`}
      bgUrl={getUrlS3(bgUrl)}
      bgColor={bgColor}
    >
      <StyledUploader isMobile={isMobile} className={className}>
        <WidgetHeader
          isMobile={isMobile}
          sizeLogo={sizeLogo}
          srcLogo={
            isCustomWidget ? `${srcLogo}?t=${timestamp}` : getUrlS3(srcLogo)
          }
        />

        <StyledText
          margin="20px 0"
          fontSize={isMobile ? 2 : 1.714}
          fontWeight={700}
          color={THEME.colors.black}
          lineHeight={1.25}
        >
          {confirmationMessage || t("message.uploaded_successfully")}
        </StyledText>

        {!!thumbnailSrcList.length &&
          (activeUploaderType === UPLOADER_TYPE.UPLOAD ||
            (!activeUploaderType && !isNoMediaProvided)) &&
          renderThumbnail()}
        {activeUploaderType &&
          activeUploaderType !== UPLOADER_TYPE.UPLOAD &&
          renderSocialUploadConfirmation()}

        {visibleSocialButtons && (
          <>
            <StyledMessage fontWeight="400">{socialMessage}</StyledMessage>
            <StyledFlex justify="center" margin="24px 0 24px 0">
              {socialList.map((item, index) => {
                return (
                  <StyledLink key={index} href={item.href} target="_blank">
                    <StyledIcon
                      width="36px"
                      height="36px"
                      margin={isMobile ? "0 12px" : "0 18px"}
                    >
                      {item.icon}
                    </StyledIcon>
                  </StyledLink>
                );
              })}
            </StyledFlex>
          </>
        )}

        {!isCustomWidget && (
          <StyledSubmitButton
            className={buttonUrl ? "has_url" : ""}
            backgroundUrl={getUrlS3(buttonUrl || "")}
            onClick={() => handleUploadMore()}
            backgroundColor={bgSubmit}
            highlight={highlight}
          >
            {confirmationButtonText || t("button.upload_more")}
          </StyledSubmitButton>
        )}

        {isCustomWidget && (
          <StyledGeminiSubmitButton onClick={() => handleUploadMore()}>
            {confirmationButtonText || t("button.upload_more")}
          </StyledGeminiSubmitButton>
        )}
        <WidgetFooter fontSize={isCustomWidget ? "13.5px" : undefined} />
      </StyledUploader>
    </StyledWrapperDetailWidget>
  );
};

UploaderConfirmation.propTypes = {
  uploadResults: PropTypes.array,
  metaData: PropTypes.object,
  handleUploadMore: PropTypes.func,
  isMobile: PropTypes.bool,
};

export default React.memo(UploaderConfirmation);
