import THEME from "constants/themes";
import utils from "services/utils";
import {
  StyledCol,
  StyledRow,
  StyledWrapper,
} from "styled-components/Common/CommonStyled";
import {
  StyledImageSkeleton,
  StyledSkeleton,
  StyledSkeletonButton,
} from "styled-components/WidgetBuilder/SkeletonFrameStyled";
import {
  StyledDetailWidget,
  StyledWrapperDetailWidget,
} from "styled-components/WidgetBuilder/WidgetBuilderStyled";

const SkeletonFrame = () => {
  const isMobile = utils.useDevice();

  const renderTermSkeleton = () => {
    return [...Array(3)].map((_, index) => {
      return (
        <StyledRow key={index} gutter={[16, 16]}>
          <StyledCol span={3}>
            <StyledSkeleton
              margin="16px unset"
              rowHeight="18px"
              active
              title={false}
              paragraph={{
                rows: 1,
                width: "100%",
              }}
            />
          </StyledCol>
          <StyledCol span={21}>
            <StyledSkeleton
              margin="16px unset"
              rowHeight="18px"
              active
              title={false}
              paragraph={{
                rows: 1,
                width: "100%",
              }}
            />
          </StyledCol>
        </StyledRow>
      );
    });
  };

  const renderFormItemSkeleton = () => {
    return [...Array(4)].map((_, index) => {
      return (
        <StyledSkeleton
          key={index}
          margin="16px unset"
          rowHeight="18px"
          active
          title={false}
          paragraph={{
            rows: 1,
            width: "100%",
          }}
        />
      );
    });
  };

  const renderSkeletonFrame = () => {
    return (
      <StyledRow gutter={[24, 24]} width="100%">
        <StyledCol textAlign="center" span={24}>
          <StyledImageSkeleton
            active
            width={isMobile ? "70%" : "50%"}
            height="100px"
            margin="28px 0"
          />
        </StyledCol>
        <StyledCol span={isMobile ? 24 : 12}>
          <StyledSkeleton margin="0 auto 16px auto" rowHeight="18px" active />
          <StyledImageSkeleton
            active
            width="100%"
            height="215px"
            margin={isMobile ? "12px 0 0 0" : "12px 0 28px 0"}
            hideSvgImage={true}
          />

          {!isMobile && renderTermSkeleton()}
        </StyledCol>
        <StyledCol span={isMobile ? 24 : 12}>
          <StyledWrapper height={isMobile ? "unset" : "320px"}>
            {renderFormItemSkeleton()}

            {isMobile && (
              <StyledWrapper margin="24px 0">
                {renderTermSkeleton()}
              </StyledWrapper>
            )}
          </StyledWrapper>
          <StyledSkeletonButton width="100%" active shape="round" />
        </StyledCol>
      </StyledRow>
    );
  };

  return (
    <StyledWrapperDetailWidget bgColor={THEME.colors.gray5}>
      <StyledDetailWidget
        padding={isMobile ? "20px" : "20px 48px 32px 48px"}
        isMobile={isMobile}
        formBackground={THEME.colors.white}
      >
        {renderSkeletonFrame()}
      </StyledDetailWidget>
    </StyledWrapperDetailWidget>
  );
};

export default SkeletonFrame;
