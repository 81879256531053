import React, { Component } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { debounce as _debounce, isString as _isString } from "lodash";
import { faCamera, faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import configUrls from "../../../../constants/configUrls";
import errorMsgs from "../../../../constants/errorMsgs";
import utils from "../../../../services/utils";
import "./CirclekUS.scss";
import logoImg from "../../../../assets/img/logo-white.png";
import circlekcanadaLogo from "../../../../assets/img/circlekus/circlekusLogo.png";
import fields from "./Fields";
import Form from "../../FormValidate";
import { Modal } from "react-bootstrap";
import {
  DEFAULT_WIDGET_ID,
  SUPPORTED_FILE,
} from "../../../../constants/common";

class CirclekUS extends Component {
  static propTypes = {
    globalProps: PropTypes.shape({
      setIsShowLoading: PropTypes.func,
      requestAPI: PropTypes.func,
      didMountCallback: PropTypes.func,
    }),
  };

  static defaultProps = {
    globalProps: {
      setIsShowLoading: () => {},
      requestAPI: () => {},
      didMountCallback: () => {},
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      isDisabledWidget: false,
      formState: 2, // 1 - Welcome, 2 - Upload, 3 - Thankyou

      client: null,
      challenge: null,
      selectId: null,
      location: null,

      selectedHashtags: [],
      agreementSelectField: false,
      errorMsg: "",
      uploadedFiles: [],
      uploadedFileType: "",
      previewTxt: "",
      previewImg: "",

      generalMessage: {
        message: "",
        type: "success",
      },

      uploadResults: null,
      isShowCirclekTerms: false,
    };

    this.input = {
      hashtagInput: React.createRef(),
      hashtag: React.createRef(),
    };
  }

  componentWillReceiveProps(nextProps) {
    const {
      client = null,
      challenge = null,
      selectId = null,
      uploadLocation = null,
    } = nextProps;
    this.checkChallenge(challenge);

    this.setState({
      client,
      selectId,
      location: uploadLocation,
    });
  }

  componentWillMount() {
    this.setOnChangeEmailEvent();
  }

  componentDidMount() {
    const {
      globalProps: { didMountCallback },
    } = this.props;
    didMountCallback();
  }

  checkChallenge(challenge) {
    const { selectId = null, client = null } = this.props;

    if (challenge) {
      this.setState({ challenge, isDisabledWidget: false });
      this.clearMessage();

      // Only validate challenge and show error if selectId is empty
    } else if (!challenge && !selectId && !client) {
      this.setState({ isDisabledWidget: true });
      this.setGeneralError("Invalid data", "error");
    }
  }

  setOnChangeEmailEvent() {
    this.onChangeEmailCallback = _debounce((e, setFieldValue) => {
      const email = e.target.value.trim();
      if (email && utils.validateEmail(email)) {
        this.getCreatorAPI(email, setFieldValue);
      }
    }, 1000);
  }

  getCreatorAPI = (email, setFieldValue) => {
    const {
      globalProps: { requestAPI },
    } = this.props;

    const successCallback = (resp) => {
      const {
        firstName = "",
        lastName = "",
        instagramUsername = "",
        twitterUsername = "",
      } = resp || {};

      setFieldValue("firstName", utils.capitalizeFirstLetter(firstName));
      setFieldValue("lastName", utils.capitalizeFirstLetter(lastName));
      setFieldValue("instagramUsername", instagramUsername);
      setFieldValue("twitterUsername", twitterUsername);
    };

    const failedCallback = (resp) => {
      const errorMsg = resp.error
        ? resp.error
        : resp.status
        ? resp.status.status
        : errorMsgs["en"].callAPIFailed;
      this.setState({ errorMsg });
      this.resetAutoFilledFields(setFieldValue);
    };

    const clientId = sessionStorage.getItem("clientId");
    const url = `${configUrls.API.getCreator}?clientId=${clientId}&email=${email}`;
    requestAPI("get", url, {}, successCallback, failedCallback);
  };

  resetAutoFilledFields = (setFieldValue) => {
    setFieldValue("firstName", "");
    setFieldValue("lastName", "");
    setFieldValue("instagramUsername", "");
    setFieldValue("twitterUsername", "");
  };

  uploadAPI(values, resetForm, setIsAgreed) {
    const {
      globalProps: { requestAPI },
    } = this.props;

    const {
      client: { id: clientId },
      challenge,
      selectId,
      uploadedFiles,
      uploadedFileType,
      selectedHashtags,
      location,
    } = this.state;
    const {
      email = "",
      lastName = "",
      firstName = "",
      instagramUsername = "",
      twitterUsername = "",
      tiktokUsername = "",
      description = "",
    } = values;

    const currentTimeStamp = new Date().valueOf();

    const data = {
      clientId,
      challengeId: (challenge && challenge.id) || 0,
      selectId,
      uploadedFiles,
      uploadedFileType,
      selectedHashtags,
      location,
      email,
      firstName,
      lastName,
      instagramUsername,
      twitterUsername,
      tiktokUsername,
      description,
    };

    const formData = utils.parseUploadFormData(data);
    let url = `${configUrls.API.upload}`;

    const successCallback = (resp) => {
      this.setState(
        {
          formState: 3,
          uploadResults: resp,
        },
        () => {
          resetForm();
          setIsAgreed(false);
          this.setState({ ...utils.getEmptyFormData() });
        }
      );
    };

    const failedCallback = (resp) => {
      const errorMsg = resp.error ? resp.error : errorMsgs["en"].uploadFailed;
      this.setGeneralError(errorMsg, "error");
    };

    url = `${url}?access_time=${currentTimeStamp}`;
    if (data.selectId) {
      url = `${url}&s=${data.selectId}`;
    }
    if (data.challengeId) {
      url = `${url}&c=${data.challengeId}`;
    }
    url = `${url}&widgetId=${DEFAULT_WIDGET_ID}`;
    requestAPI("post", url, formData, successCallback, failedCallback);
  }

  setServerErrMessage = () => {
    this.setState({
      isDisabledWidget: true,
      generalMessage: { type: "error", message: "Oops, something went wrong!" },
    });
  };

  clearMessage = () => {
    this.setState({ generalMessage: { message: "", type: "success" } });
  };

  setGeneralError = (message, type = "success") => {
    this.setState({ generalMessage: { message, type } });
  };

  onSubmit = async (values = {}, resetForm, setIsAgreed) => {
    const { uploadedFiles } = this.state;
    const isValidUploadedFiles = await this.validateUploadedFiles(
      uploadedFiles
    );

    Object.keys(values).forEach((key) => {
      /*if (key !== "description" && key !== "hashtags" && _isString(values[key])) {
        values[key] = values[key].toLowerCase();
      }*/

      values[key] = _isString(values[key]) && values[key].trim();
      !values[key] && delete values[key];
    });

    if (isValidUploadedFiles) {
      this.uploadAPI(values, resetForm, setIsAgreed);
    }
  };

  onUploadMore = () => {
    this.setState({
      formState: 2,
      agreementSelectField: false,
    });
  };

  onDrop = async (files, setFieldValue = () => {}) => {
    const { setIsShowLoading } = this.props.globalProps;
    files = await utils.convertFileUploads(files, setIsShowLoading);

    if (this.validateUploadedFiles(files)) {
      this.renderPreviewTxt(files);
      this.renderPreviewImg(files);
      this.setState({ uploadedFiles: files });
      setFieldValue("fileUploads", files);
    } else {
      this.setState({ uploadedFiles: [] });
    }
  };

  validateSocialUsernames = () => {};

  validateUploadedFiles = async (files) => {
    const { errorMsg, isValidFiles, previewImg, previewTxt } =
      await utils.validateUploadedFiles(files);

    if (!isValidFiles) {
      this.setState({
        uploadedFiles: [],
        previewImg,
        previewTxt,
      });
      this.setGeneralError(errorMsg, "error");

      return false;
    }

    this.setState({ uploadedFiles: files });
    this.clearMessage();
    return true;
  };

  renderPreviewTxt = (files) => {
    this.setState({ previewTxt: utils.getPreviewTxt(files) });
  };

  renderPreviewImg(files) {
    utils.getPreviewImgAndType(files).then((data) => {
      const { previewImg, uploadedFileType } = data;
      this.setState({ previewImg, uploadedFileType });
    });
  }

  moveToUploadWidget = () => {
    this.setState({ formState: 2 });
  };

  onChangeEmail = (e, setFieldValue) => {
    e.persist();
    this.onChangeEmailCallback(e, setFieldValue);
  };

  formatGroupLabel = (data) => {
    return (
      <div className="groupStyles">
        <span>{data.label}</span>
        <span className="groupBadgeStyles">{data.options.length}</span>
      </div>
    );
  };

  onSelectHashtag = (hashtag) => {
    let { selectedHashtags } = this.state;
    const index = selectedHashtags.indexOf(hashtag);

    if (index < 0) {
      selectedHashtags.push(hashtag);
    } else {
      selectedHashtags.splice(index, 1);
    }

    this.setState({ selectedHashtags });
  };

  onHashtagInput = (e) => {
    this.input.hashtag.current.value = this.input.hashtag.current.value.replace(
      /[^\w]/g,
      ""
    );
  };

  onAddHashtag = (e, setFieldValue) => {
    const { challenge, selectedHashtags } = this.state;

    if (e.keyCode === 13) {
      // Enter key
      const newHashtag = this.input.hashtag.current.value;

      if (newHashtag !== "") {
        const updatedHashtags = utils.addHashtag(
          challenge,
          selectedHashtags,
          newHashtag
        );
        const {
          challenge: newChallenge,
          selectedHashtags: newSelectedHashtags,
          isAdded,
        } = updatedHashtags;

        if (isAdded) {
          this.input.hashtag.current.value = "";
          setFieldValue("hashtag", "");
        }

        this.setState({
          challenge: newChallenge,
          selectedHashtags: newSelectedHashtags,
        });
      }
    }
  };

  handleCirclekTerms = () => {
    const { isShowCirclekTerms } = this.state;

    this.setState({
      isShowCirclekTerms: !isShowCirclekTerms,
    });
  };

  renderHashtagList() {
    const { challenge, selectedHashtags } = this.state;

    return (
      <div className="list-hashtag">
        {challenge &&
          challenge.hashtags.length > 0 &&
          challenge.hashtags.map((hashtag, i) => {
            const classes = selectedHashtags.includes(hashtag)
              ? "hashtag active"
              : "hashtag";

            return (
              <span
                className={classes}
                key={i}
                onClick={() => this.onSelectHashtag(hashtag)}
              >
                #{hashtag}
              </span>
            );
          })}
      </div>
    );
  }

  renderField = (field) => {
    const {
      placeholder = "",
      label = "",
      touched = {},
      errors = {},
      getFieldProps = () => {},
      type = "text",
      isSocialMedia = false,
      setFieldValue = () => {},
      rows = 1,
    } = field;
    const { uploadedFiles, previewTxt, previewImg } = this.state;
    const dropzoneStyles = utils.getDropzoneStyles(uploadedFiles, previewImg);
    const previewImgStyles = utils.getPreviewImgStyles(previewImg);
    let formContainer = "form-group";
    let dropzoneClassName =
      uploadedFiles.length > 0 && uploadedFiles[0].type.includes("video")
        ? "dropzone video"
        : "dropzone";

    if (type === "file") {
      formContainer = "widget-media-wrapper w-50 circlekus-media";
    }

    const isEmail = type === "email";

    if (previewImg) {
      dropzoneStyles.backgroundColor = "transparent";
      previewImgStyles.backgroundImage = `url(${previewImg})`;

      if (uploadedFiles && uploadedFiles.length > 1) {
        dropzoneStyles.backgroundColor = "#343a40";
      }
    } else {
      previewImgStyles.backgroundImage = "none";
    }

    let customizeOnChange = () => {};

    if (isEmail) {
      const { onChange = () => {} } = field.getFieldProps(label); // get Formik Input onChange Event
      customizeOnChange = (e) => {
        onChange(e);
        this.onChangeEmail(e, setFieldValue);
      };
    }

    return (
      <div className={formContainer}>
        {type === "file" && (
          <div>
            <div className="text-wrapper">
              <h4>Hello, Circle K Community!</h4>
              <p>
                We want to hear from you! Share your favorite Circle K photos
                with us for a chance to be featured on our Instagram.
              </p>
            </div>
            <div className="widget-media">
              <div className="preview-img" style={previewImgStyles}>
                <Dropzone
                  onDrop={(files) => this.onDrop(files, setFieldValue)}
                  accept={SUPPORTED_FILE.ACCEPTED}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div
                      {...getRootProps()}
                      className={dropzoneClassName}
                      style={dropzoneStyles}
                      title={utils.getFileUploadToolTip()}
                    >
                      <input {...getInputProps()} />
                    </div>
                  )}
                </Dropzone>
                {uploadedFiles.length >= 1 && <p>{previewTxt}</p>}
                {uploadedFiles.length === 0 && (
                  <div className="preview-txt-default">
                    <FontAwesomeIcon icon={faCamera} size="lg" />
                  </div>
                )}
              </div>
            </div>
            <p className={uploadedFiles.length >= 1 ? "text hide" : "text"}>
              Drag and Drop or Click here <br />
              to upload your media file
            </p>
          </div>
        )}

        {(type === "text" || type === "email") && !isSocialMedia && (
          <div className="input-wrapper">
            <input
              placeholder={placeholder}
              className="form-control form-control-sm"
              type={type}
              name={label}
              id={label}
              key={label}
              {...getFieldProps(label)}
              {...(isEmail && { onChange: (e) => customizeOnChange(e) })}
              {...(isEmail && { onKeyUp: (e) => customizeOnChange(e) })}
            />
            <small className="error-message uploader-error-message">
              {touched[label] && errors[label]}
            </small>
          </div>
        )}

        {type === "tel" && (
          <div className="input-wrapper">
            <input
              placeholder={placeholder}
              className="form-control form-control-sm"
              type={type}
              name={label}
              id={label}
              key={label}
              {...getFieldProps(label)}
            />
            <small className="error-message uploader-error-message">
              {touched[label] && errors[label]}
            </small>
          </div>
        )}

        {type === "textarea" && (
          <div className="input-wrapper">
            <textarea
              className="form-control form-control-sm"
              type={type}
              id={label}
              key={label}
              placeholder={placeholder}
              {...getFieldProps(label)}
              rows={rows}
            />
          </div>
        )}

        {type === "hashtag" && (
          <div className="input-wrapper">
            <label>Select Hashtags</label>
            {this.renderHashtagList()}
            <input
              placeholder={placeholder}
              className="form-control form-control-sm"
              type={type}
              name={label}
              id={label}
              key={label}
              {...getFieldProps(label)}
              ref={this.input.hashtag}
              onKeyDown={(e) => this.onAddHashtag(e, setFieldValue)}
              onInput={(e) => this.onHashtagInput(e)}
              maxLength={255}
            />
            <small className="error-message uploader-error-message">
              {touched[label] && errors[label]}
            </small>
          </div>
        )}

        {isSocialMedia && (
          <div className="input-wrapper">
            <div className="social-media-input">
              <span>@</span>
              <input
                name={label}
                id={label}
                key={label}
                placeholder={placeholder}
                type={type}
                className="form-control form-control-sm"
                {...getFieldProps(label)}
              />
            </div>
            <small className="error-message uploader-error-message">
              {touched[label] && errors[label]}
            </small>
          </div>
        )}
      </div>
    );
  };

  render() {
    const {
      isDisabledWidget,
      formState,
      uploadResults,
      uploadedFiles,
      generalMessage,
      uploadedFileType,
      isShowCirclekTerms,
    } = this.state;

    const widgetContainerClasses = isDisabledWidget
      ? "widget-container disabled"
      : "widget-container";

    const lastUploadedFile = utils.getLastUploadedFile(uploadResults);

    return (
      <div className={`widget-circlekus ${widgetContainerClasses}`}>
        {formState === 2 && (
          <div className="container">
            <div className="widget-block widget-upload">
              <div className="widget-header px-5">
                <img src={circlekcanadaLogo} alt="circlekcanadaLogo" />
              </div>
              <Form
                fields={fields}
                logo={logoImg}
                uploadedFiles={uploadedFiles}
                generalMessage={generalMessage}
                renderField={this.renderField}
                clearMessage={this.clearMessage}
                onSubmit={this.onSubmit}
                setGeneralError={this.setGeneralError}
                isNewForm={true}
                isNewFormResponsive={true}
                clientName="circlekus"
                isFromCirclekus={true}
                onAdditonalTermsClick={this.handleCirclekTerms}
              />
              <div className="widget-footer">
                <div className="signature text-center">
                  Powered By{" "}
                  <a
                    href="https://www.entribe.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={logoImg} alt="logo" className="logo" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Start Thank You Form */}
        {formState === 3 && (
          <div className="container">
            <div className="widget-block widget-thankyou">
              <div className="widget-header">
                <img src={circlekcanadaLogo} alt="circlekcanadaLogo" />
              </div>
              {/* Start Last Upload */}
              <div className="widget-body">
                <h3>Thank you for the upload!</h3>
                <div className="uploaded-media">
                  {lastUploadedFile && (
                    <img
                      src={lastUploadedFile}
                      alt="widget upload"
                      onError={(e) =>
                        utils.handleThumbnailLoadedError(
                          e,
                          uploadedFileType || ""
                        )
                      }
                    />
                  )}
                  {uploadResults && uploadResults.type === "video" && (
                    <FontAwesomeIcon icon={faPlayCircle} size="lg" />
                  )}
                </div>
                {/* End Last Upload */}
              </div>
              <div className="widget-footer">
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={() => this.onUploadMore()}
                >
                  Upload More
                </button>

                <div className="widget-footer">
                  <div className="signature text-center">
                    Powered By{" "}
                    <a
                      href="https://www.entribe.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      <img src={logoImg} alt="logo" className="logo" />
                    </a>
                  </div>
                </div>
              </div>
              {/* End Footer */}
            </div>
          </div>
        )}
        {/* End Thank You Form */}

        <Modal
          size="lg"
          centered
          show={isShowCirclekTerms}
          onHide={this.handleCirclekTerms}
          dialogClassName="circlek-terms-modal"
          contentClassName="circlek-terms-body"
        >
          <Modal.Header closeButton>
            <h4 className="circlek-terms-title">
              CircleK Content Licensing Terms
            </h4>
          </Modal.Header>
          <Modal.Body>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  "By submitting UGC, you are consenting Circle K and their parent(s), agents, affiliates and/or assignees, a non-exclusive, fully paid and royalty-free, transferable, sub-licensable, worldwide, perpetual license to display, distribute, reproduce, and create derivatives of the Photos and content, in whole or in part, without further review or participation from you. The content uploaded is your original content, and does not infringe the intellectual property rights, privacy rights, publicity rights, or other legal rights of any third party.",
              }}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default withRouter(CirclekUS);
