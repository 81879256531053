const getYoutubeChannelIdTypes = {
  GET_YOUTUBE_CHANNEL_ID_REQUEST: "GET_YOUTUBE_CHANNEL_ID_REQUEST",
  GET_YOUTUBE_CHANNEL_ID_SUCCEEDED: "GET_YOUTUBE_CHANNEL_ID_SUCCEEDED",
  GET_YOUTUBE_CHANNEL_ID_FAILED: "GET_YOUTUBE_CHANNEL_ID_FAILED",
  GET_YOUTUBE_CHANNEL_ID_END: "GET_YOUTUBE_CHANNEL_ID_END",
};

const getYoutubeVideoListTypes = {
  GET_YOUTUBE_VIDEO_LIST_REQUEST: "GET_YOUTUBE_VIDEO_LIST_REQUEST",
  GET_YOUTUBE_VIDEO_LIST_SUCCEEDED: "GET_YOUTUBE_VIDEO_LIST_SUCCEEDED",
  GET_YOUTUBE_VIDEO_LIST_FAILED: "GET_YOUTUBE_VIDEO_LIST_FAILED",
  GET_YOUTUBE_VIDEO_LIST_END: "GET_YOUTUBE_VIDEO_LIST_END",
};

const getGoogleTokenInformationTypes = {
  GET_GOOGLE_TOKEN_INFORMATION_REQUEST: "GET_GOOGLE_TOKEN_INFORMATION_REQUEST",
  GET_GOOGLE_TOKEN_INFORMATION_SUCCEEDED:
    "GET_GOOGLE_TOKEN_INFORMATION_SUCCEEDED",
  GET_GOOGLE_TOKEN_INFORMATION_FAILED: "GET_GOOGLE_TOKEN_INFORMATION_FAILED",
  GET_GOOGLE_TOKEN_INFORMATION_END: "GET_GOOGLE_TOKEN_INFORMATION_END",
};

const refreshOAuthGoogleTokenTypes = {
  REFRESH_OAUTH_GOOGLE_TOKEN_REQUEST: "REFRESH_OAUTH_GOOGLE_TOKEN_REQUEST",
  REFRESH_OAUTH_GOOGLE_TOKEN_SUCCEEDED: "REFRESH_OAUTH_GOOGLE_TOKEN_SUCCEEDED",
  REFRESH_OAUTH_GOOGLE_TOKEN_FAILED: "REFRESH_OAUTH_GOOGLE_TOKEN_FAILED",
  REFRESH_OAUTH_GOOGLE_TOKEN_END: "REFRESH_OAUTH_GOOGLE_TOKEN_END",
};
const storeGoogleDataTypes = {
  STORE_GOOGLE_DATA_REQUEST: "STORE_GOOGLE_DATA_REQUEST",
};

export {
  getYoutubeChannelIdTypes,
  getYoutubeVideoListTypes,
  getGoogleTokenInformationTypes,
  refreshOAuthGoogleTokenTypes,
  storeGoogleDataTypes,
};
