const fields = [
  { label: "fileUploads", order: 1, type: "file", requireTdext: "Upload file" },
  {
    label: "email",
    placeholder: "Email*",
    order: 2,
    type: "email",
    isRequired: true,
    requireTdext: "Upload file",
  },
  {
    label: "firstName",
    placeholder: "First name*",
    order: 3,
    type: "text",
    isRequired: true,
    requireTdext: "First name",
  },
  {
    label: "lastName",
    placeholder: "Last name*",
    order: 4,
    type: "text",
    isRequired: true,
    requireTdext: "Last name",
  },
];

export default fields;
