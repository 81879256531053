import { Carousel, Col, Row } from "antd";
import { HEIGHT_DRAG_AND_DROP, PREFIX_CLS } from "constants/common";
import THEME from "constants/themes";
import styled from "styled-components";
import { StyledFlex, StyledIcon } from "styled-components/Common/CommonStyled";

export const StyledCarousel = styled(Carousel)`
  &.${PREFIX_CLS}-carousel, .slick-slider {
    height: calc(100% - 28px) !important;
  }

  .slick-slide {
    padding: 4px !important;
  }
`;

export const StyledWrapperUpload = styled.div`
  ${({ display }) => `display: ${display}`};
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: ${({ bgColor }) => bgColor || "#f5f9fd"};
  height: ${({ isMobile }) =>
    isMobile ? HEIGHT_DRAG_AND_DROP.mobile : HEIGHT_DRAG_AND_DROP.desktop}px;
  border: 1px dashed #d9e4ef;
  ${({ cursor }) => `cursor: ${cursor}`};
  padding: 10px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;

  &.dynamic-widget {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${THEME.colors.gray3};
    border-radius: 20px;
  }
`;

export const StyledGoogleFileItem = styled(Row)`
  padding: 5px;
  cursor: pointer;

  ${Col} {
    &:last-child {
      display: flex;
    }
  }

  .icon-remove {
    display: none;
  }

  .icon-checked {
    display: flex;
    pointer-events: none;
  }

  ${StyledIcon} {
    transition: all 1s ease-out;
    align-items: center;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: rgba(1, 1, 1, 0.1);

    .icon-checked {
      display: none;
    }

    .icon-remove {
      display: flex;
    }
  }
`;

export const StyledSourceIconContainer = styled(StyledFlex)`
  z-index: 9;
  transform: scale(0.915);

  div {
    margin: 3.25px;

    .instagram_icon {
      margin-right: 6px;
    }

    #google_picker_btn {
      margin-top: 8.5px;
      margin-left: 6px;
    }

    .standard-upload-icon {
      margin-top: 2px;
    }
  }
`;

export const StyledSourceIcon = styled(StyledIcon)`
  &:focus-visible {
    border-radius: unset;
  }
`;

export const StyledDropzone = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const StyledHelperTextOnDropzone = styled.p`
  z-index: 9;
  margin: 8px 0 0 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  text-align: center;
  ${({ color }) => color && `color: ${color}`};
`;
