const mediaTakenLocationOptions = [
  {
    options: [
      { value: "British Columbia", label: "British Columbia" },
      { value: "Alberta", label: "Alberta" },
      { value: "Saskatchewan", label: "Saskatchewan" },
      { value: "Manitoba ", label: "Manitoba" },
      { value: "Ontario", label: "Ontario" },
      { value: "Quebec", label: "Quebec" },
      { value: "Newfoundland", label: "Newfoundland" },
      { value: "Prince Edward Island", label: "Prince Edward Island" },
      { value: "Nova Scotia", label: "Nova Scotia" },
      { value: "Yukon", label: "Yukon" },
      { value: "NorthWest Territories", label: "NorthWest Territories" },
      { value: "Nunavut", label: "Nunavut" },
    ],
  },
];

const fields = [
  { label: "fileUploads", order: 1, type: "file" },
  {
    label: "email",
    placeholder: "Email",
    order: 2,
    type: "email",
    isRequired: true,
  },
  {
    label: "firstName",
    placeholder: "First name",
    order: 3,
    type: "text",
    isRequired: false,
  },
  {
    label: "lastName",
    placeholder: "Last name",
    order: 4,
    type: "text",
    isRequired: false,
  },
  {
    label: "instagramUsername",
    placeholder: "Instagram username",
    order: 5,
    type: "text",
    isSocialMedia: true,
    isRequired: true,
  },
  {
    label: "twitterUsername",
    placeholder: "Twitter username",
    order: 6,
    type: "text",
    isSocialMedia: true,
    isRequired: true,
  },
  {
    label: "mediaTakenLocation",
    placeholder: "Where In Canada was this photo taken",
    order: 7,
    type: "select",
    selectOption: mediaTakenLocationOptions,
    isRequired: false,
  },
  {
    label: "circlekCanadaAgreement",
    placeholder:
      "I confirm that the creation of this photo did not disrupt the customer experience at CircleK, did not harm the customers or team members of CircleK, and did not violate government health and safety mandated regulations",
    order: 8,
    type: "checkbox",
    isRequired: false,
  },
];

export default fields;
