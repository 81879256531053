import { IconUploadDragger, IconXCircle } from "assets/img/icons";
import ScrollableWrapper from "components/shared/ScrollableWrapper";
import { CLASS_NAME, MEDIA_TYPE, SUPPORTED_FILE } from "constants/common";
import THEME from "constants/themes";
import GeminiContext from "contexts/Gemini";
import GeminiBodyContext from "contexts/GeminiBody";
import { t } from "i18next";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import utils from "services/utils";
import { generateThumbnailList } from "services/widgetBuilder";
import {
  StyledIcon,
  StyledWrapper,
} from "styled-components/Common/CommonStyled";
import { StyledGeminiUpload } from "styled-components/CustomWidget/GeminiStyled";
import {
  StyledInnerDropzone,
  StyledUploadContainer,
} from "styled-components/WidgetBuilder/WidgetBuilderStyled";
import { StyledThumbnail } from "styled-components/WidgetBuilder/WidgetTikTokStyled";
import { StyledSourceIcon } from "styled-components/WidgetBuilder/WidgetUploadSourceStyled";

const UploadSource = (props) => {
  const { globalProps } = props;
  const { setIsShowLoading } = globalProps || {};
  const timelineLoaded = useRef(false);
  const { uploadedFiles, setUploadedFiles } = useContext(GeminiBodyContext);
  const { handleErrorMsg, widgetInfo } = useContext(GeminiContext);

  const [thumbnailSrcList, setThumbnailSrcList] = useState([]);

  const showThumbnails = useMemo(() => {
    return !!uploadedFiles?.length && !!thumbnailSrcList.length;
  }, [uploadedFiles, thumbnailSrcList]);

  useEffect(() => {
    if (uploadedFiles.length && !timelineLoaded.current) {
      handleThumbnailSrcList();
    }
    return () => {
      if (!uploadedFiles.length) {
        timelineLoaded.current = false;
      }
    };
  }, [uploadedFiles]);

  const handleThumbnailSrcList = async () => {
    setIsShowLoading(true);
    let convertedList = generateThumbnailList(uploadedFiles);
    convertedList = await Promise.all(convertedList).finally(() => {
      setIsShowLoading(false);
    });
    setThumbnailSrcList(convertedList);
    timelineLoaded.current = true;
  };

  const handleStandardUpload = () => {
    const el = document.getElementById("dropzone-upload");
    if (el) {
      el.click();
    }
  };

  const onDrop = async (fileUploads) => {
    fileUploads = await utils.convertFileUploads(fileUploads, setIsShowLoading);
    await validateUploadedFilesCustom(fileUploads)
      .then((result) => {
        if (result) {
          setUploadedFiles(fileUploads);
        } else {
          setUploadedFiles([]);
        }
      })
      .catch((error) => console.log("Error", error));
  };

  const validateUploadedFilesCustom = async (files) => {
    const { errorMsg, isValidFiles } = await utils.validateUploadedFiles(
      files,
      "en",
      widgetInfo.widgetSettingsDTO
    );

    if (!isValidFiles) {
      setUploadedFiles([]);
      handleErrorMsg(errorMsg);
      return false;
    }

    return true;
  };

  const renderDropzone = () => {
    const colorProps = {
      colorUploadIcon: THEME.colors.gray1,
      bgColor: "#F0F4F9",
    };

    return (
      <StyledGeminiUpload
        className="gemini-upload-dropzone"
        bgColor={colorProps.bgColor}
        tabIndex={-1}
      >
        <StyledUploadContainer className="dropzone preview-img">
          <Dropzone
            className="gemini-dropzone"
            onDrop={(files) => onDrop(files)}
            accept={SUPPORTED_FILE.ACCEPTED}
          >
            {({ getRootProps, getInputProps }) => (
              <StyledInnerDropzone
                id="dropzone-upload"
                {...getRootProps()}
                color={colorProps.colorUploadIcon}
              >
                <input {...getInputProps()} />
                <StyledSourceIcon
                  colorPath="#131314"
                  size={36}
                  tabIndex={0}
                  onKeyDown={(e) => utils.onKeyDown(e, handleStandardUpload)}
                  cursor="pointer"
                >
                  <IconUploadDragger />
                </StyledSourceIcon>
                <StyledWrapper
                  margin="14px auto"
                  width="76%"
                  textAlign="center"
                  color="#444746"
                  fontWeight={400}
                >
                  {t("gemini.upload_desc")}
                </StyledWrapper>
              </StyledInnerDropzone>
            )}
          </Dropzone>
        </StyledUploadContainer>
      </StyledGeminiUpload>
    );
  };

  const renderContentThumbnails = () => {
    return (
      !!thumbnailSrcList?.length &&
      thumbnailSrcList?.map((src, index) => {
        return (
          <StyledThumbnail key={index} subtractedValue={10}>
            {renderThumbnailItem(src, () => removeFile(index))}
          </StyledThumbnail>
        );
      })
    );
  };

  const filterList = (list, index) =>
    list.filter((_, fileIndex) => fileIndex !== index);

  const removeFile = (index) => {
    const filteredList = filterList(thumbnailSrcList, index);
    const uploadedFilesTemp = filterList(uploadedFiles, index);
    setThumbnailSrcList(filteredList);
    setUploadedFiles(uploadedFilesTemp);
  };

  const renderThumbnailItem = (src, actionRemove) => {
    return (
      <>
        <StyledIcon
          tabIndex={0}
          size={16}
          cursor="pointer"
          onClick={actionRemove}
          onKeyDown={(e) => utils.onKeyDown(e, actionRemove)}
        >
          <IconXCircle />
        </StyledIcon>
        <img
          src={src}
          onError={(e) => utils.handleThumbnailLoadedError(e, MEDIA_TYPE.IMAGE)}
        />
      </>
    );
  };

  return (
    <StyledWrapper margin="24px 0 16px 0">
      {!showThumbnails && renderDropzone()}
      {showThumbnails && (
        <ScrollableWrapper
          className={`gemini ${CLASS_NAME.THUMBNAIL_WRAPPER}`}
          bgColor={"#f5f9fd"}
          border="1px dashed #d9e4ef"
          padding="10px"
          overflow="auto hidden"
        >
          {renderContentThumbnails()}
        </ScrollableWrapper>
      )}
    </StyledWrapper>
  );
};

export default UploadSource;
