import React, { Component } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { debounce as _debounce, chunk as _chunk } from "lodash";
import Preload from "react-preload";
import configUrls from "../../../../constants/configUrls";
import errorMsgs from "../../../../constants/errorMsgs";
import uploadFileType, {
  DEFAULT_WIDGET_ID,
} from "../../../../constants/common";
import utils from "../../../../services/utils";
import EntribeLogo from "../../../../assets/img/logo-black.png";
import animatedLogo from "../../../../assets/img/entribe/logo-animation.gif";
import topLogo from "../../../../assets/img/entribe/entribe-logo.png";
import img_1 from "../../../../assets/img/entribe/gallery/entribe-1.jpg";
import img_2 from "../../../../assets/img/entribe/gallery/entribe-2.jpg";
import img_3 from "../../../../assets/img/entribe/gallery/entribe-3.jpg";
import img_4 from "../../../../assets/img/entribe/gallery/entribe-4.jpg";
import img_5 from "../../../../assets/img/entribe/gallery/entribe-5.jpg";
import img_6 from "../../../../assets/img/entribe/gallery/entribe-6.jpg";
import img_7 from "../../../../assets/img/entribe/gallery/entribe-7.jpg";
import img_8 from "../../../../assets/img/entribe/gallery/entribe-8.jpg";
import img_9 from "../../../../assets/img/entribe/gallery/entribe-9.jpg";
import img_10 from "../../../../assets/img/entribe/gallery/entribe-10.jpg";
import img_11 from "../../../../assets/img/entribe/gallery/entribe-11.jpg";
import img_12 from "../../../../assets/img/entribe/gallery/entribe-12.jpg";
import img_13 from "../../../../assets/img/entribe/gallery/entribe-13.jpg";
import img_14 from "../../../../assets/img/entribe/gallery/entribe-14.jpg";
import img_15 from "../../../../assets/img/entribe/gallery/entribe-15.jpg";
import img_16 from "../../../../assets/img/entribe/gallery/entribe-16.jpg";
import img_17 from "../../../../assets/img/entribe/gallery/entribe-17.jpg";
import img_18 from "../../../../assets/img/entribe/gallery/entribe-18.jpg";
import img_19 from "../../../../assets/img/entribe/gallery/entribe-19.jpg";
import img_20 from "../../../../assets/img/entribe/gallery/entribe-20.jpg";
import img_21 from "../../../../assets/img/entribe/gallery/entribe-21.jpg";
import img_22 from "../../../../assets/img/entribe/gallery/entribe-22.jpg";
import img_23 from "../../../../assets/img/entribe/gallery/entribe-23.jpg";
import img_24 from "../../../../assets/img/entribe/gallery/entribe-24.jpg";
import img_25 from "../../../../assets/img/entribe/gallery/entribe-25.jpg";
import img_26 from "../../../../assets/img/entribe/gallery/entribe-26.jpg";
import img_27 from "../../../../assets/img/entribe/gallery/entribe-27.jpg";
import img_28 from "../../../../assets/img/entribe/gallery/entribe-28.jpg";
import img_29 from "../../../../assets/img/entribe/gallery/entribe-29.jpg";
import img_30 from "../../../../assets/img/entribe/gallery/entribe-30.jpg";
import img_31 from "../../../../assets/img/entribe/gallery/entribe-31.jpg";
import img_32 from "../../../../assets/img/entribe/gallery/entribe-32.jpg";
import img_33 from "../../../../assets/img/entribe/gallery/entribe-33.jpg";
import img_34 from "../../../../assets/img/entribe/gallery/entribe-34.jpg";
import img_35 from "../../../../assets/img/entribe/gallery/entribe-35.jpg";
import img_36 from "../../../../assets/img/entribe/gallery/entribe-36.jpg";
import img_37 from "../../../../assets/img/entribe/gallery/entribe-37.jpg";
import img_38 from "../../../../assets/img/entribe/gallery/entribe-38.jpg";
import img_39 from "../../../../assets/img/entribe/gallery/entribe-39.jpg";
import img_40 from "../../../../assets/img/entribe/gallery/entribe-40.jpg";
import img_41 from "../../../../assets/img/entribe/gallery/entribe-41.jpg";
import img_42 from "../../../../assets/img/entribe/gallery/entribe-42.jpg";
import img_43 from "../../../../assets/img/entribe/gallery/entribe-43.jpg";
import img_44 from "../../../../assets/img/entribe/gallery/entribe-44.jpg";
import img_45 from "../../../../assets/img/entribe/gallery/entribe-45.jpg";
import img_46 from "../../../../assets/img/entribe/gallery/entribe-46.jpg";
import img_47 from "../../../../assets/img/entribe/gallery/entribe-47.jpg";
import img_48 from "../../../../assets/img/entribe/gallery/entribe-48.jpg";
import img_49 from "../../../../assets/img/entribe/gallery/entribe-49.jpg";
import img_50 from "../../../../assets/img/entribe/gallery/entribe-50.jpg";
import img_51 from "../../../../assets/img/entribe/gallery/entribe-51.jpg";
import img_52 from "../../../../assets/img/entribe/gallery/entribe-52.jpg";
import img_53 from "../../../../assets/img/entribe/gallery/entribe-53.jpg";
import img_54 from "../../../../assets/img/entribe/gallery/entribe-54.jpg";
import img_55 from "../../../../assets/img/entribe/gallery/entribe-55.jpg";
import img_56 from "../../../../assets/img/entribe/gallery/entribe-56.jpg";
import img_57 from "../../../../assets/img/entribe/gallery/entribe-57.jpg";
import img_58 from "../../../../assets/img/entribe/gallery/entribe-58.jpg";
import img_59 from "../../../../assets/img/entribe/gallery/entribe-59.jpg";
import img_60 from "../../../../assets/img/entribe/gallery/entribe-60.jpg";

import facebookLogo from "../../../../assets/img/entribe/gallery/facebook-logo.png";

import "./EnTribe.scss";

const loadingIndicator = (
  <div className="preloader-container">
    <img className="scale-up-center" src={animatedLogo} alt="EnTribe Logo" />
  </div>
);

const maxPreloadDelay = 45000;
const defaultUploadBtnTxt = "Upload";

const createImageGallery = () => {
  let imageGalleryLibrary = [
    img_1,
    img_2,
    img_3,
    img_4,
    img_5,
    img_6,
    img_7,
    img_8,
    img_9,
    img_10,
    img_11,
    img_12,
    img_13,
    img_14,
    img_15,
    img_16,
    img_17,
    img_18,
    img_19,
    img_20,
    img_21,
    img_22,
    img_23,
    img_24,
    img_25,
    img_26,
    img_27,
    img_28,
    img_29,
    img_30,
    img_31,
    img_32,
    img_33,
    img_34,
    img_35,
    img_36,
    img_37,
    img_38,
    img_39,
    img_40,
    img_41,
    img_42,
    img_43,
    img_44,
    img_45,
    img_46,
    img_47,
    img_48,
    img_49,
    img_50,
    img_51,
    img_52,
    img_53,
    img_54,
    img_55,
    img_56,
    img_57,
    img_58,
    img_59,
    img_60,
  ];
  let pos;
  let arrImages = [];
  for (let i = 0; i < 16; i++) {
    pos = Math.floor(Math.random() * imageGalleryLibrary.length);
    arrImages.push(imageGalleryLibrary[pos]);
    imageGalleryLibrary.splice(pos, 1);
  }
  return arrImages;
};

const imageGallery = createImageGallery();

class EnTribe extends Component {
  static propTypes = {
    globalProps: PropTypes.shape({
      setIsShowLoading: PropTypes.func,
      requestAPI: PropTypes.func,
      didMountCallback: PropTypes.func,
    }),
  };

  static defaultProps = {
    globalProps: {
      setIsShowLoading: () => {},
      requestAPI: () => {},
      didMountCallback: () => {},
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      isDisabledWidget: false,
      formState: 2, // 1 - Welcome, 2 - Upload, 3 - Thankyou

      client: null,
      challenge: null,
      selectId: null,
      location: null,

      selectedHashtags: ["#entribe"],

      email: "",
      firstName: "",
      lastName: "",
      instagramUsername: "",

      isValidEmail: true,
      isValidFirstName: true,
      isValidLastName: true,
      isValidInstagram: true,
      isValidAboutImg: true,
      isValidCapturedPlace: true,

      emailErrMsg: "",
      firstNameErrMsg: "",
      lastNameErrMsg: "",
      instagramErrMsg: "",
      descriptionErrMsg: "",
      shotLocationErrMsg: "",

      isAgreed: false,

      errorMsg: "",
      uploadedFiles: [],
      uploadedFileType: "",
      previewTxt: "",
      previewImg: "",

      uploadResults: null,

      // Use to calculate and render text with typing animation
      subTitle: "Hello EnTribe Creator!",
      subTitleIndex: 0,
      displaySubTitle: "",
      subHeader: "Thank you for being part of our creator community!",
      subHeaderClass: "sub-header",

      // Prevent browser cache for GIF image
      logoId: 0,

      chunkImages_1: [],
      chunkImages: [],

      uploadBtnText: defaultUploadBtnTxt,
    };

    this.input = {
      email: React.createRef(),
      firstName: React.createRef(),
      lastName: React.createRef(),
      instagramUsername: React.createRef(),
      description: React.createRef(),
      shotLocation: React.createRef(),
      files: React.createRef(),
      isAgreed: React.createRef(),
    };
  }

  componentWillReceiveProps(nextProps) {
    const {
      client = null,
      challenge = null,
      selectId = null,
      uploadLocation = null,
    } = nextProps;
    this.checkChallenge(challenge);

    this.setState({
      client,
      selectId,
      location: uploadLocation,
    });
  }

  componentWillMount() {
    this.setOnChangeEmailEvent();
    this.setState({ logoId: Math.random() });
  }

  componentDidMount() {
    const {
      globalProps: { didMountCallback },
    } = this.props;

    let { subHeaderClass } = this.state;
    subHeaderClass += " sub-header-animation";

    didMountCallback();
    // Play text animation after the logo end animating
    setTimeout(() => {
      this.animateTxt();
      this.setState({
        subHeaderClass,
      });
    }, 2500);
  }

  checkChallenge(challenge) {
    const { selectId = null, client = null } = this.props;

    if (challenge) {
      this.setState({ challenge, isDisabledWidget: false, errorMsg: "" });

      // Only validate challenge and show error if selectId is empty
    } else if (!challenge && !selectId && !client) {
      this.setState({
        isDisabledWidget: true,
        errorMsg: "Invalid data",
      });
    }
  }

  setOnChangeEmailEvent() {
    this.onChangeEmailCallback = _debounce((e) => {
      const email = this.getInputValue("email");

      if (email && utils.validateEmail(email)) {
        this.getCreatorAPI(email);
      } else {
        // TODO, maybe use later
        // this.resetAutoFilledFields();
      }
    }, 1000);
  }

  getCreatorAPI = (email) => {
    const {
      globalProps: { requestAPI },
    } = this.props;

    const successCallback = (resp) => {
      const { firstName = "", lastName = "", instagramUsername = "" } = resp;

      this.setState({
        firstName,
        lastName,
        instagramUsername,
        errorMsg: "",
        firstNameErrMsg: "",
        lastNameErrMsg: "",
        emailErrMsg: "",
        instagramErrMsg: "",
      });
    };

    const failedCallback = (resp) => {
      const errorMsg = resp.error
        ? resp.error
        : resp.status
        ? resp.status.status
        : errorMsgs["en"].callAPIFailed;
      this.setState({ errorMsg });
      this.resetAutoFilledFields();
    };
    const clientId = sessionStorage.getItem("clientId");
    const url = `${configUrls.API.getCreator}?clientId=${clientId}&email=${email}`;
    requestAPI("get", url, {}, successCallback, failedCallback);
  };

  uploadAPI() {
    const {
      globalProps: { requestAPI },
    } = this.props;

    const {
      client: { id: clientId },
      challenge,
      selectId,
      location,
      uploadedFiles,
      uploadedFileType,
      selectedHashtags,
    } = this.state;

    const parsedUploadedFiles = [];
    const currentTimeStamp = new Date().valueOf();
    const shotLocation = {
      name: "Shot Location",
      values: [this.getInputValue("shotLocation")],
      isTaggable: false,
      isSearchable: true,
    };

    const description = {
      name: "Description",
      values: [this.getInputValue("description")],
      isTaggable: false,
      isSearchable: true,
    };
    const additional = {
      custom: [shotLocation, description],
    };

    for (let i = 0; i < uploadedFiles.length; i++) {
      parsedUploadedFiles.push(uploadedFiles[i]);
    }

    const data = {
      clientId,
      challengeId: (challenge && challenge.id) || 0,
      selectId,
      uploadedFiles: parsedUploadedFiles,
      uploadedFileType,
      selectedHashtags,
      location,
      email: this.getInputValue("email"),
      firstName: this.getInputValue("firstName"),
      lastName: this.getInputValue("lastName"),
      instagramUsername: this.getInputValue("instagramUsername"),
      description: this.getInputValue("description"),
      additional,
    };

    const formData = utils.parseUploadFormData(data);
    let url = `${configUrls.API.upload}`;

    const successCallback = (resp) => {
      this.setState(
        {
          formState: 3,
          uploadResults: resp,
        },
        () => {
          this.resetForm();
        }
      );
    };

    const failedCallback = (resp) => {
      const errorMsg = resp.error ? resp.error : errorMsgs["en"].uploadFailed;
      this.setState({ errorMsg });
    };

    url = `${url}?access_time=${currentTimeStamp}`;
    if (data.selectId) {
      url = `${url}&s=${data.selectId}`;
    }
    if (data.challengeId) {
      url = `${url}&c=${data.challengeId}`;
    }
    url = `${url}&widgetId=${DEFAULT_WIDGET_ID}`;
    requestAPI("post", url, formData, successCallback, failedCallback);
  }

  onSubmit = async () => {
    const { uploadedFiles } = this.state;
    const isValidInput = this.validateForm();
    const isValidUploadedFiles = await this.validateUploadedFiles(
      uploadedFiles
    );
    const { isAgreed } = this.state;

    if (isValidInput && isValidUploadedFiles && isAgreed) {
      this.uploadAPI();
    }
  };

  onChangeEmail = (e) => {
    if (e.keyCode === 13) {
      // Enter key
      this.onSubmit();
      return;
    }

    e.persist();
    this.onChangeEmailCallback(e);
    this.setState({ email: e.target.value });
  };

  onChangeInput = (e, name) => {
    this.setState({ [name]: e.target.value });
  };

  onToggleAgreement = (e) => {
    const isAgreed = e.target.checked;
    this.input.isAgreed.current.value = isAgreed ? "on" : "off";
    this.setState({ isAgreed });
  };

  onEnter = (e) => {
    if (e.keyCode === 13) {
      // Enter key
      this.onSubmit();
    }
  };

  onChangeSelect = (e, name) => {
    this.setState({ [name]: e });
  };

  getInputValue = (name) => {
    if (!name) {
      return "";
    }

    return this.input[name].current ? this.input[name].current.value : "";
  };

  validateInput = (data) => {
    const {
      email = "",
      firstname = "",
      lastName = "",
      instagramUsername = "",
      description = "",
      shotLocation = "",
    } = data;

    let isValidForm = true;

    let isValidEmail = true;
    let isValidFirstName = true;
    let isValidLastName = true;
    let isValidInstagram = true;
    let isValidAboutImg = true;
    let isValidCapturedPlace = true;

    let emailErrMsg = "";
    let firstNameErrMsg = "";
    let lastNameErrMsg = "";
    let instagramErrMsg = "";
    let descriptionErrMsg = "";
    let shotLocationErrMsg = "";

    if (!email) {
      isValidForm = false;
      isValidEmail = false;
      emailErrMsg = errorMsgs["en"].requiredEmail;
    } else {
      if (!utils.validateEmail(email)) {
        isValidForm = false;
        isValidEmail = false;
        emailErrMsg = errorMsgs["en"].invalidEmail;
      } else {
        isValidEmail = true;
      }
    }

    if (!firstname) {
      isValidForm = false;
      isValidFirstName = false;
      firstNameErrMsg = errorMsgs["en"].requiredFirstName;
    } else {
      isValidFirstName = true;
    }

    if (!lastName) {
      isValidForm = false;
      isValidLastName = false;
      lastNameErrMsg = errorMsgs["en"].requiredLastname;
    } else {
      isValidLastName = true;
    }

    if (!instagramUsername) {
      isValidForm = false;
      isValidInstagram = false;
      instagramErrMsg = "(!) Please enter Instagram username.";
    } else {
      isValidInstagram = true;
    }

    if (!description) {
      isValidForm = false;
      isValidAboutImg = false;
      descriptionErrMsg = "(!) Please tell us about the image.";
    } else {
      isValidAboutImg = true;
    }

    if (!shotLocation) {
      isValidForm = false;
      isValidCapturedPlace = false;
      shotLocationErrMsg =
        "(!) Please tell us where was this picture captured.";
    } else {
      isValidCapturedPlace = true;
    }

    return {
      isValidForm, // validate success or failed

      isValidEmail,
      isValidFirstName,
      isValidLastName,
      isValidInstagram,
      isValidAboutImg,
      isValidCapturedPlace,

      emailErrMsg,
      firstNameErrMsg,
      lastNameErrMsg,
      instagramErrMsg,
      descriptionErrMsg,
      shotLocationErrMsg,
    };
  };

  validateForm = () => {
    const email = this.getInputValue("email");
    const firstname = this.getInputValue("firstName");
    const lastName = this.getInputValue("lastName");
    const instagramUsername = this.getInputValue("instagramUsername");
    const description = this.getInputValue("description");
    const shotLocation = this.getInputValue("shotLocation");

    const data = {
      email,
      firstname,
      lastName,
      instagramUsername,
      description,
      shotLocation,
    };

    const {
      isValidForm,

      isValidEmail,
      isValidFirstName,
      isValidLastName,
      isValidInstagram,
      isValidAboutImg,
      isValidCapturedPlace,

      emailErrMsg,
      firstNameErrMsg,
      lastNameErrMsg,
      instagramErrMsg,
      descriptionErrMsg,
      shotLocationErrMsg,
    } = this.validateInput(data);

    this.setState({
      isValidEmail,
      isValidFirstName,
      isValidLastName,
      isValidInstagram,
      isValidAboutImg,
      isValidCapturedPlace,

      emailErrMsg,
      firstNameErrMsg,
      lastNameErrMsg,
      instagramErrMsg,
      descriptionErrMsg,
      shotLocationErrMsg,
    });

    return isValidForm;
  };

  resetForm = () => {
    this.setState({ ...utils.getEmptyFormData() });
  };

  resetAutoFilledFields = () => {
    this.setState({
      firstName: "",
      lastName: "",
      instagramUsername: "",
    });
  };

  validateUploadedFiles = async (files) => {
    const { errorMsg, isValidFiles, previewImg, previewTxt } =
      await utils.validateUploadedFiles(files);

    if (!isValidFiles) {
      this.setState({
        uploadedFiles: [],
        errorMsg,
        previewImg,
        previewTxt,
      });

      return false;
    }

    this.setState({ uploadedFiles: files, errorMsg });
    return true;
  };

  renderPreviewTxt = (files) => {
    this.setState({ previewTxt: utils.getPreviewTxt(files) });
  };

  renderChildImageGroup = (arr, direction) => {
    const chunksCell = this.separateImages(arr, 4); //split into 4 cells, making 4 arrays with 4 items;
    return (
      <div className="image-holder">
        <div className={`images ${direction}`}>
          {chunksCell.map((photo, index) => {
            return (
              <img
                className="img1"
                src={photo}
                alt="EnTribe gallery"
                key={index}
              />
            );
          })}
        </div>
      </div>
    );
  };

  renderBlockChildImage = (arr, index) => {
    const chunksBlock = this.separateImages(arr, 4); //split into 4 blocks, making 4 arrays with 4 items;

    return (
      <div key={index} className="block-child">
        {this.renderChildImageGroup(chunksBlock[0], "clockwise")}
        {this.renderChildImageGroup(chunksBlock[1], "counterClockwise")}
        {this.renderChildImageGroup(chunksBlock[2], "counterClockwise")}
        {this.renderChildImageGroup(chunksBlock[3], "clockwise")}
      </div>
    );
  };

  renderMobileBlockChildImage = (arr, index) => {
    const chunksBlock = this.separateImages(arr, 4); //split into 4 blocks, making 4 arrays with 4 items;

    return (
      <div key={index}>
        <div className="block-child">
          {this.renderChildImageGroup(chunksBlock[0], "clockwise")}
          {this.renderChildImageGroup(chunksBlock[1], "counterClockwise")}
          {this.renderChildImageGroup(chunksBlock[2], "counterClockwise")}
          {this.renderChildImageGroup(chunksBlock[3], "clockwise")}
        </div>
        <div className="block-child">
          {this.renderChildImageGroup(chunksBlock[1], "clockwise")}
          {this.renderChildImageGroup(chunksBlock[2], "counterClockwise")}
          {this.renderChildImageGroup(chunksBlock[3], "counterClockwise")}
          {this.renderChildImageGroup(chunksBlock[0], "clockwise")}
        </div>
        <div className="block-child">
          {this.renderChildImageGroup(chunksBlock[2], "clockwise")}
          {this.renderChildImageGroup(chunksBlock[3], "counterClockwise")}
          {this.renderChildImageGroup(chunksBlock[0], "counterClockwise")}
          {this.renderChildImageGroup(chunksBlock[1], "clockwise")}
        </div>
        <div className="block-child">
          {this.renderChildImageGroup(chunksBlock[3], "clockwise")}
          {this.renderChildImageGroup(chunksBlock[0], "counterClockwise")}
          {this.renderChildImageGroup(chunksBlock[1], "counterClockwise")}
          {this.renderChildImageGroup(chunksBlock[2], "clockwise")}
        </div>
        <div className="block-child">
          {this.renderChildImageGroup(chunksBlock[0], "clockwise")}
          {this.renderChildImageGroup(chunksBlock[1], "counterClockwise")}
          {this.renderChildImageGroup(chunksBlock[2], "counterClockwise")}
          {this.renderChildImageGroup(chunksBlock[3], "clockwise")}
        </div>
        <div className="block-child">
          {this.renderChildImageGroup(chunksBlock[1], "clockwise")}
          {this.renderChildImageGroup(chunksBlock[2], "counterClockwise")}
          {this.renderChildImageGroup(chunksBlock[3], "counterClockwise")}
          {this.renderChildImageGroup(chunksBlock[0], "clockwise")}
        </div>
      </div>
    );
  };

  populateImageArray = (arr) => {
    while (arr.length < 64) {
      let clonedArr = [...arr];
      arr.forEach((item) => {
        if (clonedArr.length < 64) {
          clonedArr.push(item);
        }
      });
      arr = [...clonedArr];
    }

    return arr;
  };

  separateImages = (arr = [], size) => {
    let result = Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
      arr.slice(i * size, i * size + size)
    );

    return result.length > 1 ? result : result[0];
  };

  handleListPhotos = (images) => {
    if (images.length < 64) {
      images = this.populateImageArray(images);
    } else if (images.length > 64) {
      let spares = images.length - 64;
      images.splice(64, spares);
    }

    return this.separateImages(images, 16);
  };

  animateTxt = () => {
    const { subTitleIndex, subTitle } = this.state;

    let { displaySubTitle } = this.state;

    if (subTitleIndex < subTitle.length) {
      displaySubTitle += subTitle.charAt(subTitleIndex);
      this.setState(
        { displaySubTitle, subTitleIndex: subTitleIndex + 1 },
        () => {
          setTimeout(() => this.animateTxt(), 160);
        }
      );
    }
  };

  selectFiles = (e) => {
    this.input.files.current.click();
  };

  onUploadFiles = async (e) => {
    const selectedFiles = this.input.files.current.files;
    const countSelectedFiles = selectedFiles.length;

    utils.getPreviewImgAndType(selectedFiles).then((data) => {
      const { uploadedFileType } = data;
      this.setState({ uploadedFileType });
    });

    if (
      countSelectedFiles > 0 &&
      (await this.validateUploadedFiles(selectedFiles))
    ) {
      this.setState({
        uploadedFiles: selectedFiles,
        uploadBtnText: `Selected ${selectedFiles.length} file(s)`,
      });
    } else {
      this.setState({ uploadedFiles: [], uploadBtnText: defaultUploadBtnTxt });
    }
  };

  renderGallery = (imageGallery, isMobile = false) => {
    return (
      <div className="block-parent-wrapper">
        <div className="block-parent">
          {!isMobile &&
            imageGallery.map((arr, index) =>
              this.renderBlockChildImage(arr, index)
            )}
          {isMobile &&
            imageGallery.map((arr, index) =>
              this.renderMobileBlockChildImage(arr, index)
            )}
        </div>
      </div>
    );
  };

  render() {
    const {
      displaySubTitle,
      isDisabledWidget,
      formState,
      isAgreed,
      errorMsg,
      logoId,

      email,
      firstName,
      lastName,
      instagramUsername,
      emailErrMsg,
      firstNameErrMsg,
      lastNameErrMsg,
      instagramErrMsg,

      isValidEmail,
      isValidFirstName,
      isValidLastName,
      isValidInstagram,

      uploadBtnText,
      subHeader,
      subHeaderClass,
    } = this.state;

    let prefixSymbol = "input-group-text";
    const widgetContainerClasses = isDisabledWidget
      ? "widget-container disabled"
      : "widget-container";
    const logoStyles = {
      backgroundImage: `url(${topLogo}?${logoId})`,
    };

    // Create an empty array with 4 empty array item: [[], [], [], []];
    const chunkImages = [[], [], [], []];
    // Get distinced images from the gallery, separate into 4 child arrays: [[1,2,3,4], [5,6,7,8], [9,10,11,12], [13,14,15,6]]
    const chunkImagesTemplate = _chunk(
      this.handleListPhotos(imageGallery)[0],
      4
    );

    for (let i = 0; i < 4; i++) {
      chunkImagesTemplate.forEach((images) => {
        if (i === 0) {
          chunkImages[i].push(images[0]);
          chunkImages[i].push(images[1]);
          chunkImages[i].push(images[2]);
          chunkImages[i].push(images[3]);
        } else if (i === 1) {
          chunkImages[i].push(images[1]);
          chunkImages[i].push(images[2]);
          chunkImages[i].push(images[3]);
          chunkImages[i].push(images[0]);
        } else if (i === 2) {
          chunkImages[i].push(images[2]);
          chunkImages[i].push(images[3]);
          chunkImages[i].push(images[0]);
          chunkImages[i].push(images[1]);
        } else {
          chunkImages[i].push(images[3]);
          chunkImages[i].push(images[0]);
          chunkImages[i].push(images[1]);
          chunkImages[i].push(images[2]);
        }
      });
    }

    return (
      <Preload
        loadingIndicator={loadingIndicator}
        images={imageGallery}
        autoResolveDelay={maxPreloadDelay}
        resolveOnError={true}
        mountChildren={true}
      >
        <div className={`widget-entribe ${widgetContainerClasses}`}>
          <div className="container">
            <div className="mobile-gallery-container">
              <div className="gallery">
                {this.renderGallery(chunkImages, true)}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-7">
                {formState === 2 && (
                  <div className="widget-block widget-upload">
                    <div className="widget-header">
                      <div className="logo" style={logoStyles}></div>
                      <h2>{displaySubTitle}</h2>
                      <div className={subHeaderClass}>{subHeader}</div>
                    </div>
                    {/* End Header */}

                    <div className="widget-body">
                      <div className="widget-form">
                        <div className="error-msg">
                          <p>{errorMsg} &nbsp;</p>
                        </div>
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control form-control-sm"
                            ref={this.input.email}
                            onKeyDown={(e) => this.onEnter(e)}
                            onChange={(e) => this.onChangeEmail(e)}
                            placeholder="Email"
                            value={email}
                          />
                          <small>{!isValidEmail && emailErrMsg} &nbsp;</small>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            ref={this.input.firstName}
                            onKeyDown={(e) => this.onEnter(e)}
                            onChange={(e) => this.onChangeInput(e, "firstName")}
                            placeholder="First Name"
                            value={firstName}
                          />
                          <small>
                            {!isValidFirstName && firstNameErrMsg} &nbsp;
                          </small>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            ref={this.input.lastName}
                            onKeyDown={(e) => this.onEnter(e)}
                            onChange={(e) => this.onChangeInput(e, "lastName")}
                            placeholder="Last Name"
                            value={lastName}
                          />
                          <small>
                            {!isValidLastName && lastNameErrMsg} &nbsp;
                          </small>
                        </div>
                        <div className="form-group">
                          <div className="input-group input-group-sm">
                            <div className="input-group-prepend">
                              <span className={prefixSymbol}>@</span>
                            </div>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              ref={this.input.instagramUsername}
                              onKeyDown={(e) => this.onEnter(e)}
                              onChange={(e) =>
                                this.onChangeInput(e, "instagramUsername")
                              }
                              placeholder="Instagram"
                              value={instagramUsername}
                            />
                          </div>
                          <small>
                            {!isValidInstagram && instagramErrMsg} &nbsp;
                          </small>
                        </div>
                      </div>
                      {/* End Input Fields */}

                      <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={(e) => this.selectFiles(e)}
                      >
                        {uploadBtnText}
                      </button>
                      <input
                        type="file"
                        multiple={true}
                        accept={uploadFileType}
                        ref={this.input.files}
                        onChange={(e) => this.onUploadFiles(e)}
                      />
                      <div className="row">
                        <div className="col-sm-8">
                          <div className="widget-agreement">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value="on"
                                id="agreement"
                                checked={isAgreed}
                                ref={this.input.isAgreed}
                                onChange={(e) => {
                                  this.onToggleAgreement(e);
                                }}
                              />
                              <label className="form-check-label">
                                I agree to the{" "}
                                <a
                                  href={configUrls.App.terms}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <u>Terms & Conditions*</u>
                                </a>
                              </label>
                            </div>
                          </div>
                        </div>
                        {/* End Agree Checkbox */}
                      </div>
                      <button
                        type="button"
                        className="btn btn-sm btn-primary btn-success btn-submit"
                        onClick={() => this.onSubmit()}
                        disabled={!isAgreed}
                      >
                        Submit
                      </button>
                    </div>
                    {/* End Body */}

                    <div className="widget-footer">
                      <div className="signature">
                        Powered By{" "}
                        <a
                          href="https://www.entribe.com"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={EntribeLogo} alt="logo" />
                        </a>
                      </div>
                    </div>
                    {/* End Footer */}
                  </div>
                )}

                {formState === 3 && (
                  <div className="widget-block widget-thankyou">
                    <div className="widget-header">
                      <div className="logo" style={logoStyles}></div>
                    </div>
                    <div className="widget-body entribe-body-thankyou">
                      <div className="body-container">
                        <h2>
                          Your submission is complete. Thank you for sharing
                          with us!
                        </h2>
                        <h2 className="space">
                          Thanks for being a member of the growing #entribe
                          community.
                        </h2>
                        <p>Follow us on social to see more from #entribe:</p>

                        <ul className="list-unstyled">
                          <li>
                            <div className="social-logo">
                              <img src={facebookLogo} alt="Facebook logo" />
                            </div>
                            <a
                              href="https://www.facebook.com/EnTribeCRM/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              https://www.facebook.com/EnTribeCRM/
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="widget-footer">
                      <div className="signature">
                        Powered By{" "}
                        <a
                          href="https://www.entribe.com"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={EntribeLogo} alt="logo" />
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* End Form */}

              <div className="col-lg-5 d-none d-lg-block d-xl-block gallery">
                {this.renderGallery(chunkImages)}
              </div>
              {/* End Gallery */}
            </div>
            <div className="mobile-gallery-container">
              <div className="gallery">
                {this.renderGallery(chunkImages, true)}
              </div>
            </div>
          </div>
          {/* End Upload Form */}
        </div>
      </Preload>
    );
  }
}

export default withRouter(EnTribe);
