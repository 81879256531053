import * as React from "react";

// Default context values
const values = {
  widgetInfo: null,
  videoList: null,
  setVideoList: () => {},
  selectedVideoList: [],
  setSelectedVideoList: () => {},
  tikTokUser: null,
  setTikTokUser: () => {},
  activeUploaderType: null,
  setActiveUploaderType: () => {},
  handleLoadMoreTikTok: () => {},
  isCancelUpload: false,
  setIsCancelUpload: () => {},
  resetDataInStore: () => {},
  setUploadPayloadList: () => {},
  uploadInfo: {},
  setUploadInfo: () => {},
  isMobile: false,
  formState: 1,
  className: "",
  googleDrive: undefined,
  setGoogleDrive: () => {},
  modalType: "",
  setModalType: () => {},
  setIsShowLoading: () => {},
  errorMsg: "",
  setErrorMsg: () => {},
  handleErrorMsg: () => {},
  terms: [],
  setTerms: () => {},
  socialSource: {
    isInstagram: false,
    isTikTok: false,
  },
  authorizeWindow: null,
  setAuthorizeWindow: () => {},
};

const WidgetBuilderContext = React.createContext(values);
export default WidgetBuilderContext;
