import CustomizedSelect from "components/shared/CustomizedSelect";
import { MAILING_ADDRESS_FIELD_NAME } from "constants/common";
import { dataState } from "constants/dataStateUSA";
import WidgetBuilderContext from "contexts/WidgetBuilder";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  StyledFormItemCustomWidget as StyledFormItem,
  StyledInputItem,
} from "styled-components/WidgetBuilder/WidgetBuilderStyled";

const MailingAddress = (props) => {
  const { mailingAddress = {} } = props || {};
  const { t } = useTranslation();
  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([...dataState]);

  const { uploadInfo, isMobile } = useContext(WidgetBuilderContext);

  useEffect(() => {
    setStateList(renderOptions(dataState, MAILING_ADDRESS_FIELD_NAME.STATE));
  }, []);

  useEffect(() => {
    const state = uploadInfo[MAILING_ADDRESS_FIELD_NAME.STATE];
    const dataTemp = dataState.find((item) => item.state === state);
    setCityList(dataTemp?.city || []);
  }, [uploadInfo[MAILING_ADDRESS_FIELD_NAME.STATE]]);

  const renderOptions = (options, fieldName) => {
    if (fieldName === MAILING_ADDRESS_FIELD_NAME.STATE) {
      return options.map((item) => {
        return {
          value: item.state,
          label: item.state,
        };
      });
    }

    return options.map((item) => {
      return {
        value: item,
        label: item,
      };
    });
  };

  const setRules = (fieldName) => {
    const rules = [];
    rules.push({
      required: mailingAddress.required,
      message: t("validation.required", {
        field: fieldName,
      }),
    });
    return rules;
  };

  return (
    <>
      <StyledFormItem
        name={MAILING_ADDRESS_FIELD_NAME.STREET}
        rules={setRules(t("widget_builder.body.mailing_address.street"))}
      >
        <StyledInputItem
          isMobile={isMobile}
          placeholder={`${mailingAddress[MAILING_ADDRESS_FIELD_NAME.STREET]}${
            mailingAddress.required ? "*" : ""
          }`}
        />
      </StyledFormItem>

      <StyledFormItem
        name={MAILING_ADDRESS_FIELD_NAME.STATE}
        rules={setRules(t("widget_builder.body.mailing_address.state"))}
      >
        <CustomizedSelect
          options={stateList}
          isMobile={isMobile}
          placeholder={`${mailingAddress[MAILING_ADDRESS_FIELD_NAME.STATE]}${
            mailingAddress.required ? "*" : ""
          }`}
        />
      </StyledFormItem>

      <StyledFormItem
        name={MAILING_ADDRESS_FIELD_NAME.CITY}
        rules={setRules(t("widget_builder.body.mailing_address.city"))}
      >
        <CustomizedSelect
          options={renderOptions(cityList)}
          isMobile={isMobile}
          placeholder={`${mailingAddress[MAILING_ADDRESS_FIELD_NAME.CITY]}${
            mailingAddress.required ? "*" : ""
          }`}
        />
      </StyledFormItem>

      <StyledFormItem
        name={MAILING_ADDRESS_FIELD_NAME.ZIP_CODE}
        rules={setRules(t("widget_builder.body.mailing_address.zip_code"))}
      >
        <StyledInputItem
          isMobile={isMobile}
          placeholder={`${mailingAddress[MAILING_ADDRESS_FIELD_NAME.ZIP_CODE]}${
            mailingAddress.required ? "*" : ""
          }`}
        />
      </StyledFormItem>
    </>
  );
};

export default MailingAddress;
