import { GEMINI_FIELD_NAME } from "constants/Custom/gemini";
import { FIELD_NAME, TYPE_CUSTOM_FIELD } from "constants/common";
import { useTranslation } from "react-i18next";
import { setFieldRules } from "services/widgetBuilder";
import { StyledGeminiFormItem } from "styled-components/CustomWidget/GeminiStyled";
import {
  StyledInputItem,
  StyledTextAreaItem,
} from "styled-components/WidgetBuilder/WidgetBuilderStyled";

const GeminiForm = () => {
  const { t } = useTranslation();

  const fieldList = [
    {
      label: t("form_label.email_address"),
      errorName: t("form_label.email_address"),
      name: FIELD_NAME.email,
      required: true,
      type: "email",
    },
    {
      label: t("form_label.first_name"),
      errorName: t("form_label.first_name"),
      name: FIELD_NAME.firstName,
      required: true,
    },
    {
      label: t("form_label.last_name"),
      errorName: t("form_label.last_name"),
      name: FIELD_NAME.lastName,
      required: true,
    },
    {
      label: `@${t("form_label.instagram")}`,
      name: FIELD_NAME.instagram,
    },
    {
      label: `@${t("form_label.tiktok")}`,
      name: FIELD_NAME.tiktok,
    },
    {
      label: `@X`,
      name: FIELD_NAME.twitter,
    },
    {
      label: t("gemini.form.share_story"),
      name: GEMINI_FIELD_NAME.DESCRIPTION,
      type: TYPE_CUSTOM_FIELD.paragraph,
      required: true,
      errorName: t("gemini.form.share_story"),
    },
  ];

  return (
    <>
      {fieldList.map((field) => {
        if (field.type === TYPE_CUSTOM_FIELD.paragraph) {
          return (
            <StyledGeminiFormItem
              key={field.name}
              name={field.name}
              className={TYPE_CUSTOM_FIELD.paragraph}
              rules={setFieldRules(field, t)}
            >
              <StyledTextAreaItem
                alt={`${t(
                  "widget_builder.placeholder.short_answer_form_field"
                )}: ${field.name}`}
                placeholder={`${field.label}${field.required ? "*" : ""}`}
                aria-label={`${field.label}`}
              />
            </StyledGeminiFormItem>
          );
        }

        return (
          <StyledGeminiFormItem
            name={field.name}
            key={field.name}
            rules={setFieldRules(field, t)}
          >
            <StyledInputItem
              placeholder={`${field.label}${field.required ? "*" : ""}`}
            />
          </StyledGeminiFormItem>
        );
      })}
    </>
  );
};

export default GeminiForm;
