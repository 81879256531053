const errorFieldPrefix = "(!)";

export default {
  en: {
    callAPIFailed:
      "Sorry, our service is under maintenance. Please try in a couple of minutes.",
    requiredEmail: `${errorFieldPrefix} Please enter email.`,
    requiredFirstName: `${errorFieldPrefix} Please enter first name.`,
    requiredLastname: `${errorFieldPrefix} Please enter last name.`,
    requiredInstagram: `${errorFieldPrefix} Please enter instagram user name.`,
    invalidEmail: `${errorFieldPrefix} Email address is invalid.`,
    requiredSelect: `${errorFieldPrefix} Please select at least a single option.`,
    requiredFile: "Please select file(s) to upload.",
    multipleVideos: `We don't allow multiple videos to be uploaded at the same time.`,
    invalidFilesize:
      "The selected image is too big. Please choose one with maximum dimensions of 16000 x 16000 up to 150MB in size.",
    maxFiles: "Please select 20 items or less.",
    maxPhotoPerUpload:
      "Max number of photos per upload is {{maxPhotoPerUpload}}.",
    maxVideoPerUpload:
      "Max number of videos per upload is {{maxVideoPerUpload}}.",
    invalidFileType:
      'Unsupported file type. See supported file types here <a href="https://www.entribe.com/uploader-support" target="_blank" title="https://www.entribe.com/uploader-support">Uploader Support and FAQ</a>',
    uploadFailed: "Could not upload content.",
    unSupportedFileTypeBegin:
      "<p>We were unable to process the files you attached.</p>",
    unSupportedFileTypeEnd:
      '<ul><li>Make sure you are uploading support file types and sizes. (See <a href="https://www.entribe.com/uploader-support" title="https://www.entribe.com/uploader-support">Uploader Support and FAQ</a> for details)</li><li>Check your internet connection is stable.</li><li>Try refreshing your browser</li></ul>Error Code: Not Available</p>',
  },
  es: {
    callAPIFailed:
      "Sorry, our service is under maintenance. Please try in a couple of minutes.",
    requiredEmail: `${errorFieldPrefix} Please enter email.`,
    requiredFirstName: `${errorFieldPrefix} Please enter first name.`,
    requiredLastname: `${errorFieldPrefix} Please enter last name.`,
    requiredInstagram: `${errorFieldPrefix} Please enter instagram user name.`,
    invalidEmail: `${errorFieldPrefix} Email address is invalid.`,
    requiredSelect: `${errorFieldPrefix} Please select at least a single option.`,
    requiredFile: "Please select file(s) to upload.",
    multipleVideos: `We don't allow multiple videos to be uploaded at the same time.`,
    maxFiles: "Please select 20 items or less.",
    invalidFileType: "Suba un vídeo o una imagen por favor.",
    invalidFilesize:
      "The selected image is too big. Please choose one with maximum dimensions of 16000 x 16000 up to 150MB in size.",
    uploadFailed: "Could not upload content.",
    unSupportedFileTypeBegin:
      "<p>We were unable to process the files you attached.</p>",
    unSupportedFileTypeEnd:
      '<ul><li>Make sure you are uploading support file types and sizes. (See <a href="https://www.entribe.com/uploader-support" title="https://www.entribe.com/uploader-support">www.entribe.com/uploader-support</a> for details)</li><li>Check your internet connection is stable.</li><li>Try refreshing your browser</li></ul>Error Code: Not Available</p>',
  },
  pt: {
    callAPIFailed:
      "Sorry, our service is under maintenance. Please try in a couple of minutes.",
    requiredEmail: `${errorFieldPrefix} Please enter email.`,
    requiredFirstName: `${errorFieldPrefix} Please enter first name.`,
    requiredLastname: `${errorFieldPrefix} Please enter last name.`,
    requiredInstagram: `${errorFieldPrefix} Please enter instagram user name.`,
    invalidEmail: `${errorFieldPrefix} Email address is invalid.`,
    requiredSelect: `${errorFieldPrefix} Please select at least a single option.`,
    requiredFile: "Please select file(s) to upload.",
    multipleVideos: `We don't allow multiple videos to be uploaded at the same time.`,
    maxFiles: "Please select 20 items or less.",
    invalidFileType:
      'Unsupported file type. See supported file types here <a href="https://www.entribe.com/uploader-support" target="_blank" title="https://www.entribe.com/uploader-support">Uploader Support and FAQ</a>',
    invalidFilesize:
      "The selected image is too big. Please choose one with maximum dimensions of 16000 x 16000 up to 150MB in size.",
    uploadFailed: "Could not upload content.",
    unSupportedFileTypeBegin:
      "<p>We were unable to process the files you attached.</p>",
    unSupportedFileTypeEnd:
      '<ul><li>Make sure you are uploading support file types and sizes. (See <a href="https://www.entribe.com/uploader-support" title="https://www.entribe.com/uploader-support">Uploader Support and FAQ</a> for details)</li><li>Check your internet connection is stable.</li><li>Try refreshing your browser</li></ul>Error Code: Not Available</p>',
  },
};
