import { Button, Modal, Row, Tooltip } from "antd";
import styled, { css } from "styled-components";
import THEME from "../../constants/themes";
import { StyledText } from "./WidgetBuilderStyled";
import { PREFIX_CLS } from "constants/common";

const StyledModal = styled(Modal)`
  .${PREFIX_CLS}-modal-header {
    border-radius: 6px 6px 0 0;

    .${PREFIX_CLS}-modal-title {
      font-weight: 600 !important;
    }
  }

  .${PREFIX_CLS}-modal-body {
    padding: ${({ padding }) => padding || "16px 20px"};
  }

  .${PREFIX_CLS}-modal-footer {
    ${({ border }) => `border: ${border} !important`};
  }

  .${PREFIX_CLS}-modal-content {
    border-radius: 6px;

    .${PREFIX_CLS}-modal-close {
      outline: none;
    }
  }
`;

const StyledUploadProgressItem = styled(Row)`
  padding: ${({ padding }) => padding || "6px 0"};
  font-weight: ${({ fontWeight }) => fontWeight || 600};
  ${({ width }) => `width: ${width}`};

  .customized-progress-bar {
    display: flex;
    align-items: center;
  }
`;

const StyledProgressBar = styled.div`
  width: 100%;
  height: 10px;
  background-color: ${THEME.colors.gray5};
`;

const StyledProgressBarInner = styled.div`
  width: ${({ width }) => `${width}%`};
  max-width: 100%;
  height: 10px;
  background-color: ${({ color }) => color};
  transition: width 1s;
`;

const StyledFileName = styled.div`
  width: 75%;

  ${StyledText} {
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledButton = styled(Button)`
  ${({ margin }) => `margin: ${margin}`};
  animation: none;
  transition: none;

  &,
  &:hover {
    border-radius: 16px;
    background: ${({ bgColor }) =>
      bgColor ? `${bgColor} !important` : "unset"};
    color: ${({ bgColor }) => (bgColor ? THEME.colors.white : "unset")};
    font-weight: 600;
    border-color: ${({ bgColor }) =>
      bgColor ? `${bgColor} !important` : "#d9e4ef !important"};
  }

  &:focus-visible {
    outline: ${({ bgColor }) =>
      bgColor
        ? `2px solid ${bgColor} !important`
        : "2px solid #d9e4ef !important"};
  }
`;

const StyledTooltip = styled(Tooltip)``;

const StyledCancelUploadOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 9999;
`;

const StyledCancelUpload = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 8px 16px;
  width: 320px;
  border-radius: 6px;
  background-color: #fff;
  z-index: 9999;

  &:focus-visible {
    outline: none;
  }
`;

export {
  StyledModal,
  StyledUploadProgressItem,
  StyledProgressBar,
  StyledProgressBarInner,
  StyledFileName,
  StyledButton,
  StyledTooltip,
  StyledCancelUpload,
  StyledCancelUploadOverlay,
};
