import { put, takeLatest } from "redux-saga/effects";
import {
  GOOGLE_OAUTH_TOKEN_API,
  GOOGLE_YOUTUBE_API,
} from "../../constants/APIs";
import { genericApiSaga, getRequest } from "../../services/api";
import * as actions from "../actions";
import {
  getYoutubeChannelIdTypes,
  getYoutubeVideoListTypes,
  refreshOAuthGoogleTokenTypes,
} from "./google.types";
import utils from "services/utils";
import { MEDIA_TYPE } from "constants/common";
import { YOUTUBE_UPLOADING } from "constants/widgetBuilder";

export function* refreshOAuthGoogleToken({ payload }) {
  yield genericApiSaga({
    gatewayCall: () =>
      getRequest(
        `${GOOGLE_OAUTH_TOKEN_API}/tokeninfo/access_token=${payload.uploaderLink}`
      ),
    *completed(response) {
      yield put(actions.refreshOAuthGoogleTokenSucceeded(response));
    },
    *failed(response) {
      yield put(actions.refreshOAuthGoogleTokenFailed(response));
    },
  });
}

export function* getYoutubeVideoList({ payload }) {
  const { googleAccessToken, ...others } = payload;
  let searchParams = {
    ...others,
    type: MEDIA_TYPE.VIDEO,
    part: YOUTUBE_UPLOADING.PART,
    maxResults: YOUTUBE_UPLOADING.MAX_RESULTS,
  };
  delete searchParams.youTubeChannelId;
  searchParams = utils.convertSearchParams(searchParams);
  yield genericApiSaga({
    gatewayCall: () =>
      getRequest(
        `${GOOGLE_YOUTUBE_API}/playlistItems?${searchParams}`,
        {},
        undefined,
        undefined,
        googleAccessToken,
        true
      ),
    *completed(response) {
      yield put(actions.getYoutubeVideoListSucceeded(response));
    },
    *failed(response) {
      yield put(actions.getYoutubeVideoListFailed(response));
    },
  });
}

export function* watcherGoogleSaga() {
  yield takeLatest(
    refreshOAuthGoogleTokenTypes.REFRESH_OAUTH_GOOGLE_TOKEN_REQUEST,
    refreshOAuthGoogleToken
  );
  yield takeLatest(
    getYoutubeVideoListTypes.GET_YOUTUBE_VIDEO_LIST_REQUEST,
    getYoutubeVideoList
  );
}
