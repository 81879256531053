import styled from "styled-components";
import THEME from "../../constants/themes";
import { StyledModal } from "../Common/CommonStyled";
import { PREFIX_CLS } from "constants/common";

const StyledLimitTermsModal = styled(StyledModal)`
  .${PREFIX_CLS}-modal-header {
    .${PREFIX_CLS}-modal-title {
      line-height: 22px;
      font-size: ${({ isMobile }) => (isMobile ? "16px" : "20px")};
      color: ${THEME.colors.black};
      font-weight: 500 !important;
    }
  }
`;

const StyledLimitTerms = styled.div`
  font-size: ${({ isMobile }) => (isMobile ? "14px" : "16px")};
  color: ${THEME.colors.black};

  ul {
    padding-left: 20px;

    li {
      &::marker {
        font-size: 12px;
      }
    }
  }
`;

export { StyledLimitTermsModal, StyledLimitTerms };
