import { PREFIX_CLS } from "constants/common";
import THEME from "constants/themes";
import styled, { css } from "styled-components";
import {
  StyledButton,
  StyledText,
} from "styled-components/Common/CommonStyled";
import {
  StyledFormItem,
  StyledUpload,
} from "styled-components/WidgetBuilder/WidgetBuilderStyled";

export const StyledHeaderLogoWrapper = styled.div`
  text-align: center;
  min-height: 110px;
`;

export const StyledHeaderLogo = styled.img`
  max-width: 376px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      max-width: 100%;
    `}
`;

export const StyledGeminiTitle = styled.p`
  font-size: 24px;
  font-weight: 500;
  width: calc(100% + 32px);
  margin: 0 0 16px -16px;
  color: #131314;
  line-height: 1.25;

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin: 0 0 16px -16px;
      font-size: 22px;
    `}
`;

export const StyledGeminiSubTitle = styled.p`
  font-size: 16px;
  font-weight: 400;
  margin: -4px 0 24px 0;
  line-height: 1.25;
`;

export const StyledSubmitButtonWrapper = styled.div`
  position: relative;
  width: calc(100% + 36px);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 -18px;
  padding: 18px;
  border-radius: 50px;
  box-shadow: inset 0 0 8px 16px white, inset 0 0 3px 2px white;
  background: linear-gradient(
    90deg,
    #217bfe 0%,
    #078efb 34.5%,
    #ac87eb 72%,
    #ee4d5d 100%
  );
`;

export const StyledGeminiSubmitButton = styled(StyledButton)`
  position: relative;
  height: 46px !important;
  width: ${({ width }) => width || "100%"};
  border-radius: 34px;
  height: ${({ isMobile }) => (isMobile ? "40px" : "52px")};
  background-color: ${THEME.colors.white};
  color: ${THEME.colors.black};
  font-size: 24px;
  font-weight: 500;
  padding: 0;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 50px;
    padding: 2px;
    background: linear-gradient(
      90deg,
      #217bfe 0%,
      #078efb 34.5%,
      #ac87eb 72%,
      #ee4d5d 100%
    );
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  &:hover {
    color: ${THEME.colors.black} !important;
  }

  &#cancel-upload-btn {
    height: unset !important;
    font-weight: 600;
    font-size: 14px;
    padding: 4px 15px;
    width: unset !important;
  }
`;

export const StyledGeminiUpload = styled(StyledUpload)`
  background-color: unset;
  border: none;
`;

export const StyledGeminiFormItem = styled(StyledFormItem)`
  .${PREFIX_CLS}-form-item-explain-error {
    font-size: 12px;
    padding: 0 16px;
  }

  textarea {
    min-height: 125px;
    max-height: 125px;
    height: 125px;
    resize: none;
  }

  input,
  textarea {
    padding: 16px 17px;
    border: none;
    background: #f0f4f9;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  &.gemini-term {
    padding: 0 0 2px 0;

    .${PREFIX_CLS}-form-item-control-input {
      min-height: fit-content !important;
    }

    .${PREFIX_CLS}-checkbox {
      padding: unset;
    }
  }
`;
