const uploadContentTypes = {
  UPLOAD_CONTENT_REQUEST: "UPLOAD_CONTENT_REQUEST",
  UPLOAD_CONTENT_SUCCEEDED: "UPLOAD_CONTENT_SUCCEEDED",
  UPLOAD_CONTENT_FAILED: "UPLOAD_CONTENT_FAILED",
  UPLOAD_CONTENT_END: "UPLOAD_CONTENT_END",
};

const uploadTikTokContentTypes = {
  UPLOAD_TIKTOK_CONTENT_REQUEST: "UPLOAD_TIKTOK_CONTENT_REQUEST",
  UPLOAD_TIKTOK_CONTENT_SUCCEEDED: "UPLOAD_TIKTOK_CONTENT_SUCCEEDED",
  UPLOAD_TIKTOK_CONTENT_FAILED: "UPLOAD_TIKTOK_CONTENT_FAILED",
  UPLOAD_TIKTOK_CONTENT_END: "UPLOAD_TIKTOK_CONTENT_END",
};

const uploadGoogleDriveContentTypes = {
  UPLOAD_GOOGLE_DRIVE_CONTENT_REQUEST: "UPLOAD_GOOGLE_DRIVE_CONTENT_REQUEST",
  UPLOAD_GOOGLE_DRIVE_CONTENT_SUCCEEDED:
    "UPLOAD_GOOGLE_DRIVE_CONTENT_SUCCEEDED",
  UPLOAD_GOOGLE_DRIVE_CONTENT_FAILED: "UPLOAD_GOOGLE_DRIVE_CONTENT_FAILED",
  UPLOAD_GOOGLE_DRIVE_CONTENT_END: "UPLOAD_GOOGLE_DRIVE_CONTENT_END",
};

const setUploadProgressTypes = {
  SET_UPLOAD_PROGRESS: "SET_UPLOAD_PROGRESS",
};

const cancelTypes = {
  CANCEL_REQUEST: "CANCEL_REQUEST",
};

const createContentTypes = {
  CREATE_CONTENT_REQUEST: "CREATE_CONTENT_REQUEST",
  CREATE_CONTENT_SUCCEEDED: "CREATE_CONTENT_SUCCEEDED",
  CREATE_CONTENT_FAILED: "CREATE_CONTENT_FAILED",
  CREATE_CONTENT_END: "CREATE_CONTENT_END",
};

const sendEmailTypes = {
  SEND_EMAIL_REQUEST: "SEND_EMAIL_REQUEST",
  SEND_EMAIL_SUCCEEDED: "SEND_EMAIL_SUCCEEDED",
  SEND_EMAIL_FAILED: "SEND_EMAIL_FAILED",
  SEND_EMAIL_END: "SEND_EMAIL_END",
};

const sendSlackTypes = {
  SEND_SLACK_REQUEST: "SEND_SLACK_REQUEST",
  SEND_SLACK_SUCCEEDED: "SEND_SLACK_SUCCEEDED",
  SEND_SLACK_FAILED: "SEND_SLACK_FAILED",
  SEND_SLACK_END: "SEND_SLACK_END",
};

// Send slack when uploading successfully
const sendSlackSuccessfulUploadTypes = {
  SEND_SLACK_SUCCESSFUL_UPLOAD_REQUEST: "SEND_SLACK_SUCCESSFUL_UPLOAD_REQUEST",
  SEND_SLACK_SUCCESSFUL_UPLOAD_SUCCEEDED:
    "SEND_SLACK_SUCCESSFUL_UPLOAD_SUCCEEDED",
  SEND_SLACK_SUCCESSFUL_UPLOAD_FAILED: "SEND_SLACK_SUCCESSFUL_UPLOAD_FAILED",
  SEND_SLACK_SUCCESSFUL_UPLOAD_END: "SEND_SLACK_SUCCESSFUL_UPLOAD_END",
};

const getClientSettingTypes = {
  GET_CLIENT_SETTING_REQUEST: "GET_CLIENT_SETTING_REQUEST",
  GET_CLIENT_SETTING_SUCCEEDED: "GET_CLIENT_SETTING_SUCCEEDED",
  GET_CLIENT_SETTING_FAILED: "GET_CLIENT_SETTING_FAILED",
  GET_CLIENT_SETTING_END: "GET_CLIENT_SETTING_END",
};

const getClientDetailTypes = {
  GET_CLIENT_DETAIL_REQUEST: "GET_CLIENT_DETAIL_REQUEST",
  GET_CLIENT_DETAIL_SUCCEEDED: "GET_CLIENT_DETAIL_SUCCEEDED",
  GET_CLIENT_DETAIL_FAILED: "GET_CLIENT_DETAIL_FAILED",
  GET_CLIENT_DETAIL_END: "GET_CLIENT_DETAIL_END",
};

const getLocationTypes = {
  GET_LOCATION_REQUEST: "GET_LOCATION_REQUEST",
  GET_LOCATION_SUCCEEDED: "GET_LOCATION_SUCCEEDED",
  GET_LOCATION_FAILED: "GET_LOCATION_FAILED",
  GET_LOCATION_END: "GET_LOCATION_END",
};

const validateCampaignTypes = {
  VALIDATE_CAMPAIGN_REQUEST: "VALIDATE_CAMPAIGN_REQUEST",
  VALIDATE_CAMPAIGN_SUCCEEDED: "VALIDATE_CAMPAIGN_SUCCEEDED",
  VALIDATE_CAMPAIGN_FAILED: "VALIDATE_CAMPAIGN_FAILED",
  VALIDATE_CAMPAIGN_END: "VALIDATE_CAMPAIGN_END",
};

const capturateContentTypes = {
  CAPTURATE_CONTENT_REQUEST: "CAPTURATE_CONTENT_REQUEST",
  CAPTURATE_CONTENT_SUCCEEDED: "CAPTURATE_CONTENT_SUCCEEDED",
  CAPTURATE_CONTENT_FAILED: "CAPTURATE_CONTENT_FAILED",
  CAPTURATE_CONTENT_END: "CAPTURATE_CONTENT_END",
};

const getGeolocationTypes = {
  GET_GEOLOCATION_REQUEST: "GET_GEOLOCATION_REQUEST",
  GET_GEOLOCATION_SUCCEEDED: "GET_GEOLOCATION_SUCCEEDED",
  GET_GEOLOCATION_FAILED: "GET_GEOLOCATION_FAILED",
  GET_GEOLOCATION_END: "GET_GEOLOCATION_END",
};

const uploadYouTubeContentTypes = {
  UPLOAD_YOUTUBE_CONTENT_REQUEST: "UPLOAD_YOUTUBE_CONTENT_REQUEST",
  UPLOAD_YOUTUBE_CONTENT_SUCCEEDED: "UPLOAD_YOUTUBE_CONTENT_SUCCEEDED",
  UPLOAD_YOUTUBE_CONTENT_FAILED: "UPLOAD_YOUTUBE_CONTENT_FAILED",
  UPLOAD_YOUTUBE_CONTENT_END: "UPLOAD_YOUTUBE_CONTENT_END",
};

const uploadInstagramContentTypes = {
  UPLOAD_INSTAGRAM_CONTENT_REQUEST: "UPLOAD_INSTAGRAM_CONTENT_REQUEST",
  UPLOAD_INSTAGRAM_CONTENT_SUCCEEDED: "UPLOAD_INSTAGRAM_CONTENT_SUCCEEDED",
  UPLOAD_INSTAGRAM_CONTENT_FAILED: "UPLOAD_INSTAGRAM_CONTENT_FAILED",
  UPLOAD_INSTAGRAM_CONTENT_END: "UPLOAD_INSTAGRAM_CONTENT_END",
};

const getCreatorTypes = {
  GET_CREATOR_REQUEST: "GET_CREATOR_REQUEST",
  GET_CREATOR_SUCCEEDED: "GET_CREATOR_SUCCEEDED",
  GET_CREATOR_FAILED: "GET_CREATOR_FAILED",
  GET_CREATOR_END: "GET_CREATOR_END",
};

const getTermsTypes = {
  GET_TERMS_REQUEST: "GET_TERMS_REQUEST",
  GET_TERMS_SUCCEEDED: "GET_TERMS_SUCCEEDED",
  GET_TERMS_FAILED: "GET_TERMS_FAILED",
  GET_TERMS_END: "GET_TERMS_END",
};

const getInstagramAccessTokenTypes = {
  GET_INSTAGRAM_ACCESS_TOKEN_REQUEST: "GET_INSTAGRAM_ACCESS_TOKEN_REQUEST",
  GET_INSTAGRAM_ACCESS_TOKEN_SUCCEEDED: "GET_INSTAGRAM_ACCESS_TOKEN_SUCCEEDED",
  GET_INSTAGRAM_ACCESS_TOKEN_FAILED: "GET_INSTAGRAM_ACCESS_TOKEN_FAILED",
  GET_INSTAGRAM_ACCESS_TOKEN_END: "GET_INSTAGRAM_ACCESS_TOKEN_END",
};

const getInstagramMediaTypes = {
  GET_INSTAGRAM_MEDIA_REQUEST: "GET_INSTAGRAM_MEDIA_REQUEST",
  GET_INSTAGRAM_MEDIA_SUCCEEDED: "GET_INSTAGRAM_MEDIA_SUCCEEDED",
  GET_INSTAGRAM_MEDIA_FAILED: "GET_INSTAGRAM_MEDIA_FAILED",
  GET_INSTAGRAM_MEDIA_END: "GET_INSTAGRAM_MEDIA_END",
};

const storeDataTypes = {
  STORE_DATA: "STORE_DATA",
};

export {
  uploadContentTypes,
  setUploadProgressTypes,
  cancelTypes,
  createContentTypes,
  sendEmailTypes,
  storeDataTypes,
  uploadTikTokContentTypes,
  sendSlackTypes,
  sendSlackSuccessfulUploadTypes,
  getClientSettingTypes,
  getClientDetailTypes,
  getLocationTypes,
  validateCampaignTypes,
  uploadGoogleDriveContentTypes,
  capturateContentTypes,
  getGeolocationTypes,
  uploadYouTubeContentTypes,
  getCreatorTypes,
  getTermsTypes,
  getInstagramAccessTokenTypes,
  getInstagramMediaTypes,
  uploadInstagramContentTypes,
};
