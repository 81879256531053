import THEME from "constants/themes";
// import useFontFaceObserver from "use-font-face-observer";
import CancelUpload from "components/widgets/UploadProgress/CancelUpload";
import UploadProgress from "components/widgets/UploadProgress/UploadProgress";
import UploadLimitTerms from "components/widgets/WidgetBuilder/UploadLimitTerms";
import UploaderConfirmation from "components/widgets/WidgetBuilder/UploaderConfirmation";
import WidgetFooter from "components/widgets/WidgetBuilder/WidgetFooter";
import { geminiWidgetId } from "config";
import {
  DURATION,
  FAILED,
  IN_CAPTURATE,
  IN_PROGRESS,
  LIMITATION_ERROR_TYPE,
  MAX_PERCENT,
  NONE,
  SUCCEEDED,
} from "constants/common";
import { DEFAULT_WIDGET_SETTINGS, FONT_FAMILY } from "constants/widgetBuilder";
import GeminiContext from "contexts/Gemini";
import FontFaceObserver from "fontfaceobserver";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import utils from "services/utils";
import { convertUploadPayload } from "services/widgetBuilder";
import {
  cancelRequest,
  getTermsRequest,
  setUploadProgress,
  storeData,
  uploadContentRequest,
} from "stores/actions";
import { StyledWrapper } from "styled-components/Common/CommonStyled";
import {
  StyledDetailWidget,
  StyledWrapperDetailWidget,
} from "styled-components/WidgetBuilder/WidgetBuilderStyled";
import geminiLogo from "../../../../assets/img/gemini/gemini-logo.gif";
import GeminiBody from "./GeminiBody";
import GeminiHeader from "./GeminiHeader";

const Gemini = (props) => {
  const { globalProps } = props;
  const { setIsShowLoading } = globalProps;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const pathLocation = useLocation();
  const { search } = pathLocation;
  const campaignId = utils.getChallengeId(search);
  const widgetInfo = {
    id: geminiWidgetId,
    clientId: sessionStorage.getItem("clientId"),
    widgetSettingsDTO: {
      ...DEFAULT_WIDGET_SETTINGS,
    },
  };
  const isMobile = utils.useDevice();
  const {
    widgetSettingsDTO: {
      maxFileSize,
      maxVideoPerUpload,
      maxPhotoPerUpload,
    } = {},
  } = widgetInfo || {};

  const {
    fileProgress,
    isCancelledProgressModal = false,
    createContentStatus,
    createdContent,
    uploadedContentList,
  } = useSelector((state) => state.widgets);

  const [uploadPayload, setUploadPayload] = useState();
  const [isCancelUpload, setIsCancelUpload] = useState(false);
  const [uploadContentPayloadList, setUploadContentPayloadList] = useState([]);
  const [formState, setFormState] = useState(1); // 1 Upload Page, 2 Thank You Page
  const [uploadResults, setUploadResults] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [isNoMediaProvided, setIsNoMediaProvided] = useState(false);
  const [isLoadedFont, setIsLoadedFont] = useState(false);
  const [isUploadLimitTerms, setIsUploadLimitTerms] = useState(false);

  useEffect(() => {
    loadFonts();
    dispatch(
      getTermsRequest({
        termsTemplateId: 2,
      })
    );
  }, []);

  useEffect(() => {
    if (createContentStatus === SUCCEEDED && createdContent) {
      setIsShowLoading(false);
      uploadContentAPI(createdContent);
    }
  }, [createContentStatus]);

  const loadFonts = () => {
    const observers = [];
    const timeoutGivingUpLoading = 12000;
    const fonts = [
      {
        fontFamily: FONT_FAMILY.GOOGLE_SANS_TEXT,
        data: {
          weight: 500,
        },
      },
      {
        fontFamily: FONT_FAMILY.GOOGLE_SANS_TEXT,
        data: {
          weight: 400,
        },
      },
    ];
    fonts.forEach((item) => {
      const obs = new FontFaceObserver(item.fontFamily, item.data);
      observers.push(obs.load(null, timeoutGivingUpLoading));
    });
    Promise.all(observers).finally(() => {
      setIsLoadedFont(true);
    });
  };

  const isInProgress = useMemo(() => {
    return fileProgress.some(
      (item) => item.status === IN_PROGRESS || item.status === IN_CAPTURATE
    );
  }, [fileProgress, isCancelledProgressModal]);

  const isInError = useMemo(() => {
    return fileProgress.every((item) => !_.isEmpty(item?.limitationError));
  }, [fileProgress, isCancelledProgressModal]);

  const isCompleteUpload = useMemo(() => {
    const fileInSucceed = fileProgress.filter(
      (item) => item.status === SUCCEEDED
    );

    return (
      !!fileProgress.length &&
      !!uploadedContentList.length &&
      !isInProgress &&
      uploadedContentList.length === fileInSucceed.length
    );
  }, [isInProgress, fileProgress, uploadedContentList]);

  useEffect(() => {
    if (isCompleteUpload) {
      // setTimeout is used to user checking error
      setTimeout(
        () => {
          if (uploadedContentList.length && !isCancelledProgressModal) {
            resetDataInStore();
            handleFormState(uploadedContentList);
          }
        },
        fileProgress.some((item) => item.limitationError)
          ? DURATION.S_3
          : DURATION.S_0
      );
    }
  }, [isCompleteUpload, isCancelledProgressModal]);

  const onCancelUpload = () => {
    if (isInProgress && !isInError) {
      setIsCancelUpload(true);
    } else {
      resetDataInStore();
    }
  };

  const handleFormState = (result) => {
    setUploadResults(result);
    setFormState(2);
    dispatch(
      storeData({
        isCancelledProgressModal: true,
      })
    );
  };

  const resetDataInStore = () => {
    const resetValues = {
      createdContent: null,
      createContentStatus: NONE,
      isCancelledProgressModal: true,
      uploadSocialContentResult: [],
    };

    dispatch(storeData(resetValues));
  };

  const handleError = async (file) => {
    return await utils.validateFileLimitation({
      file: file,
      widgetInfo,
      t,
      undefined,
      videoLengthErrorMsg: t("message.max_video_length_to_unit", {
        maxVideoLength: 10,
        unit: t("time.minutes"),
      }),
    });
  };

  const uploadContentAPI = (createdContent) => {
    const contentList = convertUploadPayload(
      createdContent,
      widgetInfo,
      campaignId,
      uploadContentPayloadList
    );
    if (contentList.length)
      contentList.forEach((content) => {
        const dataObject = utils.convertFormDataToObject(content.formData);
        const errorPromise = handleError(dataObject.file);
        errorPromise.then((result) => {
          if (!_.isEmpty(result)) {
            const { progress = 1, fileProgressId, searchParams } = content;
            const payload = {
              ...dataObject,
              fileProgressId,
              progress,
              remainingTime: 0,
              requestId: searchParams?.requestId,
              limitationError: result,
              status: FAILED,
            };
            dispatch(setUploadProgress(payload));
          } else {
            dispatch(
              uploadContentRequest({
                ...content,
                onUploadProgress,
                widgetInfo,
                isNewFlow: true,
              })
            );
          }
        });
      });
  };

  const onUploadProgress = async (params) => {
    const { progress, data, fileProgressId, requestId } = params;
    const { loaded, total } = progress;
    const percentageProgress = Math.floor((loaded / total) * MAX_PERCENT);
    const dataObject = utils.convertFormDataToObject(data);
    const endTime = new Date().getTime();
    const duration = (endTime - dataObject.startTime) / 1000;
    const bps = loaded / duration;
    const time = (total - loaded) / bps;

    const payload = {
      ...dataObject,
      fileProgressId,
      progress: percentageProgress,
      remainingTime: time,
      requestId,
    };

    dispatch(setUploadProgress(payload));
  };

  const handleUploadMore = () => {
    setFormState(1);
    resetDataInStore();
    setIsNoMediaProvided(false);
  };

  const confirmCancel = () => {
    if (fileProgress.length) {
      fileProgress.forEach((item) => {
        if (item.status === IN_PROGRESS)
          dispatch(
            cancelRequest({
              fileProgressId: item.fileProgressId,
              isNotRemove: true,
              actionType: LIMITATION_ERROR_TYPE.CANCEL,
            })
          );
      });
    }
    resetDataInStore();
    setIsCancelUpload(false);
  };

  const handleErrorMsg = (error) => {
    setErrorMsg(error);

    setTimeout(() => {
      setErrorMsg("");
    }, DURATION.S_10);
  };

  const handleUploadLimitTerms = () => {
    setIsUploadLimitTerms(!isUploadLimitTerms);
  };

  const contextValues = {
    widgetInfo,
    handleErrorMsg,
    errorMsg,
    setUploadPayload,
    setIsNoMediaProvided,
  };

  return (
    <GeminiContext.Provider value={contextValues}>
      {isLoadedFont && (
        <>
          {formState === 1 && (
            <StyledWrapperDetailWidget
              className="gemini-wrapper"
              bgColor={THEME.colors.black}
            >
              <StyledDetailWidget
                isMobile={isMobile}
                className="gemini"
                padding="39px 32px"
              >
                <GeminiHeader />
                <GeminiBody
                  globalProps={globalProps}
                  setUploadContentPayloadList={setUploadContentPayloadList}
                />
                <WidgetFooter
                  fontSize="13.5px"
                  margin="56px 0 0 0"
                  highlight="#BFC8CA"
                  handleUploadLimitTerms={handleUploadLimitTerms}
                  formState={formState}
                />
              </StyledDetailWidget>
            </StyledWrapperDetailWidget>
          )}

          {formState !== 1 && (
            <StyledWrapper>
              <UploaderConfirmation
                className="gemini"
                isMobile={isMobile}
                uploadResults={uploadResults}
                handleUploadMore={handleUploadMore}
                isCustomWidget={true}
                isNoMediaProvided={isNoMediaProvided}
                metaData={{
                  header: {
                    srcLogo: geminiLogo,
                    sizeLogo: { widthLogoDesktop: 376 },
                  },
                  colors: {
                    bgColor: THEME.colors.black,
                  },
                  body: {
                    advancedSettings: {
                      confirmationMessage: t("gemini.confirmation_upload_msg"),
                      confirmationButtonText: t("button.upload_more_files"),
                    },
                  },
                }}
              />
            </StyledWrapper>
          )}

          {!!fileProgress.length && !isCancelledProgressModal && (
            <UploadProgress
              onCancelUpload={onCancelUpload}
              widgetInfo={widgetInfo}
            />
          )}

          {isCancelUpload && (
            <CancelUpload
              isCustomWidget={isCancelUpload}
              visible={true}
              onConfirm={confirmCancel}
              bgSubmit={THEME.colors.orangeBase}
              onCancel={() => {
                setIsCancelUpload(false);
              }}
            />
          )}

          {widgetInfo && isUploadLimitTerms && (
            <UploadLimitTerms
              className="gemini-limit-terms"
              widgetDetail={widgetInfo}
              onClose={handleUploadLimitTerms}
              isMobile={isMobile}
              limitContent={t("widget_builder.custom_upload_limits", {
                maxFileSize,
                maxPhotoPerUpload,
                maxVideoPerUpload,
                maxVideoLength: 10,
              })}
            />
          )}
        </>
      )}
    </GeminiContext.Provider>
  );
};

export default Gemini;
