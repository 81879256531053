import { createAction } from "redux-actions";
import {
  getGoogleTokenInformationTypes,
  getYoutubeChannelIdTypes,
  getYoutubeVideoListTypes,
  refreshOAuthGoogleTokenTypes,
  storeGoogleDataTypes,
} from "./google.types";

// Action function
export const getGoogleTokenInformationRequest = createAction(
  getGoogleTokenInformationTypes.GET_GOOGLE_TOKEN_INFORMATION_REQUEST
);
export const getGoogleTokenInformationSucceeded = createAction(
  getGoogleTokenInformationTypes.GET_GOOGLE_TOKEN_INFORMATION_SUCCEEDED
);
export const getGoogleTokenInformationFailed = createAction(
  getGoogleTokenInformationTypes.GET_GOOGLE_TOKEN_INFORMATION_FAILED
);
export const getGoogleTokenInformationEnd = createAction(
  getGoogleTokenInformationTypes.GET_GOOGLE_TOKEN_INFORMATION_END
);

export const refreshOAuthGoogleTokenRequest = createAction(
  refreshOAuthGoogleTokenTypes.REFRESH_OAUTH_GOOGLE_TOKEN_REQUEST
);
export const refreshOAuthGoogleTokenSucceeded = createAction(
  refreshOAuthGoogleTokenTypes.REFRESH_OAUTH_GOOGLE_TOKEN_SUCCEEDED
);
export const refreshOAuthGoogleTokenFailed = createAction(
  refreshOAuthGoogleTokenTypes.REFRESH_OAUTH_GOOGLE_TOKEN_FAILED
);
export const refreshOAuthGoogleTokenEnd = createAction(
  refreshOAuthGoogleTokenTypes.REFRESH_OAUTH_GOOGLE_TOKEN_END
);

export const getYoutubeChannelIdRequest = createAction(
  getYoutubeChannelIdTypes.GET_YOUTUBE_CHANNEL_ID_REQUEST
);
export const getYoutubeChannelIdSucceeded = createAction(
  getYoutubeChannelIdTypes.GET_YOUTUBE_CHANNEL_ID_SUCCEEDED
);
export const getYoutubeChannelIdFailed = createAction(
  getYoutubeChannelIdTypes.GET_YOUTUBE_CHANNEL_ID_FAILED
);
export const getYoutubeChannelIdEnd = createAction(
  getYoutubeChannelIdTypes.GET_YOUTUBE_CHANNEL_ID_END
);

export const getYoutubeVideoListRequest = createAction(
  getYoutubeVideoListTypes.GET_YOUTUBE_VIDEO_LIST_REQUEST
);
export const getYoutubeVideoListSucceeded = createAction(
  getYoutubeVideoListTypes.GET_YOUTUBE_VIDEO_LIST_SUCCEEDED
);
export const getYoutubeVideoListFailed = createAction(
  getYoutubeVideoListTypes.GET_YOUTUBE_VIDEO_LIST_FAILED
);
export const getYoutubeVideoListEnd = createAction(
  getYoutubeVideoListTypes.GET_YOUTUBE_VIDEO_LIST_END
);

export const storeGoogleDataRequest = createAction(
  storeGoogleDataTypes.STORE_GOOGLE_DATA_REQUEST
);
