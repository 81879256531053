import { IconGoogleDrive } from "assets/img/icons";
import { google as googleConfig } from "config";
import { RESOLUTION, SUPPORTED_FILE, UPLOADER_TYPE } from "constants/common";
import { GOOGLE_PICKER } from "constants/widgetBuilder";
import WidgetBuilderContext from "contexts/WidgetBuilder";
import { useContext, useLayoutEffect, useState } from "react";
import GooglePicker from "react-google-picker";
import { useTranslation } from "react-i18next";
import utils from "services/utils";
import { StyledSourceIcon } from "styled-components/WidgetBuilder/WidgetUploadSourceStyled";

const GoogleDrive = () => {
  const { t } = useTranslation();
  const { setGoogleDrive, setActiveUploaderType, widgetInfo, handleErrorMsg } =
    useContext(WidgetBuilderContext);
  const { widgetSettingsDTO = {} } = widgetInfo || {};
  const BUTTON_ID = "google_picker_btn";
  const STYLE_ID = "google_picker_style_id";

  useLayoutEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleResize = () => {
    // Set size for the picker modal when opened on the landscape view
    const isLandscape = window.matchMedia("(orientation: landscape)").matches;
    const styles = document.getElementById(STYLE_ID);
    if (styles) {
      styles.parentNode.removeChild(styles); // remove these styles
    }
    if (isLandscape && window.innerWidth < RESOLUTION.midTablet) {
      const css = `.picker.picker-dialog-content { height: ${
          window.innerHeight - 20
        }px !important; width: ${window.innerWidth - 20}px !important; }`,
        head = document.head || document.getElementsByTagName("head")[0],
        style = document.createElement("style");
      style.id = STYLE_ID;
      head.appendChild(style);
      style.type = "text/css";
      if (style.styleSheet) {
        // This is required for IE8 and below.
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
    }
  };

  const originUrl = () => {
    // origin url is used in case a uploader is embedded an iframe
    if (
      window.location.ancestorOrigins &&
      window.location.ancestorOrigins.length > 0
    ) {
      // Access the origin of the immediate parent window
      const parentOrigin =
        window.location.ancestorOrigins[
          window.location.ancestorOrigins.length - 1
        ];
      return parentOrigin;
    } else {
      return window.location.protocol + "//" + window.location.host;
    }
  };

  const pickerCallback = async (data, accessToken) => {
    if (data?.action === GOOGLE_PICKER.ACTION.PICKED) {
      if (data?.docs) {
        // Convert files which are suitable for the available validating function
        const files = data?.docs.map((item) => {
          return {
            ...item,
            type: item.mimeType,
            size: item.sizeBytes,
            uploadType: UPLOADER_TYPE.GOOGLE_DRIVE,
          };
        });
        const { errorMsg, isValidFiles } = await utils.validateUploadedFiles(
          files,
          "en",
          widgetSettingsDTO
        );

        if (isValidFiles && !errorMsg) {
          let filterMedias = files.map(async (item) => {
            const limitationError =
              (await utils.validateFileLimitation({
                file: item,
                widgetInfo,
                t,
                type: UPLOADER_TYPE.GOOGLE_DRIVE,
              })) || "";

            return {
              fileId: item.id,
              fileName: item.name,
              mimeType: item.mimeType,
              limitationError,
            };
          });
          filterMedias = await Promise.all(filterMedias);
          setGoogleDrive({
            selectedMediaList: filterMedias,
            accessToken,
          });
          setActiveUploaderType(UPLOADER_TYPE.GOOGLE_DRIVE);
        } else {
          handleErrorMsg(errorMsg);
        }
      }
    }
  };

  const onKeyDown = () => {
    const el = document.getElementById(BUTTON_ID);
    if (el) {
      el.click();
    }
  };

  return (
    <GooglePicker
      clientId={googleConfig.clientId}
      developerKey={googleConfig.developerKey}
      scope={GOOGLE_PICKER.SCOPE}
      authImmediate={false}
      createPicker={(google, oauthToken) => {
        const view = new google.picker.DocsView(google.picker.ViewId.DOCS);
        //start on drive root and include folders to avoid the default flat file list
        view.setParent("root"); //optional
        view.setIncludeFolders(true); //optional
        view.setMimeTypes(
          `${SUPPORTED_FILE.GOOGLE_PHOTO}${SUPPORTED_FILE.GOOGLE_VIDEO}`
        );
        const url = originUrl();
        const picker = new google.picker.PickerBuilder()
          .addView(view)
          .enableFeature(google.picker.Feature.MULTISELECT_ENABLED) //optional
          .enableFeature(google.picker.Feature.NAV_HIDDEN) //optional
          .enableFeature(google.picker.Feature.MINE_ONLY) //optional
          .setOAuthToken(oauthToken)
          .setCallback((data) => pickerCallback(data, oauthToken))
          .setTitle(t("widget_builder.google_picker.title"))
          .setOrigin(url);
        picker.build().setVisible(true);
      }}
    >
      <StyledSourceIcon
        id={BUTTON_ID}
        tabIndex={0}
        cursor="pointer"
        size={37}
        onKeyDown={(e) => {
          utils.onKeyDown(e, onKeyDown);
        }}
      >
        <IconGoogleDrive />
      </StyledSourceIcon>
    </GooglePicker>
  );
};

export default GoogleDrive;
