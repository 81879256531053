import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { debounce as _debounce, isEmpty } from "lodash";
import { Trans, withTranslation } from "react-i18next";
import logo from "../../../../assets/img/inkedibles/logo.png";
import EntribeLogo from "../../../../assets/img/logo-black.png";
import {
  BRAND_NAME,
  DEFAULT_WIDGET_ID,
  TYPE_CUSTOM_FIELD,
} from "../../../../constants/common";
import configUrls from "../../../../constants/configUrls";
import errorMsgs from "../../../../constants/errorMsgs";
import utils from "../../../../services/utils";
import "./Inkedibles.scss";
import data from "./data";
import { getIPStack } from "services/widgetBuilder";

const createOptionMsg = "Input Other";

class Inkedibles extends Component {
  static propTypes = {
    globalProps: PropTypes.shape({
      setIsShowLoading: PropTypes.func,
      requestAPI: PropTypes.func,
      didMountCallback: PropTypes.func,
    }),
  };

  static defaultProps = {
    globalProps: {
      setIsShowLoading: () => {},
      requestAPI: () => {},
      didMountCallback: () => {},
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      isDisabledWidget: false,
      formState: 2, // 1 - Welcome, 2 - Upload, 3 - Thankyou

      client: null,
      challenge: null,
      selectId: null,
      location: null,

      email: "",
      firstName: "",
      lastName: "",
      instagramUsername: "",

      isAgreed: false,
      emailErrMsg: "",
      firstNameErrMsg: "",
      lastNameErrMsg: "",
      instagramErrMsg: "",
      errorMsg: "",
      uploadedFiles: [],
      uploadedFileType: "",
      previewTxt: "",
      previewImg: "",

      // Selectbox
      productsDepicted: [],
      productsUsed: [],
      inkediblesMyCreation: [],
      inkediblesTheme: [],

      photoMsgErr: "",
      creationMsgErr: "",
      myCreationMsgErr: "",
      themeMsgErr: "",
      fileCount: 0,

      uploadResults: null,
    };

    this.input = {
      email: React.createRef(),
      firstName: React.createRef(),
      lastName: React.createRef(),
      fileInput: React.createRef(),
      instagramUsername: React.createRef(),
      isAgreed: React.createRef(),

      productUsed: React.createRef(),
      creationCategory: React.createRef(),
      creationTheme: React.createRef(),
      photoTextAreaRef: React.createRef(),
      selectRef: React.createRef(),
      caption: React.createRef(),
    };

    this.IGPrefixSymbolRef = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    const {
      client = null,
      challenge = null,
      selectId = null,
      uploadLocation = null,
    } = nextProps;
    this.checkChallenge(challenge);

    this.setState({
      client,
      selectId,
      location: uploadLocation,
    });
  }

  componentWillMount() {
    this.setOnChangeEmailEvent();
  }

  componentDidMount() {
    const {
      globalProps: { didMountCallback },
    } = this.props;
    didMountCallback();
  }

  checkChallenge(challenge) {
    const { selectId = null, client = null } = this.props;

    if (challenge) {
      this.setState({ challenge, isDisabledWidget: false, errorMsg: "" });

      // Only validate challenge and show error if selectId is empty
    } else if (!challenge && !selectId && !client) {
      this.setState({
        isDisabledWidget: true,
        errorMsg: "Invalid data",
      });
    }
  }

  setOnChangeEmailEvent() {
    this.onChangeEmailCallback = _debounce((e) => {
      const email = this.getInputValue("email");

      if (email && utils.validateEmail(email)) {
        this.getCreatorAPI(email);
      } else {
        this.resetAutoFilledFields();
      }
    }, 1000);
  }

  triggerFileUpload = () => {
    const { fileInput } = this.input;
    fileInput.current.click();
  };

  getCreatorAPI = (email) => {
    const {
      globalProps: { requestAPI },
    } = this.props;

    const successCallback = (resp) => {
      const {
        firstName = "",
        lastName = "",
        instagramUsername = "",
      } = resp || {};

      this.setState({
        firstName,
        lastName,
        instagramUsername,
        errorMsg: "",
        firstNameErrMsg: "",
        lastNameErrMsg: "",
        instagramErrMsg: "",
        emailErrMsg: "",
      });
    };

    const failedCallback = (resp) => {
      const errorMsg = resp.error
        ? resp.error
        : resp.status
        ? resp.status.status
        : errorMsgs["en"].callAPIFailed;
      this.setState({ errorMsg });
      this.resetAutoFilledFields();
    };
    const clientId = sessionStorage.getItem("clientId");
    const url = `${configUrls.API.getCreator}?clientId=${clientId}&email=${email}`;
    requestAPI("get", url, {}, successCallback, failedCallback);
  };

  getLocationAPI = () => {
    const {
      globalProps: { requestAPI },
    } = this.props;

    const url = configUrls.externalAPI.getLocation;
    const successCallback = (resp) => {
      this.setState({ location: resp });
    };
    const failedCallback = (resp) => {};
    const errorCallback = () => {};

    requestAPI(
      "get",
      url,
      {},
      successCallback,
      failedCallback,
      errorCallback,
      true
    );
  };

  parseInfo = (item, textArea) => {
    if (!isEmpty(item)) {
      if (!Array.isArray(item)) {
        item = [item];
      }
      if (item[0].label.includes("Other")) {
        item = textArea;
        return [item];
      }
    }
  };

  uploadAPI() {
    const {
      globalProps: { requestAPI },
    } = this.props;

    const {
      client: { id: clientId },
      challenge,
      selectId,
      uploadedFiles,
      uploadedFileType,
      location,
      productsDepicted = "",
      productsUsed,
      inkediblesMyCreation,
      inkediblesTheme,
    } = this.state;

    const currentTimeStamp = new Date().valueOf();

    const productsDepictedValues = {
      name: "Products Depicted",
      values: productsDepicted.map((item) => item.label),
      isTaggable: true,
      isSearchable: true,
      key: "742150195738076",
      type: TYPE_CUSTOM_FIELD.dropdown,
    };

    const productsUsedValues = {
      name: "Products Used",
      values: productsUsed.map((item) => item.label),
      isTaggable: true,
      isSearchable: true,
      key: "990940311411550",
      type: TYPE_CUSTOM_FIELD.dropdown,
    };

    const creationCategoryValues = {
      name: "Creation Category",
      values: inkediblesMyCreation.map((item) => item.label),
      isTaggable: true,
      isSearchable: true,
      key: "224712432904460",
      type: TYPE_CUSTOM_FIELD.dropdown,
    };

    const creationThemeValues = {
      name: "Creation Theme",
      values: inkediblesTheme.map((item) => item.label),
      isTaggable: true,
      isSearchable: true,
      key: "965035232838278",
      type: TYPE_CUSTOM_FIELD.dropdown,
    };

    const caption = {
      name: "Caption",
      values: [this.getInputValue("caption")],
      isTaggable: false,
      isSearchable: true,
      key: "481239535684507",
      type: TYPE_CUSTOM_FIELD.paragraph,
    };

    const additional = {
      custom: [
        productsDepictedValues,
        productsUsedValues,
        creationCategoryValues,
        creationThemeValues,
        caption,
      ],
    };

    const data = {
      clientId: 12,
      challengeId: (challenge && challenge.id) || 0,
      selectId,
      uploadedFiles,
      uploadedFileType,
      location,
      email: this.getInputValue("email"),
      firstName: this.getInputValue("firstName"),
      lastName: this.getInputValue("lastName"),
      instagramUsername: this.getInputValue("instagramUsername"),
      additional,
      ipStack: getIPStack(location),
      termsIds: [1],
      termsTemplateId: 1,
      brandName: BRAND_NAME.INKEDIBLES,
    };

    const formData = utils.parseUploadFormData(data);
    let url = `${configUrls.API.upload}`;

    const successCallback = (resp) => {
      this.setState(
        {
          formState: 3,
          uploadResults: resp,
        },
        () => {
          this.resetForm();
        }
      );
    };

    const failedCallback = (resp) => {
      const errorMsg = resp.error ? resp.error : errorMsgs["en"].uploadFailed;
      this.setState({ errorMsg });
    };

    url = `${url}?access_time=${currentTimeStamp}`;
    if (data.selectId) {
      url = `${url}&s=${data.selectId}`;
    }
    if (data.challengeId) {
      url = `${url}&c=${data.challengeId}`;
    }
    url = `${url}&widgetId=${DEFAULT_WIDGET_ID}&hasEntribeStandardTerms=true&hasCustomTerms=false`;
    requestAPI("post", url, formData, successCallback, failedCallback);
  }

  onSubmit = async () => {
    const { uploadedFiles } = this.state;
    const isValidInput = this.validateInput();
    const isValidUploadedFiles = await this.validateUploadedFiles1(
      uploadedFiles
    );
    const isValidatedAdditionalFields = this.validateAdditionalFields();
    const { isAgreed } = this.state;

    if (
      isValidInput &&
      isValidUploadedFiles &&
      isAgreed &&
      isValidatedAdditionalFields
    ) {
      this.uploadAPI();
    }
  };

  onUploadMore = () => {
    this.setState({ formState: 2 });
    this.resetFormData();
  };

  onChangeEmail = (e) => {
    const {
      target: { value },
    } = e;

    if (e.keyCode === 13) {
      // Enter key
      this.onSubmit();
      return;
    }

    e.persist();

    this.onChangeEmailCallback(e);
    this.setState({ email: e.target.value }, () => {
      this.liveValidation("email", value.length, "onChange");
    });
  };

  onToggleAgreement = (e) => {
    const isAgreed = e.target.checked;
    this.input.isAgreed.current.value = isAgreed ? "on" : "off";
    this.setState({ isAgreed });
  };

  onEnter = (e) => {
    if (e.keyCode === 13) {
      // Enter key
      this.onSubmit();
    }
  };

  handleUpload = async (event) => {
    const { setIsShowLoading } = this.props.globalProps;
    const {
      current: {
        files: { length },
      },
      current: { files },
    } = event;
    let dataArray = [];

    for (var i = 0; i < length; i++) {
      dataArray.push(files[i]);
    }

    dataArray = await utils.convertFileUploads(dataArray, setIsShowLoading);

    if (await this.validateUploadedFiles1(dataArray)) {
      this.renderPreviewImg(dataArray);
      this.setState({ uploadedFiles: dataArray, fileCount: length });
    } else {
      this.setState({ fileCount: 0 });
    }
  };

  getInputValue = (name) => {
    if (!name) {
      return "";
    }

    return this.input[name].current ? this.input[name].current.value : "";
  };

  validateInput = () => {
    const email = this.getInputValue("email");
    const firstname = this.getInputValue("firstName");
    const lastName = this.getInputValue("lastName");

    const data = {
      email,
      firstname,
      lastName,
    };

    const { validInput, emailErrMsg, firstNameErrMsg, lastNameErrMsg } =
      utils.validateInput(data);

    this.setState(
      {
        emailErrMsg,
        firstNameErrMsg,
        lastNameErrMsg,
      },
      () => {
        // Validate additional info
        this.liveValidation("instagramUsername", null, null);
        this.liveValidation("productsDepicted", null, null);
        this.liveValidation("productsUsed", null, null);
        this.liveValidation("inkediblesMyCreation", null, null);
        this.liveValidation("inkediblesTheme", null, null);
      }
    );

    return validInput;
  };

  resetForm = () => {
    this.setState({ ...utils.getEmptyFormData() });
  };

  resetFormData = () => {
    this.setState({
      location: null,
      isAgreed: false,

      emailErrMsg: "",
      firstNameErrMsg: "",
      lastNameErrMsg: "",
      instagramErrMsg: "",
      errorMsg: "",
      uploadedFiles: [],
      uploadedFileType: "",
      previewTxt: "",
      previewImg: "",
      email: "",
      instagramUsername: "",
      firstName: "",
      lastName: "",
      caption: "",

      productsDepicted: [],
      productsUsed: [],
      inkediblesMyCreation: [],
      inkediblesTheme: [],

      photoMsgErr: "",
      creationMsgErr: "",
      myCreationMsgErr: "",
      themeMsgErr: "",

      fileCount: 0,
    });
  };

  resetAutoFilledFields = () => {
    this.setState({
      firstName: "",
      lastName: "",
      instagramUsername: "",
    });
  };

  validateAdditionalFields = () => {
    const {
      instagramUsername = "",
      productsDepicted,
      inkediblesTheme,
      inkediblesMyCreation,
      productsUsed,
    } = this.state;

    if (
      instagramUsername.length > 0 &&
      productsDepicted.length > 0 &&
      inkediblesTheme.length > 0 &&
      inkediblesMyCreation.length > 0 &&
      productsUsed.length > 0
    ) {
      return true;
    }

    return false;
  };

  validateUploadedFiles1 = async (files) => {
    const { errorMsg, isValidFiles, previewImg, previewTxt } =
      await utils.validateUploadedFiles(files);

    if (!isValidFiles) {
      this.setState({
        uploadedFiles: [],
        fileErrorMsg: errorMsg,
        previewImg,
        previewTxt,
      });

      return false;
    }

    this.setState({ uploadedFiles: files, fileErrorMsg: errorMsg });
    return true;
  };

  renderPreviewTxt = (files) => {
    this.setState({ previewTxt: utils.getPreviewTxt(files) });
  };

  renderPreviewImg(files) {
    utils.getPreviewImgAndType(files).then((data) => {
      const { previewImg, uploadedFileType } = data;
      this.setState({ previewImg, uploadedFileType });
    });
  }

  formatGroupLabel = (data) => {
    return (
      <div className="groupStyles">
        <span>{data.label}</span>
        <span className="groupBadgeStyles">{data.options.length}</span>
      </div>
    );
  };

  onChangeSelect = (e, action, name) => {
    this.setState({ [name]: e }, () => {
      this.liveValidation(name, null, "onChange");
    });
  };

  onChangeInput = (e) => {
    const { target } = e;
    let {
      value,
      name,
      value: { length },
    } = target;
    this.setState({ [name]: value });
    this.liveValidation(name, length, "onChange");
  };

  onBlurInput = (e) => {
    const { target } = e;
    const {
      name,
      value: { length },
    } = target;
    const IGPrefixSymbolEle = this.IGPrefixSymbolRef
      ? this.IGPrefixSymbolRef.current
      : null;

    this.liveValidation(name, length, "onBlur");

    if (IGPrefixSymbolEle) {
      IGPrefixSymbolEle.classList.remove("focused");
    }
  };

  onBlurSelect = (title) => {
    this.liveValidation(title, null, "onBlur");
  };

  onFocusIGInput = (e) => {
    const IGPrefixSymbolEle = this.IGPrefixSymbolRef
      ? this.IGPrefixSymbolRef.current
      : null;

    if (IGPrefixSymbolEle) {
      IGPrefixSymbolEle.classList.add("focused");
    }
  };

  liveValidation = (field, itemLength, event) => {
    const {
      productsDepicted,
      productsUsed,
      inkediblesMyCreation,
      inkediblesTheme,
    } = this.state;

    const instagramUsername = this.getInputValue("instagramUsername");
    const data = {
      email: this.getInputValue("email"),
      firstname: this.getInputValue("firstName"),
      lastName: this.getInputValue("lastName"),
    };

    const { emailErrMsg, firstNameErrMsg, lastNameErrMsg } =
      utils.validateInput(data);

    switch (field) {
      case "email": {
        this.setState({
          emailErrMsg,
        });
        break;
      }

      case "firstName": {
        this.setState({
          firstNameErrMsg,
        });
        break;
      }

      case "lastName": {
        this.setState({
          lastNameErrMsg,
        });
        break;
      }

      case "instagramUsername": {
        if (!instagramUsername) {
          this.setState({ instagramErrMsg: errorMsgs["en"].requiredInstagram });
        } else {
          this.setState({ instagramErrMsg: "" });
        }
        break;
      }

      case "productsDepicted": {
        if (!productsDepicted || isEmpty(productsDepicted)) {
          this.setState({ photoMsgErr: errorMsgs["en"].requiredSelect });
        } else if (productsDepicted) {
          this.setState({ photoMsgErr: "" });
        }

        break;
      }

      case "productsUsed": {
        if (!productsUsed || isEmpty(productsUsed)) {
          this.setState({ creationMsgErr: errorMsgs["en"].requiredSelect });
        } else if (!isEmpty(productsUsed)) {
          this.setState({ creationMsgErr: "" });
        }

        break;
      }
      case "inkediblesMyCreation": {
        if (!inkediblesMyCreation || isEmpty(inkediblesMyCreation)) {
          this.setState({ myCreationMsgErr: errorMsgs["en"].requiredSelect });
        } else {
          this.setState({ myCreationMsgErr: "" });
        }

        break;
      }
      case "inkediblesTheme": {
        if (!inkediblesTheme || isEmpty(inkediblesTheme)) {
          this.setState({ themeMsgErr: errorMsgs["en"].requiredSelect });
        } else {
          this.setState({ themeMsgErr: "" });
        }

        break;
      }

      default:
    }
  };

  sortGroupOptions = (groupOption) => {
    if (groupOption) {
      groupOption.sort((a, b) => {
        let A = a.label.toLowerCase();
        let B = b.label.toLowerCase();
        if (A < B) return -1;
        if (A > B) return 1;
        return 0;
      });
    }
    return groupOption;
  };

  render() {
    const { t } = this.props;
    const {
      isDisabledWidget,
      formState,

      email,
      firstName,
      lastName,
      instagramUsername,
      isAgreed,
      emailErrMsg,
      errorMsg,
      fileErrorMsg,
      uploadedFiles,
      previewImg,

      productsDepicted,
      productsUsed,
      inkediblesMyCreation,
      inkediblesTheme,
      caption,

      fileCount,
      firstNameErrMsg,
      lastNameErrMsg,
      instagramErrMsg,
      photoMsgErr,
      creationMsgErr,
      myCreationMsgErr,
      themeMsgErr,
      uploadResults,
      uploadedFileType,
    } = this.state;

    const {
      groupedOptions,
      groupedOptions1,
      groupedOptions2,
      groupedOptions3,
    } = data;
    const otherOption = [{ value: "Other", label: "Other" }];
    const sortedGroupOptions2 =
      this.sortGroupOptions(groupedOptions2).concat(otherOption);
    const sortedGroupOptions3 =
      this.sortGroupOptions(groupedOptions3).concat(otherOption);

    const { fileInput } = this.input;
    const widgetContainerClasses = isDisabledWidget
      ? "widget-container disabled"
      : "widget-container";
    const basicInputClasses =
      "form-control form-control-sm widget-inkedibles-form";
    const emailClasses = emailErrMsg
      ? basicInputClasses
      : `${basicInputClasses} form-control-error`;
    const firstNameClasses = firstNameErrMsg
      ? basicInputClasses
      : `${basicInputClasses} form-control-error`;
    const lastNameClasses = lastNameErrMsg
      ? basicInputClasses
      : `${basicInputClasses} form-control-error`;
    const dropzoneStyles = utils.getDropzoneStyles(uploadedFiles, previewImg);
    const previewImgStyles = utils.getPreviewImgStyles(previewImg);

    const lastUploadedFile = utils.getLastUploadedFile(uploadResults);

    if (previewImg) {
      dropzoneStyles.backgroundColor = "transparent";
      previewImgStyles.backgroundImage = `url(${previewImg})`;

      if (uploadedFiles && uploadedFiles.length > 1) {
        dropzoneStyles.backgroundColor = "#343a40";
      }
    } else {
      previewImgStyles.backgroundImage = "none";
    }

    const descriptionTransProps = {
      i18nKey: "inkedibles.description",
      components: {
        linkTo: <a />,
      },
    };

    return (
      <div className={`widget-inkedibles ${widgetContainerClasses}`}>
        {formState === 2 && (
          <div className="container background ">
            <div className="widget-block widget-upload upload-inkedibles">
              <div className="widget-header">
                <img src={logo} alt="logo" />
              </div>

              <div className="widget-body">
                <div className="widget-form">
                  <div className="error-msg">
                    <p dangerouslySetInnerHTML={{ __html: errorMsg }} />
                  </div>
                  <div className="inkedibles-description">
                    <Trans {...descriptionTransProps} />
                  </div>
                  <button
                    type="button"
                    className="btn btn-sm btn-primary button"
                    onClick={this.triggerFileUpload}
                    title={utils.getFileUploadToolTip()}
                  >
                    {fileCount > 1
                      ? `Selected ${fileCount} files`
                      : fileCount === 1
                      ? `Selected ${fileCount} file`
                      : "Upload"}
                    <input
                      type="file"
                      ref={fileInput}
                      className="inputFile"
                      onChange={() => this.handleUpload(fileInput)}
                      multiple
                    />
                  </button>
                  <div className="mt-4 error-msg file-select">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: fileErrorMsg,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className={emailClasses}
                      ref={this.input.email}
                      onKeyUp={(e) => this.onChangeEmail(e)}
                      onChange={(e) => this.onChangeEmail(e)}
                      onBlur={this.onBlurInput}
                      name="email"
                      placeholder="Email"
                      value={email}
                    />
                    <small className="small"> {emailErrMsg} &nbsp;</small>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className={firstNameClasses}
                      ref={this.input.firstName}
                      onKeyDown={(e) => this.onEnter(e)}
                      onChange={(e) => this.onChangeInput(e)}
                      name="firstName"
                      onBlur={this.onBlurInput}
                      placeholder="First Name"
                      value={firstName}
                    />
                    <small className="small"> {firstNameErrMsg}</small>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className={lastNameClasses}
                      ref={this.input.lastName}
                      onKeyDown={(e) => this.onEnter(e)}
                      onChange={(e) => this.onChangeInput(e)}
                      name="lastName"
                      onBlur={this.onBlurInput}
                      placeholder="Last Name"
                      value={lastName}
                    />
                    <small className="small"> {lastNameErrMsg}</small>
                  </div>
                  <div className="form-group">
                    <div className="input-group input-group-sm">
                      <div
                        className="input-group-prepend"
                        ref={this.IGPrefixSymbolRef}
                      >
                        <span className="input-group-text">@</span>
                      </div>
                      <input
                        type="text"
                        className={lastNameClasses}
                        ref={this.input.instagramUsername}
                        onKeyDown={(e) => this.onEnter(e)}
                        onChange={(e) => this.onChangeInput(e)}
                        onFocus={(e) => this.onFocusIGInput(e)}
                        name="instagramUsername"
                        onBlur={this.onBlurInput}
                        placeholder="Instagram"
                        value={instagramUsername}
                      />
                    </div>
                    <small className="small"> {instagramErrMsg}</small>
                  </div>
                  <div className="form-group">
                    <CreatableSelect
                      required
                      isMulti
                      classNamePrefix="react-select"
                      options={groupedOptions}
                      formatGroupLabel={this.formatGroupLabel}
                      onChange={(e, action) =>
                        this.onChangeSelect(e, action, "productsDepicted")
                      }
                      onBlur={() => this.onBlurSelect("productsDepicted")}
                      value={productsDepicted}
                      placeholder="Products depicted"
                      formatCreateLabel={() => createOptionMsg}
                    />
                    <small className="small">{photoMsgErr}</small>
                  </div>
                  <div className="form-group">
                    <CreatableSelect
                      required
                      isMulti
                      classNamePrefix="react-select"
                      options={groupedOptions1}
                      onChange={(e, action) =>
                        this.onChangeSelect(e, action, "productsUsed")
                      }
                      onBlur={() => this.onBlurSelect("productsUsed")}
                      formatGroupLabel={this.formatGroupLabel}
                      value={productsUsed}
                      placeholder="Products used"
                      formatCreateLabel={() => createOptionMsg}
                    />
                    <small className="small">{creationMsgErr}</small>
                  </div>
                  <div className="form-group">
                    <CreatableSelect
                      required
                      isMulti
                      classNamePrefix="react-select"
                      options={sortedGroupOptions2}
                      onChange={(e, action) =>
                        this.onChangeSelect(e, action, "inkediblesMyCreation")
                      }
                      onBlur={() => this.onBlurSelect("inkediblesMyCreation")}
                      formatGroupLabel={this.formatGroupLabel}
                      value={inkediblesMyCreation}
                      placeholder="Creation category"
                      formatCreateLabel={() => createOptionMsg}
                    />
                    <small className="small">{myCreationMsgErr}</small>
                  </div>
                  <div className="form-group">
                    <Select
                      required
                      isMulti
                      classNamePrefix="react-select"
                      onChange={(e, action) =>
                        this.onChangeSelect(e, action, "inkediblesTheme")
                      }
                      onBlur={() => this.onBlurSelect("inkediblesTheme")}
                      options={sortedGroupOptions3}
                      value={inkediblesTheme}
                      placeholder="Creation theme"
                      formatCreateLabel={() => createOptionMsg}
                    />
                    <small className="small">{themeMsgErr}</small>
                  </div>
                  <div className="form-group">
                    <textarea
                      rows={3}
                      className="text-area"
                      ref={this.input.caption}
                      onKeyDown={this.onEnter}
                      onChange={this.onChangeInput}
                      name="caption"
                      onBlur={this.onBlurInput}
                      placeholder="Add a caption"
                      value={caption}
                    />
                  </div>
                </div>
                {/* End Input Fields */}
                <div className="row widget-agreement">
                  <div className="col-12 col-sm-9">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="on"
                        id="agreement"
                        checked={isAgreed}
                        ref={this.input.isAgreed}
                        onChange={this.onToggleAgreement}
                      />
                      <label className="form-check-label" htmlFor="agreement">
                        I agree to the{" "}
                        <a
                          href={configUrls.App.terms}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms & Conditions*
                        </a>
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-sm-3 right-col submit">
                    <button
                      type="button"
                      className="btn btn-sm btn-success"
                      onClick={() => this.onSubmit()}
                      disabled={!isAgreed}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
              {/* End Agree Checkbox */}
              <div className="widget-footer">
                <div className="signature">
                  Powered By{" "}
                  <a
                    href="https://www.entribe.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={EntribeLogo} alt="logo" />
                  </a>
                </div>
              </div>
              {/* End Footer */}
            </div>
          </div>
        )}
        {/* End Upload Form */}

        {formState === 3 && (
          <div className="container container-thankyou">
            <div className="widget-block widget-thankyou">
              <div className="widget-header inkedibles-header"></div>
              <div className="widget-body inkedibles-body">
                <h3>Thank you for the upload!</h3>
                <div className="uploaded-media">
                  {lastUploadedFile && (
                    <img
                      src={lastUploadedFile}
                      alt="widget upload"
                      onError={(e) =>
                        utils.handleThumbnailLoadedError(
                          e,
                          uploadedFileType || ""
                        )
                      }
                    />
                  )}
                  {uploadResults && uploadResults.type === "video" && (
                    <FontAwesomeIcon icon={faPlayCircle} size="lg" />
                  )}
                </div>
                {/* End Last Upload */}
              </div>
              <div className="widget-footer inkedibles-footer">
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={() => this.onUploadMore()}
                >
                  Upload More
                </button>
                <div className="signature">
                  Powered By{" "}
                  <a
                    href="https://www.entribe.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={EntribeLogo} alt="logo" />
                  </a>
                </div>
              </div>
              {/* End Footer */}
            </div>
          </div>
        )}
        {/* End Thank You Form */}
      </div>
    );
  }
}

export default withRouter(withTranslation()(Inkedibles));
