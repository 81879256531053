import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import configUrls from "../../../constants/configUrls";
import utils from "../../../services/utils";
import errorMsgs from "../../../constants/errorMsgs";

import { orderBy } from "lodash";

const BaseFormikMX = ({
  fields = [],
  renderField = () => {},
  clearMessage = () => {},
  setGeneralError = () => {},
  onSubmit = async () => {},
  generalMessage: { type = "", message = "" } = {},
  logo,
  uploadedFiles = [],
  isNewForm = false,
  isNewFormResponsive = false,
  isFromMasterSpa = false,
  clientName = "",
}) => {
  const [isAgreed, setIsAgreed] = useState(false);
  const [isOver21, setIsOver21] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const generalMessageClassName =
    type === "error" ? "general-message error" : "general-message success";
  const GRADUATION_YEAR = "graduationYear";
  let initialValues = {};
  let validationSchema = {};
  const checkLegalClients = ["fireballWhisky", "buffaloTrace"];
  const checkOver21Client = ["spaGirlCocktails"];
  const eitherSocialAccount = ["reeses"];

  useEffect(() => {
    if (eitherSocialAccount.includes(clientName)) {
      handleDynamicRequiredSocialInput();
    }
  });

  fields.forEach((item) => {
    const {
      label = "",
      placeholder = "",
      isRequired = false,
      isCheckSpecialCharacter = false,
      isCheckUrlLink = false,
    } = item;
    const { string = () => {} } = Yup;
    const { specialCharacter, specialCharacterWithSlash, validYear } = utils;
    const value = string().trim();
    initialValues[label] = "";

    if (label === "email") {
      validationSchema[label] = value
        .email("(!) Dirección de correo electrónico inválida")
        .required("(!) Se requiere tu correo electrónico");
    } else {
      if (
        isRequired &&
        eitherSocialAccount.includes(clientName) &&
        (label === "instagramUsername" || label === "tiktokUsername")
      ) {
        validationSchema[label] = value.required(
          `(!) Instagram or TikTok username is required`
        );
      } else if (isRequired) {
        if (label === "firstName") {
          validationSchema[label] = value.required(`(!) Se requiere tu nombre`);
        } else if (label === "lastName") {
          validationSchema[label] = value.required(
            `(!) Se requiere tu apellido`
          );
        } else if (label === "instagramUsername") {
          validationSchema[label] = value.required(
            `(!) Se requiere tu usuario Instagram`
          );
        } else if (label === "twitterUsername") {
          validationSchema[label] = value.required(
            `(!) Se requiere tu usuario Twitter`
          );
        } else if (label === "profession") {
          validationSchema[label] = value.required(
            `(!) Se requiere tu Profesion`
          );
        } else if (label === "product") {
          validationSchema[label] = value.required(
            `(!) Se requiere tu Productos representados`
          );
        } else if (label === "tipsProfession") {
          validationSchema[label] = value.required(
            `(!) Se requiere tu Tipos de creaciones`
          );
        } else {
          validationSchema[label] = value.required(
            `(!) ${placeholder} is required`
          );
        }
      }

      if (label === GRADUATION_YEAR) {
        if (isRequired) {
          validationSchema[label] = value
            .matches(validYear, `${placeholder} is not valid`)
            .required(`(!) ${placeholder} is required`);
        } else {
          validationSchema[label] = value.matches(
            validYear,
            `${placeholder} is not valid`
          );
        }
      }

      if (isCheckSpecialCharacter) {
        if (isRequired) {
          validationSchema[label] = value
            .matches(
              specialCharacter,
              `${placeholder} only allow characters such as _ .`
            )
            .required(`(!) ${placeholder} is required`);
        } else {
          validationSchema[label] = value.matches(
            specialCharacter,
            `${placeholder} only allow characters such as _ .`
          );
        }
      }

      if (isCheckUrlLink) {
        if (isRequired) {
          validationSchema[label] = value
            .matches(
              specialCharacterWithSlash,
              `${placeholder} only allow characters such as - . /`
            )
            .required(`(!) ${placeholder} is required`);
        } else {
          validationSchema[label] = value.matches(
            specialCharacterWithSlash,
            `${placeholder} only allow characters such as - . /`
          );
        }
      }
    }
  });

  const {
    values,
    getFieldProps,
    setValues,
    resetForm,
    touched,
    errors,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object().shape(validationSchema),
    onSubmit: (values) => {
      setValues({ ...values });
      onSubmit(values, resetForm, setIsAgreed);
    },
  });

  const handleDynamicRequiredSocialInput = () => {
    const instagramIndex = findIndex("instagramUsername");
    const tiktokIndex = findIndex("tiktokUsername");
    if (values.instagramUsername === "" && values.tiktokUsername === "") {
      fields[instagramIndex].isRequired = true;
      fields[tiktokIndex].isRequired = true;
    }
    if (values.instagramUsername !== "") {
      fields[tiktokIndex].isRequired = false;
    }
    if (values.tiktokUsername !== "") {
      fields[instagramIndex].isRequired = false;
    }
  };

  const orderedFields = orderBy(fields, ["order"], ["asc"]);

  const fieldsAfterModified = orderedFields.map((field) => {
    return { ...field, touched, errors, getFieldProps, setFieldValue };
  });

  const fileUploadField = fieldsAfterModified.find(
    (input) => input.type === "file"
  );
  const anotherFields = fieldsAfterModified.filter(
    (input) => input.type !== "file"
  );

  useEffect(() => {
    checkDisabledForm();
  }, [isAgreed, isOver21]);

  const findIndex = (name) => {
    return fields.findIndex((item) => item.label === name);
  };

  const clickedCheckbox = () => {
    // checkDisabledForm();
    setIsAgreed(!isAgreed);
  };

  const clickedIsOver21 = () => {
    setIsOver21(!isOver21);
  };

  const submitForm = (e) => {
    e.preventDefault();
    handleSubmit();
    clearMessage();
    if (uploadedFiles.length > 0) {
      clearMessage();
    } else {
      setGeneralError(errorMsgs["es"].invalidFileType, "error");
    }
  };

  const checkDisabledForm = () => {
    const isRequiredToCheckLegel =
      checkLegalClients.includes(clientName) ||
      checkOver21Client.includes(clientName);
    if (!isRequiredToCheckLegel) {
      setIsDisabled(!isAgreed);
    } else {
      setIsDisabled(!isAgreed || !isOver21);
    }
  };

  return (
    <div
      className={
        isNewForm ? "formik-container container px-5" : "formik-container"
      }
    >
      <div className={isNewForm ? "widget-body d-flex" : "widget-body"}>
        {renderField(fileUploadField)}
        <div className={isNewForm ? "widget-form w-50 px-0" : "widget-form"}>
          <div
            className={generalMessageClassName}
            dangerouslySetInnerHTML={{ __html: message }}
          />
          {anotherFields.map((field) => renderField(field))}
          {isNewForm && (
            <div className="widget-agreement px-0">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="isOver21"
                  onChange={clickedIsOver21}
                  checked={isOver21}
                />

                {checkLegalClients.includes(clientName) && (
                  <label className="form-check-label" htmlFor="isOver21">
                    I confirm that I am of legal drinking age in my country.
                  </label>
                )}

                {checkOver21Client.includes(clientName) && (
                  <label className="form-check-label" htmlFor="isOver21">
                    I confirm that I am 21 years or older
                  </label>
                )}
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="agreement"
                  onChange={clickedCheckbox}
                  checked={isAgreed}
                />
                <label className="form-check-label" htmlFor="agreement">
                  Estoy de acuerdo con los{" "}
                  <a
                    href={configUrls.App.terms}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="term"
                  >
                    Términos y Condiciones*
                  </a>
                </label>
              </div>
            </div>
          )}
          {isNewForm && (
            <div className="widget-footer p-0">
              <button
                className="btn button w-100 mx-0"
                disabled={isDisabled}
                onClick={submitForm}
                type="button"
              >
                Cargar
              </button>
            </div>
          )}
        </div>
      </div>
      {!isNewForm && (
        <div className="widget-agreement add-padding-left">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="agreement"
              onChange={clickedCheckbox}
              checked={isAgreed}
            />
            <label className="form-check-label" htmlFor="agreement">
              Estoy de acuerdo con los{" "}
              <a
                href={configUrls.App.terms}
                target="_blank"
                rel="noopener noreferrer"
                className="term"
              >
                Terminos y Condiciones*
              </a>
            </label>
          </div>
          {isNewFormResponsive && (
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="agreement"
                onChange={clickedCheckbox}
                checked={isAgreed}
              />
              <label className="form-check-label" htmlFor="agreement">
                Estoy de acuerdo con los{" "}
                <a
                  href={configUrls.App.terms}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="term"
                >
                  Terminos y Condiciones*
                </a>
              </label>
            </div>
          )}
        </div>
      )}
      {!isNewForm && (
        <div className="widget-footer">
          <button
            className="btn btn-sm button"
            disabled={!isAgreed}
            onClick={submitForm}
            type="button"
          >
            {isFromMasterSpa ? "Cargar" : "Envio"}
          </button>
          <div className="signature add-padding-left">
            Desarrollada por
            <a
              href="https://www.entribe.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={logo} alt="logo" />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default BaseFormikMX;
