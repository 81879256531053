import axios from "axios";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Router } from "react-router-dom";

import Routes from "./routes";
import history from "./services/history";
import utils from "./services/utils";

import "bootstrap/dist/css/bootstrap.min.css";
import { IN_PROGRESS, PATH_NAME } from "constants/common";
import "./App.scss";
import { useSelector } from "react-redux";
import SkeletonFrame from "components/widgets/WidgetBuilder/SkeletonFrame";

function App() {
  const { getInstagramMediaStatus, isHiddenGeneralLoading } = useSelector(
    (state) => state.widgets
  );
  const { getYoutubeVideoListStatus } = useSelector(
    (state) => state.googleStore
  );
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    utils.loadPendoScript();
  }, []);

  const didMountCallback = () => {
    // TBD - do something as global actions after component was mounted
  };

  const initializePendo = (location, clientId) => {
    const { pendo = {} } = window;

    try {
      if (pendo && !_.isEmpty(pendo)) {
        setTimeout(() => {
          const initData = {
            visitor: {
              id: "",
            },
            account: {
              id: clientId,
            },
          };
          pendo.initialize(initData);
          sessionStorage.setItem("location", JSON.stringify(location));
        }, 2000);
      }
    } catch (error) {
      console.info("[Pendo] Could not send tracking data!");
    }
  };

  // Common request function to call API
  const requestAPI = (
    method,
    url,
    data,
    successCallback,
    failedCallback,
    errorCallback,
    isServiceAPI,
    isWidgetBuilder,
    customHeader
  ) => {
    setIsShowLoading(true);

    axios.defaults.withCredentials = false;
    axios.defaults.validateStatus = () => true;

    let headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-client-id": sessionStorage.getItem("clientId"),
    };
    if (customHeader) {
      headers = { ...headers, ...customHeader };
    }

    axios["mode"] = "no-cors";
    const config =
      data && data.timeout
        ? {
            headers: headers,
            validateStatus: () => true,
            timeout: data.timeout,
          }
        : { headers: headers, validateStatus: () => true };

    axios[method](url, data, config)
      .then((resp) => {
        setIsShowLoading(false);
        // unpredictable responsve data format from external APIs, so no need to check data for success case
        if (isServiceAPI) {
          successCallback(resp.data);
        }

        if (resp.data && resp.data.status) {
          if (resp.data.status.code === 200) {
            if (url.includes("content/public/upload-content")) {
              successCallback(
                resp.data.result &&
                  resp.data.result.length > 0 &&
                  !isWidgetBuilder
                  ? resp.data.result[0]
                  : resp.data.result
              );
              const { pendo } = window;
              const clientId = sessionStorage.getItem("clientId");
              const clientName = utils.capitalizeFirstLetter(
                sessionStorage.getItem("clientName")
              );
              const trackingData = {
                visitorId: resp.data.result[0].creatorId,
                accountId: clientId,
                timestamp: new Date().getTime(),
                properties: { uploadedContent: resp.data.result },
              };
              if (clientName === "Default") {
                pendo.track(
                  `Client Id: ${clientId} - Widget Uploaded`,
                  trackingData
                );
              } else {
                pendo.track(
                  `Client ${
                    clientName ? "Name: " + clientName : "Id: " + clientId
                  } - Widget Uploaded`,
                  trackingData
                );
              }
            } else {
              successCallback(resp.data.result);
            }
          } else {
            failedCallback(resp.data);
          }
        }
      })
      .catch((err) => {
        setIsShowLoading(false);

        if (errorCallback) {
          errorCallback();
        }
        if (failedCallback && err.message.includes("timeout of")) {
          failedCallback({
            error:
              "Sorry, a timeout error has occurred. Please check your connection and try again.",
          });
        }
        console.log("Error", err);
      });
  };

  let globalClasses = "";
  if (!isDisabled) {
    globalClasses =
      (isShowLoading ||
        getInstagramMediaStatus === IN_PROGRESS ||
        getYoutubeVideoListStatus === IN_PROGRESS) &&
      !isHiddenGeneralLoading
        ? "loading-state"
        : "";
  } else {
    globalClasses = "disabled";
  }

  const globalProps = {
    setIsShowLoading,
    isShowLoading,
    requestAPI,
    didMountCallback,
    initializePendo,
  };

  const routes = useMemo(() => <Routes globalProps={globalProps} />, []); // Prevent the page component re-render after opening modal
  const {
    location: { pathname = "" },
  } = history;
  let widgetInkedibles = "";
  if (pathname === "/inkediblescustom") {
    widgetInkedibles = "widget-inkedibles-main";
  }

  const isGemini = pathname === `/${PATH_NAME.GEMINI}`;

  return (
    <div className={`widget ${globalClasses} ${widgetInkedibles}`}>
      {(isShowLoading ||
        getInstagramMediaStatus === IN_PROGRESS ||
        getYoutubeVideoListStatus === IN_PROGRESS) &&
        !isHiddenGeneralLoading && (
          <div className={`loading-bar ${isGemini ? "gemini" : ""}`}>
            <div className="line"></div>
            <div className="subline inc"></div>
            <div className="subline dec"></div>
          </div>
        )}

      <Router history={history}>{routes}</Router>
    </div>
  );
}

export default App;
