import PropTypes from "prop-types";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { getUrlS3 } from "services/funcHelper";
import { IconArrowBottom, IconClose } from "../../../assets/img/icons";
import {
  SCRIPT_TAG,
  SCROLLBAR_WIDTH,
  TYPE_TERM_CONDITION,
} from "../../../constants/common";
import THEME from "../../../constants/themes";
import WidgetBuilderContext from "../../../contexts/WidgetBuilder";
import utils from "../../../services/utils";
import {
  StyledButton,
  StyledIcon,
} from "../../../styled-components/Common/CommonStyled";
import {
  StyledAgreeButtonContainer,
  StyledModalContent,
  StyledTermConditions,
} from "../../../styled-components/WidgetBuilder/WidgetBuilderStyled";
import PDFViewer from "../../shared/PDFViewer";
import useScrollHandler from "../../shared/ScrollHandler";

const TermConditions = (props) => {
  const termRef = useRef();
  const { terms, onClose, isMobile, handleAgreeTerm, className, widgetInfo } =
    props;

  const { metadata } = widgetInfo || {};
  const { colors } = metadata || {};
  const { bgSubmit } = colors || {};
  const {
    id,
    documentBody = "",
    hyperlinkText = "",
    displayType,
    url,
    textMustView,
  } = terms;

  const [PDFInfo, setPDFInfo] = useState();
  const [isDisabledButton, setIsDisabledButton] = useState(true);

  useScrollHandler(termRef, true);

  // The scroll listener
  const handleScroll = useCallback(
    (e) => {
      const { clientHeight, scrollTop, scrollHeight } = e.target;

      if (clientHeight + scrollTop >= scrollHeight - SCROLLBAR_WIDTH) {
        setIsDisabledButton(false);
      }
    },
    [termRef.current]
  );

  // Attach the scroll listener to the div
  useEffect(() => {
    if (termRef.current) {
      const termContainer = termRef.current;
      const hasVerticalScroll =
        termContainer.scrollHeight > termContainer.clientHeight;

      if (hasVerticalScroll) {
        termContainer.addEventListener("scroll", (e) => handleScroll(e));
      } else {
        setIsDisabledButton(false);
      }

      return () => {
        termContainer.removeEventListener("scroll", (e) => handleScroll(e));
      };
    }
  }, [termRef.current]);

  const onChangePDFPage = (event) => {
    setPDFInfo(event);
  };

  const isLastPage = useMemo(() => {
    if (PDFInfo) {
      const { currentPage = 0, doc } = PDFInfo;
      const { _pdfInfo: { numPages } = {} } = doc || {};

      return currentPage + 1 === numPages;
    }

    return false;
  }, [PDFInfo]);

  const disabled = useMemo(() => {
    switch (displayType) {
      case TYPE_TERM_CONDITION.popupFile:
        return {
          element: !isLastPage && terms.isMustView && !terms.isAgreed,
        };
      case TYPE_TERM_CONDITION.popupText:
        return {
          element: isDisabledButton && terms.isMustView && !terms.isAgreed,
        };

      default:
        break;
    }
  }, [terms, isLastPage, isDisabledButton]);

  const renderArrow = () => {
    let otherProps = {
      width: "35px",
      height: "35px",
    };

    if (isMobile) {
      otherProps = { ...otherProps, width: "30px", height: "30px" };
    }

    return (
      <StyledIcon color={bgSubmit} {...otherProps}>
        <IconArrowBottom />
      </StyledIcon>
    );
  };

  const isScriptTag = useMemo(
    () => className?.includes(SCRIPT_TAG),
    [className]
  );

  const renderTerms = () => {
    const otherProps = {
      onChangePDFPage,
      isScriptTag,
    };

    if (displayType === TYPE_TERM_CONDITION.popupFile) {
      const termUrl = getUrlS3(url);
      return <PDFViewer isMobile={isMobile} data={termUrl} {...otherProps} />;
    }

    return <div dangerouslySetInnerHTML={{ __html: documentBody }} />;
  };

  const width = useMemo(() => {
    let result = 650;

    if (isMobile) result = "90%";
    else {
      if (displayType === TYPE_TERM_CONDITION.popupFile) {
        result = "70%";
      }
    }

    return result;
  }, [displayType, isMobile]);

  const renderFooter = () => {
    return (
      textMustView && (
        <StyledAgreeButtonContainer>
          <StyledButton
            onClick={() => {
              if (!disabled.element) handleAgreeTerm(id);
            }}
            type="primary"
            fontSize={isMobile ? "14px" : "16px"}
            radius="36px"
            height="fit-content"
            padding={isMobile ? "0.5rem 2rem" : "0.5rem 4rem"}
            backgroundColor={bgSubmit}
            isDisabled={disabled.element}
          >
            {textMustView}
          </StyledButton>
        </StyledAgreeButtonContainer>
      )
    );
  };

  const onCloseModal = () => {
    onClose();
  };

  const renderTitle = () => {
    return (
      <>
        {hyperlinkText}
        <StyledIcon
          className="close-icon"
          tabIndex={0}
          onClick={onCloseModal}
          onKeyDown={(e) => utils.onKeyDown(e, onCloseModal)}
          cursor="pointer"
          color={THEME.colors.darkBlue5}
          width="16px"
          height="16px"
        >
          <IconClose />
        </StyledIcon>
      </>
    );
  };

  return (
    <StyledTermConditions
      width={isScriptTag ? "90%" : width}
      wrapClassName={`terms ${className}`}
      title={renderTitle()}
      centered
      visible={terms}
      footer={textMustView ? renderFooter() : false}
      onCancel={onCloseModal}
      isMobile={isMobile}
      closable={false}
    >
      <StyledModalContent className={className} tabIndex={-1} ref={termRef}>
        {renderTerms()}
        {disabled.element && renderArrow()}
      </StyledModalContent>
    </StyledTermConditions>
  );
};

TermConditions.propTypes = {
  terms: PropTypes.object,
  onClose: PropTypes.func,
  isMobile: PropTypes.bool,
  handleAgreeTerm: PropTypes.func,
  setPDFInfo: PropTypes.func,
  className: PropTypes.string,
};

export default TermConditions;
