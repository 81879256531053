export default {
  tl: {
    teampixel: 269, // Account: teampixel
    inkediblescustom: 270, // Account: inkedibles
    newgeneration: 275, // Account: newgeneration
    circlek: 276, // Account circlek
    masks4all: 278, // Account masks4all
    bareorganics: 279,
    k9vitamins: 284,
    phillybysegway: 285, // Account phillybysegway
    hudson303: 281, //Account hudson303
    wellerbourbon: 283, // Account wellerbourbon
    buffalotrace: 282, // Account buffalotrace
    ucdavis: 286, // Account ucdavis
    masterspas: 287,
    spagirlcocktails: 288,
    fireballwhisky: 289,
    butleruniversity: 290,
    utulsa: 291,
    airbnb: 292,
    sephora: 298,
    veuve: 299,
    nfl: 300,
    targetable: 301,
    reeses: 302,
    hersheysph: 303,
    fieldturf: 304,
    entribe: 204,
    hersheysbr: 305,
    tostbeverages: 306,
    onebrandsca: 307,
    hersheysreposteria: 308,
    gemini: 752,
  },
  qa: {
    teampixel: 269, // Account: teampixel
    inkediblescustom: 270, // Account: inkedibles
    newgeneration: 275, // Account: newgeneration
    circlek: 276, // Account circlek
    masks4all: 278, // Account masks4all
    bareorganics: 279,
    k9vitamins: 284, // Account k9vitamins
    phillybysegway: 285, // Account phillybysegway
    hudson303: 281, //Account hudson303
    wellerbourbon: 283, // Account wellerbourbon
    buffalotrace: 282, // Account buffalotrace
    ucdavis: 286, // Account ucdavis
    masterspas: 287,
    spagirlcocktails: 288,
    fireballwhisky: 289,
    butleruniversity: 290,
    utulsa: 291,
    airbnb: 292,
    sephora: 298,
    veuve: 299,
    nfl: 300,
    targetable: 301,
    reeses: 302,
    hersheysph: 303,
    fieldturf: 304,
    entribe: 204,
    hersheysbr: 305,
    tostbeverages: 306,
    onebrandsca: 307,
    hersheysreposteria: 308,
    gemini: 752,
  },
  qav2: {
    teampixel: 51, // Account: teampixel
    inkediblescustom: 32, // Account: inkedibles
    newgeneration: 275, // Account: newgeneration
    circlek: 60, // Account circlek
    masks4all: 278, // Account masks4all
    bareorganics: 279,
    k9vitamins: 16, // Account k9vitamins
    phillybysegway: 285, // Account phillybysegway
    hudson303: 281, //Account hudson303
    wellerbourbon: 283, // Account wellerbourbon
    buffalotrace: 53, // Account buffalotrace
    ucdavis: 49, // Account ucdavis
    masterspas: 57,
    spagirlcocktails: 14,
    fireballwhisky: 289,
    butleruniversity: 55,
    utulsa: 41,
    airbnb: 292,
    sephora: 298,
    veuve: 299,
    nfl: 1,
    targetable: 10,
    onebrandsca: 54,
    reeses: 50,
    hersheysreposteria: 42,
    tostbeverages: 4,
    hersheyindia: 58,
    onxhomes: 47,
    circlekcanada: 61,
    hersheysbr: 45,
    hersheysph: 52,
    gemini: 752,
  },
  prod: {
    teampixel: 87, // Account: teampixel
    inkediblescustom: 98, // Account: inkedibles
    newgeneration: 107, // Account: newgeneration
    circlek: 62, // Account circlek
    masks4all: 74, // Account masks4all
    bareorganics: 67,
    k9vitamins: 114,
    phillybysegway: 109, // Account phillybysegway
    hudson303: 110, // Account hudson303
    wellerbourbon: 111, // Account wellerbourbon
    buffalotrace: 112, // Account buffalotrace
    ucdavis: 41, // Account ucdavis
    masterspas: 116,
    spagirlcocktails: 117,
    fireballwhisky: 120,
    butleruniversity: 122,
    utulsa: 123,
    sephora: 60,
    veuve: 83,
    nfl: 16,
    targetable: 133,
    reeses: 134,
    hersheysph: 131,
    fieldturf: 140,
    entribe: 7,
    hersheysbr: 124,
    tostbeverages: 141,
    onebrandsca: 129,
    hersheysreposteria: 130,
    gemini: 182,
  },
  prodv2: {
    teampixel: 68, // Account: teampixel
    inkediblescustom: 63, // Account: inkedibles
    newgeneration: 107, // Account: newgeneration
    masks4all: 55, // Account masks4all
    bareorganics: 67,
    phillybysegway: 109, // Account phillybysegway
    hudson303: 110, // Account hudson303
    wellerbourbon: 111, // Account wellerbourbon
    buffalotrace: 33, // Account buffalotrace
    fireballwhisky: 120,
    reeses: 36,
    entribe: 7,
    tostbeverages: 65,
    targetable: 38,
    spagirlcocktails: 42,
    k9vitamins: 44,
    vaynermedia: 45,
    hersheysreposteria: 46,
    kissesindia: 48,
    hersheysbr: 49,
    diadelbesokissesmx: 61,
    gemini: 182,
  },
};
