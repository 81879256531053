import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { URL } from "../../../constants/common";
import THEME from "../../../constants/themes";
import { StyledText } from "../../../styled-components/Common/CommonStyled";
import {
  StyledLimitTerms,
  StyledLimitTermsModal,
} from "../../../styled-components/WidgetBuilder/UploadLimitTerms";
import { StyledLink } from "../../../styled-components/WidgetBuilder/WidgetBuilderStyled";
import ModalTitle from "../../shared/ModalTitle";

const UploadLimitTerms = (props) => {
  const { t } = useTranslation();
  const { widgetDetail, onClose, isMobile, className, limitContent } = props;
  const {
    widgetSettingsDTO: {
      maxFileSize,
      maxVideoPerUpload,
      maxPhotoPerUpload,
      maxVideoLength,
    } = {},
    metadata,
  } = widgetDetail || {};
  const { colors } = metadata || {};
  const { highlight } = colors || {};

  const renderUploaderSupport = () => {
    return (
      <>
        <StyledText margin="8px 0 0 0" color={THEME.colors.black}>
          {t("widget_builder.still_trouble_uploading")}
          <StyledLink
            tabIndex={0}
            target="_blank"
            rel="noopener noreferrer"
            href={URL.UPLOADER_SUPPORT}
            color={highlight}
          >
            {t("widget_builder.visit_FAQ")}
          </StyledLink>
        </StyledText>
      </>
    );
  };

  const renderWidgetSetting = () => {
    return (
      <>
        <StyledLimitTerms
          isMobile={isMobile}
          dangerouslySetInnerHTML={{
            __html:
              limitContent ||
              t("widget_builder.upload_limits", {
                maxFileSize,
                maxPhotoPerUpload,
                maxVideoPerUpload,
                maxVideoLength,
              }),
          }}
        />

        {renderUploaderSupport()}
      </>
    );
  };

  const titleProps = {
    title: t("widget_builder.what_can_upload"),
    onClose,
    tabIndex: true,
  };

  return (
    <StyledLimitTermsModal
      width={360}
      title={<ModalTitle {...titleProps} />}
      centered
      visible={true}
      footer={false}
      onCancel={onClose}
      isMobile={isMobile}
      maskStyle={{ backgroundColor: THEME.maskOverlay }}
      closable={false}
      wrapClassName={className}
    >
      {renderWidgetSetting()}
    </StyledLimitTermsModal>
  );
};

UploadLimitTerms.propTypes = {
  widgetDetail: PropTypes.object,
  onClose: PropTypes.func,
  isMobile: PropTypes.bool,
  className: PropTypes.string,
};

export default UploadLimitTerms;
