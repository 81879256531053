import { handleActions } from "redux-actions";
import {
  DEFAULT_ERROR,
  DEFAULT_GETTING_CHANNEL_ERROR,
  DEFAULT_GETTING_YOUTUBE_VIDEO_ERROR,
  FAILED,
  IN_PROGRESS,
  NONE,
  SUCCEEDED,
} from "../../constants/common";
import {
  getGoogleTokenInformationTypes,
  getYoutubeChannelIdTypes,
  getYoutubeVideoListTypes,
  refreshOAuthGoogleTokenTypes,
  storeGoogleDataTypes,
} from "./google.types";
import { YOUTUBE_UPLOADING } from "constants/widgetBuilder";

const initialState = {
  getGoogleTokenInformationStatus: NONE,
  googleTokenInformation: null,
  refreshToken: null,
  youTubeVideoData: null,
  youTubeChannelId: null,
  googleAccessToken: null,
  playlistId: null,
};

const getGoogleTokenInformationRequest = (state) => {
  return {
    ...state,
    getGoogleTokenInformationStatus: IN_PROGRESS,
  };
};

const getGoogleTokenInformationSucceeded = (state, { payload }) => {
  if (payload) {
    const { result: googleTokenInformation } = payload || {};

    // TODO, will be update once we have offical response data format from BE

    const stateTemp = {
      ...state,
      getGoogleTokenInformationStatus: SUCCEEDED,
      googleTokenInformation,
    };

    return {
      ...stateTemp,
    };
  }
};

const getGoogleTokenInformationFailed = (
  state,
  { payload = DEFAULT_ERROR }
) => {
  if (payload.data) {
    const { message = "", status = NONE, error = "" } = payload.data;

    return {
      ...state,
      getGoogleTokenInformationStatus: FAILED,
      error: error || `${status} ${message}`,
    };
  }

  return {
    ...state,
    getGoogleTokenInformationStatus: FAILED,
  };
};

const getGoogleTokenInformationEnd = (state) => {
  return {
    ...state,
    getGoogleTokenInformationStatus: NONE,
  };
};

const refreshOAuthGoogleTokenRequest = (state) => {
  return {
    ...state,
    refreshOAuthGoogleTokenStatus: IN_PROGRESS,
  };
};

const refreshOAuthGoogleTokenSucceeded = (state, { payload }) => {
  if (payload) {
    const { result } = payload || {};

    // TODO, will be update once we have offical response data format from BE

    const stateTemp = {
      ...state,
      refreshOAuthGoogleTokenStatus: SUCCEEDED,
    };

    return {
      ...stateTemp,
    };
  }
};

const refreshOAuthGoogleTokenFailed = (state, { payload = DEFAULT_ERROR }) => {
  if (payload.data) {
    const { message = "", status = NONE, error = "" } = payload.data;

    return {
      ...state,
      refreshOAuthGoogleTokenStatus: FAILED,
      error: error || `${status} ${message}`,
    };
  }

  return {
    ...state,
    refreshOAuthGoogleTokenStatus: FAILED,
  };
};

const refreshOAuthGoogleTokenEnd = (state) => {
  return {
    ...state,
    refreshOAuthGoogleTokenStatus: NONE,
  };
};

const getYoutubeVideoListRequest = (state) => {
  return {
    ...state,
    getYoutubeVideoListStatus: IN_PROGRESS,
  };
};

const getYoutubeVideoListSucceeded = (state, { payload }) => {
  if (payload) {
    const { data } = payload || {};
    const pageInfo = {
      ...data.pageInfo,
      nextPageToken: data.nextPageToken,
    };
    let list = data.items
      .filter(
        (item) =>
          item.status.privacyStatus === YOUTUBE_UPLOADING.STATUS_PRIVACY.PUBLIC
      )
      .map((item) => ({
        id: item.contentDetails.videoId,
        cover_image_url: item.snippet.thumbnails.high.url || "",
      }));

    const stateTemp = {
      ...state,
      getYoutubeVideoListStatus: SUCCEEDED,
      youTubeVideoData: {
        pageInfo,
        list,
      },
    };

    return {
      ...stateTemp,
    };
  }
};

const getYoutubeVideoListFailed = (state, { payload = DEFAULT_ERROR }) => {
  if (payload) {
    return {
      ...state,
      getYoutubeVideoListStatus: FAILED,
      error: DEFAULT_GETTING_YOUTUBE_VIDEO_ERROR,
      youTubeVideoData: null,
      youTubeChannelId: null,
      playlistId: null,
    };
  }

  return {
    ...state,
    getYoutubeVideoListStatus: FAILED,
  };
};

const getYoutubeVideoListEnd = (state) => {
  return {
    ...state,
    getYoutubeVideoListStatus: NONE,
  };
};

const storeGoogleDataRequest = (state, { payload }) => {
  return {
    ...state,
    ...payload,
  };
};

const googleReducers = handleActions(
  {
    [getGoogleTokenInformationTypes.GET_GOOGLE_TOKEN_INFORMATION_REQUEST]:
      getGoogleTokenInformationRequest,
    [getGoogleTokenInformationTypes.GET_GOOGLE_TOKEN_INFORMATION_SUCCEEDED]:
      getGoogleTokenInformationSucceeded,
    [getGoogleTokenInformationTypes.GET_GOOGLE_TOKEN_INFORMATION_FAILED]:
      getGoogleTokenInformationFailed,
    [getGoogleTokenInformationTypes.GET_GOOGLE_TOKEN_INFORMATION_END]:
      getGoogleTokenInformationEnd,

    [refreshOAuthGoogleTokenTypes.REFRESH_OAUTH_GOOGLE_TOKEN_REQUEST]:
      refreshOAuthGoogleTokenRequest,
    [refreshOAuthGoogleTokenTypes.REFRESH_OAUTH_GOOGLE_TOKEN_SUCCEEDED]:
      refreshOAuthGoogleTokenSucceeded,
    [refreshOAuthGoogleTokenTypes.REFRESH_OAUTH_GOOGLE_TOKEN_FAILED]:
      refreshOAuthGoogleTokenFailed,
    [refreshOAuthGoogleTokenTypes.REFRESH_OAUTH_GOOGLE_TOKEN_END]:
      refreshOAuthGoogleTokenEnd,

    [getYoutubeVideoListTypes.GET_YOUTUBE_VIDEO_LIST_REQUEST]:
      getYoutubeVideoListRequest,
    [getYoutubeVideoListTypes.GET_YOUTUBE_VIDEO_LIST_SUCCEEDED]:
      getYoutubeVideoListSucceeded,
    [getYoutubeVideoListTypes.GET_YOUTUBE_VIDEO_LIST_FAILED]:
      getYoutubeVideoListFailed,
    [getYoutubeVideoListTypes.GET_YOUTUBE_VIDEO_LIST_END]:
      getYoutubeVideoListEnd,

    [storeGoogleDataTypes.STORE_GOOGLE_DATA_REQUEST]: storeGoogleDataRequest,
  },
  initialState
);

export default googleReducers;
