import React from "react";
import { Switch } from "react-router-dom";
import Route from "./Route";
import PropTypes from "prop-types";

import NotFound from "../components/pages/NotFound/NotFound";
import Custom from "../components/widgets/Custom/Custom";
import WidgetBuilder from "../components/widgets/WidgetBuilder/WidgetBuilder";

Routes.propTypes = {
  globalProps: PropTypes.shape({
    setIsShowLoading: PropTypes.func,
    requestAPI: PropTypes.func,
    didMountCallback: PropTypes.func,
  }),
};

export default function Routes(props) {
  const { globalProps } = props;
  /** Detect page change, maybe use later */

  return (
    <Switch>
      {/* Custom */}
      <Route
        path="/:clientName"
        component={() => <Custom globalProps={globalProps} />}
      />
      {/* Default */}
      <Route
        path="/"
        component={() => <WidgetBuilder globalProps={globalProps} />}
      />
      {/* Others */}
      <Route
        path="/404"
        component={() => <NotFound globalProps={globalProps} />}
      />
      {/* redirect user to SignIn page if route does not exist and user is not authenticated */}
      <Route component={() => <NotFound globalProps={globalProps} />} />
    </Switch>
  );
}
